import { Grid, Typography, useTheme } from '@mui/material';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { howWhyRanksDataState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../../react-i18next/localization-context';

export const EpsWidget: React.FC = () => {
  const theme = useTheme();
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  const { t } = React.useContext(LocalizationContext);
  if (!howWhyRanksData.how) {
    return <>No quote data</>;
  }
  const quote = howWhyRanksData.how.quote;
  const { eps, peRatio } = quote;

  //TODO: embedder shown for embedder only in div/yield
  // const getExDividendsDate = () => {
  //   //TODO: Why should return date datatype.
  //   const exDividentDateString = howWhyRanksData.why?.exDividends?.exDividendDate;
  //   if (!exDividentDateString) {
  //     return formatting.NOT_AVAILABLE;
  //   }
  //   const date = DateTimeHelper.resolveDate(exDividentDateString);
  //   const days = DateTimeHelper.daysFromNow(date);
  //   const dateString = DateTimeHelper.format(date);
  //   return `${dateString} (${days})`;
  // };

  return (
    <Grid container columnSpacing={1}>
      <Grid
        item
        xs={6}
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderRight: `1px solid ${theme.palette.divider}`, pl: 1, pr: 1 }}>
        <Grid item>
          <Typography variant={'captionSmall'}>{t('app.php.why.labels.eps')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'captionSmall'} lineHeight={1}>
            <strong>{NumberFormatHelper.toCurrency(eps)}</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant={'captionSmall'}>{t('app.php.why.labels.pe')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'captionSmall'} component="span">
            <strong>{NumberFormatHelper.toFractionalString(peRatio)}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
