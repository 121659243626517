import { Grid, InputAdornment, Typography, useTheme } from '@mui/material';
import {
  isPortfolioCombinationsLoadingState,
  isSelectedRecommendedStateOpenState,
  portfolioAccountsWithoutCombinationsState,
  portfolioManagementSelectedCombinationState,
  portfolioSelectedAccountIdsSelectorState,
  portfolioSelectedEntityState,
  portfoliosDataState,
  processPortfolioUrl,
} from '@op/shared/src/states/portfolio-states';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  AccountSelectIconItemWidget,
  MenuProps,
  OPSelectDropDownHeader,
  OPSelectField,
  StyledDropdownItemWithBorder,
} from '../styled';

import { PortfolioCombinationEntity, unique } from '@op/shared/src/models';
import { tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import { isDataLoadingState, subViewState, tradeSimulatorOperationTypeState, viewState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import {
  portfolioManagementCombinationsUpdaterState,
  selectedRecommendationState,
} from '@op/shared/src/states/portfolio-management-states';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { IconComponent } from '../common';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';

export const PortfolioAccountsFilterWidget: React.FC = () => {
  const portfolioWithoutCombinations = useRecoilValue(portfolioAccountsWithoutCombinationsState);
  const isPortfolioCombinationsLoading = useRecoilValue(isPortfolioCombinationsLoadingState);
  const [portfolioSelectedAccountIds, setPortfolioSelectedAccountIds] = useRecoilState(
    portfolioSelectedAccountIdsSelectorState,
  );
  const [selectedPortfolioIds, setSelectedPortfolioIds] = useState<number[]>([]);
  const debouncedOnPortfolioChange = useMemo(() => debounce(setPortfolioSelectedAccountIds, 1000), []);
  const [isOpen, setIsOpen] = useState(false);
  const [isGlobalSelected, setIsGlobalSelected] = useState(false);
  const [isLocalSected, setIsLocalSelected] = useState(false);
  const theme = useTheme();

  // To enable URL search
  const [searchParams] = useSearchParams();
  const setNotifications = useSetRecoilState(notificationsState);
  const portfoliosData = useRecoilValue(portfoliosDataState);
  const fetchAndSetData = useFetchAndSetData();
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setView = useSetRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const [isDataLoading, setIsDataLoading] = useRecoilState(isDataLoadingState);
  const resetPortfolioManagementSelectedCombination = useResetRecoilState(portfolioManagementSelectedCombinationState);
  const resetPortfolioManagementSelectedEntity = useResetRecoilState(portfolioSelectedEntityState);
  const resetSelectedRecommendation = useResetRecoilState(selectedRecommendationState);
  const resetportfolioManagementCombinationsUpdate = useResetRecoilState(portfolioManagementCombinationsUpdaterState);
  const setportfolioSelectedEntity = useSetRecoilState(portfolioSelectedEntityState);
  const setIsSelectedRecommendedStateOpen = useSetRecoilState(isSelectedRecommendedStateOpenState);

  const Select_All_Global_key = -1;
  const Select_All_Personal_key = -1;

  useEffect(() => {
    if (!portfolioWithoutCombinations || portfolioWithoutCombinations.length === 0) {
      return;
    }
    // URL search params operation
    const searchWatchListId = searchParams.get('id');
    const searchSymbol = searchParams.get('symbol');
    if (searchWatchListId !== null && searchSymbol !== null) {
      const urlData = processPortfolioUrl(portfoliosData, searchWatchListId, searchSymbol);
      if (!urlData) {
        setNotifications([{ type: 'error', content: 'Unknown error occurred' }]);
      }
      if (urlData) {
        setSelectedPortfolioIds([urlData.id]);
        setPortfolioSelectedAccountIds([urlData.id]);
        urlData.entity && onPortfolioSelected(urlData.entity);
        return;
      }
    }
    let ids: number[] = [];
    if (portfolioSelectedAccountIds && portfolioSelectedAccountIds.length > 0) {
      ids = portfolioSelectedAccountIds;
    } else {
      const personalIds = portfolioWithoutCombinations.filter((p) => !p.isGlobal).map((p) => p.portfolioId);
      ids =
        personalIds && personalIds.length > 0 ? personalIds : portfolioWithoutCombinations.map((p) => p.portfolioId);
    }
    setSelectedPortfolioIds(ids);
    setPortfolioSelectedAccountIds(ids);
  }, [portfolioWithoutCombinations]);

  // to select the symbol in selected portfolio account from URl params
  const onPortfolioSelected = async (entity: PortfolioCombinationEntity) => {
    if (isDataLoading) {
      return false;
    }
    setIsDataLoading(true);
    const symbol = entity.symbol.trim().toUpperCase();
    const isSucessful = await fetchAndSetData(symbol, undefined);
    if (!isSucessful) {
      setIsDataLoading(false);
      return false;
    }
    setSubView(undefined);
    setView('portfolioManagement');
    setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
    resetPortfolioManagementSelectedCombination();
    resetPortfolioManagementSelectedEntity();
    resetSelectedRecommendation();
    resetportfolioManagementCombinationsUpdate();
    setportfolioSelectedEntity(entity);
    setIsSelectedRecommendedStateOpen(false);
    setIsDataLoading(false);
    return true;
  };

  const onPortfolioChange = (portfolioIds: number[]) => {
    if (isPortfolioCombinationsLoading) {
      return;
    }
    if (portfolioIds.includes(-1) || portfolioIds.includes(-2)) {
      return;
    }
    setSelectedPortfolioIds(portfolioIds);
    debouncedOnPortfolioChange(portfolioIds);
  };

  const clearFilter = () => {
    setSelectedPortfolioIds([]);
    debouncedOnPortfolioChange([]);
  };

  if (!portfolioWithoutCombinations) {
    return null;
  }

  const getAllPortfolioIds = (value: boolean) => {
    return portfolioWithoutCombinations
      .filter((portfolio) => portfolio.isGlobal === value)
      .map((portfolio) => portfolio.portfolioId);
  };

  const getCombinationsLength = (isGlobal: boolean) => {
    let totalSum = 0;
    portfolioWithoutCombinations
      .filter((portfolio) => portfolio.isGlobal === isGlobal)
      .map((portfolio) => (totalSum += portfolio.totalCombinations));
    return totalSum;
  };

  const onClickAllGlobal = (value: boolean) => {
    const globalIds = getAllPortfolioIds(true);
    let allPortfolioIds: number[];
    if (value) {
      allPortfolioIds = selectedPortfolioIds.filter((s) => !globalIds.includes(s));
    } else {
      allPortfolioIds = selectedPortfolioIds.concat(globalIds.filter((item) => selectedPortfolioIds.indexOf(item) < 0));
    }
    allPortfolioIds = unique(allPortfolioIds);
    setSelectedPortfolioIds(allPortfolioIds);
    setIsGlobalSelected(!value);
    debouncedOnPortfolioChange(allPortfolioIds);
  };

  const onClickAllLocal = (value: boolean) => {
    const localIds = getAllPortfolioIds(false);
    let allPortfolioIds: number[];
    if (value) {
      allPortfolioIds = selectedPortfolioIds.concat(localIds.filter((item) => selectedPortfolioIds.indexOf(item) < 0));
    } else {
      allPortfolioIds = selectedPortfolioIds.filter((s) => !localIds.includes(s));
    }
    allPortfolioIds = unique(allPortfolioIds);
    setSelectedPortfolioIds(allPortfolioIds);
    setIsLocalSelected(!value);
    debouncedOnPortfolioChange(allPortfolioIds);
  };

  const checkArraySame = (value: boolean) => {
    const localIds = getAllPortfolioIds(value);
    return localIds.every((val) => {
      return selectedPortfolioIds.indexOf(val) >= 0;
    });
  };

  const renderGridItem = (
    isGlobal: boolean,
    portfolioName: string,
    totalCombinations: number,
    isSelected: boolean,
    portfolioType: string,
  ) => {
    return (
      <Grid container justifyContent={'space-between'} alignItems="center">
        <Grid item xs={1.5}>
          <AccountSelectIconItemWidget
            isGlobal={isGlobal}
            isSelected={isSelected}
            portfolioTypeStatus={portfolioType}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography className={isGlobal ? 'global-label-value' : 'local-label-value'}>
            {portfolioName.trim()}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography className={isGlobal ? 'global-count-value' : 'person-count-value'}>
            {totalCombinations}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderCustomItems = (
    isGlobal: boolean,
    portfolioName: string,
    totalCombinations: number,
    isSelected: boolean,
  ) => {
    return (
      <Grid container justifyContent={'space-between'} alignItems="center">
        <Grid item xs={1.5}>
          <AccountSelectIconItemWidget
            isGlobal={isGlobal}
            isSelected={isSelected && totalCombinations > 0}
            portfolioTypeStatus=""
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{
              color:
                isSelected && isGlobal && totalCombinations > 0
                  ? theme.palette.success.main
                  : isSelected && !isGlobal && totalCombinations > 0
                  ? theme.palette.primary.main
                  : 'inherit',
            }}>
            {portfolioName.trim()}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography
            style={{
              color:
                isSelected && isGlobal && totalCombinations > 0
                  ? '#ffffff'
                  : isSelected && !isGlobal && totalCombinations > 0
                  ? '#ffffff'
                  : 'inherit',
              backgroundColor:
                isSelected && isGlobal && totalCombinations > 0
                  ? theme.palette.success.main
                  : isSelected && !isGlobal && totalCombinations > 0
                  ? theme.palette.primary.main
                  : 'inherit',
              borderRadius: theme.shape.borderRadius,
              textAlign: 'center',
              border: `1px solid ${theme.palette.grey[500]}`,
            }}>
            {totalCombinations}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <OPSelectField
      id="porfolio-accounts-filter-widget"
      variant="outlined"
      multiple
      value={selectedPortfolioIds}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderValue={(selected) => {
        if (!portfolioWithoutCombinations) {
          return '';
        }
        let names = [];
        for (let id of selected as number[]) {
          for (let p of portfolioWithoutCombinations) {
            if (p.portfolioId === id) {
              names.push(p.portfolioName);
            }
          }
        }
        return names.join(', ');
      }}
      startAdornment={
        <InputAdornment position="start">
          {isOpen ? (
            <IconComponent name="portfolioOpen" stroke={theme.palette.primary.main} />
          ) : (
            <IconComponent name="portfolioClosed" stroke={theme.palette.primary.main} />
          )}
        </InputAdornment>
      }
      onChange={(event) => onPortfolioChange(event.target.value as number[])}
      fullWidth
      MenuProps={MenuProps}>
      <OPSelectDropDownHeader type="" onClearFilter={clearFilter} />
      <StyledDropdownItemWithBorder
        color="default"
        key={Select_All_Global_key}
        value={Select_All_Global_key}
        onClick={() => onClickAllGlobal(isGlobalSelected)}>
        {renderCustomItems(true, 'Select All Global', getCombinationsLength(true), checkArraySame(true))}
      </StyledDropdownItemWithBorder>
      <StyledDropdownItemWithBorder
        color="default"
        key={Select_All_Personal_key}
        value={Select_All_Personal_key}
        onClick={() => onClickAllLocal(isLocalSected)}>
        {renderCustomItems(false, 'Select All Personal', getCombinationsLength(false), checkArraySame(false))}
      </StyledDropdownItemWithBorder>
      {portfolioWithoutCombinations?.map((item) => {
        let isSelected = selectedPortfolioIds.indexOf(item.portfolioId) > -1;
        return (
          <StyledDropdownItemWithBorder
            color="default"
            key={item.portfolioId}
            value={item.portfolioId}
            selected={isSelected}>
            {renderGridItem(
              item.isGlobal,
              item.portfolioName,
              item.totalCombinations,
              isSelected,
              item.portfolioTypeStatus,
            )}
          </StyledDropdownItemWithBorder>
        );
      })}
    </OPSelectField>
  );
};
