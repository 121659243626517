import React, { useState } from 'react';

import { ReactComponent as CoveredCallIcon } from '@op/shared/assets/images/coveredCallIcon.svg';
import { ReactComponent as CreditSpreadIcon } from '@op/shared/assets/images/creditSpreadIcon.svg';
import { ReactComponent as HomelandIcon } from '@op/shared/assets/images/HomelandIcon.svg';
import { ReactComponent as OfficelandIcon } from '@op/shared/assets/images/OfficelandIcon.svg';
import { ReactComponent as TechnicalIdeasIcon } from '@op/shared/assets/images/technicalIdeasIcon.svg';
import { ReactComponent as addIcon } from '@op/shared/assets/images/addIcon.svg';
import { ReactComponent as blueFilledAddIcon } from '@op/shared/assets/images/blueFilledAddIcon.svg';
import { ReactComponent as cancel } from '@op/shared/assets/images/cancel.svg';
import { ReactComponent as cancelLoader } from '@op/shared/assets/images/cancel-loader.svg';
import { ReactComponent as congratulation } from '@op/shared/assets/images/congratulations.svg';
import { ReactComponent as congratulationDark } from '@op/shared/assets/images/congratulations-dark.svg';
import { ReactComponent as creditSpread } from '@op/shared/assets/images/credit-spread.svg';
import { ReactComponent as defaultAddIcon } from '@op/shared/assets/images/defaultAddIcon.svg';
import { ReactComponent as deleteIcon } from '@op/shared/assets/images/deletedIcon.svg';
import { ReactComponent as downloadExcelIcon } from '@op/shared/assets/images/download-excel-icon.svg';
import { ReactComponent as downloadExcelSuccessIcon } from '@op/shared/assets/images/download-excel-success-icon.svg';
import { ReactComponent as editIcon } from '@op/shared/assets/images/editIcon.svg';
import { ReactComponent as filterIcon } from '@op/shared/assets/images/filter-icon.svg';
import { ReactComponent as flagIcon } from '@op/shared/assets/images/flagIcon.svg';
import { ReactComponent as globalIcon } from '@op/shared/assets/images/globalIcon.svg';
import { ReactComponent as globalPortfolio } from '@op/shared/assets/images/globalPortfolio.svg';
import { ReactComponent as hoverPersonalPortfolioIcon } from '@op/shared/assets/images/hover-personal-portfolio-icon.svg';
import { ReactComponent as infoIcon } from '@op/shared/assets/images/info-icon.svg';
import { ReactComponent as mobileWithWatches } from '@op/shared/assets/images/mobileWithWatches.svg';
import { ReactComponent as newFeed } from '@op/shared/assets/images/newFeed.svg';
import { ReactComponent as newsHover } from '@op/shared/assets/images/newsHover.svg';
import { ReactComponent as personalPortfolioIcon } from '@op/shared/assets/images/personal-portfolio-icon.svg';
import { ReactComponent as personalWatchlistIcon } from '@op/shared/assets/images/personal-watchlist-icon.svg';
import { ReactComponent as pinActive } from '@op/shared/assets/images/pinActive.svg';
import { ReactComponent as pinInactive } from '@op/shared/assets/images/pinInactive.svg';
import { ReactComponent as plusAddPositionTo } from '@op/shared/assets/images/plusAddPositionTo.svg';
import { ReactComponent as portfolioClosed } from '@op/shared/assets/images/portfolioClosed.svg';
import { ReactComponent as portfolioOpen } from '@op/shared/assets/images/portfolioOpen.svg';
import { ReactComponent as searchIcon } from '@op/shared/assets/images/searchIcon.svg';
import { ReactComponent as sliderOpenIcon } from '@op/shared/assets/images/sliderOpenIcon.svg';
import { ReactComponent as sortBy } from '@op/shared/assets/images/sort-by.svg';
import { ReactComponent as srClose } from '@op/shared/assets/images/srClose.svg';
import { ReactComponent as srOpen } from '@op/shared/assets/images/srOpen.svg';
import { ReactComponent as tradeHover } from '@op/shared/assets/images/tradeHover.svg';
import { ReactComponent as tradeIdea } from '@op/shared/assets/images/tradeIdea.svg';
import { ReactComponent as tradeIdeaDisabled } from '@op/shared/assets/images/tradeIdeaDisabled.svg';
import { ReactComponent as trendingDown } from '@op/shared/assets/images/TrendingDown.svg';
import { ReactComponent as trendingNeutral } from '@op/shared/assets/images/TrendingNeutral.svg';
import { ReactComponent as trendingUp } from '@op/shared/assets/images/TrendingUp.svg';
import { ReactComponent as userIcon } from '@op/shared/assets/images/userIcon.svg';
import { ReactComponent as visaCardIcon } from '@op/shared/assets/images/visaCardIcon.svg';
import { ReactComponent as warning } from '@op/shared/assets/images/warning.svg';
import { ReactComponent as watchlistIcon } from '@op/shared/assets/images/watchlistIcon.svg';
import { ReactComponent as SCGreen } from '@op/shared/assets/images/scGreen.svg';
import { ReactComponent as SCRed } from '@op/shared/assets/images/scRed.svg';
import { ReactComponent as SCWarning } from '@op/shared/assets/images/scWarning.svg';
import { ReactComponent as optionsGridWarning } from '@op/shared/assets/images/option-grid-warning.svg';
import { ReactComponent as optionsGridTick } from '@op/shared/assets/images/option-grid-tick.svg';
import { ReactComponent as telegramIcon } from '@op/shared/assets/images/telegram-icon.svg';
import { ReactComponent as emailIcon } from '@op/shared/assets/images/email-icon.svg';
import { ReactComponent as twitterIcon } from '@op/shared/assets/images/twitter-icon.svg';
import { ReactComponent as stockTwitsIcon } from '@op/shared/assets/images/stock-twits-icon.svg';
import { ReactComponent as linkIcon } from '@op/shared/assets/images/link-icon.svg';

type IconComponentProps = {
  name: string;
  fill?: string;
  stroke?: string;
  size?: number;
  width?: number | string;
  height?: number | string;
  strokeWidth?: number;
  style?: any;
  hoverColor?: string;
  hoverIcon?: string;
  onClick?: () => void;
};
type IconTypes = { [name: string]: React.FC<React.SVGProps<SVGElement>> };

const iconTypes: any = {
  userIcon,
  globalIcon,
  deleteIcon,
  flagIcon,
  searchIcon,
  watchlistIcon,
  portfolioClosed,
  portfolioOpen,
  pinActive,
  pinInactive,
  newFeed,
  newsHover,
  tradeIdea,
  tradeHover,
  sliderOpenIcon,
  srOpen,
  srClose,
  personalPortfolioIcon,
  globalPortfolio,
  hoverPersonalPortfolioIcon,
  trendingUp,
  trendingDown,
  trendingNeutral,
  infoIcon,
  filterIcon,
  personalWatchlistIcon,
  CoveredCallIcon,
  CreditSpreadIcon,
  TechnicalIdeasIcon,
  editIcon,
  congratulation,
  visaCardIcon,
  mobileWithWatches,
  plusAddPositionTo,
  addIcon,
  defaultAddIcon,
  blueFilledAddIcon,
  cancel,
  warning,
  OfficelandIcon,
  HomelandIcon,
  congratulationDark,
  cancelLoader,
  tradeIdeaDisabled,
  downloadExcelIcon,
  sortBy,
  creditSpread,
  downloadExcelSuccessIcon,
  SCGreen,
  SCRed,
  SCWarning,
  optionsGridWarning,
  optionsGridTick,
  telegramIcon,
  emailIcon,
  twitterIcon,
  stockTwitsIcon,
  linkIcon,
};

export const IconComponent = ({ name, ...props }: IconComponentProps) => {
  const [hover, setHover] = useState(false);

  let Icon = hover && props.hoverIcon ? iconTypes[props.hoverIcon] : iconTypes[name];
  let cWidth = props.size ? props.size : props.width;
  let cHeight = props.size ? props.size : props.height;
  let styleOBJ = {
    ...props.style,
    width: cWidth,
    height: cHeight,
    stroke: hover && props.hoverColor ? props.hoverColor : props.stroke,
  };
  return (
    <Icon
      {...props}
      style={styleOBJ}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.onClick}
    />
  );
};
