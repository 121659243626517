import { atom } from 'recoil';
import { Scan, Sector, SortingType } from '@op/shared/src/models';
import { SentimentType } from '@op/shared/src/models';

export const sortState = atom({
  key: 'sortState',
  default: SortingType.ASCENDING,
});

export const sentimentState = atom({
  key: 'sentimentState',
  default: SentimentType.ALL,
});

export const sectorState = atom<Sector>({
  key: 'sectorState',
  default: Sector.empty(),
});

export const scanState = atom<Scan>({
  key: 'scanState',
  default: Scan.empty(),
});
