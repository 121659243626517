import { Avatar, Typography, useTheme } from '@mui/material';
import { CurrencyCode, Exchange } from '@op/shared/src/models/enums/enums';

import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';

interface CurrencyWidgetProps {
  exchange: string | undefined;
}

export const CurrencyWidget: React.FC<CurrencyWidgetProps> = ({ exchange }: CurrencyWidgetProps) => {
  const customization = useRecoilValue(customizationState);
  const theme = useTheme();
  const styleObj = { height: 18, borderRadius: theme.shape, backgroundColor: theme.palette.primary.main };

  if (!exchange || exchange.trim() === '') {
    return null;
  }

  if (!customization.showCurrencyOrExchangeFlags) {
    return null;
  }

  const getCurrency = (value: string) => {
    switch (value) {
      case Exchange.XSTO: // Stockholm
      case Exchange.INDXSTO:
        return (
          <Avatar alt="currency" variant={'square'} sx={styleObj}>
            <Typography variant="caption">{CurrencyCode.Stockholm}</Typography>
          </Avatar>
        );
      case Exchange.XCSE:
      case Exchange.INDXCSE: // Copenhegan
        return (
          <Avatar alt="currency" variant={'square'} sx={styleObj}>
            <Typography variant="caption">{CurrencyCode.Copenhagen}</Typography>
          </Avatar>
        );
      case Exchange.XHEL:
      case Exchange.INDXHEL: // Helsinki
        return (
          <Avatar alt="currency" variant={'square'} sx={styleObj}>
            <Typography variant="caption">{CurrencyCode.Helsinki}</Typography>
          </Avatar>
        );
      case Exchange.XTSE: // Canada
        return (
          <Avatar alt="currency" variant={'square'} sx={styleObj}>
            <Typography variant="caption">{CurrencyCode.Canada}</Typography>
          </Avatar>
        );
      default:
        return (
          <Avatar alt="currency" variant={'square'} sx={styleObj}>
            <Typography variant="caption">{CurrencyCode.US}</Typography>
          </Avatar>
        );
    }
  };

  return getCurrency(exchange);
};
