import { ICurrentSubscription, SecurityModel } from '../models';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { ApiService } from './api-service';

export const getPaymentPlans = async (): Promise<ResponseViewModel<ICurrentSubscription>> => {
  const url = '/payments/getPaymentPlans';
  const response = await ApiService.instance.get<ICurrentSubscription>(url);
  const vm = new ResponseViewModel<ICurrentSubscription>();
  vm.data = response.data as unknown as ICurrentSubscription;
  vm.errors = response.errors;
  return vm;
};

export const addSubscription = async (request: any): Promise<ResponseViewModel<SecurityModel | null>> => {
  const url = '/payments/addSubscription';
  const response = await ApiService.instance.put<any, SecurityModel>(url, request);
  const editReponse = SecurityModel.fromJson(response.data); //SecurityModel.fromSubscriptionData(response as any);
  const vm = new ResponseViewModel<SecurityModel>();
  vm.data = editReponse as unknown as SecurityModel;
  vm.errors = response.errors;
  return vm;
};

export const changeSubscriptionInformation = async (request: any): Promise<ResponseViewModel<SecurityModel | null>> => {
  const url = '/payments/changeInformation';
  const response = await ApiService.instance.post<any, SecurityModel>(url, request);
  const editReponse = SecurityModel.fromSubscriptionData(response as any);
  const vm = new ResponseViewModel<SecurityModel>();
  vm.data = editReponse as unknown as SecurityModel;
  vm.errors = response.errors;
  return vm;
};

export const cancelsubscription = async (request: any): Promise<ResponseViewModel<SecurityModel | null>> => {
  const url = '/payments/cancelSubscription';
  const response = await ApiService.instance.post<any, SecurityModel>(url, request);
  const editReponse = SecurityModel.fromSubscriptionData(response as any);
  const vm = new ResponseViewModel<SecurityModel>();
  vm.data = editReponse as unknown as SecurityModel;
  vm.errors = response.errors;
  return vm;
};
