import { Grid, Skeleton, Typography } from '@mui/material';
import { IData } from '@op/shared/src/models';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { PortfolioPosition } from '@op/shared/src/models/portfolio/portfolio-position-model';
import React from 'react';
import { ToExtendedChange2 } from '../common/to-extended-change';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPLightTypograpghy } from '../styled';

export interface IPositionFooterSec1Props extends IData<PortfolioPosition> {
  hasQuotes: boolean;
}

export const PortfolioCombinationPositionsWidget: React.FC<IPositionFooterSec1Props> = ({
  data,
  hasQuotes,
}: IPositionFooterSec1Props) => {
  const { t } = React.useContext(LocalizationContext);
  const positionType = data.positionName();
  const price = data.price();
  return (
    <Grid container className="js-position-container" sx={{ borderBottom: '1px dashed grey', pb: 1, px: 2 }}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight="bold" className="js-porfolio-accounts-positionType">
          {positionType}
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={6} container className="js-porfolio-accounts-unrealized-percentage">
          <OPLightTypograpghy sx={{ pr: 1 }}>{t('app.php.what.portfolio.labels.unrealizedPL')}</OPLightTypograpghy>
          <OPBoldTypograpghy>
            <ToExtendedChange2
              change={data.unrealizedProfitAndLoss()}
              percentageChange={data.unrealizedProfitAndLossPercentage()}
              asIntFractional={true}
              numberOfFractionalDigits={0}
              language="en-US"
              showCurrencySymbolForNumbers={true}
            />
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2} container className="js-porfolio-accounts-mark" alignItems={'center'}>
          <OPLightTypograpghy sx={{ pr: 1 }}>{t('app.php.what.portfolio.labels.mark')}</OPLightTypograpghy>
          {price === 0 && hasQuotes ? (
            <Skeleton animation="wave" variant="rectangular" height={15} width={50} />
          ) : (
            <OPBoldTypograpghy>{hasQuotes ? NumberFormatHelper.toFractionalString(price) : 'N/A'}</OPBoldTypograpghy>
          )}
        </Grid>
        <Grid item xs={4} container className="js-porfolio-accounts-cost-basis" justifyContent={'flex-end'}>
          <OPLightTypograpghy sx={{ pr: 1 }}>{t('app.php.common.labels.costBasis')}</OPLightTypograpghy>
          <OPBoldTypograpghy>{NumberFormatHelper.toFractionalString(data.costBasis)}</OPBoldTypograpghy>
        </Grid>
      </Grid>
    </Grid>
  );
};
