import { Button, Container, Grid, Link, Paper, TextField, Typography, useTheme } from '@mui/material';
import { EditForgotPassword } from '@op/shared/src/models';
import { AuthenticationService, ProfileDetailsService } from '@op/shared/src/services';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { LogoWidget } from '../header';
import LocalizationContext from '../react-i18next/localization-context';
import { LoginSignupContainer } from './login-widget';
import { useOPNavigate } from '../states/use-navigate-search';

export const ForgotPasswordWidget: React.FC = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const temp = { email: '' };
  const [fields, setFields] = useState(new EditForgotPassword());
  const [errors, setErrors] = useState<any>({});
  const { t } = React.useContext(LocalizationContext);
  useEffect(() => {
    document.title = 'Forgot Password | OptionsPlay';
  }, []);
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );
  const theme = useTheme();

  const navigate = useOPNavigate();
  const [isVerified, setIsVerified] = useState(false);

  const onSignIn = async (): Promise<void> => {
    navigate('/login');
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };
  const handleResetPassword = async (): Promise<void> => {
    if (!fields.email) {
      temp.email = 'Email Address is required';
      setErrors({ ...temp });
    } else if (!pattern.test(fields.email)) {
      temp.email = 'Email Address is Invalid';
      setErrors({ ...temp });
    } else {
      (async () => {
        if (pattern.test(fields.email)) {
          try {
            const editResponse = await ProfileDetailsService.instance.ForgotPassword(fields);
            if (editResponse) {
              setNotifications([
                { type: 'success', content: 'Reset Password instructions have been successfully sent to your email' },
              ]);
              onSignIn();
            }
          } catch (error) {
            setNotifications([{ type: 'error', content: 'Reset Password failed!' }]);
          }
        }
      })();
    }
  };
  const handleEmail = () => {
    (async () => {
      if (pattern.test(fields.email)) {
        const response = await AuthenticationService.instance.canUseEmail(fields.email);
        if (!response) {
          setIsVerified(true);
        }
        temp.email = '';
        setErrors({ ...temp });
      } else {
        temp.email = 'Please enter a proper email address';
        setErrors({ ...temp });
      }
    })();
  };

  return (
    //TODO:- need to do one more level of refactor
    <LoginSignupContainer
      direction="column"
      alignItems="center"
      container
      justifyContent="center"
      sx={{ height: '100vh' }}>
      <Grid item>
        <Paper sx={{ py: 4, px: 3 }}>
          <Container
            sx={{
              py: 2,
              textAlign: 'center',
            }}
            maxWidth="xs">
            <Grid container xs={12}>
              <Grid item container rowSpacing={1} justifyContent="center">
                <Grid item xs={12}>
                  <LogoWidget direction="row" />
                </Grid>
                <Grid item sx={{ py: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                    {t('app.php.password.forgot.text.forgotYourPassword')}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                    {t('app.php.password.forgot.text.forgotYourPasswordText')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('app.php.common.inputs.email')}
                    variant="outlined"
                    id="email"
                    name="email"
                    sx={{ width: 380 }}
                    onChange={handleChange}
                    onBlur={handleEmail}
                    value={fields.email}
                    helperText={<span>{errors.email}</span>}
                    error={errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    sx={{
                      '&:hover': {
                        background: theme.palette.success.main,
                      },
                      width: 380,
                      height: 60,
                      backgroundColor: theme.palette.success.main,
                    }}
                    onClick={handleResetPassword}>
                    <Typography variant="button">{t('app.php.password.forgot.buttons.send')}</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Paper>
        <Grid item container>
          <Grid item xs={12} sx={{ textAlign: 'center', cursor: 'pointer', pt: 1 }}>
            <Link variant="body1" sx={{ color: theme.palette.background.default }} onClick={onSignIn}>
              {t('app.php.password.forgot.buttons.backToSignIn')}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </LoginSignupContainer>
  );
};
