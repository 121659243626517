import { Grid } from '@mui/material';
import { IData, PortfolioCombinationEntity } from '@op/shared/src/models';
import { isHubInitiatedState, portfolioSelectedAccountIdsSelectorState } from '@op/shared/src/states';
import {
  subscribePortfolioAlerts,
  unsubscribePortfolioAlerts,
} from '@op/shared/src/states/portfolio-alerts-hub-states';
import React, { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useRecoilValue } from 'recoil';
import { PortfolioCombinationWidget } from './portfolio-combination-widget';
import { PortfolioCombinationLoaderWidget } from './portfolio-what-loader-widget';
import { HeightWidget } from '../routes';

export interface IPortfolioCombinationWidgetProps extends IData<PortfolioCombinationEntity[]> {
  loadMoreCallback: (startIndex: number) => Promise<PortfolioCombinationEntity[]>;
  hasNextPage: boolean;
}

export const PortfolioCombinationsWidget: React.FC<IPortfolioCombinationWidgetProps> = ({
  data,
  loadMoreCallback,
  hasNextPage,
}: IPortfolioCombinationWidgetProps) => {
  const [combiantionEntities, setCombiantionEntities] = useState<PortfolioCombinationEntity[]>([]);
  const isHubInitialized = useRecoilValue(isHubInitiatedState);
  const portfolioSelectedAccountIds = useRecoilValue(portfolioSelectedAccountIdsSelectorState);

  useEffect(() => {
    if (!data || data.length === 0) {
      //Need to check in web
      setCombiantionEntities([]);
      return;
    }
    setCombiantionEntities(data);
  }, [data]);

  useEffect(() => {
    if (!isHubInitialized) {
      return;
    }
    (async () => {
      await subscribePortfolioAlerts(portfolioSelectedAccountIds);
    })();
    //Clearing function
    return () => {
      (async () => {
        await unsubscribePortfolioAlerts(portfolioSelectedAccountIds);
      })();
    };
  }, [isHubInitialized, portfolioSelectedAccountIds]);

  const loadMore = async () => {
    if (!data || data.length === 0) {
      return;
    }
    const newEntities = await loadMoreCallback(combiantionEntities.length);
    if (newEntities.length === 0) {
      setCombiantionEntities(combiantionEntities);
      return;
    }
    const slicedItems = combiantionEntities.concat(newEntities);
    setCombiantionEntities(slicedItems);
  };

  const loading = false;
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: false,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: `0px 0px 700px 0px`,
    delayInMs: undefined,
  });

  return (
    <HeightWidget height={700} sx={{ pb: 6 }}>
      <Grid container>
        {combiantionEntities.map((entity, i) => (
          <Grid container key={i}>
            <Grid item xs={12} key={i} id={`js-protfolio-accounts-combination-${i}`} py={0.6} pr={0}>
              <PortfolioCombinationWidget data={entity} index={i} />
            </Grid>
          </Grid>
        ))}
        {hasNextPage && (
          <Grid item xs={12} ref={sentryRef}>
            <PortfolioCombinationLoaderWidget />
          </Grid>
        )}
      </Grid>
    </HeightWidget>
  );
};
