export class Rule {
  ruleMatch = '';
  sentiment = '';
  period = -1;
  reason = '';
  sentence = '';

  static fromSelf = (data: Rule): Rule => {
    const rule = new Rule();
    rule.ruleMatch = data.ruleMatch;
    rule.sentiment = data.sentiment;
    rule.period = data.period;
    rule.reason = data.reason;
    rule.sentence = data.sentence;
    return rule;
  };
}
