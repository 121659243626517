import { OptionsPlayBreakpoints, fontSizeMediaQuery } from './options-play-breakpoints';
import { ThemeOptions } from '@mui/material';

//TODO: Extract common settings like Typography, shape, breakpoints
declare module '@mui/material/styles' {
  interface Palette {
    timeFrame: Palette['primary'];
    tolerance: Palette['primary'];
    collapsible: Palette['primary'];
    selectAndTextField: Palette['primary'];
    selection: Palette['primary'];
    globalSelect: Palette['primary'];
    personalSelect: Palette['primary'];
    legButtons: Palette['primary'];
    orangeButton: Palette['primary'];
    symbolCard: Palette['background'];
    header: Palette['background'];
    highChart?: Palette['primary'];
    slider?: Palette['primary'];
    optionGridDisabled?: Palette['primary'];
  }
  interface PaletteOptions {
    timeFrame?: PaletteOptions['primary'];
    tolerance?: PaletteOptions['primary'];
    collapsible?: PaletteOptions['primary'];
    selectAndTextField?: PaletteOptions['primary'];
    selection?: PaletteOptions['primary'];
    globalSelect: PaletteOptions['primary'];
    personalSelect: PaletteOptions['primary'];
    legButtons?: PaletteOptions['primary'];
    orangeButton?: PaletteOptions['primary'];
    symbolCard?: PaletteOptions['background'];
    header?: PaletteOptions['background'];
    highChart?: PaletteOptions['primary'];
    slider?: PaletteOptions['primary'];
    optionGridDisabled?: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    captionSmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    captionSmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionSmall: true;
  }
}

export const questradeLightTheme: ThemeOptions = {
  breakpoints: {
    values: OptionsPlayBreakpoints,
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#6FAE2F', // USED FOR PRIMARY COLOR
      light: '#D0E5EE', // USED FOR STYLED HEADER FOR SELECT COMPONENT
      dark: 'rgba(0, 0, 0, 0.54)', // In general used for the hover effect
    },
    secondary: {
      main: '#2D5C89',
      light: '#AABED2', // used for hover color and selected color
      dark: '#98AABC', // USED FOR BORDER COLOR FOR SELECT,TEXTFIELDS
    },
    background: {
      default: '#F1F4F5',
      paper: '#FFFFFF',
    },
    info: {
      main: '#DBDFE4',
      light: '#000000', // USED FOR DEFAULT ICON STROKE VALUE
      dark: '#F1F4F5', // Used for the heading background
    },
    success: {
      main: '#41A406', // SUCCESS COLOR
      light: '#b8e0b9',
    },
    error: {
      main: '#C01717', // ERROR COLOR
      light: '#ED004F',
    },
    warning: {
      main: '#F08F37', // WARNING COLOR FOR NEUTRAL
      light: 'rgb(223, 150, 65, 0.2)',
      dark: '#E6740E',
    },
    text: {
      primary: '#000000',
      secondary: '#3E5060', // USED FOR THE PLACEHOLDER COLOR
      disabled: '#3C679B', // USED FOR THE HIGHLIGHT OF PL GRAPH LINE
    },
    timeFrame: {
      light: '#DBEFC9',
      main: '#C2E4A2',
      dark: '#9DD36A',
      contrastText: '#000000',
    },
    tolerance: {
      light: '#F5CCCC',
      main: '#EEA8A8',
      dark: '#E06464',
      contrastText: '#000000',
    },
    collapsible: {
      main: '#FFFFFF',
      light: '#FFFFFF',
    },
    selectAndTextField: {
      light: '#FFFFFF',
      main: '#000000',
    },
    selection: {
      main: '#D3EBBD',
      light: 'inherit',
      dark: '#98AABC', // used for strategy-card border color
      contrastText: '#000000',
    },
    globalSelect: {
      main: '#000000',
      light: '#98AABC',
      dark: '#6FAE2F',
    },
    personalSelect: {
      main: '#000000',
      light: '#98AABC',
    },
    legButtons: {
      main: '#2D5C89', //stock
      light: '#6FAE2F', //call
      dark: '#C01717', //put
    },
    orangeButton: {
      main: 'inherit', // backgroundColr
      light: '#6FAE2F', //selected border Color
      dark: '#98AABC', //  Border color
    },
    symbolCard: {
      default: '#FFFFFF',
    },
    header: {
      default: '#F1F4F5',
    },
    highChart: {
      main: '#FFFFFFF2',
      light: '#FFFFFF',
      dark: '#585858',
      contrastText: '#000000',
    },
    slider: {
      main: '#C3C7CD',
    },
    optionGridDisabled: {
      main: '#D1D1D1',
      light: '#858585',
      contrastText: '#858585',
    },
    divider: '#969FA3',
  },
  typography: {
    captionSmall: {
      fontSize: '0.75rem',
      lineHeight: 2,
    },
    body1: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
    body2: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '&.MuiDateCalendar-root': {
          backgroundColor: '#FFFFFF',
        },
        '&.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root': {
          color: '#858585',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: '20px',
        },
        indicator: {
          backgroundColor: '#006490',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 5,
          minHeight: 20,
          textTransform: 'capitalize',
          fontFamily: '"Open Sans", "Arial"',
          color: '#006490',
          '&.Mui-selected': {
            color: '#006490',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            outlineColor: '#98AABC',
            fontFamily: '"Open Sans", "Arial"',
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          fontFamily: '"Open Sans", "Arial"',
        },
      },
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
        outlined: {
          borderColor: '#AABED2',
          color: '#2D5C89',
          '&:hover': {
            borderColor: '#AABED2',
            color: '#2D5C89',
          },
        },
        contained: {
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#6FAE2F',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          padding: '8px 16px 8px 16px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
        },
        button: {
          textTransform: 'capitalize',
          [fontSizeMediaQuery('xl', false)]: {
            fontSize: '0.80rem',
          },
          [fontSizeMediaQuery('xl')]: {
            fontSize: '0.90rem',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 4,
          textTransform: 'initial',
          margin: 4,
          color: '#000000',
          backgroundColor: '#FFFFFF',
          border: '1px solid #98AABC',
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#FFFFFF',
            border: '2px solid #6FAE2F',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#98AABC',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          '& .MuiAutocomplete-popupIndicator': {
            color: '#000000',
          },
        },
        listbox: {
          backgroundColor: '#FFFFFF',
          padding: 0,
        },
        option: {
          fontFamily: '"Open Sans", "Arial"',
          ':hover': {
            backgroundColor: '#E2E7E9 !important',
          },
          borderBottom: '1px solid #98AABC',
          "&[aria-selected='true']": {
            fontWeight: 'bold',
            backgroundColor: '#E2E7E9 !important',
            ':hover': {
              backgroundColor: '#E2E7E9 !important',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#527280',
        },
        track: {
          opacity: 1,
          backgroundColor: '#AABED2',
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: '#CBE8B0',
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F1F4F5',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          borderBottom: '1px solid #98AABC', //secondary.dark
          borderLeft: '1px solid #98AABC',
          borderRight: '1px solid #98AABC',
          ':hover': {
            backgroundColor: '#E2E7E9',
          },
          '&.Mui-selected': {
            backgroundColor: '#E2E7E9 !important',
            ':hover': {
              backgroundColor: '#E2E7E9 !important',
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          color: '#41A406',
        },
      },
    },
  },
};

export const questradeDarkTheme: ThemeOptions = {
  breakpoints: {
    values: OptionsPlayBreakpoints,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#83CA31', // USED FOR PRIMARY COLOR
      light: '#474747', // USED FOR STYLED HEADER FOR SELECT COMPONENT
      dark: 'rgba(0, 0, 0, 0.54)', // In general used for the hover effect
    },
    secondary: {
      main: '#FFFFFF',
      light: '#505050', // used for hover color and selected color
      dark: '#686868', // USED FOR BORDER COLOR FOR SELECT,TEXTFIELDS
    },
    background: {
      default: '#000000',
      paper: '#1A1A1A',
    },
    info: {
      main: '#343434',
      light: '#FFFFFF', // USED FOR DEFAULT ICON STROKE VALUE
      dark: '#343434', // Used for the heading background
    },
    success: {
      main: '#83CA31', // SUCCESS COLOR
      light: '#b8e0b9',
    },
    error: {
      main: '#FF2525', // ERROR COLOR
      light: '#ED004F',
    },
    warning: {
      main: '#FF8B05', // WARNING COLOR FOR NEUTRAL
      light: 'rgb(223, 150, 65, 0.2)',
      dark: '#E6740E',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF', // USED FOR THE PLACEHOLDER COLOR
      disabled: '#6293CA', // USED FOR THE HIGHLIGHT OF PL GRAPH LINE
    },
    timeFrame: {
      light: '#DBEFC9',
      main: '#C2E4A2',
      dark: '#9DD36A',
      contrastText: '#000000',
    },
    tolerance: {
      light: '#F5CCCC',
      main: '#EEA8A8',
      dark: '#C91A33',
      contrastText: '#000000',
    },
    collapsible: {
      main: '#1A1A1A',
      light: '#686868',
    },
    selectAndTextField: {
      light: '#000000',
      main: '#FFFFFF',
    },
    selection: {
      main: '#D3EBBD',
      light: '#282828',
      dark: '#686868',
      contrastText: '#000000',
    },
    globalSelect: {
      main: '#FFFFFF',
      light: '#999999',
      dark: '#83CA31',
    },
    personalSelect: {
      main: '#FFFFFF',
      light: '#999999',
    },
    legButtons: {
      main: '#2D5C89', //stock
      light: '#83CA31', //call
      dark: '#FF2525', //put
    },
    orangeButton: {
      main: '#000000', // backgroundColor
      light: '#83CA31', //selected border Color
      dark: '#686868', //  Border color
    },
    symbolCard: {
      default: '#282828',
    },
    header: {
      default: '#000000',
    },
    highChart: {
      main: '#FFFFFFF2',
      light: '#FFFFFF',
      dark: '#585858',
      contrastText: '#000000',
    },
    slider: {
      main: '#545454',
    },
    optionGridDisabled: {
      main: '#545454',
      light: '#686868',
      contrastText: '#878787',
    },
    divider: '#686868',
  },
  typography: {
    captionSmall: {
      fontSize: '0.75rem',
      lineHeight: 2,
    },
    body1: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
    body2: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '&.MuiDateCalendar-root': {
          backgroundColor: '#000000',
        },
        '&.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root': {
          color: '#686868',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: '20px',
        },
        indicator: {
          backgroundColor: '#83CA31',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 5,
          minHeight: 20,
          textTransform: 'capitalize',
          fontFamily: '"Open Sans", "Arial"',
          color: '#FFFFFF',
          '&.Mui-selected': {
            color: '#83CA31',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            outlineColor: '#686868',
            fontFamily: '"Open Sans", "Arial"',
          },
        },
      ],
      styleOverrides: {
        root: {
          '& input': {
            '&::placeholder': {
              color: '#A0A0A0',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          fontFamily: '"Open Sans", "Arial"',
        },
      },
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          // color: 'yellow',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
        outlined: {
          borderColor: '#686868',
          color: '#FFFFFF',
          '&:hover': {
            borderColor: '#686868',
            color: '#FFFFFF',
          },
        },
        contained: {
          color: '#000000',
          '&:hover': {
            backgroundColor: '#83CA31',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          padding: '8px 16px 8px 16px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
        },
        button: {
          textTransform: 'capitalize',
          [fontSizeMediaQuery('xl', false)]: {
            fontSize: '0.80rem',
          },
          [fontSizeMediaQuery('xl')]: {
            fontSize: '0.90rem',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 4,
          textTransform: 'initial',
          margin: 4,
          color: '#FFFFFF',
          backgroundColor: '#000000',
          border: '1px solid #686868',
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#000000',
            border: '2px solid #83CA31',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#686868',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          '& .MuiAutocomplete-popupIndicator': {
            color: '#FFFFFF',
          },
        },
        listbox: {
          backgroundColor: '#000000',
          padding: 0,
        },
        option: {
          fontFamily: '"Open Sans", "Arial"',
          borderBottom: '1px solid #686868',
          ':hover': {
            backgroundColor: '#1A1A1A !important',
          },
          "&[aria-selected='true']": {
            fontWeight: 'bold',
            backgroundColor: '#000000 !important',
            ':hover': {
              backgroundColor: '#1A1A1A !important',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#FFFFFF',
        },
        track: {
          opacity: 1,
          backgroundColor: '#999999',
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: '#CBE8B0',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          borderBottom: '1px solid #686868', //secondary.dark
          borderLeft: '1px solid #686868',
          borderRight: '1px solid #686868',
          backgroundColor: '#000000 !important',
          '&:hover': {
            backgroundColor: '#1A1A1A !important',
          },
          '&.Mui-selected': {
            backgroundColor: '#000000 !important',
            ':hover': {
              backgroundColor: '#1A1A1A !important',
            },
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: '#343434',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          color: '#83CA31',
        },
      },
    },
  },
};

export const questradeBlueTheme: ThemeOptions = {
  breakpoints: {
    values: OptionsPlayBreakpoints,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#83CA31', // USED FOR PRIMARY COLOR
      light: '#074571', // USED FOR STYLED HEADER FOR SELECT COMPONENT
      dark: '#FFFFFF', // In general used for the hover effect
    },
    secondary: {
      main: '#FFFFFF',
      light: '#AABD2', // used for hover color and selected color
      dark: '#ADCEDE', // USED FOR BORDER COLOR FOR SELECT,TEXTFIELDS
    },
    background: {
      default: '#022640',
      paper: '#003154',
    },
    info: {
      main: '#074571',
      light: '#FFFFFF', // USED FOR DEFAULT ICON STROKE VALUE
      dark: '#074571', // Used for the heading background
    },
    success: {
      main: '#83CA31', // SUCCESS COLOR
      light: '#b8e0b9',
    },
    error: {
      main: '#FF2525', // ERROR COLOR
      light: '#ED004F',
    },
    warning: {
      main: '#FF8B05', // WARNING COLOR FOR NEUTRAL
      light: 'rgb(223, 150, 65, 0.2)',
      dark: '#FF8B05',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF', // USED FOR THE PLACEHOLDER COLOR
      disabled: '#3C679B', // USED FOR THE HIGHLIGHT OF PL GRAPH LINE
    },
    timeFrame: {
      light: '#DBEFC9',
      main: '#C2E4A2',
      dark: '#9DD36A',
      contrastText: '#000000',
    },
    tolerance: {
      light: '#F5CCCC',
      main: '#EEA8A8',
      dark: '#E06464',
      contrastText: '#000000',
    },
    collapsible: {
      main: '#003154',
      light: '#29789F',
    },
    selectAndTextField: {
      light: '#003154',
      main: '#FFFFFF',
      dark: '#022640',
    },
    selection: {
      main: '#D3EBBD',
      light: '#074571',
      dark: '#ADCEDE',
      contrastText: '#022640',
    },
    globalSelect: {
      main: '#FFFFFF',
      light: '#29789F',
      dark: '#83CA31',
    },
    personalSelect: {
      main: '#000000',
      light: '#29789F',
    },
    legButtons: {
      main: '#ADCEDE', //stock
      light: '#83CA31', //call
      dark: '#FF2525', //put
    },
    orangeButton: {
      main: '#074571', // backgroundColr
      light: '#83CA31', //selected border Color
      dark: '#ADCEDE', //  Border color
    },
    symbolCard: {
      default: '#074571',
    },
    header: {
      default: '#022640',
    },
    highChart: {
      main: '#FFFFFFF2',
      light: '#FFFFFF',
      dark: '#074571',
      contrastText: '#000000',
    },
    slider: {
      main: '#074571',
    },
    optionGridDisabled: {
      main: '#074571',
      light: '#29789F',
      contrastText: '#29789F',
    },
    divider: '#ADCEDE',
  },
  typography: {
    captionSmall: {
      fontSize: '0.75rem',
      lineHeight: 2,
    },
    body1: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
    body2: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '&.MuiDateCalendar-root': {
          backgroundColor: '#022640',
        },
        '&.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root': {
          color: '#29789F',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: '20px',
        },
        indicator: {
          backgroundColor: '#83CA31',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 5,
          minHeight: 20,
          textTransform: 'capitalize',
          fontFamily: '"Open Sans", "Arial"',
          color: '#FFFFFF',
          '&.Mui-selected': {
            color: '#83CA31',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            outlineColor: '#ADCEDE',
            fontFamily: '"Open Sans", "Arial"',
          },
        },
      ],
      styleOverrides: {
        root: {
          // '& input': {
          //   '&::placeholder': {
          //     color: '#FFFFFF',
          //   },
          // },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          fontFamily: '"Open Sans", "Arial"',
        },
      },
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          // color: 'yellow',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#000000',
          borderRadius: 5,
          textTransform: 'capitalize',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
        outlined: {
          borderColor: '#ADCEDE',
          color: '#FFFFFF',
          '&:hover': {
            borderColor: '#AABED2',
            color: '#FFFFFF',
          },
        },
        contained: {
          color: '#022640',
          '&:hover': {
            backgroundColor: '#6FAE2F',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          padding: '8px 16px 8px 16px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
        },
        button: {
          textTransform: 'capitalize',
          [fontSizeMediaQuery('xl', false)]: {
            fontSize: '0.80rem',
          },
          [fontSizeMediaQuery('xl')]: {
            fontSize: '0.90rem',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 4,
          textTransform: 'initial',
          margin: 4,
          color: '#FFFFFF',
          backgroundColor: '#022640',
          border: '1px solid #ADCEDE',
          '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: '#022640',
            border: '2px solid #83CA31',
            ':hover': {
              backgroundColor: '#022640',
              border: '2px solid #ADCEDE',
            },
          },
          ':hover': {
            backgroundColor: '#022640',
            border: '1px solid #ADCEDE',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#ADCEDE',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          '& .MuiAutocomplete-popupIndicator': {
            color: '#FFFFFF',
          },
        },
        listbox: {
          backgroundColor: '#022640',
          padding: 0,
        },
        option: {
          fontFamily: '"Open Sans", "Arial"',
          borderBottom: '1px solid #ADCEDE',
          ':hover': {
            backgroundColor: '#1A1A1A !important',
          },
          "&[aria-selected='true']": {
            fontWeight: 'bold',
            backgroundColor: '#022640 !important',
            ':hover': {
              backgroundColor: '#1A1A1A !important',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#527280',
        },
        track: {
          opacity: 1,
          backgroundColor: '#AABED2',
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: '#CBE8B0',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          borderBottom: '1px solid #ADCEDE', //secondary.dark
          borderLeft: '1px solid #ADCEDE',
          borderRight: '1px solid #ADCEDE',
          backgroundColor: '#022640 !important',
          '&:hover': {
            backgroundColor: '#1A1A1A !important',
          },
          '&.Mui-selected': {
            fontWeight: 'bold',
            backgroundColor: '#022640 !important',
            '&:hover': {
              backgroundColor: '#1A1A1A !important',
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          color: '#83CA31',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.MuiTextField-root': {
            svg: {
              color: '#FFFFFF',
            },
          },
        },
      },
    },
  },
};

export const questradeWebLightTheme: ThemeOptions = {
  breakpoints: {
    values: OptionsPlayBreakpoints,
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#389B3C', // USED FOR PRIMARY COLOR
      light: '#CED6E2', // USED FOR STYLED HEADER FOR SELECT COMPONENT
      dark: 'rgba(0, 0, 0, 0.54)', // In general used for the hover effect
    },
    secondary: {
      main: '#2D5C89',
      light: '#AABED2', // used for hover color and selected color
      dark: '#CED6E2', // USED FOR BORDER COLOR FOR SELECT,TEXTFIELDS
    },
    background: {
      default: '#F8F8FA',
      paper: '#FFFFFF',
    },
    info: {
      main: '#CED6E2',
      light: '#262D33', // USED FOR DEFAULT ICON STROKE VALUE
      dark: '#F2F2F8', // Used for the heading background
    },
    success: {
      main: '#389B3C', // SUCCESS COLOR
      light: '#b8e0b9',
    },
    error: {
      main: '#D33E54', // ERROR COLOR
      light: '#ED004F',
    },
    warning: {
      main: '#ED7D37', // WARNING COLOR FOR NEUTRAL
      light: 'rgb(223, 150, 65, 0.2)',
      dark: '#E6740E',
    },
    text: {
      primary: '#262D33',
      secondary: '#262D33', // USED FOR THE PLACEHOLDER COLOR
      disabled: '#CED6E2', // USED FOR THE HIGHLIGHT OF PL GRAPH LINE
    },
    timeFrame: {
      light: '#EBF7EB',
      main: '#ADDEAF',
      dark: '#5DBE62',
      contrastText: '#262D33',
    },
    tolerance: {
      light: '#FEE9EC',
      main: '#F9A5B2',
      dark: '#F54C64',
      contrastText: '#262D33',
    },
    collapsible: {
      main: '#FFFFFF',
      light: '#CED6E2',
    },
    selectAndTextField: {
      light: '#FFFFFF',
      main: '#000000',
    },
    selection: {
      main: '#EBF7EB',
      light: 'inherit',
      dark: '#CED6E2',
      contrastText: '#262D33',
    },
    globalSelect: {
      main: '#262D33',
      light: '#5E6D83',
      dark: '#389B3C',
    },
    personalSelect: {
      main: '#262D33',
      light: '#5E6D83',
    },
    legButtons: {
      main: '#0066DB', //stock
      light: '#389B3C', //call
      dark: '#D33E54', //put
    },
    orangeButton: {
      main: '#FFFFFF', // backgroundColr
      light: '#389B3C', //selected border Color
      dark: '#78899F', //  Border color
    },
    symbolCard: {
      default: '#FFFFFF',
    },
    header: {
      default: '#F8F8FA',
    },
    highChart: {
      main: '#262D33',
      light: '#FFFFFF',
      dark: '#5E6D83',
      contrastText: '#FFFFFF',
    },
    slider: {
      main: '#CED6E2',
    },
    optionGridDisabled: {
      main: '#F2F2F8',
      light: '#CED6E2',
      contrastText: '#CED6E2',
    },
    divider: '#CED6E2',
  },
  typography: {
    captionSmall: {
      fontSize: '0.75rem',
      lineHeight: 2,
    },
    body1: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
    body2: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '&.MuiDateCalendar-root': {
          backgroundColor: '#FFFFFF',
        },
        '&.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root': {
          color: '#CED6E2',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: '20px',
        },
        indicator: {
          backgroundColor: '#389B3C',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 5,
          minHeight: 20,
          textTransform: 'capitalize',
          fontFamily: '"Open Sans", "Arial"',
          color: '#262D33',
          '&.Mui-selected': {
            color: '#389B3C',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            outlineColor: '#98AABC',
            fontFamily: '"Open Sans", "Arial"',
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          fontFamily: '"Open Sans", "Arial"',
        },
      },
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100, //Kept Maximum border radius
          textTransform: 'capitalize',
        },
        outlined: {
          borderColor: '#78899F',
          color: '#262D33',
          '&:hover': {
            borderColor: '#78899F',
            color: '#262D33',
          },
        },
        contained: {
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#6FAE2F',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          padding: '8px 16px 8px 16px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
        },
        button: {
          textTransform: 'capitalize',
          [fontSizeMediaQuery('xl', false)]: {
            fontSize: '0.80rem',
          },
          [fontSizeMediaQuery('xl')]: {
            fontSize: '0.90rem',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: '5px 10px 5px 10px',
          textTransform: 'initial',
          margin: 4,
          color: '#262D33',
          backgroundColor: '#F2F2F8',
          borderRadius: 100,
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#EBF7EB',
            border: '1px solid #389B3C',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#78899F',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          '& .MuiAutocomplete-popupIndicator': {
            color: '#000000',
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            color: '#98AABC',
          },
        },
        listbox: {
          backgroundColor: '#FFFFFF',
          padding: 0,
        },
        option: {
          fontFamily: '"Open Sans", "Arial"',
          ':hover': {
            backgroundColor: '#F2F2F8 !important',
          },
          borderBottom: '1px solid #CED6E2',
          "&[aria-selected='true']": {
            fontWeight: 'bold',
            backgroundColor: '#CED6E2 !important',
            ':hover': {
              backgroundColor: '#F2F2F8 !important',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#78899F',
        },
        track: {
          opacity: 1,
          backgroundColor: '#CED6E2',
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: '#ADDEAF',
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F1F4F5',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Arial"',
          borderBottom: '1px solid #78899F', //secondary.dark
          borderLeft: '1px solid #78899F',
          borderRight: '1px solid #78899F',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#F2F2F8',
          },
          '&.Mui-selected': {
            backgroundColor: '#CED6E2 !important',
            '&:hover': {
              backgroundColor: '#F2F2F8 !important',
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          color: '#389B3C',
        },
      },
    },
  },
};
