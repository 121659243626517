import { Grid } from '@mui/material';
import { customizationState, howWhyRanksDataState, selectedSymbolState } from '@op/shared/src/states';
import React, { ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { HeightWidget, PageContainerWidget } from '.';
import { HowTabsWidget } from '../how';
import { QuoteBarWidget } from '../quoteBar/quote-bar-widget';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';
import { useMinWidthBreakpoint } from '../states/use-min-width-breakpoint';
import { MidTabPanelWidget } from '../why/mid-tab-panel-widget';

interface props {
  children: ReactNode;
}

export const AppContainer: React.FC<props> = ({ children }: props) => {
  const isMinWidthBreakpoint = useMinWidthBreakpoint();
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const fetchAndSetData = useFetchAndSetData();
  const [searchParams] = useSearchParams();
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  const customization = useRecoilValue(customizationState);
  document.title = 'OptionsPlay Ideas | OptionsPlay';

  useEffect(() => {
    const symbol = searchParams.get('symbol');
    /* Technical-Idea, Covered-Call, SP and CC url cantains symbols.
     * If symbol is present in url, it should load/api call for that symbol.
     * The respective widget make its own api call for how/why and ranks apis.
     */
    if (symbol && symbol.trim() !== '') {
      return;
    }
    /*
     * This useEffect is for initial load only. Other time resective widget's onItemSelected
     * function will pull how/why/ranks data.
     * However, for navigation change i.e watchilst -> Portfolio -> watchlist. making this uncessary call.
     * Hence, if these data are present then should not make this call.
     */
    if (howWhyRanksData.how !== undefined) {
      return;
    }
    /**
     * Ideally, `fetchAndSetData` should be called in HowWidget.
     * However, quotebar also needs some parts of `How` and `Why` data.
     * So, in cases where only Quotebar or HowWidget is renderd, like embedder with only HowWidget,
     * the data will not be called. Hence, this call is present in grand-parent widget i.e AppContainer.
     */
    (async () => {
      await fetchAndSetData(selectedSymbol);
    })();
  }, [searchParams]);

  if (!customization) {
    return;
  }

  const getFirstColumnWidth = () => {
    if (!customization.showWhatPanel) {
      return 6;
    }
    return isMinWidthBreakpoint ? 7 : 6;
  };

  const getSecondColumnWidth = () => {
    if (!customization.showWhatPanel) {
      return 12;
    }
    return isMinWidthBreakpoint ? 8 : 11;
  };

  const getThirdColumnWidth = () => {
    if (!customization.showWhatPanel) {
      return 6;
    }
    return isMinWidthBreakpoint ? 5 : 6;
  };

  return (
    <PageContainerWidget>
      <Grid container columnSpacing={1}>
        <Grid item xs={getFirstColumnWidth()} container columnSpacing={1}>
          {customization.showWhatPanel && (
            <Grid item xs={isMinWidthBreakpoint ? 4 : 1}>
              {children}
            </Grid>
          )}
          <Grid item xs={getSecondColumnWidth()}>
            <HeightWidget noScroll height={customization.maxHeight}>
              <Grid container>
                <Grid item xs={12}>
                  <QuoteBarWidget />
                </Grid>
                <Grid item xs={12}>
                  <MidTabPanelWidget />
                </Grid>
              </Grid>
            </HeightWidget>
          </Grid>
        </Grid>
        <Grid item xs={getThirdColumnWidth()} position={'relative'}>
          <HeightWidget height={customization.maxHeight}>
            <HowTabsWidget />
          </HeightWidget>
        </Grid>
      </Grid>
    </PageContainerWidget>
  );
};
