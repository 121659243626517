import {
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { SharedTradeDeatails } from '@op/shared/src/models';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { ProfileDetailsService } from '@op/shared/src/services';
import React, { useEffect, useState } from 'react';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayDatePickerWidget, OptionsPlayTextField } from '../styled';

const cellWidthUrl = {
  width: '100px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const Obj = {
  start: 0,
  length: 10,
  orderColumn: 'timestamp',
  orderDirection: 'desc',
  startDate: '',
  endDate: '',
  isValid: true,
  isAnyMessageShown: false,
  symbol: '',
};

export const TableShared: React.FC = () => {
  let payloadData = Obj;

  const [symbol, setSymbol] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = React.useState(0);
  const [values, setValues] = useState(new SharedTradeDeatails());
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { t } = React.useContext(LocalizationContext);
  const origin = window.location.origin;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    payloadData.start = newPage * rowsPerPage;
    fetchTrade();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(+event.target.value);
    payloadData.length = parseInt(event.target.value);
    fetchTrade();
  };

  const fetchTrade = async (sDate?: Date, eDate?: Date): Promise<void> => {
    try {
      const formattedStartDate = sDate ? getDateFormat(sDate) : getDateFormat(new Date(startDate));
      const formattedEndDate = eDate ? getDateFormat(eDate) : getDateFormat(new Date(endDate));
      payloadData.symbol = symbol;
      payloadData.startDate = formattedStartDate;
      payloadData.endDate = formattedEndDate;
      if (new Date(payloadData.endDate).getTime() < new Date(payloadData.startDate).getTime()) {
        payloadData.startDate = formattedEndDate;
        payloadData.endDate = formattedStartDate;
        setStartDate(endDate);
        setEndDate(startDate);
      }
      const response = await ProfileDetailsService.instance.SharedTrades(payloadData);
      if (response.hasErrors) {
        return;
      }
      payloadData = Obj;
      setValues(response.data || new SharedTradeDeatails());
    } catch (error) {
      console.error('useProfiles: error fetching data: ', error);
    }
  };

  useEffect(() => {
    let sDate = new Date();
    let eDate = new Date();
    sDate.setMonth(sDate.getMonth() - 6);
    setStartDate(getDateFormat(sDate));
    setEndDate(getDateFormat(eDate));
    fetchTrade(sDate, eDate);
  }, []);

  const onApply = () => {
    fetchTrade();
  };

  const getDateFormat = (fDate: Date) => {
    const year = fDate.getFullYear();
    const monthWithOffset = fDate.getUTCMonth() + 1;
    const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
    const date = fDate.getDate().toString().length < 2 ? `0${fDate.getDate()}` : fDate.getDate();
    return `${year}-${month}-${date}`;
  };

  const renderSharedHead = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: '100%' }}>
        <Grid item>
          <Typography variant="body1">{t('app.php.common.labels.symbol')}</Typography>
        </Grid>
        <Grid item sx={{ width: '15%' }}>
          <OptionsPlayTextField
            fullWidth
            value={symbol}
            id="symbol"
            name="symbol"
            variant="outlined"
            size="small"
            type="search"
            onChange={(e) => {
              setSymbol(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1">{t('app.php.profile.sharedTrades.labels.startDate')}</Typography>
        </Grid>
        <Grid item sx={{ width: '20%' }}>
          <OptionsPlayDatePickerWidget
            value={startDate}
            max={new Date()}
            onChangeDate={(e) => setStartDate(e as string)}
            onBlur={(e) => setStartDate(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1">{t('app.php.profile.sharedTrades.labels.endDate')}</Typography>
        </Grid>
        <Grid item sx={{ width: '20%' }}>
          <OptionsPlayDatePickerWidget
            value={endDate}
            max={new Date()}
            onChangeDate={(e) => setEndDate(e as string)}
            onBlur={(e) => setEndDate(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onApply}>
            <Typography variant="button">{t('app.php.profile.sharedTrades.js.applyButton')}</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderTableBody = () => {
    if (values.data.length === 0) {
      return (
        <TableRow>
          <Typography>No matching records found</Typography>
        </TableRow>
      );
    }
    return values.data.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.symbol}</TableCell>
        <TableCell>{DateTimeHelper.format(row.timestamp)}</TableCell>
        <TableCell padding="none">{row.fullName}</TableCell>
        <Tooltip title={`${origin}/share/${row.shareId}`}>
          <TableCell padding="none">
            <Link variant="body1" href={`${origin}/share/${row.shareId}`} target="_blank">
              <Typography style={cellWidthUrl}>{`${origin}/share/${row.shareId}`}</Typography>
            </Link>
          </TableCell>
        </Tooltip>
        <Tooltip title={`${origin}/screenshot/${row.shareId}`}>
          <TableCell padding="none">
            <Link variant="body1" href={`${origin}/screenshot/${row.shareId}`} target="_blank">
              <Typography style={cellWidthUrl}>{`${origin}/screenshot/${row.shareId}`}</Typography>
            </Link>
          </TableCell>
        </Tooltip>
      </TableRow>
    ));
  };

  const renderTable = () => {
    return (
      <>
        <Grid xl={12} sm={6} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head">
                    <TableSortLabel
                      active={payloadData.orderColumn === 'symbol'}
                      direction={payloadData.orderDirection === 'asc' ? 'asc' : 'desc'}
                      onClick={() => {
                        payloadData.orderDirection = payloadData.orderDirection === 'asc' ? 'desc' : 'asc';
                        payloadData.orderColumn = 'symbol';
                        fetchTrade();
                      }}
                      // classes={{
                      //   icon: payloadData.orderColumn == 'symbol' ? classes.activeSortIcon : classes.inactiveSortIcon,
                      // }}
                    >
                      {t('app.php.common.labels.symbol')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell variant="head">
                    <TableSortLabel
                      active={payloadData.orderColumn === 'timestamp'}
                      direction={payloadData.orderDirection === 'asc' ? 'asc' : 'desc'}
                      onClick={() => {
                        payloadData.orderDirection = payloadData.orderDirection === 'asc' ? 'desc' : 'asc';
                        payloadData.orderColumn = 'timestamp';
                        fetchTrade();
                      }}>
                      {t('app.php.profile.sharedTrades.js.dateTitle')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell variant="head">{t('app.php.profile.sharedTrades.js.strategyNameTitle')}</TableCell>
                  <TableCell variant="head">{t('app.php.profile.sharedTrades.js.shareUrlTitle')}</TableCell>
                  <TableCell variant="head">{t('app.php.profile.sharedTrades.js.imageUrlTitle')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={values.recordsFiltered}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12}>
        {renderSharedHead()}
      </Grid>
      <Grid item xs={12}>
        {renderTable()}
      </Grid>
    </Grid>
  );
};
