import React, { useContext } from 'react';
import { Combination, IncomeCombination } from '@op/shared/src/models';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import LocalizationContext from '../react-i18next/localization-context';

export const useBreakEvenDescription = () => {
  const { t } = useContext(LocalizationContext);

  //extracted from sentiments function of combination.ts because of localization.
  const breakevenDesc = (data?: Combination | IncomeCombination) => {
    if (!data) {
      return;
    }
    const perihelion = data.payoff(0.1);
    const aphelion = data.payoff(data.highSpotBound());
    const breakevens = data.breakevens();
    let focus = breakevens.length === 2 ? data.payoff((breakevens[0] + breakevens[1]) / 2, data.expiration()) : 0;
    focus = breakevens.length === 4 ? data.payoff((breakevens[1] + breakevens[2]) / 2, data.expiration()) : focus;
    if (breakevens.length === 2 && perihelion > 1 && aphelion > 1) {
      const breakevenZeroDescription = NumberFormatHelper.toCurrency(breakevens[0]);
      const breakevenFirstDescription = NumberFormatHelper.toCurrency(breakevens[1]);
      return `${t('app.php.how.breakevenDesc.below')}
            ${breakevenZeroDescription} ${t('app.php.how.breakevenDesc.or')}
            ${t('app.php.how.breakevenDesc.above')}
            ${breakevenFirstDescription}`;
    } else if (perihelion >= 1 && aphelion < 1) {
      const breakevenZeroDescription = NumberFormatHelper.toCurrency(breakevens[0]);
      return `${t('app.php.how.breakevenDesc.below')} ${t(breakevenZeroDescription)}`;
    } else if (perihelion < 1 && aphelion >= 1) {
      const breakevenZeroDescription = NumberFormatHelper.toCurrency(breakevens[0]);
      return `${t('app.php.how.breakevenDesc.above')} ${breakevenZeroDescription}`;
    } else if (focus >= 1) {
      const breakevenZeroDescription = NumberFormatHelper.toCurrency(breakevens[0]);
      const breakevenFirstDescription = NumberFormatHelper.toCurrency(breakevens[1]);
      return `${t('app.php.how.breakevenDesc.between')}
              ${breakevenZeroDescription}
              ${t('app.php.how.breakevenDesc.and')}
              ${breakevenFirstDescription}`;
    }
  };

  return breakevenDesc;
};
