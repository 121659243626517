import { Grid } from '@mui/material';
import { Quote } from '@op/shared/src/models';
import { Permissions, WatchListsOperationTypes, WatchListsTypes } from '@op/shared/src/models/enums/enums';
import {
  accountState,
  customizationState,
  originalWatchListState,
  selectedSymbolState,
  selectedWatchListIdState,
  selectedWatchListState,
  watchListCrudOperationState,
  watchListNewsFeedState,
  watchListSearchTextState,
} from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { EmptyRowWidget } from '../common';
import { TradeIdeaLoaderWidget, WhatLoaderWidget } from '../ideas/what-loader-widget';
import { HeightWidget } from '../routes';
import { GuideItem } from '../side-menu';
import { useMinWidthBreakpoint } from '../states/use-min-width-breakpoint';
import { useWindowSize } from '../states/use-window-size';
import { WatchListRowWidget } from './watch-lists-row-widget';

export const WatchListWidget: React.FC = () => {
  const isNormalView = useMinWidthBreakpoint();
  const windowSize = useWindowSize();
  const selectedWatchList = useRecoilValue(selectedWatchListState);
  const [selectedWatchListId, setSelectedWatchListId] = useRecoilState(selectedWatchListIdState);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [quoteArray, setQuoteArray] = useState<Quote[]>([]);
  const account = useRecoilValue(accountState);
  const watchListSearchText = useRecoilValue(watchListSearchTextState);
  const watchListOperation = useRecoilValue(watchListCrudOperationState);
  const setNewsFeedState = useSetRecoilState(watchListNewsFeedState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setOriginalWatchList = useSetRecoilState(originalWatchListState);
  const canManageGlobalB2BWatchList = account.securityModel.hasPermission(Permissions.MANAGE_GLOBAL_B2_B_WATCH_LISTS);
  const customization = useRecoilValue(customizationState);

  const itemsPerPage = 10;
  const loading = false;

  useEffect(() => {
    if (!selectedWatchList || !selectedWatchList.quotes || selectedWatchList.quotes.length === 0) {
      setQuoteArray([]);
      return;
    }
    if (watchListSearchText && watchListSearchText.trim() !== '') {
      const newQuoteArray = selectedWatchList.quotes.filter((q) => q.symbol.includes(watchListSearchText));
      setQuoteArray(newQuoteArray.slice(0, itemsPerPage));
      setHasNextPage(true);
      return;
    }
    setQuoteArray(selectedWatchList.quotes.slice(0, itemsPerPage));
    setSelectedWatchListId(selectedWatchList.id);
    setHasNextPage(true);
  }, [selectedWatchList, watchListSearchText]);

  useEffect(() => {
    if (!selectedWatchListId) {
      return;
    }
    if (selectedWatchListId === selectedWatchList.id) {
      setOriginalWatchList(selectedWatchList);
    }
  }, [selectedWatchListId]);

  useEffect(() => {
    setNewsFeedState(false);
  }, [selectedSymbol]);

  const loadMore = async () => {
    if (!selectedWatchList || selectedWatchList.quotes.length === 0) {
      return;
    }
    if (watchListSearchText) {
      setHasNextPage(false);
      return;
    }
    const sliceLength = quoteArray.length + itemsPerPage;
    const slicedWatchlist = selectedWatchList.quotes.slice(0, sliceLength);
    if (slicedWatchlist.length === 0) {
      return;
    }
    setQuoteArray(slicedWatchlist);
    setHasNextPage(sliceLength < selectedWatchList.quotes.length);
  };

  const getContainerHeight = () => {
    if (!customization) {
      return 600;
    }
    return isNormalView ? customization.maxHeight - 170 : (windowSize?.height || customization.maxHeight) - 200;
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: false,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    // rootMargin: `0px 0px ${containerHeight}px 0px`,
    rootMargin: `0px 0px ${getContainerHeight()}px 0px`,
    delayInMs: undefined,
  });

  if (!quoteArray) {
    return <WhatLoaderWidget />;
  }

  if (quoteArray.length === 0) {
    const isSelectedWatchListTypeNone =
      selectedWatchList?.type === WatchListsTypes.NONE ||
      (canManageGlobalB2BWatchList && selectedWatchList?.type === WatchListsTypes.GLOBAL_B2B);
    const message = isSelectedWatchListTypeNone ? 'Use Search bar to Add Symbol in your watchlist' : 'Symbol not found';
    return <EmptyRowWidget message={message} hideSearchIcon={isSelectedWatchListTypeNone} />;
  }

  if (
    watchListOperation === WatchListsOperationTypes.NEW ||
    watchListOperation === WatchListsOperationTypes.NEWGLOBAL
  ) {
    return <EmptyRowWidget message={'Create New WatchList'} />;
  }

  return (
    <HeightWidget id="watchlist" height={getContainerHeight()}>
      <div style={{ paddingLeft: '37%' }}>
        <GuideItem selector=".grid_companyName_helpPinPlaceholder" />
      </div>
      <div style={{ paddingRight: '8%', display: 'flex', justifyContent: 'flex-end' }}>
        <GuideItem selector=".grid_sentiment_helpPinPlaceholder" />
      </div>
      <div style={{ paddingRight: '16%', display: 'flex', justifyContent: 'flex-end' }}>
        <GuideItem selector=".grid_technicalRank_helpPinPlaceholder" />
      </div>
      <Grid id="watch-lists" container rowSpacing={1} sx={{ pb: 2.5 }}>
        {quoteArray.map((quote: Quote, index) => (
          <Grid item xs={12} key={index} id={`js-watchlist-item${index}`}>
            <WatchListRowWidget key={quote.symbol} data={quote} />
          </Grid>
        ))}
        {hasNextPage && (
          <Grid item xs={12} ref={sentryRef}>
            <TradeIdeaLoaderWidget />
          </Grid>
        )}
      </Grid>
    </HeightWidget>
  );
};
