import { ExpandedQuote, LinkedPortfolio, LinkedWatchlist, SentimentType } from '..';

import { Rule } from './rule';
import formatting from '../how/formatting';

export class Trade {
  dateOfScan: Date = new Date();
  sentiment = '';
  rules: Rule[] = [];
  technicalRank = -1;
  symbol = '';
  companyName = '';
  sector = '';
  marketCap = -1;
  impliedVolatilityRank = -1;
  liquidityExplanation = '';
  liquidityRank = -1;
  expandedQuote: ExpandedQuote | undefined;
  signals: { date: string; name: string; symbol: string; value: number }[] = [];
  exchange = ''; //TODO: Ideally symbol should be { symbol, exchange } object.
  price = 0;
  portfolios: LinkedPortfolio[] = [];
  watchlists: LinkedWatchlist[] = [];

  static fromJson = (item: Trade): Trade => {
    const trade = new Trade();
    trade.dateOfScan = new Date(item.dateOfScan);
    trade.sentiment = item.sentiment;
    trade.technicalRank = +item.technicalRank;
    trade.symbol = item.symbol;
    trade.exchange = formatting.symbolDotExchangeToExchange(item.symbol);
    trade.companyName = item.companyName;
    trade.sector = item.sector;
    trade.marketCap = +item.marketCap;
    trade.impliedVolatilityRank = +item.impliedVolatilityRank;
    trade.liquidityExplanation = item.liquidityExplanation;
    trade.liquidityRank = +item.liquidityRank;
    trade.rules = item.rules;
    trade.expandedQuote = item.expandedQuote;
    trade.signals = item.signals;
    trade.price = item.price;
    trade.portfolios = item.portfolios;
    trade.watchlists = item.watchlists;
    return trade;
  };

  get symbolDisplay() {
    return formatting.symbolDotExchangeToSymbol(this.symbol);
  }

  isBullish = (): boolean => {
    return this.sentiment.trim().toUpperCase() === SentimentType.BULLISH.toString();
  };
}
