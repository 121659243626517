export class Scan {
  value = '';
  text = '';
  isSelected = true;

  static fromSelf = (data: Scan): Scan => {
    const scan = new Scan();
    scan.isSelected = true;
    scan.text = data.text;
    scan.value = data.value;
    return scan;
  };

  static fromText = (text: string): Scan => {
    const scan = new Scan();
    scan.isSelected = true;
    scan.text = text;
    scan.value = text;
    return scan;
  };

  static empty = (): Scan => {
    return new Scan();
  };

  isEmpty = (): boolean => {
    return this.value === '';
  };
}
