import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary, Grid, InputAdornment, MenuItem, useTheme } from '@mui/material';
import { SubscriptionPaymentForms } from '@op/shared/src/models';
import { CardPaymentTypeState, SubscriptionPaymentFormsState } from '@op/shared/src/states';
import React, { ReactNode } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IconComponent } from '../../common';
import LocalizationContext from '../../react-i18next/localization-context';
import {
  OPBoldTypograpghy,
  OPSemiBoldTypograpghy,
  OptionsPlayMaskedFormatInput,
  OptionsPlayTextField,
} from '../../styled';
import { BillingInfoWidget } from './billing-info-widget';
import { CreditCardTypeWidget, creditCardType } from './credit-card-type-widget';
import { months, years } from './data-list';

interface IEditCardInfoWidgetProps {
  errors: SubscriptionPaymentForms;
  setErrors: (v: SubscriptionPaymentForms) => void;
}

export const EditCardInfoWidget: React.FC<IEditCardInfoWidgetProps> = ({
  errors,
  setErrors,
}: IEditCardInfoWidgetProps) => {
  const [cardData, setCardData] = useRecoilState(SubscriptionPaymentFormsState);
  const setCardType = useSetRecoilState(CardPaymentTypeState);
  const [billingData, setBillingData] = useRecoilState(SubscriptionPaymentFormsState);
  const yearslist = years();
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.name === 'postalCode' && event.target.value.length > 10) {
      return;
    }
    setBillingData({ ...billingData, [event.target.name]: event.target.value });
    setCardData({ ...cardData, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: '' });
  };

  const handleCardChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length > cardData.cardNumberMaxLength) {
      return;
    }
    setCardData({ ...cardData, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: '' });
  };

  const isCreditCardAccordianError = () => {
    return errors.cardName || errors.cardNumber || errors.cardCvv || errors.cardMonth ? true : false;
  };

  const isBillingAccordianError = () => {
    return errors.address || errors.city || errors.state || errors.postalCode ? true : false;
  };

  const getAccordianHeader = (error: boolean, title: ReactNode) => {
    if (error) {
      return (
        <Grid container alignItems={'center'}>
          <Grid item xs={8}>
            <OPBoldTypograpghy variant="subtitle2">{title}</OPBoldTypograpghy>
          </Grid>
          <Grid item container xs={4} justifyContent="right">
            <OPBoldTypograpghy variant="subtitle2" sx={{ pr: 1 }}>
              {t('app.php.subscriptions.allFieldsRequired')}
            </OPBoldTypograpghy>
            <IconComponent name="warning" stroke={'#ffffff'} />
          </Grid>
        </Grid>
      );
    }
    return <OPBoldTypograpghy variant="subtitle2">{title}</OPBoldTypograpghy>;
  };

  const renderCardAccordian = () => {
    return (
      <div>
        <Accordion defaultExpanded={true} disableGutters elevation={0}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon sx={{ color: '#fff' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              background: isCreditCardAccordianError() ? theme.palette.error.main : theme.palette.primary.main,
              borderTopRightRadius: theme.shape.borderRadius,
              borderTopLeftRadius: theme.shape.borderRadius,
              color: '#fff',
              margin: 0,
            }}>
            {getAccordianHeader(isCreditCardAccordianError(), t('app.php.subscriptions.creditCard'))}
          </AccordionSummary>
          <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: '10px 0px 10px 0px' } }}>
            {renderCardInfo()}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true} disableGutters elevation={0} sx={{ '&.MuiAccordion-root': { marginTop: 4 } }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon sx={{ color: '#fff' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              background: isBillingAccordianError() ? theme.palette.error.main : theme.palette.primary.main,
              borderTopRightRadius: theme.shape.borderRadius,
              borderTopLeftRadius: theme.shape.borderRadius,
              color: '#fff',
              margin: 0,
            }}>
            {getAccordianHeader(isBillingAccordianError(), t('app.php.subscriptions.billingAddress'))}
          </AccordionSummary>
          <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: '10px 0px 10px 0px' } }}>
            <BillingInfoWidget errors={errors} setErrors={setErrors} />
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const renderCardInfo = () => {
    return (
      <Grid container rowSpacing={2}>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={3}>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('app.php.subscriptions.nameOnCard')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item xs={9}>
            <OptionsPlayTextField
              variant="outlined"
              id="cardName"
              name="cardName"
              onChange={handleChange}
              size="small"
              autoFocus
              inputProps={{ style: { textTransform: 'uppercase' } }}
              value={cardData.cardName}
              helperText={errors.cardName}
              error={errors.cardName && errors.cardName !== '' ? true : false}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={3}>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('app.php.subscriptions.cardNumber')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item xs={9}>
            <OptionsPlayTextField
              variant="outlined"
              id="cardNumber"
              name="cardNumber"
              type="number"
              value={cardData.cardNumber}
              onChange={handleCardChange}
              onBlur={() => {
                setCardType(creditCardType(cardData.cardNumber));
              }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CreditCardTypeWidget cardNumber={cardData.cardNumber} />
                  </InputAdornment>
                ),
              }}
              helperText={errors.cardNumber}
              error={errors.cardNumber && errors.cardNumber !== '' ? true : false}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} columnSpacing={2} justifyContent="space-between" alignItems="center">
          <Grid item container xs={7} columnSpacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={5}>
              <OPSemiBoldTypograpghy variant="subtitle2">Month/Year</OPSemiBoldTypograpghy>
            </Grid>
            <Grid item xs={7} columnSpacing={1} container>
              <Grid item xs={6}>
                <OptionsPlayTextField
                  id="outlined-select-currency"
                  select
                  name="cardMonth"
                  size="small"
                  onChange={handleChange}
                  value={cardData.cardMonth}
                  fullWidth
                  helperText={errors.cardMonth}
                  error={errors.cardMonth && cardData.cardMonth === '' ? true : false}
                  variant="outlined">
                  {months.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </OptionsPlayTextField>
              </Grid>
              <Grid item xs={6}>
                <OptionsPlayTextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  name="cardYear"
                  onChange={handleChange}
                  value={cardData.cardYear}
                  fullWidth
                  helperText={errors.cardYear}
                  error={errors.cardYear && cardData.cardYear === '' ? true : false}
                  variant="outlined">
                  {yearslist.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </OptionsPlayTextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={4} justifyContent="space-between" columnSpacing={1} alignItems="center">
            <Grid item xs={3} textAlign="right">
              <OPSemiBoldTypograpghy variant="subtitle2">CVV</OPSemiBoldTypograpghy>
            </Grid>

            <Grid item xs={8}>
              <OptionsPlayTextField
                variant="outlined"
                id="cvv"
                name="cardCvv"
                size="small"
                type="password"
                onChange={handleChange}
                value={cardData.cardCvv}
                helperText={errors.cardCvv}
                error={errors.cardCvv ? true : false}
                fullWidth
                InputProps={{
                  inputComponent: OptionsPlayMaskedFormatInput as any,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return renderCardAccordian();
};
