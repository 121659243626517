import { OptionsPlayBreakpoints } from './options-play-breakpoints';
import { ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    timeFrame: Palette['primary'];
    tolerance: Palette['primary'];
    collapsible: Palette['primary'];
    selectAndTextField: Palette['primary'];
    selection: Palette['primary'];
    globalSelect: Palette['primary'];
    personalSelect: Palette['primary'];
    legButtons: Palette['primary'];
    orangeButton: Palette['primary'];
    symbolCard: Palette['background'];
    header: Palette['background'];
    highChart?: Palette['primary'];
    slider?: Palette['primary'];
    optionGridDisabled?: Palette['primary'];
  }
  interface PaletteOptions {
    timeFrame?: PaletteOptions['primary'];
    tolerance?: PaletteOptions['primary'];
    collapsible?: PaletteOptions['primary'];
    selectAndTextField?: PaletteOptions['primary'];
    selection?: PaletteOptions['primary'];
    globalSelect: PaletteOptions['primary'];
    personalSelect: PaletteOptions['primary'];
    legButtons?: PaletteOptions['primary'];
    orangeButton?: PaletteOptions['primary'];
    symbolCard?: PaletteOptions['background'];
    header?: PaletteOptions['background'];
    highChart?: PaletteOptions['primary'];
    slider?: PaletteOptions['primary'];
    optionGridDisabled?: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    captionSmall: React.CSSProperties;
    body1: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    captionSmall?: React.CSSProperties;
    body1?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionSmall: true;
    body1: true;
  }
}

export const mlDarkTheme: ThemeOptions = {
  breakpoints: {
    values: OptionsPlayBreakpoints,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#1975b9',
      light: '#2C2C2C',
      dark: '#ffffff',
    },
    secondary: {
      main: '#f50057',
      light: '#11181E',
      dark: '#585D61',
    },
    background: {
      default: '#000000',
      paper: '#000000',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255,255,255,0.9)',
    },
    success: {
      light: '#b8e0b9',
      main: '#00A94F',
    },
    error: {
      main: '#EA3E33',
      light: '#ED004F',
    },
    warning: {
      light: '#f4eb01',
      main: '#ffdb8d',
      dark: '#E6740E',
    },
    info: {
      main: '#575757',
      light: '#FFFFFF',
      dark: '#333333', // Used for the heading background
    },
    timeFrame: {
      light: '#84BDF1',
      main: '#5BA0E0',
      dark: '#3585CE',
      contrastText: '#FFFFFF',
    },
    tolerance: {
      light: '#F39B95',
      main: '#F0776D',
      dark: '#E84429',
      contrastText: '#FFFFFF',
    },
    collapsible: {
      main: '#1A1A1A',
    },
    selectAndTextField: {
      light: '#000000',
      main: '#FFFFFF',
    },
    // Op grid selection color
    selection: {
      main: '#BFE0FA',
      light: 'rgb(17,24,30)',
      dark: '#9E9E9E',
      contrastText: '#000000',
    },
    globalSelect: {
      main: '#b8e0b9',
      light: '#b8e0b9',
      dark: '#00A94F',
    },
    personalSelect: {
      main: '#1975b9',
      light: '#1975b9',
    },
    legButtons: {
      main: '#1975b9', //stock
      light: '#b8e0b9', //call
      dark: '#EA3E33', //put
    },
    orangeButton: {
      main: 'rgb(223, 150, 65, 0.2)', // backgroundColr
      light: '#DF9641', //selected border Color
      dark: '#BFE0FA', //  Border color
    },
    symbolCard: {
      default: '#000000',
    },
    header: {
      default: '#000000',
    },
    highChart: {
      main: '#FFFFFFF2',
      dark: '#585D61',
      light: '#FFFFFF',
      contrastText: '#000000'
    },
    slider: {
      main: '#585D61'
    },
    optionGridDisabled: {
      main: '#1F1F1F',
      light: '#BDBDBD',
      contrastText: '#FFFFFF'
    },
    divider: '#686868',
  },
  typography: {
    captionSmall: {
      fontSize: '0.625rem',
      lineHeight: 2,
    },
    body1: {
      fontSize: '0.688rem',
    },
    body2: {
      fontSize: '0.8rem',
    },
    button: {
      fontSize: '0.813rem',
    },
    subtitle1: {
      fontSize: '0.75rem',
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: '20px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 5,
          minHeight: 20,
          textTransform: 'capitalize',
          fontFamily: '"Lato", "sans-serif"',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#0078D4',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 16px 8px 16px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Lato", "sans-serif"',
        },
        button: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          lineHeight: 0.8,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 4,
          textTransform: 'initial',
          margin: 4,
          color: '#FFFFFF',
          backgroundColor: '#1F262B',
          border: '1px solid #686868',
          '&.Mui-selected,&.Mui-selected:hover': {
            color: '#000000',
            backgroundColor: '#1975b9',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: '0.5px dashed #585D61',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
        listbox: {},
        option: {},
      },
    },
  },
};
