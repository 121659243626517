import { IFilter } from '..';

export class SaveFilter {
  title: string = '';
  filters: IFilter[] = [];

  public toJson = () => {
    return JSON.stringify(this);
  };

  static fromJson = (data: SaveFilter): SaveFilter => {
    const savefilter = new SaveFilter();
    savefilter.title = data.title;
    savefilter.filters = data.filters;
    return savefilter;
  };
}
