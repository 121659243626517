import { Grid, LinearProgress, Typography } from '@mui/material';

import { IDataProps } from '@op/shared/src/models';
import LocalizationContext from '../react-i18next/localization-context';
import React from 'react';
import { customizationState } from '@op/shared/src/states';
import { useRecoilValue } from 'recoil';

export interface ITechnicalScoreProps
  extends IDataProps<{ date: string; name: string; symbol: string; value: number }[]> {
  isOnlyScore?: boolean;
}

export const TechnicalScoreWidget: React.FC<ITechnicalScoreProps> = ({ data, isOnlyScore }: ITechnicalScoreProps) => {
  const { t } = React.useContext(LocalizationContext);
  // const theme = useTheme<Theme>();
  const customization = useRecoilValue(customizationState);
  const signal = data?.find((s) => s?.name?.toUpperCase() === 'TechnicalRank'.toUpperCase());
  let techincalScoreInWords = '';
  const isMinimalistic = customization?.isMinimalistic || true;

  if (!signal) {
    return null;
  }
  // const getBackgroundColor = () => {
  //   if (!signal?.value) {
  //     return {
  //       backgroundColor: 'none',
  //     };
  //   }
  //   const rank = signal.value;
  //   let bgColor = theme.palette.error.main;
  //   if (rank >= 1 && rank <= 2) {
  //     bgColor = theme.palette.error.main;
  //     techincalScoreInWords = 'Very Weak';
  //   } else if (rank >= 3 && rank <= 4) {
  //     bgColor = theme.palette.error.main;
  //     techincalScoreInWords = 'Weak';
  //   } else if (rank >= 5 && rank <= 6) {
  //     bgColor = theme.palette.warning.main;
  //     techincalScoreInWords = 'Neutral';
  //   } else if (rank >= 7 && rank <= 8) {
  //     bgColor = theme.palette.success.main;
  //     techincalScoreInWords = 'Strong';
  //   } else if (rank >= 9 && rank <= 10) {
  //     bgColor = theme.palette.success.main;
  //     techincalScoreInWords = 'Very Strong';
  //   } else {
  //     bgColor = 'none';
  //   }
  //   return {
  //     backgroundColor: bgColor,
  //   };
  // };

  const renderTechnicalScore = () => {
    if (!signal || signal.value <= 0) {
      return (
        <Grid container>
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item className="js-technical-score-key">
              <Typography variant="body1">{t('app.php.why.titles.TechnicalScore')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" fontWeight="bold" className="js-technical-score-value">
                {t('app.php.why.titles.noRank')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs sm={8} className="js-technical-score-key">
            <Typography variant="captionSmall">{t('app.php.why.titles.TechnicalScore')}</Typography>
          </Grid>
          <Grid item xs="auto" sm={4} textAlign="right" className="js-technical-score-value">
            <Typography variant="captionSmall">
              {isMinimalistic ? (
                <>
                  <b style={{ paddingRight: '5px' }}>{techincalScoreInWords}</b>
                  <strong>{`${signal.value}/10`}</strong>
                </>
              ) : (
                <>
                  {signal.value}/10 ({techincalScoreInWords})
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
        {isMinimalistic ? null : <LinearProgress variant="determinate" value={Math.round((signal.value / 10) * 100)} />}
      </>
    );
  };

  const renderOnlyScore = () => {
    if (!signal || signal.value <= 0) {
      return null;
    }

    return (
      <Typography variant="body1">
        {t('app.php.why.titles.strength')} {signal.value}
      </Typography>
    );
  };

  if (isOnlyScore) {
    return renderOnlyScore();
  }

  return <>{renderTechnicalScore()}</>;
};
