import { Button, Grid, Typography } from '@mui/material';
import optionPlayMainLogo from '@op/shared/assets/images/optionPlayMainLogo.png';
import { ILeg } from '@op/shared/src/models';
import {
  ActionTypes,
  CombinationType,
  EventType,
  PorfolioAction,
  tradeSimulatorOperationType,
} from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import formatting from '@op/shared/src/models/how/formatting';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { PortfolioAlertModel } from '@op/shared/src/models/portfolio/portfolio-alert-model';
import { Recommendation } from '@op/shared/src/models/portfolio/recommendation-model';
import {
  flippedState,
  howDataState,
  selectedSymbolState,
  strategyModifyState,
  subViewState,
  tradeSimulatorOperationTypeState,
  tradeTicketCombinationState,
} from '@op/shared/src/states';
import {
  portfolioManagementCombinationState,
  selectedRecommendationState,
} from '@op/shared/src/states/portfolio-management-states';
import {
  isSelectedRecommendedStateOpenState,
  portfolioManagementSelectedCombinationState,
  portfolioSelectedCombinationState,
  rollOptimalState,
} from '@op/shared/src/states/portfolio-states';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  CombinationHeadingWidget,
  PlChartWidget,
  PortfolioOptionsPlayScoreWidget,
  ProfitLossWidget,
  RedGreenWidget,
  StrategyCardWidget,
  ToExtendedChange2,
} from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayKeyValueWidget, StrategyDataWidget } from '../single-trade/strategy-data-widget';
import { OutlinedButton } from '../styled';
export interface IPortfolioManagementStrategyWidgetProps {
  id: number;
  onEditCombinationCallback: (action: string, legs?: ILeg[]) => void;
}

export const PortfolioManagementStrategyWidget: React.FC<IPortfolioManagementStrategyWidgetProps> = ({
  id,
  onEditCombinationCallback,
}: IPortfolioManagementStrategyWidgetProps) => {
  const howData = useRecoilValue(howDataState);
  const portfolioCombination = useRecoilValue(portfolioSelectedCombinationState);
  const combination = useRecoilValue(portfolioManagementCombinationState(id));
  const resultingCombination = useRecoilValue(portfolioManagementCombinationState(2));
  const selectedRecommendation = useRecoilValue<Recommendation | undefined>(selectedRecommendationState);
  const rollOptimal = useRecoilValue(rollOptimalState);
  const setPortfolioManagementSelectedCombination = useSetRecoilState(portfolioManagementSelectedCombinationState);
  const setTradeSimulator = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setStrategyModify = useSetRecoilState(strategyModifyState);
  const isFlipped = useRecoilValue(flippedState);
  const { t } = React.useContext(LocalizationContext);
  const setTradeTicket = useSetRecoilState(tradeTicketCombinationState);
  // const setView = useSetRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const setIsSelectedRecommendedStateOpen = useSetRecoilState(isSelectedRecommendedStateOpenState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  if (!combination || !portfolioCombination || !howData) {
    return null;
  }

  const newSentiment = combination.sentiment();

  const logActivity = (controlType: string, controlName: string, value?: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };
  const onTrade = () => {
    setTradeTicket({
      combination: combination,
      resultingCombination: resultingCombination,
    });
    setIsSelectedRecommendedStateOpen(true);
    setSubView('tradeTicket');
  };

  const renderCost = () => {
    switch (combination.combinationType) {
      case CombinationType.PORTFOLIO_ADJUSTMENT:
        throw new Error('Incompatible combination type');
      case CombinationType.PORTFOLIO_CURRENT:
        return (
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <Typography variant="body1" textAlign="right">
                {t('app.php.common.labels.costBasis')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold">
                {NumberFormatHelper.toCurrency(combination.costBasis())}
              </Typography>
            </Grid>
          </Grid>
        );
      case CombinationType.PORTFOLIO_RESULTING:
        return (
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <Typography variant="body1" textAlign="right">
                {t('app.php.how.howPanel.labels.adjustedCostBasis')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold">
                {NumberFormatHelper.toCurrency(combination.adjustedCostBasis())}
              </Typography>
            </Grid>
          </Grid>
        );
      default:
        throw new Error('Incompatible combination type');
    }
  };

  const renderUnrealisedPL = () => {
    const unrealizedPL = combination.unrealizedProfitAndLoss();
    const percentageChange = combination.unrealizedProfitAndLossPercentage();
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                <Typography variant="body1" textAlign="right">
                  {t('app.php.what.portfolio.labels.unrealizedPL')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  <ToExtendedChange2
                    change={unrealizedPL}
                    percentageChange={undefined}
                    asIntFractional={true}
                    numberOfFractionalDigits={0}
                    language="en-US"
                    showCurrencySymbolForNumbers={true}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                <Typography variant="body1" textAlign="right">
                  {t('app.php.how.toolbox.whatIfSimulator.text.return')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  <RedGreenWidget value={unrealizedPL}>
                    {NumberFormatHelper.toPercentage(percentageChange, 'never')}
                  </RedGreenWidget>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderCurrentCombinationIfPossible = () => {
    if (!combination.isCurrent) {
      return undefined;
    }
    return <>{renderCombination(0)}</>;
  };

  const renderRecommendedCombinationIfPossible = () => {
    if (!combination.isAdjustment) {
      return undefined;
    }
    return (
      <StrategyCardWidget
        title={renderStrategyTitle()}
        chartWidget={<PlChartWidget data={combination} showAxis={false} />}
        actionArea={
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              {renderRecommendations()}
            </Grid>
            <Grid item xs={12}>
              {renderStrategyControls()}
            </Grid>
          </Grid>
        }
        disableActionArea
        strategyControls={
          <Typography variant="body1" fontWeight="bold" textAlign="center">
            {combination.combinationName}
          </Typography>
        }
        isActive={true}
      />
    );
  };

  const onSelectRecommendation = (recommendation: Recommendation | undefined) => {
    if (!recommendation) {
      return undefined;
    }
    setTradeSimulator(tradeSimulatorOperationType.Modify);
    onEditCombinationCallback(ActionTypes.Recommendation, recommendation.legs);
    logActivity('BUTTON', 'positionsManagementSelectedRecommendation', recommendation.adjustmentText);
  };

  const onClickModify = () => {
    setStrategyModify(combination);
    setPortfolioManagementSelectedCombination(combination);
    setTradeSimulator(tradeSimulatorOperationType.Modify);
    logActivity('BUTTON', 'positionsManagementEditRecommentadionButton');
  };

  const renderRecommendation = (r: Recommendation) => {
    return (
      <OutlinedButton
        startIcon={
          r.isAlert ? <img src={optionPlayMainLogo} style={{ width: 25, height: 25 }} alt="OptionsPlay logo" /> : null
        }
        color="primary"
        fullWidth
        onClick={() => onSelectRecommendation(r)}>
        <Typography variant="button">{r?.adjustmentText}</Typography>
      </OutlinedButton>
    );
  };

  const renderClosePositionRecommendation = () => {
    if (selectedRecommendation && !selectedRecommendation.isClosed) {
      return;
    }
    const legs = portfolioCombination.getLegsToClosePosition();
    return renderRecommendation(Recommendation.fromAdjustment(PorfolioAction.CLOSE_POSITION, legs));
  };

  const renderRollPositionRecommendation = () => {
    if (selectedRecommendation && !selectedRecommendation.isRoll) {
      return;
    }
    const legs = PortfolioAlertModel.getRollPositionLegsForOptimalCall(howData, portfolioCombination, rollOptimal);
    if (legs.length === 0) {
      return undefined;
    }
    return renderRecommendation(Recommendation.fromAdjustment(PorfolioAction.ROLL_POSITION, legs));
  };

  const renderAlertRecommendationIfPossible = () => {
    let recommendation: Recommendation | undefined = selectedRecommendation;
    if (!recommendation) {
      return;
    }
    if (recommendation.adjustmentText === 'Roll Position') {
      const legs = PortfolioAlertModel.getRollPositionLegsForOptimalCall(howData, portfolioCombination, rollOptimal);
      recommendation = Recommendation.fromAdjustment(PorfolioAction.ROLL_POSITION, legs);
      recommendation.isAlert = true;
      // recommendation.isRoll = true;
    }
    return renderRecommendation(recommendation);
  };

  const renderRecommendations = () => {
    if (!combination.isAdjustment) {
      return undefined;
    }

    return (
      <Grid container justifyContent="center" rowSpacing={1}>
        <Grid item xs={9}>
          {renderAlertRecommendationIfPossible()}
        </Grid>
        <Grid item xs={9}>
          {renderClosePositionRecommendation()}
        </Grid>
        <Grid item xs={9}>
          {renderRollPositionRecommendation()}
        </Grid>
      </Grid>
    );
  };

  //TODO: Fix the translation to get parametrized conversion.
  const renderClosedStrategyTitle = () => {
    const title = t('app.php.how.positionsManagement.labels.closedPosition')?.toString();
    return title?.replace('{SYMBOL}', formatting.symbolDotExchangeToSymbol(combination.symbol)) || '';
  };

  const renderResultantCombinationIfPossible = () => {
    if (!combination.isResulting) {
      return undefined;
    }
    if (combination.isClosedPosition()) {
      return renderClosedStrategy();
    }
    return renderCombination(2);
  };

  const renderClosedStrategy = () => {
    const unrealizedProfitAndLossPercentage = combination.unrealizedProfitAndLossPercentage();

    return (
      <StrategyCardWidget
        title={
          <Typography variant="subtitle2" textAlign="center" lineHeight={1.2}>
            {t('app.php.how.positionsManagement.titles.resultingPL')}
          </Typography>
        }
        chartWidget={
          <Grid container columnSpacing={1} justifyContent="center" textAlign="center" ml={0} sx={{ height: '100%' }}>
            <Grid item container xs={12} justifyContent="center" textAlign="center" sx={{ py: '4px' }}>
              <OptionsPlayKeyValueWidget
                label={<Typography variant="body1">{t('app.php.common.labels.costBasis')}</Typography>}
                value={
                  <Typography fontWeight="bold" variant="body1" textAlign="left">
                    {NumberFormatHelper.toFractionalString(combination.adjustedCostBasis(true))}
                  </Typography>
                }
              />
            </Grid>
            <Grid item container xs={12} justifyContent="center" textAlign="center">
              <OptionsPlayKeyValueWidget
                label={
                  <Typography variant="body1"> {t('app.php.how.positionsManagement.labels.currentMid')}</Typography>
                }
                value={
                  <Typography variant="body1" fontWeight="bold" textAlign="left">
                    {NumberFormatHelper.toFractionalString(combination.midPrice())}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="subtitle1">{t('app.php.how.positionsManagement.labels.yourPLWouldBe')}:</Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="body1" textAlign="center" fontWeight="bold">
                  <RedGreenWidget value={combination.unrealizedProfitAndLoss()}>
                    {NumberFormatHelper.toCurrency(combination.unrealizedProfitAndLoss(), 'exceptZero')}
                  </RedGreenWidget>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" textAlign="center" fontWeight="bold">
                  <RedGreenWidget value={combination.unrealizedProfitAndLoss()}>
                    {NumberFormatHelper.toPercentage(unrealizedProfitAndLossPercentage, 'never')}
                  </RedGreenWidget>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {unrealizedProfitAndLossPercentage && unrealizedProfitAndLossPercentage >= 50 ? (
                  <img
                    src={optionPlayMainLogo}
                    style={{
                      width: '70px',
                      height: '70px',
                      padding: 5,
                    }}
                    alt="OptionsPlay logo"
                  />
                ) : undefined}
              </Grid>
            </Grid>
          </Grid>
        }
        strategyControls={
          <Typography variant="body1" fontWeight="bold" textAlign="center">
            {renderClosedStrategyTitle()}
          </Typography>
        }
        isActive={true}
        disableActionArea={true}
      />
    );
  };

  const renderCombination = (combinationId: number) => {
    return (
      <StrategyCardWidget
        title={renderStrategyTitle()}
        chartWidget={<PlChartWidget data={combination} showAxis={false} />}
        actionArea={renderCombinationActionArea(combinationId)}
        strategyControls={
          <Typography variant="body1" fontWeight="bold" textAlign="center">
            {combination.combinationName}
          </Typography>
        }
        isActive={true}
      />
    );
  };

  const renderCombinationActionArea = (combinationId: number) => {
    if (isFlipped) {
      return <StrategyDataWidget data={combination} />;
    }
    if (combinationId !== 0) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} container columnSpacing={1}>
            <Grid item xs={6}>
              <Typography variant="body1" textAlign="right">
                {t('app.php.how.tradingStrategies.labels.strategy')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold">
                <RedGreenWidget value={newSentiment}>{newSentiment}</RedGreenWidget>
              </Typography>
            </Grid>
          </Grid>
          {renderCost()}
          <Grid item xs={12}>
            <ProfitLossWidget data={combination} />
          </Grid>
          <Grid item xs={12}>
            <PortfolioOptionsPlayScoreWidget index={combinationId} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} container columnSpacing={1}>
          <Grid item xs={6}>
            <Typography variant="body1" textAlign="right">
              {t('app.php.how.tradingStrategies.labels.strategy')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold">
              <RedGreenWidget value={newSentiment}>{newSentiment}</RedGreenWidget>
            </Typography>
          </Grid>
        </Grid>
        {renderCost()}
        <Grid item xs={12}>
          {renderUnrealisedPL()}
        </Grid>
        <Grid item xs={12}>
          <PortfolioOptionsPlayScoreWidget index={combinationId} />
        </Grid>
      </Grid>
    );
  };

  const renderStrategyTitle = () => {
    const titles = combination.isAdjustment ? combination.adjustmentNameInPortfolio : combination.nameInPortfolio;
    const title = Array.isArray(titles) ? titles.join(' ') : titles;
    return <CombinationHeadingWidget text={title} />;
  };

  const renderStrategyControls = () => {
    return (
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          <Button
            id={`index${id}`}
            variant="contained"
            name="trade"
            color="primary"
            data-name="portfolioTradeCombinationButtonBelowChart"
            disabled={combination.positions.length > 0 ? false : true}
            onClick={() => onTrade()}
            fullWidth>
            <Typography variant="button">{t('app.php.common.buttons.trade')}</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <OutlinedButton
            id={`index${id}`}
            name="modify"
            color="primary"
            data-name="portfolioModifyCombinationButtonBelowChart"
            onClick={onClickModify}
            fullWidth>
            <Typography variant="button">{t('app.php.common.buttons.modify')}</Typography>
          </OutlinedButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {renderCurrentCombinationIfPossible()}
      {renderRecommendedCombinationIfPossible()}
      {renderResultantCombinationIfPossible()}
    </>
  );
};
