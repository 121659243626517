import { LegType, PriceCalculationMethod } from '@op/shared/src/models/enums/enums';
import { ExpandedQuote, ILeg, Leg, OptionChain } from '.';
import { BasicPositionModel } from './basic-position-model';
import formatting from './formatting';

export class Position extends BasicPositionModel {
  private constructor() {
    super();
  }

  public static fromSelf(position: Position | BasicPositionModel) {
    let clone = new Position();

    // clone.legData = Leg.fromSelf(legData);
    clone.underlyingQuote = position.underlyingQuote;
    clone.positionQuote = position.positionQuote;
    clone.optionType = position.optionType || 'S';
    clone.selectedPrice = position.selectedPrice;
    clone.isPortfolio = position.isPortfolio;
    clone.chain = position.chain;

    clone.hash = position.hash;
    clone.symbol = position.underlyingQuote.symbol;
    clone.ulSymbol = position.positionQuote.symbol;
    clone.costBasis = position.costBasis;
    clone.legType = position.legType;
    clone.type = position.legType;

    clone.strike = position.strike;
    clone.strikePrice = position.strikePrice;
    clone.quantity = position.quantity;
    clone.buyOrSell = position.buyOrSell;
    clone.dividendYield = position.dividendYield;
    clone.expiry = position.expiry;
    clone.multiplier = position.multiplier;
    clone.repeated = position.repeated;
    // clone.price = position.price;
    // clone.ownedQuantity = position.isOwned() ? position.quantity : 0;
    clone.ownedQuantity = position.ownedQuantity;
    clone.shortCode = position.shortCode;
    clone.closePositionType = position.closePositionType;
    return clone;
  }

  static fromLeg = (
    legData: Leg,
    chain: OptionChain | undefined,
    quote: ExpandedQuote,
    optionType: string,
    selectedPrice: PriceCalculationMethod,
    isPortfolio: boolean,
  ) => {
    const position = new Position();
    const positionQuote = ExpandedQuote.fromSelf(quote);
    const clonedLeg = Leg.fromSelf(legData);
    position.fromLeg(clonedLeg, quote, positionQuote, optionType, selectedPrice, isPortfolio);
    position.chain = chain;
    position.multiplier = position.premiumMultiplier();
    // TODO: This should move to basic position constructor or expirty should be a getter.
    const isSecurityLeg = legData?.legType?.trim().toUpperCase() === LegType.SECURITY.toUpperCase();
    if (isSecurityLeg) {
      position.expiry = undefined;
      //TODO: There should be one property for strike not two i.e `strike` and `strikePrice`.
      position.strike = undefined;
      position.strikePrice = undefined;
    } else if (position.chain !== undefined) {
      position.expiry = position.chain.findExpiry(position.expiry, 0, 0, optionType);
    }

    if (position.isSecurityType() || position.chain === undefined) {
      const quote = position.underlyingQuote;
      if (!position.positionQuote) {
        throw new Error('positionQuote is undefined');
      }
      position.positionQuote.last = quote.last;
      position.positionQuote.bid = quote.bid;
      position.positionQuote.ask = quote.ask;
    } else {
      const row = position.chain.findRow(position.strike?.toString() || '', position.expiry, position.optionType);
      if (row) {
        const t = position.type?.toLowerCase();
        if (t?.trim()?.toLowerCase() === 'put') {
          position.positionQuote.last = row.putAsk;
          position.positionQuote.bid = row.putBid;
          position.positionQuote.ask = row.putAsk;
          position.shortCode = row.putShortCode;
        } else {
          position.positionQuote.last = row.callAsk;
          position.positionQuote.bid = row.callBid;
          position.positionQuote.ask = row.callAsk;
          position.shortCode = row.callShortCode;
        }
      }
    }

    return position;
  };

  //NOTE: Moved from basic-combination-model.ts
  static extractPositions = (positions: BasicPositionModel[]) => {
    if (!positions) {
      throw new Error('PositionCopy cannot be null or undefined');
    }
    let extractedPositions: BasicPositionModel[] = [];
    let k;
    for (let i = 0; i < positions.length; i++) {
      let pos = positions[i];
      let replica = false;
      for (k = 0; k < extractedPositions.length; k++) {
        let p = extractedPositions[k];
        replica =
          p.legType === pos.type &&
          (pos.type.toUpperCase().trim() === LegType.SECURITY.toString().toUpperCase() ||
            (formatting.sameDate(pos.expiry, p.expiry) && pos.strikePrice === p.strikePrice));
        if (replica) {
          p.quantity += pos.quantity;
          p.ownedQuantity += pos.isOwned() ? pos.quantity : 0;
          p.repeated = true;
          break;
        }
      }
      if (!replica) {
        let clone = Position.fromSelf(pos);
        clone.repeated = false;
        extractedPositions.push(clone);
      }
    }

    extractedPositions = extractedPositions.filter((position) => {
      return position.quantity !== 0;
    });

    extractedPositions.sort((p1, p2) => {
      if (p1.legType.toUpperCase().trim() === LegType.SECURITY.toString().toUpperCase()) {
        return -1;
      }
      if (p2.legType.toUpperCase().trim() === LegType.SECURITY.toString().toUpperCase()) {
        return 1;
      }
      let result = (p1.strikePrice || 0) - (p2.strikePrice || 0);
      if (!result) {
        result = (p1.expiry?.getTime() || 0) - (p2.expiry?.getTime() || 0);
      }
      if (!result) {
        result = p1.legType.toUpperCase().trim() === LegType.CALL.toString().toUpperCase() ? -1 : 1;
      }
      return result;
    });
    return extractedPositions;
  };

  strikeList = (): Array<number> | undefined => {
    if (!this.isSecurityType()) {
      let e = this.expiry;
      return this.chain ? this.chain.getStrikeListForExpiry(e, this.optionType) : undefined;
    }
    return [];
  };
}
