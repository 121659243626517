import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { IPortfolioAccount } from '@op/shared/src/models';
import { debounce } from 'lodash';
import React from 'react';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayPortfolioTableWidget, ScrollableBox } from '../styled';

export interface SaveAccountDialogProps {
  selectedAccountData: IPortfolioAccount[];
  onUpdateAccounts: (value: string[]) => void;
  onCloseAccountsDialog: () => void;
}

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const MuiDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, pt: 1, pb: 1, pl: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 10, top: 10 }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const SaveAccountsDialog: React.FC<SaveAccountDialogProps> = ({
  selectedAccountData,
  onUpdateAccounts,
  onCloseAccountsDialog,
}: SaveAccountDialogProps) => {
  const [open, setOpen] = React.useState(true);
  const { t } = React.useContext(LocalizationContext);
  const [editData, setEditData] = React.useState<IPortfolioAccount[]>(selectedAccountData);

  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
    onCloseAccountsDialog();
  };

  const handleSave = async () => {
    //  setOpen(false);
    let filteredValues = editData.filter((el) => el.enabled === true).map(({ accountName }) => accountName);
    await onUpdateAccounts(filteredValues);
  };

  const debouncedSave = debounce(handleSave, 1000);

  const onCheckAccount = (event: boolean, index: number) => {
    editData[index].enabled = event;
    setEditData([...editData]);
  };

  const renderAccounts = () => {
    return editData.map((acc, index) => {
      return (
        <TableRow key={index}>
          <TableCell align="left" sx={{ width: '50%' }}>
            {acc.accountName}
          </TableCell>
          <TableCell align="left" sx={{ width: '30%' }}>
            {acc.accountType}
          </TableCell>
          <TableCell sx={{ width: '20%' }}>
            <Checkbox
              size="small"
              checked={acc.enabled}
              onChange={(e: any) => onCheckAccount(e.target.checked, index)}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderAccountsData = () => {
    return (
      <OptionsPlayPortfolioTableWidget>
        <TableHead>
          <TableRow>
            <TableCell align="left">Account Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Sync</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderAccounts()}</TableBody>
      </OptionsPlayPortfolioTableWidget>
    );
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ sx: { minWidth: '25%', maxWidth: '40%', borderRadius: '15px' } }}>
      <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>
        TD Ameritrade Accounts
      </MuiDialogTitle>
      <DialogContent dividers sx={{ p: 1 }}>
        <TableContainer>
          <ScrollableBox sx={{ minHeight: '40%', width: '100%', background: theme.palette.background.paper }}>
            {!editData || editData.length === 0 ? (
              <Typography textAlign={'center'} variant="h6">
                No accounts available
              </Typography>
            ) : (
              renderAccountsData()
            )}
          </ScrollableBox>
        </TableContainer>
      </DialogContent>
      {editData && editData.length != 0 && (
        <DialogActions>
          <Button color="primary" variant="contained" onClick={debouncedSave}>
            <Typography variant="button">Sync Now</Typography>
          </Button>
          <Button color="primary" variant="contained" onClick={handleClose}>
            <Typography variant="button">{t('app.php.common.buttons.cancel')}</Typography>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
