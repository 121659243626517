import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Theme, Typography } from '@mui/material';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import { whatIfSimulatorState } from '@op/shared/src/states/how/how-states';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayPercentageFormatInputWidget, OptionsPlaySlider, OptionsPlayThumbComponent } from '../styled';
import { OptionsPlayTextField } from '../styled/options-play-components';
import { PLSimulatorSubWidget } from './pl-simulator-sub-widget';
import { GuideItem } from '../side-menu';
import { selectedSymbolState } from '@op/shared/src/states';

export interface IWhatIfImpliedVolatilityWidgetProps {}

export const WhatIfImpliedVolatilityWidget: React.FC<IWhatIfImpliedVolatilityWidgetProps> = () => {
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [whatIfSimulator, setWhatIfSimulator] = useRecoilState(whatIfSimulatorState);
  //whatIfVolatility is the value(number type) used for all calculations.
  const [whatIfVolatility, setWhatIfVolatility] = useState<number>(0);
  //WhatIfVolatitliyInputValue state is to represent text field value. It should be string to allow empty when user deletes everything.
  const [whatIfVolatilityInputValue, setWhatIfVolatilityInputValue] = useState('');
  const { t } = React.useContext(LocalizationContext);
  useEffect(() => {
    if (!whatIfSimulator) {
      return;
    }
    const volatility = whatIfSimulator.whatIfVolatility;
    setWhatIfVolatility(volatility);
    setWhatIfVolatilityInputValue(volatility.toFixed(2));
  }, [whatIfSimulator]);

  const onSliderChangeCommittedVolatility = (whatIfVol: number | number[]) => {
    if (!whatIfSimulator) {
      return;
    }
    const newWhatIfSimulator = WhatIfSimulator.fromSelf(whatIfSimulator);
    newWhatIfSimulator.whatIfVolatility = whatIfVol as number;
    setWhatIfSimulator(newWhatIfSimulator);
    ApplicationContext.userActivityHub?.logActivity(
      'whatIfVolatilityInput',
      'whatIfVolatilityInput',
      `${whatIfVol}`,
      'slidechange',
      selectedSymbol,
    );
  };

  const debouncedOnSliderChangeForVolatility = useMemo(
    () => debounce(onSliderChangeCommittedVolatility, 50),
    [whatIfSimulator],
  );

  // WhatIfSimulator can be undefined, if the security does not have an option chain.
  if (!whatIfSimulator) {
    return null;
  }

  const maxVolatility = whatIfSimulator.maxVolatility();
  const minVolatility = whatIfSimulator.minVolatility();

  const onKeyPressVolatility = (key: string) => {
    if (key.trim().toUpperCase() !== 'ENTER') {
      return;
    }
    let volatitlity = whatIfVolatility;
    if ((whatIfVolatility || 0) > maxVolatility) {
      volatitlity = maxVolatility;
    }
    if ((whatIfVolatility || 0) < minVolatility) {
      volatitlity = minVolatility;
    }
    setWhatIfVolatility(volatitlity);
    setWhatIfVolatilityInputValue(volatitlity.toFixed(2) || '');
    ApplicationContext.userActivityHub?.logActivity(
      'whatIfVolatilityInput',
      'whatIfVolatilityInput',
      `${volatitlity?.toFixed(2)}`,
      'blur',
      selectedSymbol,
    );
    updateWhatIfVolatility(volatitlity);
  };

  const updateWhatIfVolatility = (volatitlity?: number): void => {
    const newWhatIfSimulator = WhatIfSimulator.fromSelf(whatIfSimulator);
    if (whatIfVolatility === undefined) {
      return;
    }
    newWhatIfSimulator.whatIfVolatility = volatitlity || whatIfVolatility;
    setWhatIfSimulator(newWhatIfSimulator);
  };

  const onSliderChangeVolatility = (event: Event, value: number | number[], activeThumb: number) => {
    setWhatIfVolatilityInputValue((value as number).toFixed(2));
    setWhatIfVolatility(value as number);
    debouncedOnSliderChangeForVolatility(value);
  };

  const renderVolatilitySlider = (whatIfVolatilityState: number) => {
    // All from left.
    const midSprice = (maxVolatility + minVolatility) / 2;
    const sliderLength = maxVolatility - minVolatility;
    const carretLength = midSprice - minVolatility;
    const thumbLength = whatIfVolatility - minVolatility;
    const carretPercentage = (carretLength / sliderLength) * 100;
    const thumbPercentage = (thumbLength / sliderLength) * 100;

    const getBackgroundGradient = (theme: Theme) => {
      const defaultColor = theme.palette.slider.main;
      const errorColor = theme.palette.error.main;
      const successColor = theme.palette.success.main;
      if (thumbPercentage <= carretPercentage) {
        return `linear-gradient(90deg, ${defaultColor} ${thumbPercentage}%, ${errorColor} ${thumbPercentage}% ${carretPercentage}%, ${defaultColor} ${carretPercentage}%)`;
      }
      return `linear-gradient(90deg, ${defaultColor} ${carretPercentage}%, ${successColor} ${carretPercentage}% ${thumbPercentage}%, ${defaultColor} ${thumbPercentage}%)`;
    };

    return (
      <Box sx={{ position: 'relative' }}>
        <OptionsPlaySlider
          components={{ Thumb: OptionsPlayThumbComponent }}
          id="slide-whatIfVolatilityInput"
          data-name="whatIfVolatilityInput"
          data-value={whatIfSimulator.whatIfVolatility}
          value={whatIfVolatilityState}
          onChange={onSliderChangeVolatility}
          // onChangeCommitted={(event: any, value: number | number[]) => onSliderChangeCommittedVolatility(value)}
          min={minVolatility}
          max={maxVolatility}
          step={0.01}
          aria-labelledby="discrete-slider"
          track={false}
          sx={{
            '& .MuiSlider-rail': {
              height: 5,
              borderRadius: 0.3,
              background: getBackgroundGradient,
            },
          }}
        />
        <KeyboardArrowUpIcon
          fontSize="small"
          onClick={() => onSliderChangeCommittedVolatility(midSprice)}
          sx={{
            fontWeight: 'bold',
            position: 'absolute',
            top: '15px',
            left: `${carretPercentage}%`,
            transform: `translate(-50%, 0%)`,
            color: 'primary.main',
            cursor: 'pointer',
          }}
        />
      </Box>
    );
  };

  return (
    <React.Fragment>
      <GuideItem selector=".volatilitySlider_helpPinPlaceholder" />
      <PLSimulatorSubWidget
        label={
          <Typography id="range-slider" variant="body1">
            {t('app.php.how.toolbox.whatIfSimulator.labels.volatilityIs')}
          </Typography>
        }
        box={
          <OptionsPlayTextField
            variant="outlined"
            fullWidth
            value={whatIfVolatilityInputValue}
            onChange={(event: any) => {
              setWhatIfVolatilityInputValue(event.values.value);
              setWhatIfVolatility(event.values.floatValue);
            }}
            onKeyPress={(event) => onKeyPressVolatility(event.key)}
            onBlur={() => onKeyPressVolatility('ENTER')}
            name="whatIfVolatility"
            className="op-formatted-textbox"
            id="what-if-volatility"
            InputProps={{
              inputComponent: OptionsPlayPercentageFormatInputWidget as any,
            }}
          />
        }
        leftLabel={
          <Typography variant="body1" sx={{ pr: 1 }}>
            {NumberFormatHelper.toPercentage(minVolatility)}
          </Typography>
        }
        slider={renderVolatilitySlider(whatIfVolatility || 0)}
        rightLabel={
          <Typography variant="body1" textAlign="right" sx={{ pl: 1 }}>
            {NumberFormatHelper.toPercentage(maxVolatility)}
          </Typography>
        }
      />
    </React.Fragment>
  );
};
