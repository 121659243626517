export interface IExpandedQuote {
  extendedChangeFormatted: any; //TODO: What is exchange formattted. we should (may be) get rid of it.
  hasOption: boolean;
  week52High: number;
  week52HighDate?: any;
  week52Low: number;
  week52LowDate?: any;
  beta: number;
  cusip?: any;
  eps: number;
  div: number;
  divExDate?: string;
  divFrequency?: any;
  divPayDate?: any;
  iad?: any;
  peRatio: number;
  prBookRatio?: any;
  sharesOutstanding: number;
  yield: number;
  sector: string;
  industry: string;
  currency: string;
  marketCapitalization: number;
  name: string;
  tradeTick?: any;
  bid: number;
  ask: number;
  changeSign: string;
  time?: any;
  vWap: number;
  option?: any;
  exchange: string;
  isTradeable: boolean;
  symbol: string;
  volume: number;
  last: number;
  change: number;
  percentageChange: number;
}

export class ExpandedQuote implements IExpandedQuote {
  extendedChangeFormatted: any;
  hasOption: boolean = false;
  week52High: number = 0;
  week52HighDate?: string;
  week52Low: number = 0;
  week52LowDate?: string;
  beta: number = 0;
  cusip?: any;
  eps: number = 0;
  div: number = 0;
  divExDate?: string;
  divFrequency?: number;
  divPayDate?: Date;
  iad?: any;
  peRatio: number = 0;
  prBookRatio?: number;
  sharesOutstanding: number = 0;
  yield: number = 0;
  sector: string = '';
  industry: string = '';
  currency: string = '';
  marketCapitalization: number = 0;
  name: string = '';
  tradeTick?: any;
  bid: number = 0;
  ask: number = 0;
  changeSign: string = '';
  time?: any;
  vWap: number = 0;
  option?: any;
  exchange: string = '';
  isTradeable: boolean = false;
  symbol: string = '';
  volume: number = 0;
  last: number = 0;
  change: number = 0;
  percentageChange: number = 0;

  static fromSelf = (other: ExpandedQuote) => {
    let clone = new ExpandedQuote();
    clone.extendedChangeFormatted = other.extendedChangeFormatted;
    clone.hasOption = other.hasOption;
    clone.week52High = other.week52High;
    clone.week52HighDate = other.week52HighDate;
    clone.week52Low = other.week52Low;
    clone.week52LowDate = other.week52LowDate;
    clone.beta = other.beta;
    clone.cusip = other.cusip;
    clone.eps = other.eps;
    clone.div = other.div;
    clone.divExDate = other.divExDate;
    clone.divFrequency = other.divFrequency;
    clone.divPayDate = other.divPayDate;
    clone.iad = other.iad;
    clone.peRatio = other.peRatio;
    clone.prBookRatio = other.prBookRatio;
    clone.sharesOutstanding = other.sharesOutstanding;
    clone.yield = other.yield;
    clone.sector = other.sector;
    clone.industry = other.industry;
    clone.currency = other.currency;
    clone.marketCapitalization = other.marketCapitalization;
    clone.name = other.name;
    clone.tradeTick = other.tradeTick;
    clone.bid = other.bid;
    clone.ask = other.ask;
    clone.changeSign = other.changeSign;
    clone.time = other.time;
    clone.vWap = other.vWap;
    clone.option = other.option;
    clone.exchange = other.exchange;
    clone.isTradeable = other.isTradeable;
    clone.symbol = other.symbol;
    clone.volume = other.volume;
    clone.last = other.last;
    clone.change = other.change;
    clone.percentageChange = other.percentageChange;
    return clone;
  };

  static fromSample = (symbols: string[]) => {
    const model: ExpandedQuote = {
      extendedChangeFormatted: undefined,
      hasOption: true,
      week52High: 182.94,
      week52HighDate: undefined,
      week52Low: 118.86,
      week52LowDate: undefined,
      beta: 1.2143,
      cusip: null,
      eps: 5.614,
      div: 0.88,
      divExDate: '2021-08-06T00:00:00Z',
      divFrequency: undefined,
      divPayDate: undefined,
      iad: null,
      peRatio: 26.32,
      prBookRatio: undefined,
      sharesOutstanding: 16427,
      yield: 0.5599999999999999,
      sector: 'Electronic Technology',
      industry: 'Telecommunications Equipment',
      currency: 'USD',
      marketCapitalization: 2586951288000,
      name: 'Apple Inc',
      tradeTick: null,
      bid: 154.75,
      ask: 154.84,
      changeSign: 'd',
      time: null,
      vWap: 0,
      option: null,
      exchange: 'XNAS',
      isTradeable: true,
      symbol: 'AAPL',
      volume: 96665232,
      last: 154.73,
      change: 3.79,
      percentageChange: -2.391,
    };
    const quotes = [];
    for (let symbol of symbols) {
      const clone = ExpandedQuote.fromSelf(model);
      clone.symbol = symbol;
      clone.ask += Math.random() * 10;
      clone.bid += Math.random() * 10;
      clone.last += Math.random() * 10;
      quotes.push(clone);
    }
    return quotes;
  };
}
