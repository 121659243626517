import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { PowerhouseProService } from '@op/shared/src/services';
import { EmaildeliveryState, isConfigurationUpdatedState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayDivider } from '../styled';

export const PortfolioAlert: React.FC = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const response = useRecoilValue(EmaildeliveryState);
  const frequencyList = response.data || [];
  const configuration = ApplicationContext.configuration;
  const data = configuration?.applicationConfiguration?.applicationPreferences.portfolioRecommendationsEmailDelivery;
  const [checked, setChecked] = useState(data?.sendOPPortfolioRecommendationsByEmail);
  const [frequency, setFrequency] = React.useState(data?.opPortfolioRecommendationsEmailDeliveryFrequency);
  const { t } = React.useContext(LocalizationContext);
  const [isConfigurationChanged, setIsConfigurationChanged] = useRecoilState(isConfigurationUpdatedState);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, newVal: boolean) => {
    setChecked(newVal);
  };

  const handleDropDownChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setFrequency(event.target.value as string);
  };

  const handleUpdate = async () => {
    const data = [
      {
        Keys: [
          'applicationPreferences',
          'portfolioRecommendationsEmailDelivery',
          'sendOPPortfolioRecommendationsByEmail',
        ],
        Value: checked,
      },
      {
        Keys: [
          'applicationPreferences',
          'portfolioRecommendationsEmailDelivery',
          'opPortfolioRecommendationsEmailDeliveryFrequency',
        ],
        Value: frequency,
      },
    ];
    const clonedConfiguration = cloneDeep(configuration);
    clonedConfiguration.applicationConfiguration.applicationPreferences.portfolioRecommendationsEmailDelivery.sendOPPortfolioRecommendationsByEmail =
      checked;
    clonedConfiguration.applicationConfiguration.applicationPreferences.portfolioRecommendationsEmailDelivery.opPortfolioRecommendationsEmailDeliveryFrequency =
      frequency;
    ApplicationContext.configuration = clonedConfiguration;

    const editResponse = await PowerhouseProService.instance.PortfolioAlert(data);
    if (editResponse?.isSuccess === true) {
      setIsConfigurationChanged(isConfigurationChanged + 1);
      setNotifications([{ type: 'success', content: `${t('app.php.profile.common.js.yourSettingsHaveBeenUpdated')}` }]);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={
            <Typography variant="h6">
              {t('app.php.profile.portfolioAlertsDelivery.titles.optionsPlayPortfolioAlertsDelivery')}
            </Typography>
          }
          sx={{ pb: 0 }}
        />
        <OptionsPlayDivider />
        <CardContent>
          <FormControlLabel
            control={<Checkbox checked={checked} size="small" onChange={handleChange} color="primary" />}
            label={
              <Typography variant="subtitle1">
                {t('app.php.profile.portfolioAlertsDelivery.labels.deliverByEmail')?.toString() || ''}
              </Typography>
            }
          />
        </CardContent>

        <CardHeader
          title={
            <Typography variant="subtitle1">
              {t('app.php.profile.portfolioAlertsDelivery.titles.emailDeliveryFrequencySettings')}
            </Typography>
          }
          sx={{ pb: 0 }}
        />
        <OptionsPlayDivider />
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Select Frequency</InputLabel>
                <Select
                  sx={{ width: 300 }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={frequency}
                  onChange={handleDropDownChange}
                  label="Select Frequency">
                  {frequencyList.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.localizedName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button id="update" color="primary" variant="contained" onClick={handleUpdate}>
              <Typography variant="button">{t('app.php.common.buttons.update')}</Typography>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
