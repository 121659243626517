import { Sector } from '..';
import { ICollection } from '..';

export class Sectors implements ICollection<Sector>, IterableIterator<Sector> {
  private pointer = 0;
  data: Sector[] = [];

  public constructor(sectors: Sector[]) {
    this.data = [...sectors];
  }

  public next(): IteratorResult<Sector> {
    if (this.pointer < this.data.length) {
      return {
        done: false,
        value: this.data[this.pointer++],
      };
    } else {
      return {
        done: true,
        value: null,
      };
    }
  }

  [Symbol.iterator](): IterableIterator<Sector> {
    return this;
  }
}
