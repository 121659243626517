import {
  ICallOptimal,
  IExpandedQuote,
  ILeg,
  IncomeCombination,
  IOptimal,
  IPutOptimal,
  Leg,
  OptionChain,
  Predictions,
  StandardDeviation,
} from '.';
import { ICoveredCallOptimalPriorityMapping, SentimentType } from '..';
import { LegType, OptionType, PriceCalculationMethod } from '../enums/enums';
import ApplicationContext from './application-context';
import { CoveredCallExtensions } from './coveredCallExtensions';
import DateTimeHelper from './date-time-helper';
import HowDataModel from './how-data-model';

export interface IBuilderData {
  quote: IExpandedQuote;
  chain: OptionChain;
  stdDev: StandardDeviation;
  predictions: Predictions;
}

export class IncomeBuilder {
  private _quote: IExpandedQuote;
  private _chain: OptionChain;
  private _stdDev: StandardDeviation;
  private _predictions: Predictions;
  private _shares = 100;
  private _costBasisPerUnit = 0;
  private _timeframe: 'Short' | 'Medium' | 'Long' = 'Medium';
  private _aggressive: 'Conservative' | 'Optimal' | 'Aggressive' = 'Optimal';
  private _optimals: IOptimal[] = [];
  private _altOptimal: IOptimal | undefined;
  private _legs: ILeg[] | undefined;
  private _priceCalculationMethod = PriceCalculationMethod.BID_ASK;
  private _optionType = 'S';
  private _priorities: ICoveredCallOptimalPriorityMapping[] | undefined;

  constructor(data: IBuilderData) {
    const { quote, chain, stdDev, predictions } = data;
    this._quote = quote;
    this._chain = chain;
    this._stdDev = stdDev;
    this._predictions = predictions;
  }

  private getPriority = () => {
    if (!this._priorities) {
      throw new Error('Priorities are undefined. Consider configuring by `withPriorities`');
    }
    const optimal = this._priorities.find(
      (op) => op.timeFrame === this._timeframe && op.aggressiveness === this._aggressive,
    );
    return optimal?.priority || -1;
  };

  private findOptimal = () => {
    const priority = this.getPriority();
    //This call is not necessarily of the given pirority. rather nearby. So it can be alternative as well.
    return CoveredCallExtensions.findOptimal(this._optimals, priority);
  };

  //TODO: call build() method to get the combination by legs.
  assembleCall = (optimal: ICallOptimal) => {
    const multiplier = OptionChain.getSecurityQuantity(this._optionType);
    const optionQty = Math.floor(this._shares / multiplier) || 1;
    const legs = [
      {
        legType: optimal.legType,
        strikePrice: optimal.strike,
        quantity: -optionQty,
        expiry: DateTimeHelper.resolveExpiry(optimal.expiry),
      },
      {
        legType: LegType.SECURITY,
        strikePrice: undefined,
        quantity: optionQty * multiplier,
        expiry: undefined,
        costBasis: this._costBasisPerUnit,
      },
    ];
    const combination = this.buildIncome(legs, optimal.priority);
    combination.noCallOptimalCombinationTitle = 'Covered Call';
    return combination as IncomeCombination;
  };

  private assemblePut = (optimal: IPutOptimal) => {
    const multiplier = OptionChain.getSecurityQuantity(this._optionType);
    const optionQty = Math.floor(this._shares / multiplier) || 1;
    const legs = [
      {
        legType: optimal.legType,
        strikePrice: optimal.strike,
        quantity: -optionQty, //TODO: Verify put also needs negative quantity.
        expiry: DateTimeHelper.resolveExpiry(optimal.expiry),
      },
    ];
    const combination = this.buildIncome(legs, optimal.priority);
    return combination as IncomeCombination;
  };

  private buildIncome = (legs: Leg[], priority: number) => {
    return IncomeCombination.fromData(
      legs,
      { quote: this._quote, chain: this._chain, stdDev: this._stdDev, predictions: this._predictions },
      this._priceCalculationMethod,
      priority,
    );
  };

  withShares = (shares: number) => {
    this._shares = shares;
    return this;
  };

  withCostBasisPerUnit = (costBasisPerUnit: number) => {
    this._costBasisPerUnit = costBasisPerUnit;
    return this;
  };

  withTimeframe = (timeframe: 'Short' | 'Medium' | 'Long') => {
    this._timeframe = timeframe;
    return this;
  };

  withAggressiveness = (aggressive: 'Conservative' | 'Optimal' | 'Aggressive') => {
    this._aggressive = aggressive;
    return this;
  };

  withAltOptimal = (optimal: IOptimal | undefined) => {
    this._altOptimal = optimal;
    return this;
  };

  withOptimals = (optimals: IOptimal[]) => {
    //TODO: May wanted to use deepClone from lodash and remove CallOptimal class itself.
    this._optimals = optimals.map((c) => ({ ...c } as IOptimal));
    return this;
  };

  withLegs = (legs: ILeg[]) => {
    this._legs = legs;
    return this;
  };

  withPriceCalculationMethod = (method: PriceCalculationMethod) => {
    this._priceCalculationMethod = method;
    return this;
  };

  withOptionType = (type: string) => {
    this._optionType = type;
    return this;
  };

  withPriorities = (priorities: ICoveredCallOptimalPriorityMapping[]) => {
    this._priorities = priorities;
    return this;
  };

  buildCall = () => {
    const optimalCall = this.findOptimal();
    //Its possible that there is no optical call found.
    if (!optimalCall) {
      console.warn('No optimal found. Consider configuring `withOptimals()`');
      return undefined;
    }
    const combination = this.assembleCall(optimalCall);
    return combination;
  };

  buildAltCall = () => {
    //NOTE: Its possible that there is no alternative call available in api.
    if (!this._altOptimal) {
      console.warn('Alt optimal is undefined. Consider configuring `withAltOptimal()`');
      return undefined;
    }
    const combination = this.assembleCall(this._altOptimal);
    return combination;
  };

  buildPut = () => {
    const optimalPut = this.findOptimal();
    //Its possible that there is no optical put found.
    if (!optimalPut) {
      console.warn('No optimal found. Consider configuring `withOptimals()`');
      return undefined;
    }
    //StrategyHelpers.assembleIncomeCombination
    const combination = this.assemblePut(optimalPut);
    return combination;
  };

  buildAltPut = () => {
    //Its possible that there is no alt call available in api.
    if (!this._altOptimal) {
      console.warn('Alt optimal is undefined. Consider configuring `withAltOptimal()`');
      return undefined;
    }
    const combination = this.assemblePut(this._altOptimal);
    return combination;
  };

  build = (priority?: number) => {
    if (!this._legs) {
      throw new Error('Legs are undefined. Configure legs `withLegs()`');
    }
    const combination = this.buildIncome(this._legs, priority || -1);
    return combination;
  };
}

export class IncomeStrategies {
  //to generate individual combinations of this sentiment.
  sentiment: SentimentType | undefined;
  shares = 100;
  costBasis = 0;
  //TODO: remove this pair props.
  incomeOptionPair: Map<OptionType, IncomeCombination | undefined> = new Map();
  altOptionsPair: Map<OptionType, IncomeCombination | undefined> = new Map();
  //TODO: May not required. As we can grab them from howData recoil itthis.
  howData: HowDataModel | undefined;
  hasOption = false;
  isTradeable = false;

  private constructor() {}

  //TODO: should accept aggressiveness and timeframe of put as well.
  static fromData = (howDataModel: HowDataModel, shares: number, costBasis: number) => {
    const model = new IncomeStrategies();
    model.howData = howDataModel;
    model.sentiment = howDataModel.originalSentiment;
    //TODO: remove it.
    model.hasOption = howDataModel.hasOption;
    model.isTradeable = howDataModel.isTradeable;
    model.costBasis = costBasis;
    model.shares = shares;
    model.initialize();
    return model;
  };

  initialize = () => {
    if (!this.howData) {
      throw new Error('howData is undefined');
    }
    const config = ApplicationContext.configuration.applicationConfiguration.coveredCall;
    const priorities = ApplicationContext.configuration.coveredCallOptimalPriorityMapping;
    let timeframe = config.call.timeFrame as 'Short' | 'Medium' | 'Long';
    let aggressive = config.call.aggressiveness as 'Conservative' | 'Optimal' | 'Aggressive';

    let builder = new IncomeBuilder(this.howData as IBuilderData)
      .withShares(this.shares)
      .withCostBasisPerUnit(this.costBasis)
      .withTimeframe(timeframe)
      .withAggressiveness(aggressive)
      .withOptimals(this.howData.callOptimals)
      .withAltOptimal(this.howData.alternativeCall)
      .withPriorities(priorities)
      .withPriceCalculationMethod(PriceCalculationMethod.BID_ASK);

    //Call optimal
    const coveredCall = builder.buildCall();
    const altCall = builder.buildAltCall();

    //NOTE: put need to start with 100 shares only.
    //Put and Call can have different time frames and aggresivess.
    timeframe = config.put.timeFrame as 'Short' | 'Medium' | 'Long';
    aggressive = config.put.aggressiveness as 'Conservative' | 'Optimal' | 'Aggressive';
    builder = builder
      .withShares(100)
      .withTimeframe(timeframe)
      .withAggressiveness(aggressive)
      .withOptimals(this.howData.putOptimals)
      .withAltOptimal(this.howData.alternativePut);

    //Put optimal
    const put = builder.buildPut();
    const altPut = builder.buildAltPut();

    //Optimal stregies
    this.incomeOptionPair.set(OptionType.CALL, coveredCall);
    this.incomeOptionPair.set(OptionType.PUT, put);

    //alternate strategies
    this.altOptionsPair.set(OptionType.CALL, altCall);
    this.altOptionsPair.set(OptionType.PUT, altPut);
  };

  get Combinations() {
    return [this.incomeOptionPair.get(OptionType.CALL), this.incomeOptionPair.get(OptionType.PUT)];
  }
}
