import ApplicationContext from './application-context';
import { DateTime } from 'luxon';
import lodash from 'lodash';

//TODO: drop support for number. app thinks in days, code is millis
type dateType = Date | string | number | null | undefined;

class DateTimeHelper {
  static readonly NOT_AVAILABLE = 'N/A';
  static readonly DEFAULT_DATE_FORMAT = 'MMM dd, yyyy';
  static readonly EXPIRY_KEY_DATE_FORMAT = 'yyyyMMdd';
  static readonly MARKET_TIME_ZONE_IANA_FORMAT = 'America/New_York';

  private static resolveStringToDate = (date: string) => {
    /**
     * We want the date coming from server to have no offset or zone information.
     *  In most part of API data is coming as "2022-07-15T00:00:00" which does not have offset (+/- hh:mm) or zone(Z) information.
     * Hence luxon will use users 'local' timezone as the zone/offset information.
     * Thus date coming from server remains intact user local timezone as well.
     * Exceptionally Earnings date from why response providing zone information "2022-07-15T00:00:00Z". Hence stripping out 'Z' to maintain same order.
     */
    const dateTime = DateTime.fromISO(date.replace('Z', ''));
    return dateTime.toJSDate();
  };

  private static resolveMillisToDate = (date: number) => {
    const dateTime = DateTime.fromMillis(date);
    return dateTime.toJSDate();
  };

  //Test: covered
  static resolveDate = (date: dateType) => {
    if (date === null || date === undefined) {
      return undefined;
    }
    if (lodash.isString(date)) {
      return DateTimeHelper.resolveStringToDate(date);
    }
    if (lodash.isNumber(date)) {
      return DateTimeHelper.resolveMillisToDate(date);
    }
    const dateTime = DateTime.fromJSDate(date);
    return dateTime.toJSDate();
  };

  static resolveExpiry(date: dateType) {
    const dateTime = DateTimeHelper.resolveDate(date);
    if (!dateTime) {
      return undefined;
    }
    if (dateTime.getDay() !== 6) {
      dateTime.setHours(16, 0, 0, 0);
    } else {
      dateTime.setHours(0, 0, 0, 0);
    }
    return dateTime;
  }

  /**
   * Returns date object in Market's time
   */
  static getCurrentDateTime = () => {
    return DateTimeHelper.resolveDate(new Date()) as Date;
  };

  static getCurrentDate = () => {
    const current = DateTimeHelper.getCurrentDateTime();
    current.setHours(0, 0, 0, 0);
    return current;
  };

  //Test: covered
  /* Adds given number of days to start date and returns resulting date
   * @param {number} numOfDays Number of days to add to start date
   * @param {Date=<Current EST Date>} startDate Number of days to add to start date
   */
  static getDateFromDaysInFuture = (numOfDays: number, startDate?: string | number | Date) => {
    let date = DateTimeHelper.getCurrentDate();
    if (startDate) {
      date = DateTimeHelper.resolveDate(startDate) as Date;
    }
    const dateTime = DateTime.fromJSDate(date);
    const futureDate = dateTime.plus({ days: numOfDays });
    return futureDate.toJSDate();
  };

  //! This method should be removed.
  /**
   * Throws out user's timezone from given date.
   * Note: be careful while using this . It modifies actual date and result could be wrong
   */
  static throwTimezoneOffset = (date?: Date | undefined | string, fromLocal?: undefined | boolean) => {
    if (typeof date === 'string') {
      let newDate = new Date(date);
      let timezoneOffset = newDate.getTimezoneOffset() * 60000;
      newDate = new Date(newDate.getTime() + timezoneOffset);
      return newDate;
    }
    if (!date) {
      return null;
    }
    let timezoneOffset = date.getTimezoneOffset() * 60000;
    if (fromLocal) {
      timezoneOffset *= -1;
    }
    let newDate = new Date(date.getTime() + timezoneOffset);
    return newDate;
  };

  //Test: covered
  static toExpiryKey = (date: dateType) => {
    const resolved = DateTimeHelper.resolveDate(date);
    if (!resolved) {
      return '';
    }
    const dateTime = DateTime.fromJSDate(resolved);
    return dateTime.setLocale('en-US').toFormat(DateTimeHelper.EXPIRY_KEY_DATE_FORMAT);
  };

  //Test: covered
  static formatUTCDate = (date: Date | undefined) => {
    if (!date) {
      return undefined;
    }
    const dateTime = DateTime.fromJSDate(date);
    const utc = dateTime.toUTC();
    return utc.setLocale(ApplicationContext.globalization.name).toLocaleString(DateTime.DATE_MED);
  };

  //Test: covered
  //TODO! NOT replaced everywhere, formatting.formatDate(expiry, formatTemplate, null, CombinationTextGeneration.language);
  static format = (date: dateType, format: string = DateTimeHelper.DEFAULT_DATE_FORMAT) => {
    const resolved = DateTimeHelper.resolveDate(date);
    if (!resolved) {
      return DateTimeHelper.NOT_AVAILABLE;
    }
    const dateTime = DateTime.fromJSDate(resolved);
    return dateTime.toFormat(format, { locale: ApplicationContext.globalization.name });
  };

  /*
   * Returns boolean to format dates if the dates are Weekly expiry or Monthly expiry
   */
  static isWeekly = (date: Date) => {
    const thirdWeek = DateTimeHelper.getWeekNumber(date);
    let isWeekly = true;
    if (thirdWeek === 3) {
      isWeekly = false;
    }
    return isWeekly;
  };

  private static getWeekNumber = (date: Date) => {
    const dayDiff = 5 - date.getDay();
    const shiftedDate = new Date();
    shiftedDate.setDate(date.getDate() + dayDiff);
    const dayOfMonth = shiftedDate.getDate();
    const result = Math.ceil(dayOfMonth / 7);
    return result;
  };

  //Test: covered
  static sameDate = (date1: Date | undefined, date2: Date | undefined) => {
    if (!date1 || !date2) {
      return false;
    }
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    const first = DateTime.fromJSDate(date1);
    const second = DateTime.fromJSDate(date2);
    return first.equals(second);
  };

  //Test: covered
  static daysFromNow = (date?: dateType) => {
    const resolved = DateTimeHelper.resolveDate(date);
    if (!resolved) {
      return 0;
    }
    const dateTime = DateTime.fromJSDate(resolved);
    const now = DateTime.local();
    //today is dateTime at start of day e.g 2022-05-07T00:00:00
    const today = DateTime.fromObject({ day: now.day, month: now.month, year: now.year });
    const duration = dateTime.diff(today, 'days');
    return duration.days;
  };

  static startDateTimeFormat = (date: string | Date) => {
    const startDate = DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd') + 'T00:00:00Z';
    return startDate;
  };

  static endDateTimeFormat = (date: string | Date) => {
    const endDate = DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd') + 'T23:59:59Z';
    return endDate;
  };

  static startDateTimeFormatEST = (hours: number) => {
    const startDate = DateTime.now().minus({ hours: hours });
    const startDateUTC = startDate.toUTC();
    return startDateUTC;
  };

  static endTimeOfDayFormat = (date: string | Date) => {
    const endTime = DateTime.fromJSDate(new Date(date)).set({ hour: 23, minute: 59 });
    return endTime;
  };

  static fullMonthDayYearFormatEST = (date: Date | undefined) => {
    if (!date) {
      return undefined;
    }
    return DateTime.fromJSDate(new Date(date)).minus({ hours: 4 }).toFormat('MMMM dd yyyy');
  };

  static PastUTCDate = (days: number) => {
    const pastDate = DateTime.now().minus({ days });
    const pastDateUTC = pastDate.toUTC();
    return pastDateUTC;
  };

  static FutureUTCDate = (days: number) => {
    const futureDate = DateTime.now().plus({ days });
    const futureDateUTC = futureDate.toUTC();
    return futureDateUTC;
  };

  static withoutTime = (date: Date) => {
    const date1 = new Date(date);
    date1.setHours(0, 0, 0, 0);
    return date1.getTime();
  };

  static getReportFormattedDate(date: string): string {
    const createdDate = new Date(date);
    const currentDate = new Date();
    const timeDiff = this.withoutTime(currentDate) - this.withoutTime(createdDate);
    const msInDay = 86400000; // Number of milliseconds in a day

    if (timeDiff === 0) {
      return `Today ${this.format(createdDate, 't')}`;
    }
    if (timeDiff === msInDay) {
      return `Yesterday ${this.format(createdDate, 't')}`;
    }
    return date;
  }

  static isDateExpired = (date: string) => {
    return this.resolveDate(date) < this.getCurrentDate();
  };
}

export default DateTimeHelper;
