import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import LocalizationContext from '../react-i18next/localization-context';
import { CommonListItemIcon } from './common-list-item-icon-widget';
import { OPDialogWidget } from '../common';
import { OutlinedButton } from '../styled/options-play-components';
import logo from '@op/shared/assets/images/contactLogo.png';

const LogoStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '300px',
  height: '185px',
};

export default function BusinessQueriesDialog() {
  const [open, setOpen] = React.useState(false);
  const { t } = React.useContext(LocalizationContext);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const renderDialogAction = () => {
    return (
      <OutlinedButton size="small" onClick={handleClose}>
        <Typography variant="button">Close</Typography>
      </OutlinedButton>
    );
  };
  const renderDialogContent = () => {
    return (
      <div>
        <Typography gutterBottom>
          Welcome to OptionsPlay, a better way to simplify and profit from the world of options.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay packs the tools and techniques of pro Wall Street traders into a powerful, intuitive tool. You’ll
          gain new ways to generate income and manage risk. Straight talk. Simple charts. Real power.
        </Typography>
        <Typography gutterBottom>
          Try it out, explore different strategies and experience the benefits of options trading!
        </Typography>

        <Typography gutterBottom>
          <Link href="https://www.optionsplay.com/" target="_blank">
            www.optionsplay.com
          </Link>
        </Typography>

        <Typography gutterBottom>
          For business inquiries, click{' '}
          <Link href="mailto:business.inquiries@optionsplay.com" target="_blank">
            here
          </Link>
          .
        </Typography>

        <Typography variant="body1" gutterBottom>
          Note: market data is delayed up to 20-minutes and trade ideas and historical analysis are generated nightly,
          both based on freely available data. We cannot attest to or be accountable for the accuracy of such data.
          Real-time data may be available for an additional fee.
        </Typography>
      </div>
    );
  };
  return (
    <div>
      <CommonListItemIcon
        icon={<BusinessCenterOutlinedIcon />}
        text={t('app.php.footer.labels.businessInquiries')}
        callback={handleClickOpen}
      />

      <OPDialogWidget
        open={open}
        onHandleClose={handleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={<img src={logo} alt="Logo" style={LogoStyle}></img>}
      />
    </div>
  );
}
