import { Scan } from '..';
import { ICollection } from '..';

export class Scans implements ICollection<Scan> {
  data: Scan[] = [];

  public constructor(scans: Scan[]) {
    this.data = [...scans];
  }

  public get isEmpty(): boolean {
    return this.data.length === 0;
  }
}
