import { Grid, InputAdornment, useTheme } from '@mui/material';
import Ascending from '@op/shared/assets/images/ascending.svg';
import Descending from '@op/shared/assets/images/descending.svg';
import { ISort, SortingType, Sorts } from '@op/shared/src/models';
import { debounce } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import LocalizationContext from '../react-i18next/localization-context';
import { MenuProps, OPSelectDropDownHeader, OPSelectField, StyledDropdownItemWithBorder } from '../styled';
import { IconComponent } from './icons';

interface SortingWidgetProps {
  onSort: (sorts: Sorts) => void;
  selectedSorts: Sorts;
  defaultSortOptions: Sorts;
  isLoading?: boolean;
}

export const SortingWidget: React.FC<SortingWidgetProps> = ({
  onSort,
  selectedSorts,
  defaultSortOptions,
  isLoading,
}: SortingWidgetProps) => {
  const [selectedSort, setSelectedSort] = useState(selectedSorts.data[0]);
  const debouncedOnSort = useMemo(() => debounce(onSort, 1000), []);
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();

  const onOptionChange = (sortOptionName: string): void => {
    const sort = defaultSortOptions.data.find((s) => s.name.toUpperCase() === sortOptionName.toUpperCase());
    if (!sort) {
      throw new Error('Sort is undefined');
    }
    setSelectedSort(sort);
    debouncedOnSort(new Sorts([sort]));
  };

  const onOrderChange = (): void => {
    const sort: ISort = { name: selectedSort.name, order: selectedSort.order, title: selectedSort.title };
    const newOrder =
      sort.order === SortingType.ASCENDING.toString().toUpperCase()
        ? SortingType.DESCENDING.toString().toUpperCase()
        : SortingType.ASCENDING.toString().toUpperCase();
    sort.order = newOrder;
    setSelectedSort(sort);
    debouncedOnSort(new Sorts([sort]));
  };

  const renderOrder = () => {
    const order = selectedSort ? selectedSort.order.toUpperCase() : SortingType.ASCENDING.toString().toUpperCase();
    if (order === SortingType.ASCENDING.toString().toUpperCase()) {
      return (
        <IconComponent
          name="sortBy"
          stroke={theme.palette.info.light}
          style={{ cursor: 'pointer' }}
          size={20}
          onClick={onOrderChange}
        />
      );
    } else {
      return (
        <IconComponent
          name="sortBy"
          stroke={theme.palette.info.light}
          size={20}
          style={{ cursor: 'pointer', transform: ' rotateX(180deg)' }}
          onClick={onOrderChange}
        />
      );
    }
  };

  return (
    <Grid container flexWrap="nowrap">
      <Grid item xs>
        <OPSelectField
          id="js-accounts-sorting-widget"
          variant="outlined"
          value={selectedSort ? selectedSort.name : ''}
          onChange={(event) => !isLoading && onOptionChange(event.target.value as string)}
          sx={{
            width: '100%',
            height: 35,
            // borderRadius: theme.shape.borderRadius,
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            }
          }}
          startAdornment={<InputAdornment sx={{ width: '37px' }} position="start">{renderOrder()}</InputAdornment>}
          MenuProps={MenuProps}>
          <OPSelectDropDownHeader type="sort" />
          {defaultSortOptions.data.map((option) => (
            <StyledDropdownItemWithBorder key={option.name} value={option.name} color="default" selected={true}>
              {t(option.title)}
            </StyledDropdownItemWithBorder>
          ))}
        </OPSelectField>
      </Grid>
    </Grid>
  );
};
