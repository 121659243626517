import { Box, Button, Grid, Paper, Tab, Tabs, useTheme } from '@mui/material';
import {
  accountState,
  configurationState,
  customizationState,
  isSubscriptionRenewedState,
  previousLocationState,
} from '@op/shared/src/states';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  AccountSetting,
  ChangeEmail,
  ChangePassword,
  Commissions,
  PortfolioTabWidget,
  ProfileTabsWidget,
  SharedTrade,
} from '.';
import { BillingHistory, SubscriptionWidget } from '../subscription/v2';

import HomeIcon from '@mui/icons-material/Home';
import Skeleton from '@mui/material/Skeleton';
import { Permissions } from '@op/shared/src/models/enums/enums';
import LocalizationContext from '../react-i18next/localization-context';
import { useOPNavigate } from '../states/use-navigate-search';
import { PortfolioAlert } from './portfolio-tab-alert';
import { PrivacyPolicy } from './privacy-policy';
import { UserSettingsWidget } from './user-settings-widget';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const SkeletonPortfolio: React.FC = () => {
  return (
    <>
      <Grid sx={{ pt: 0 }}>
        <Skeleton animation="wave" variant="text" height={400} width="90%" />
      </Grid>
    </>
  );
};

const accountTabs = [
  { id: 1, tabname: 'app.php.profile.menu.titles.profile' },
  { id: 2, tabname: 'app.php.profile.menu.titles.accountSettings' },
  { id: 3, tabname: 'app.php.profile.menu.titles.subscriptions' },
  { id: 4, tabname: 'app.php.profile.menu.titles.portfolio' },
  { id: 5, tabname: 'app.php.profile.menu.titles.portfolioAlertsDelivery' },
  { id: 6, tabname: 'app.php.profile.menu.titles.billingHistory' },
  { id: 7, tabname: 'app.php.profile.menu.titles.sharedTrades' },
  { id: 8, tabname: 'app.php.profile.menu.titles.security' },
  { id: 9, tabname: 'app.php.profile.menu.titles.changeEmailAddress' },
  { id: 10, tabname: 'app.php.profile.menu.titles.commissions' },
  { id: 11, tabname: 'app.php.profile.menu.titles.incomeSettings' },
  { id: 12, tabname: 'app.php.profile.menu.titles.privacyPolicy' },
];

const TabPanel: React.FC<ITabPanelProps> = (props: ITabPanelProps) => {
  const { children, value, index } = props;
  return <div>{value === index - 1 && <Box> {children}</Box>}</div>;
};
interface IAccountTabProps {
  status: number;
}

const AccountTabsWidget: React.FC<IAccountTabProps> = ({ status }: IAccountTabProps) => {
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const prevLocation = useRecoilValue(previousLocationState);
  const [account] = useRecoilState(accountState);
  const isSubscriptionRenewed = useRecoilValue(isSubscriptionRenewedState);
  const { t } = useContext(LocalizationContext);
  const [value, setValue] = useState(status);
  const [selectedTabIndex, setselectedTabIndex] = useState(0);
  const theme = useTheme();
  const navigate = useOPNavigate();

  useEffect(() => {
    setValue(value);
    setselectedTabIndex(value);
  }, []);

  useEffect(() => {
    // Uncomment the below line for dynamic language change of page title.
    //document.title = `${listTabs[value].tabname} | OptionsPlay`;
    switch (value) {
      case 0:
        document.title = `profile | OptionsPlay`;
        break;
      case 1:
        document.title = `accountSettings | OptionsPlay`;
        break;
      case 2:
        document.title = `subscriptions | OptionsPlay`;
        break;
      case 3:
        document.title = `portfolio | OptionsPlay`;
        break;
      case 4:
        document.title = `portfolioAlertDelivery | OptionsPlay`;
        break;
      case 5:
        document.title = `billingHistory | OptionsPlay`;
        break;
      case 6:
        document.title = `sharedTrades | OptionsPlay`;
        break;
      case 7:
        document.title = `changePassword | OptionsPlay`;
        break;
      case 8:
        document.title = `changeEmailAddress | OptionsPlay`;
        break;
      case 9:
        document.title = `commissions | OptionsPlay`;
        break;
      case 10:
        document.title = `incomeSettings | OptionsPlay`;
        break;
      case 11:
        document.title = `PrivacyPolicy | OptionsPlay`;
        break;
      default:
        document.title = ` OptionsPlay for the Rest of Us | Welcome to OptionsPlay `;
    }
  }, [value]);

  if (!account || !account.securityModel || !configuration) {
    return <></>;
  }

  const hideItemIfNoPermission = (permission: boolean, val: number) => {
    if (!permission) {
      listTabs = listTabs.filter((i) => i.id !== val);
    }
  };

  const isAdmin = account.isAdmin;
  const hasManualPortfolioUpoadAccess = account.hasManualPortfolioUpoadAccess;
  const isViewOPPEnabled = account.isViewOPPEnabled;
  // const subscriptionStatus = account.securityModel.subscriptionStatus;
  const manageProfilePermission = account.securityModel.hasPermission(Permissions.MANAGE_PROFILE);
  const profileSubscriptionPermission = account.securityModel.hasPermission(Permissions.MANAGE_PROFILE_SUBSCRIPTIONS);
  const portfolioAlertPermission = account.securityModel.hasPermission(Permissions.MANAGE_PORTFOLIO_EMAIL_DELIVERY);
  const sharedTradesPermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARING);
  const changePasswordPermission = account.securityModel.hasPermission(Permissions.ALLOW_CHANGE_PASSWORD);
  const commissionPermission = account.securityModel.hasPermission(Permissions.MANAGE_COMMISSIONS);
  const icomeSettingsPermission = account.securityModel.hasPermission(Permissions.MANAGE_COVERED_CALL_SETTINGS);
  const portfolioPermission = account.securityModel.hasPermission(Permissions.VIEW_POWERHOUSE_O_P_P);
  const individualSubscriptionEnabled = configuration.individualSubscriptionEnabled;

  let listTabs = [...accountTabs];

  //TODO : need to refactor below code
  if (!isAdmin || !commissionPermission) {
    //removing commissions for non admin and if Manage Commission permission is not there.
    listTabs = listTabs.filter((i) => i.id !== 10);
  }
  if (!isViewOPPEnabled) {
    //removing portfolio fon non viewOPP user
    listTabs = listTabs.filter((i) => i.id !== 4 && i.id !== 5);
  }
  if (!manageProfilePermission) {
    //removing changeEmail && account settings
    listTabs = listTabs.filter((i) => i.id !== 9 && i.id !== 2 && i.id !== 1);
  }
  if (!profileSubscriptionPermission || !individualSubscriptionEnabled || !customization.showSubscription) {
    //removing billing history
    listTabs = listTabs.filter((i) => i.id !== 6 && i.id !== 3);
  }
  // Hiding billing history as it moved inside the subscription
  listTabs = listTabs.filter((i) => i.id !== 6);
  // hide subscription & billing history for nordic
  // if (isNordic() || isTmx()) {
  //   listTabs = listTabs.filter((i) => i.id != 3 && i.id != 6);
  // }

  // if (!isTmx()) {
  listTabs = listTabs.filter((i) => i.id !== 12);
  // }

  hideItemIfNoPermission(portfolioAlertPermission, 5);
  hideItemIfNoPermission(sharedTradesPermission, 7);
  hideItemIfNoPermission(changePasswordPermission, 8);
  hideItemIfNoPermission(portfolioPermission, 4);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    const newindexOfSelectedTab = accountTabs.find((v) => v.id === listTabs[newValue].id)?.id || 0;
    setValue(newindexOfSelectedTab - 1);
    setselectedTabIndex(newindexOfSelectedTab - 1);
  };

  // const getConfiguration = async () => {
  //   const response = await PowerhouseProService.instance.Configuration();
  //   if (response.hasErrors || !response.data) {
  //     return;
  //   }
  //   setConfiguration(response.data);
  // };

  const navigatebackToApp = () => {
    if (isSubscriptionRenewed) {
      window.location.href = window.location.origin;
      return;
    }
    if (prevLocation === '/profile') {
      navigate('/');
    }
    navigate(`${prevLocation || '/'}`);
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid container xs={12} sm={10} md={10} xl={8} spacing={2} justifyContent="center">
        <Grid item xs={8} sm={3} md={3}>
          <Paper>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              orientation="vertical"
              value={listTabs.findIndex((v) => v.id === selectedTabIndex + 1)}
              onChange={handleChange}>
              {listTabs.map((tab) => (
                <Tab
                  sx={{
                    p: '12px 16px',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    alignItems: 'flex-end',
                  }}
                  key={tab.id}
                  label={t(tab.tabname)}
                />
              ))}
            </Tabs>
          </Paper>
          {!account.isValidSubscription ? undefined : (
            <Button
              onClick={() => navigatebackToApp()}
              sx={{
                width: '100%',
                alignItems: 'flex-end',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                '& .MuiButton-iconSizeMedium > *:first-child': {
                  marginBottom: '3px',
                },
              }}
              variant="outlined"
              startIcon={<HomeIcon />}>
              {t('app.php.profile.menu.titles.backToTheApp')}
            </Button>
          )}
        </Grid>

        <Grid item md={8} xs={12} sm={8}>
          <Suspense fallback={<SkeletonPortfolio />}>
            <TabPanel value={value} index={accountTabs[0].id}>
              <ProfileTabsWidget />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[1].id}>
              <AccountSetting />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[2].id}>
              <SubscriptionWidget />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[3].id}>
              <PortfolioTabWidget isAdmin={isAdmin} hasManualPortfolioUpoadAccess={hasManualPortfolioUpoadAccess} />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[4].id}>
              <PortfolioAlert />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[5].id}>
              <BillingHistory />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[6].id}>
              <SharedTrade />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[7].id}>
              <ChangePassword />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[8].id}>
              <ChangeEmail />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[9].id}>
              <Commissions />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[10].id}>
              <UserSettingsWidget showIncomeSettings={icomeSettingsPermission} />
            </TabPanel>
            <TabPanel value={value} index={accountTabs[11].id}>
              {<PrivacyPolicy />}
            </TabPanel>
          </Suspense>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountTabsWidget;
