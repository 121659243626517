import {
  ExpandedQuote,
  ICallOptimal,
  IExpandedQuote,
  IPutOptimal,
  OptionChain,
  Predictions,
  StandardDeviation,
} from '@op/shared/src/models/how';
import { IHow } from '@op/shared/src/models/how/how-entity';
import { ITradingStrategy } from '@op/shared/src/models/how/trading-strategy';
import { SentimentType } from '../what/sentiment-type';

class HowDataModel {
  readonly symbol;
  private readonly _stdDev: StandardDeviation;
  private readonly _predictions: Predictions;
  quote: IExpandedQuote;
  tradingStrategiesRaw: Map<SentimentType, ITradingStrategy[]> = new Map();
  originalSentiment: SentimentType;
  optionType = 'S';
  chain: OptionChain | undefined;
  callOptimals: ICallOptimal[] = [];
  putOptimals: IPutOptimal[] = [];
  alternativeCall: ICallOptimal | undefined;
  alternativePut: IPutOptimal | undefined;
  hasOption: boolean = false;
  isTradeable: boolean = false;

  constructor(how: IHow, symbol: string) {
    this.symbol = symbol;
    this.quote = ExpandedQuote.fromSelf(how.expandedQuote);
    this.originalSentiment = this.toSentimentType(how.sentiment);
    this.callOptimals = how.callOptimals.sort((first, second) => first.priority - second.priority);
    this.putOptimals = how.putOptimals.sort((first, second) => first.priority - second.priority);
    this.alternativeCall = how.alternativeCall;
    this.alternativePut = how.alternativePut;
    this.hasOption = how.expandedQuote.hasOption;
    this.isTradeable = how.expandedQuote.isTradeable;
    this._stdDev = new StandardDeviation(
      how.expandedQuote.symbol || '',
      how.expandedQuote.last || 0,
      how.standardDeviations,
    );
    this._predictions = new Predictions(how.expandedQuote.symbol || '', how.predictions);
    this.tradingStrategiesRaw.set(SentimentType.BULLISH, how.tradingStrategies);
    this.tradingStrategiesRaw.set(SentimentType.BEARISH, how.oppositeStrategies);
    this.tradingStrategiesRaw.set(SentimentType.NEUTRAL, how.neutralStrategies);
    if (how.optionChains && how.optionChains.length > 0) {
      // takes the chains from the how API and loads them into an array of objects represented as rows
      this.chain = OptionChain.fromData(how.expandedQuote.symbol, how.optionChains, how.expandedQuote.last);
      this.quote.hasOption = true;
    }
  }

  get stdDev() {
    return this._stdDev;
  }

  get predictions() {
    return this._predictions;
  }

  toSentimentType = (sentiment: string) => {
    switch (sentiment.trim().toUpperCase()) {
      case SentimentType.ALL:
        return SentimentType.ALL;
      case SentimentType.BEARISH:
        return SentimentType.BEARISH;
      case SentimentType.BULLISH:
        return SentimentType.BULLISH;
      case SentimentType.NEUTRAL:
        return SentimentType.NEUTRAL;
      default:
        return SentimentType.BULLISH;
    }
  };
}

export default HowDataModel;
