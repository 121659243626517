import { Card, CardContent, CardHeader, SxProps, Theme } from '@mui/material';
import React, { ReactNode } from 'react';
import { OptionsPlayDivider } from '../styled';

export interface IProfileCardWidgetProps {
  sx?: SxProps<Theme>;
  title: ReactNode;
  content: ReactNode;
  isMinimalistic?: boolean;
  isCardElevated?: boolean;
}

export const ProfileCardWidget: React.FC<IProfileCardWidgetProps> = ({
  sx,
  title,
  content,
  isMinimalistic,
  isCardElevated = true,
}: IProfileCardWidgetProps) => {
  return (
    <Card elevation={isMinimalistic || !isCardElevated ? 0 : 2}>
      {isMinimalistic ? null : (
        <>
          <CardHeader sx={{ pb: 1 }} title={<>{title}</>} />
          <OptionsPlayDivider />
        </>
      )}
      <CardContent
        sx={sx}
        style={{
          paddingTop: isMinimalistic ? 4 : 16,
          paddingLeft: isMinimalistic ? 4 : 16,
          paddingRight: isMinimalistic ? 4 : 16,
        }}>
        {content}
      </CardContent>
    </Card>
  );
};
