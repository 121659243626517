import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { cloneDeep } from '@op/shared/src/models';
import { Aggressiveness, EventType, TimeFrame } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { PowerhouseProService } from '@op/shared/src/services';
import { isConfigurationUpdatedState, selectedSymbolState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ProfileCardWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPToggleButton } from '../styled';
import Calls from './calls';
import Puts from './puts';
import { GuideItem } from '../side-menu';

export const IncomeSettingTab = styled(Tab)(({ theme }) => ({
  minWidth: '72px',
  letterSpacing: '0.02857em',
  alignItems: 'flex-start',
}));

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface IIncomeSettingsProps {
  isMinimalistic: boolean;
}
const TabPanel: React.FC<ITabPanelProps> = (props: ITabPanelProps) => {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
};

export const IncomeSettingsWidget: React.FC<IIncomeSettingsProps> = ({ isMinimalistic }: IIncomeSettingsProps) => {
  const theme = useTheme();
  const setNotifications = useSetRecoilState(notificationsState);
  const { t } = React.useContext(LocalizationContext);
  const [value, setValue] = useState(0);
  const configuration = ApplicationContext.configuration;
  const timeFrameCall = configuration.applicationConfiguration?.coveredCall.call.timeFrame;
  const aggressivenessCall = configuration.applicationConfiguration?.coveredCall.call.aggressiveness;
  const timeFramePut = configuration.applicationConfiguration?.coveredCall.put.timeFrame;
  const aggressivenessPut = configuration.applicationConfiguration?.coveredCall.put.aggressiveness;
  const [defaultTimeFrameCall, setDefaultTimeFrameCall] = React.useState(timeFrameCall);
  const [defaultAggressivenessCall, setDefaultAggressivenessCall] = React.useState(aggressivenessCall);
  const [isConfigurationChanged, setIsConfigurationChanged] = useRecoilState(isConfigurationUpdatedState);
  const [changedPut, setChangedPut] = React.useState(false);
  const [changedCall, setChangedCall] = React.useState(false);
  const [defaultTimeFramePut, setDefaultTimeFramePut] = React.useState(timeFramePut);
  const [defaultAggressivenessPut, setDefaultAggressivenessPut] = React.useState(aggressivenessPut);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [isCanceled, setIsCanceled] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
    setChangedCall(false);
    setChangedPut(false);
  };

  const getCallData = (timeFrameCall: TimeFrame, AggressivenessCall: Aggressiveness, isChanged: boolean) => {
    setDefaultTimeFrameCall(timeFrameCall);
    setDefaultAggressivenessCall(AggressivenessCall);
    setChangedCall(isChanged);
  };

  const getPutData = (timeFramePut: TimeFrame, AggressivenessPut: Aggressiveness, isChanged: boolean) => {
    setDefaultTimeFramePut(timeFramePut);
    setDefaultAggressivenessPut(AggressivenessPut);
    setChangedPut(isChanged);
  };

  const handleUpdate = () => {
    if (changedCall) {
      handleUpdateCall();
    }

    if (changedPut) {
      handleUpdatePut();
    }
  };

  const handleCancel = () => {
    setIsCanceled(isCanceled + 1);
  };

  const handleUpdateCall = async () => {
    const data = [
      {
        Keys: ['coveredCall', 'call', 'timeFrame'],
        Value: defaultTimeFrameCall,
      },
      {
        Keys: ['coveredCall', 'call', 'aggressiveness'],
        Value: defaultAggressivenessCall,
      },
    ];

    const clonedConfiguration = cloneDeep(configuration);
    clonedConfiguration.applicationConfiguration.coveredCall.call.aggressiveness = defaultAggressivenessCall;
    clonedConfiguration.applicationConfiguration.coveredCall.call.timeFrame = defaultTimeFrameCall;
    ApplicationContext.configuration = clonedConfiguration;

    const saveConfigResponse = await PowerhouseProService.instance.SaveConfigurations(data);
    setIsConfigurationChanged(isConfigurationChanged + 1);
    if (saveConfigResponse.data) {
      setNotifications([{ type: 'success', content: `${t('app.php.profile.incomeSettings.js.incomeSettingsSaved')}` }]);
    }
    ApplicationContext.userActivityHub?.logActivity(
      'BUTTON',
      'incomeStrategiesDefaultSettingsChanged',
      {
        call: {
          timeFrame: defaultTimeFrameCall,
          riskTolerance: defaultAggressivenessCall,
        },
        put: {
          timeFrame: clonedConfiguration.applicationConfiguration.coveredCall.put.timeFrame,
          riskTolerance: clonedConfiguration.applicationConfiguration.coveredCall.put.aggressiveness,
        },
      },
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const handleUpdatePut = async () => {
    const data = [
      {
        Keys: ['coveredCall', 'put', 'timeFrame'],
        Value: defaultTimeFramePut,
      },
      {
        Keys: ['coveredCall', 'put', 'aggressiveness'],
        Value: defaultAggressivenessPut,
      },
    ];

    const clonedConfiguration = cloneDeep(configuration);
    clonedConfiguration.applicationConfiguration.coveredCall.put.aggressiveness = defaultAggressivenessPut;
    clonedConfiguration.applicationConfiguration.coveredCall.put.timeFrame = defaultTimeFramePut;
    ApplicationContext.configuration = clonedConfiguration;

    const editResponse = await PowerhouseProService.instance.SaveConfigurations(data);
    setIsConfigurationChanged(isConfigurationChanged + 1);
    if (editResponse.data) {
      setNotifications([{ type: 'success', content: `${t('app.php.profile.incomeSettings.js.incomeSettingsSaved')}` }]);
    }
    ApplicationContext.userActivityHub?.logActivity(
      'incomeStrategiesDefaultSettingsChanged',
      'incomeStrategiesDefaultSettingsChanged',
      {
        call: {
          timeFrame: clonedConfiguration.applicationConfiguration.coveredCall.call.timeFrame,
          riskTolerance: clonedConfiguration.applicationConfiguration.coveredCall.call.aggressiveness,
        },
        put: {
          timeFrame: defaultTimeFramePut,
          riskTolerance: defaultAggressivenessPut,
        },
      },
      'click',
      selectedSymbol as string,
    );
  };

  const renderTabLabel = (label: string) => {
    return (
      <Typography variant="button" sx={{ pl: 1, pr: 1, textTransform: 'capitalize' }}>
        {t(label)}
      </Typography>
    );
  };

  const renderContent = () => {
    return (
      <Grid
        sx={{
          width: '100%',
        }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{
            '& .MuiTab-textColorInherit.Mui-selected': {
              color: '#0078D4',
              '& .MuiTab-wrapper': {
                borderBottom: '2px solid #0078D4',
                width: 'fit-content',
              },
            },
          }}>
          <IncomeSettingTab label={renderTabLabel('app.php.profile.incomeSettings.labels.calls')} />
          <IncomeSettingTab label={renderTabLabel('app.php.profile.incomeSettings.labels.puts')} />
        </Tabs>
        <Box>
          <TabPanel value={value} index={0}>
            <Box sx={{ p: isMinimalistic ? 0 : 2 }}>
              <Calls callback={getCallData} isCanceled={isCanceled} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ p: isMinimalistic ? 0 : 2 }}>
              <Puts callback={getPutData} isCanceled={isCanceled} />
            </Box>
          </TabPanel>
        </Box>
        <Grid item container justifyContent="right">
          <GuideItem selector=".incomePanelCurrentSettings_helpPinPlaceholder" />
          <Button
            id="pw-savedefault"
            onClick={(e) => {
              e.preventDefault();
              handleUpdate();
            }}
            size="small"
            color="primary"
            variant="contained"
            sx={{ marginX: 1, marginTop: '2px' }}>
            <Typography sx={{ color: theme.palette.selectAndTextField.dark }} variant="button">
              {t('app.php.profile.incomeSettings.buttons.saveAsDefault')}
            </Typography>
          </Button>
          <Button
            color="primary"
            variant="outlined"
            style={{
              backgroundColor: theme.palette.background.paper,
            }}
            onClick={(e) => {
              e.preventDefault();
              handleCancel();
            }}>
            <Typography variant="body1" sx={{ paddingX: 1 }}>
              {t('app.php.common.buttons.cancel')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <ProfileCardWidget
      title={t('app.php.profile.incomeSettings.titles.incomeSettings')}
      content={renderContent()}
      isMinimalistic={isMinimalistic}
    />
  );
};
