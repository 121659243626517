import { Box, Container, Grid, styled, TextField, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { Constants } from '@op/shared/src/constant';
import { Account, signInModel, TokenResponse } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { getThemeType } from '@op/shared/src/models/theme-type';
import { AuthenticationService, getLocales, PowerhouseProService } from '@op/shared/src/services';
import { accessTokenKey, accountState, configurationState, refreshTokenKey, themeState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { ResponseViewModel } from '@op/shared/src/view-models/responses/response-viewmodel';
import React, { FormEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { LogoWidget } from '../header';
import LocalizationContext from '../react-i18next/localization-context';
import { useGlobalization } from '../states/use-globalization';
import { CircularLoading } from '../styled';

//TODO:- need to do one more level of refactor
export const LoginSignupContainer = styled(Grid)(() => ({
  flex: 1,
  background: `linear-gradient(rgba(28, 138, 219, .9), rgba(28, 138, 219, .9)), url("https://app-dev.optionsplay.com/Content/images/backgrounds/9.jpg")`,
  backgroundSize: 'cover',
  minHeight: '100%',
  minWidth: '100%',
  zIndex: 2,
}));

export const LoginWidget: React.FC<{}> = () => {
  const navigate = useNavigate();
  const setGlobalizationLanguage = useGlobalization();
  const [searchParams, _setSearchParams] = useSearchParams();
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme();
  const [loginRequest, setloginRequest] = useState(new signInModel());
  const [loginRequestBase64, _setloginRequestBase64] = useState(new signInModel());
  const [errors, setErrors] = useState<any>({});
  const [badcredentialerror, setError] = useState<string[]>([]);
  const [account, setAccount] = useRecoilState(accountState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setConfiguration = useSetRecoilState(configurationState);
  const setActiveTheme = useSetRecoilState(themeState);
  const [loader, setLoader] = useState(false);
  const newEmail = localStorage.getItem('newEmail');
  const returnURL = searchParams.get(Constants.returnUrlKey) || '/';

  if (account) {
    navigate(returnURL, { replace: true });
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === 'Password') {
      loginRequest['Password'] = value;
    } else if (name === 'Login') {
      loginRequest['Login'] = value;
    }
    setloginRequest({ ...loginRequest });
  };

  const validate = (): boolean => {
    const temp = { email: '', password: '' };
    const pattern = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');
    if (!loginRequest.Login) {
      temp.email = 'Username is required';
    } else if (!pattern.test(loginRequest.Login)) {
      temp.email = 'Username is Invalid';
    }
    if (!loginRequest.Password) {
      temp.password = 'Password is required';
    }
    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === '');
  };

  const onSubmit = async (event: FormEvent<Element>): Promise<void> => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    setLoader(true);
    if (!loginRequest.Login) {
      setNotifications([{ type: 'error', content: 'Username is Required' }]);
      setLoader(false);
      return;
    }
    if (!loginRequest.Password) {
      setNotifications([{ type: 'error', content: 'Password is Required' }]);
      setLoader(false);
      return;
    }
    loginRequestBase64.Login = window.btoa(loginRequest.Login); //Buffer.from(loginRequest.Login, 'utf8').toString('base64');//Buffer.from(loginRequest.Login).toString('base64');
    loginRequestBase64.Password = window.btoa(loginRequest.Password); //Buffer.from(loginRequest.Password, 'utf8').toString('base64');//Buffer.from(loginRequest.Password).toString('base64');
    const tokenRequest = signInModel.fromJson(loginRequestBase64).toTokenRequest();
    const response: ResponseViewModel<TokenResponse> = await AuthenticationService.instance.token(tokenRequest);
    if (response.hasErrors || response.data === null || response.data === undefined) {
      ApplicationContext.accessToken = undefined;
      ApplicationContext.refreshToken = undefined;
      setErrors(response.getErrors);
      setError(response.getErrors);
      setLoader(false);
      return;
    }
    const accountData = Account.fromTokenResponse(response.data);
    localStorage.setItem(accessTokenKey, response.data.access_token);
    localStorage.setItem(refreshTokenKey, response.data.refresh_token);
    localStorage.removeItem('newEmail');
    const configurationResponse = await PowerhouseProService.instance.Configuration();
    const localeResponse = await getLocales();
    const configuration = configurationResponse.data;
    if (!configuration || !localeResponse || !localeResponse.data) {
      return;
    }
    setGlobalizationLanguage(localeResponse, configuration, true);
    //const lightboxData = configuration?.lightbox;
    const theme = getThemeType(configuration.userSettings?.theme || '');
    //setLightboxData(lightboxData);
    setAccount(accountData);
    setActiveTheme(theme);
    setConfiguration(configuration);
    setLoader(false);
    // setting empty fallbacksymbol for embedders
    window.localStorage.setItem('additionalData', JSON.stringify({ fallbackRanksSymbol: '', shouldFallBack: false }));
    navigate(returnURL, { replace: true });
  };

  const LoginMessage: React.FC = () => {
    return (
      <Typography>
        We have sent an email to your new email : <br />
        <strong>{newEmail}</strong> . Please check your email inbox and follow the instructions within the email to
        complete your email change process. Please also check your spam and junk folders. If you do not receive the
        email with 10 minutes, please contact our support team at
        <div style={{ paddingTop: 10 }}>
          <a href="mailto: info@optionsplay.com" style={{ textDecoration: 'none' }}>
            info@optionsplay.com
          </a>
        </div>
      </Typography>
    );
  };

  const renderErrors = (): JSX.Element | null => {
    if (badcredentialerror.length === 0) {
      return null;
    }
    return (
      <Box>
        {badcredentialerror.map((e, index) => {
          return (
            <Typography key={index} color="secondary" sx={{ pb: 3 }}>
              {e}
            </Typography>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <Container style={{ padding: theme.spacing(5, 7), textAlign: 'center', maxWidth: '500px' }} maxWidth="xs">
        <form id="login" data-name={loginRequest.Login} data-value="loggedIn" onSubmit={onSubmit}>
          <Grid container rowSpacing={1} justifyContent="center">
            <Grid item xs={12}>
              {newEmail ? <LoginMessage /> : ''}
            </Grid>
            <Grid item xs={12}>
              <LogoWidget direction="row" />
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ pb: 2 }}>
                {t('app.php.signIn.titles.loginToYourAccount')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {renderErrors()}
            </Grid>
            <Grid item xs={12} container justifyContent="center" textAlign="center" rowSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  onChange={onChange}
                  value={loginRequest.Login || ''}
                  name="Login"
                  label={t('app.php.common.inputs.email')}
                  variant="outlined"
                  sx={{ width: 380 }}
                  helperText={<span>{errors.email}</span>}
                  error={errors.email}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={onChange}
                  value={loginRequest.Password || ''}
                  type="password"
                  name="Password"
                  label={t('app.php.common.inputs.password')}
                  variant="outlined"
                  sx={{ width: 380 }}
                  error={errors.password}
                  helperText={<span>{errors.password}</span>}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={{
                    width: 380,
                    height: 60,
                    backgroundColor: theme.palette.success.main,
                    '&:hover': {
                      background: theme.palette.success.main,
                    },
                  }}
                  type="submit">
                  <Typography variant="button">{t('app.php.common.inputs.login')}</Typography>
                  {loader && <CircularLoading />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
