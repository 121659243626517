import { Avatar, Button, Card, Grid, List, Typography, styled, useTheme } from '@mui/material';
import { EventType, SubscriptionStatus } from '@op/shared/src/models/enums/enums';
import {
  SubscriptionPaymentFormsState,
  accountState,
  currentSubscriptionScreenState,
  currentSubscriptionState,
  selectedSymbolState,
} from '@op/shared/src/states';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { OPBoldTypograpghy, OPListItem } from '../../styled';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import Us from '@op/shared/assets/images/us.png';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import React from 'react';
import LocalizationContext from '../../react-i18next/localization-context';
import { SubscriptionCardLayout } from './subscription-card-layout';
import { SubscriptionHeaderWidget } from './subscription-footer-widget';
import { ISubscriptionWidgetProps } from './subscription-widget';

export const OPPortfolioStyledHoverCard = styled(Card)(({ theme }) => ({
  boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
  outline: '1px solid transparent',
  border: '1.5px solid transparent',
  transition: 'all 0.2s ease-in-out',
  WebkitTransition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  ':hover': {
    boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
    outline: '0px solid transparent',
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease-in-out',
    WebkitTransition: 'all 0.2s ease-in-out',
  },
}));

export const ChoosePlanWidget: React.FC<ISubscriptionWidgetProps> = ({
  activeStep,
  setActiveStep,
}: ISubscriptionWidgetProps) => {
  const theme = useTheme();
  const CurrentSubscriptionData = useRecoilValue(currentSubscriptionState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [cardData, setCardData] = useRecoilState(SubscriptionPaymentFormsState);
  const setCurrentSubscriptionScreen = useSetRecoilState(currentSubscriptionScreenState);
  const { t } = React.useContext(LocalizationContext);
  const subscriptionStatus = CurrentSubscriptionData.currentSubscription?.subscriptionStatus;
  const account = useRecoilValue(accountState);
  const cardHeight = '470px';

  if (!CurrentSubscriptionData) {
    return null;
  }

  if (!account || !account.securityModel) {
    return;
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    let newCardData = { ...cardData };
    newCardData.plan = value;
    setCardData({ ...newCardData, [event.target.name]: event.target.value });
    setActiveStep(activeStep + 1);
    logActivity('INPUT', 'paymentsSelectSubscription', event.target.value);
    logActivity('BUTTON', 'paymentsChooseSubscriptionChooseButton');
  };

  const handleBack = () => {
    logActivity('BUTTON', 'paymentsChooseSubscriptionBackButton');
    setCurrentSubscriptionScreen('currentsubscription');
  };

  const logActivity = (controlType: string, controlName: string, value?: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const getPrice = (type: string) => {
    if (CurrentSubscriptionData.currentPricingModel) {
      if (type === 'monthly') {
        return CurrentSubscriptionData.currentPricingModel?.userMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.currentPricingModel?.userAnnualPrice.toFixed(2);
    }
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.pricingModels &&
      CurrentSubscriptionData.pricingModels.length > 0
    ) {
      if (type === 'monthly') {
        return CurrentSubscriptionData.pricingModels[0].userMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.pricingModels[0].userAnnualPrice.toFixed(2);
    }
  };
  const getActualPrice = (type: string) => {
    if (CurrentSubscriptionData.currentPricingModel) {
      if (type === 'monthly') {
        return CurrentSubscriptionData.currentPricingModel?.regularMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.currentPricingModel?.regularAnnualPrice.toFixed(2);
    }
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.pricingModels &&
      CurrentSubscriptionData.pricingModels.length > 0
    ) {
      if (type === 'monthly') {
        return CurrentSubscriptionData.pricingModels[0].regularMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.pricingModels[0].regularAnnualPrice.toFixed(2);
    }
  };
  const getDiscountString = (type: string) => {
    if (
      CurrentSubscriptionData.currentPricingModel &&
      (CurrentSubscriptionData.currentPricingModel.discountMonthlyRate > 0 ||
        CurrentSubscriptionData.currentPricingModel.discountAnnualRate > 0)
    ) {
      if (type === 'monthly') {
        return ` (${t('app.php.subscriptions.afterDiscount')} ${
          CurrentSubscriptionData.currentPricingModel.discountMonthlyRate
        }%)`;
      }
      return ` (${t('app.php.subscriptions.afterDiscount')} ${
        CurrentSubscriptionData.currentPricingModel.discountAnnualRate
      }%)`;
    }
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.pricingModels &&
      CurrentSubscriptionData.pricingModels.length > 0 &&
      (CurrentSubscriptionData.pricingModels[0].discountMonthlyRate > 0 ||
        CurrentSubscriptionData.pricingModels[0].discountAnnualRate > 0)
    ) {
      if (type === 'monthly') {
        return ` (${t('app.php.subscriptions.afterDiscount')} ${
          CurrentSubscriptionData.pricingModels[0].discountMonthlyRate
        }%)`;
      }
      return ` (${t('app.php.subscriptions.afterDiscount')} ${
        CurrentSubscriptionData.pricingModels[0].discountAnnualRate
      }%)`;
    }
    return '';
  };

  const renderCardHeader = (type: string) => {
    return (
      <Grid container justifyContent="space-between" alignContent="center" style={{ color: '#ffffff' }}>
        <Grid item xs={8}>
          {type === 'monthly' ? (
            <OPBoldTypograpghy variant="subtitle1">{t('app.php.subscriptions.monthlyPlan')}</OPBoldTypograpghy>
          ) : (
            <OPBoldTypograpghy variant="subtitle1">{t('app.php.subscriptions.annualPlan')}</OPBoldTypograpghy>
          )}
        </Grid>
        <Grid item xs={4} container alignContent="center" justifyContent="right">
          <PhoneAndroidOutlinedIcon sx={{ marginX: '8px' }} fontSize="small" />
          <DesktopWindowsOutlinedIcon fontSize="small" />
        </Grid>
      </Grid>
    );
  };

  const renderHeaderText = () => {
    const plan = CurrentSubscriptionData.currentSubscription?.billingModel;
    const expiryDate = CurrentSubscriptionData.currentSubscription?.expirationDate;
    const daysRemaining = NumberFormatHelper.floor(DateTimeHelper.daysFromNow(expiryDate));
    const accountName = account.securityModel.displayName;
    if (subscriptionStatus === SubscriptionStatus.ACTIVE_TRIAL) {
      return (
        <Grid container>
          <SubscriptionHeaderWidget
            label={
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: theme.palette.success.main,
                    fontSize: '14px',
                    fontWeight: 700,
                  }}>
                  {`${t('app.php.subscriptions.yourFreeTrialHas')} ${daysRemaining} ${t(
                    'app.php.subscriptions.daysRemaining',
                  )}.
                  ${t('app.php.subscriptions.subscriptionBeginsAtEndOfFreeTrial')}
                  ${DateTimeHelper.format(expiryDate)}.
               `}
                </Typography>
              </Grid>
            }
          />
        </Grid>
      );
    }
    if (
      subscriptionStatus === SubscriptionStatus.EXPIRED_TRIAL ||
      subscriptionStatus === SubscriptionStatus.EXPIRED_EXTENDED_TRIAL
    ) {
      return (
        <SubscriptionHeaderWidget
          label={
            <OPBoldTypograpghy style={{ color: theme.palette.error.main, fontSize: '14px' }}>
              {t('app.php.subscriptions.yourFreeTrialEnded')}
            </OPBoldTypograpghy>
          }
        />
      );
    }
    if (subscriptionStatus === SubscriptionStatus.EXPIRED) {
      return (
        <SubscriptionHeaderWidget
          label={
            <Grid item xs={12}>
              <OPBoldTypograpghy style={{ color: theme.palette.success.main, fontSize: '14px' }}>
                {`${t('app.php.subscriptions.hi')} ${accountName}, ${t('app.php.subscriptions.goodToSeeYouBack')}`}
              </OPBoldTypograpghy>
              <OPBoldTypograpghy style={{ color: theme.palette.error.main, fontSize: '14px' }}>
                {`${t('app.php.subscriptions.your')} ${plan} ${t(
                  'app.php.subscriptions.subscriptionExpiredOn',
                )} ${DateTimeHelper.format(expiryDate)}`}
              </OPBoldTypograpghy>
            </Grid>
          }
        />
      );
    }
  };

  const renderBackButton = () => {
    /*
     * For an expired user, hiding the back button, as choose plan widget is initial screen.
     */
    if (subscriptionStatus === SubscriptionStatus.EXPIRED) {
      return;
    }
    return (
      <Grid container justifyContent={'center'}>
        <Grid item xs={2}>
          <Button
            onClick={handleBack}
            color="primary"
            size="large"
            variant="outlined"
            fullWidth
            startIcon={<ArrowBackIosIcon />}>
            {t('app.php.common.buttons.back')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderCardContent = (type: string) => {
    return (
      <Grid>
        <OPBoldTypograpghy
          style={{
            color: theme.palette.primary.main,
            paddingLeft: '13px',
            paddingTop: '8px',
            fontSize: '16px',
            fontWeight: 700,
          }}>
          {t('app.php.subscriptions.choosePlanList.benefits')}
        </OPBoldTypograpghy>
        <List sx={{ listStyleType: 'disc', pl: 4, textAlign: 'left' }}>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.importPortfolioPositions')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.realTimePortfolioAlerts')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.dailyTradeIdeas')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.proprietaryWatchlists')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.automatedOptions')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.realTimeMarket')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.optionsTradingSignals')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.optionsEducation')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.broadMarketResearch')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.strategistResearch')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.strategistQA')}</OPListItem>
          <OPListItem>{t('app.php.subscriptions.choosePlanList.rapidFire')}</OPListItem>

          {type === 'monthly' ? (
            //Created Empty lines to match the height of cards
            <Grid item xs={12}>
              <p>
                <br />
              </p>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <OPListItem>{t('app.php.subscriptions.choosePlanList.premiumEventDiscount')}</OPListItem>
              <OPListItem>{t('app.php.subscriptions.choosePlanList.earlyAccess')}</OPListItem>
            </Grid>
          )}
        </List>
      </Grid>
    );
  };
  const renderCardBottom = (type: string) => {
    const monthlyDiscountPrice = Number(getActualPrice('monthly')) - Number(getPrice('monthly'));
    const yearlyDiscountPrice = Number(getActualPrice('yearly')) - Number(getPrice('yearly'));
    return (
      <Grid
        item
        container
        justifyContent="center"
        marginX={2}
        paddingX={8}
        xs={11}
        rowSpacing={1}
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.mode === 'light' ? '#F2F9FE' : '#262D32',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          position: 'absolute',
          bottom: 0,
        }}>
        {type === 'monthly' ? (
          <Grid item textAlign="center">
            <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: '26px' }}>{`$${getPrice(
              'monthly',
            )}`}</OPBoldTypograpghy>
            <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: '18px' }}>{`${getDiscountString(
              'monthly',
            )}`}</OPBoldTypograpghy>
            <Button
              variant="contained"
              value="Monthly"
              size="large"
              onClick={(event: any) => handleChange(event, 'Monthly')}
              sx={{ margin: '8px' }}>
              <OPBoldTypograpghy>{t('app.php.subscriptions.choose')} </OPBoldTypograpghy>
            </Button>
          </Grid>
        ) : (
          <Grid item textAlign="center">
            <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: '26px' }}>{`$${getPrice(
              'yearly',
            )}`}</OPBoldTypograpghy>
            <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: '18px' }}>{` ${getDiscountString(
              'yearly',
            )}`}</OPBoldTypograpghy>
            <Button
              variant="contained"
              size="large"
              onClick={(event: any) => handleChange(event, 'Annual')}
              sx={{ margin: '8px' }}>
              <OPBoldTypograpghy>{t('app.php.subscriptions.choose')} </OPBoldTypograpghy>
            </Button>
          </Grid>
        )}
        {monthlyDiscountPrice > 0 && yearlyDiscountPrice > 0 && (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              background:
                type === 'monthly' && theme.palette.mode === 'dark'
                  ? '#11181e'
                  : type === 'monthly' && theme.palette.mode === 'light'
                  ? theme.palette.primary.light
                  : `linear-gradient(to right,#0078D4, #77BF45)`,
              marginTop: 'auto',
              height: 35,
              boxShadow: `0px 4px 4px rgba(0,0,0,0.4) inset`,
              borderTopRightRadius: theme.shape.borderRadius,
              borderTopLeftRadius: theme.shape.borderRadius,
            }}>
            {type === 'monthly' ? (
              <OPBoldTypograpghy variant="body1" sx={{ color: theme.palette.primary.main }}>
                {`${t('app.php.subscriptions.save')} $${monthlyDiscountPrice} `}
              </OPBoldTypograpghy>
            ) : (
              <OPBoldTypograpghy variant="body1" sx={{ color: '#fff' }}>
                {`${t('app.php.subscriptions.save')} $${yearlyDiscountPrice} `}
              </OPBoldTypograpghy>
            )}
          </Grid>
        )}
      </Grid>
    );
  };
  const renderPlan = () => {
    if (subscriptionStatus === SubscriptionStatus.ACTIVE) {
      return (
        <SubscriptionHeaderWidget
          label={
            <Grid item container xs={12} alignItems="center">
              <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: '14px', marginRight: '4px' }}>
                {t('app.php.subscriptions.chooseSubscriptionPlan')}
              </OPBoldTypograpghy>
              <Avatar alt="flag" src={Us} style={{ width: 25, height: 25, margin: 2 }} />
              <OPBoldTypograpghy
                variant="subtitle1"
                sx={{ color: theme.palette.primary.main, paddingLeft: 1, alignItems: 'center' }}>
                US
              </OPBoldTypograpghy>
            </Grid>
          }
        />
      );
    }
    return (
      <Grid item container xs={12}>
        <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: '14px', marginRight: '4px' }}>
          {t('app.php.subscriptions.chooseSubscriptionPlan')}
        </OPBoldTypograpghy>
        <Avatar alt="flag" src={Us} style={{ width: 25, height: 25, margin: 2 }} />
        <OPBoldTypograpghy variant="subtitle1" sx={{ color: theme.palette.primary.main, paddingLeft: 1 }}>
          US
        </OPBoldTypograpghy>
      </Grid>
    );
  };
  return (
    <Grid container rowSpacing={2} justifyContent="center">
      <Grid item container xs={12}>
        {renderHeaderText()}
      </Grid>
      <Grid item xs={12}>
        {renderPlan()}
      </Grid>
      <Grid item xs={12} container columnSpacing={4}>
        <Grid item xs={6}>
          {/* Empty Grid for dummy subheader above card to match heights */}
          <Grid
            justifyContent="center"
            alignItems="center"
            item
            sx={{
              backgroundColor: 'fff',
              height: '40px',
              marginBottom: -1,
            }}></Grid>
          <OPPortfolioStyledHoverCard>
            <SubscriptionCardLayout
              monthlyPlan
              sx={{
                boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
                '&.MuiCardContent-root': { padding: '0px 8px 0px 4px' },
                height: cardHeight,
                position: 'relative',
              }}
              title={renderCardHeader('monthly')}
              content={
                <Grid>
                  {renderCardContent('monthly')}
                  {renderCardBottom('monthly')}
                </Grid>
              }
            />
          </OPPortfolioStyledHoverCard>
        </Grid>
        <Grid item xs={6}>
          <Grid
            justifyContent="center"
            alignItems="center"
            item
            sx={{
              backgroundColor: theme.palette.success.main,
              height: '40px',
              padding: '8px',
              width: '150px',
              borderTopLeftRadius: theme.shape.borderRadius,
              borderTopRightRadius: theme.shape.borderRadius,
              marginBottom: -1,
            }}>
            <OPBoldTypograpghy textAlign="center" sx={{ color: '#fff' }}>
              {t('app.php.subscriptions.mostPopularDeal')}
            </OPBoldTypograpghy>
          </Grid>
          <OPPortfolioStyledHoverCard>
            <SubscriptionCardLayout
              yearlyPlan
              sx={{
                boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
                '&.MuiCardContent-root': { padding: '0px 8px 0px 4px' },
                height: cardHeight,
                position: 'relative',
              }}
              title={renderCardHeader('yearly')}
              content={
                <Grid>
                  {renderCardContent('yearly')}
                  {renderCardBottom('yearly')}
                </Grid>
              }
            />
          </OPPortfolioStyledHoverCard>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {renderBackButton()}
      </Grid>
    </Grid>
  );
};
