import {
  IStrategyTemplate,
  IncomeCombination,
  PortfolioManagementCombination,
  StrategiesProvider,
} from '@op/shared/src/models';
import HowDataModel from '@op/shared/src/models/how/how-data-model';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import TradingStrategies from '@op/shared/src/models/how/trading-strategies';
import { atom, atomFamily, selector } from 'recoil';
import { customizationState, guardRecoilDefaultValue, howDataState } from '..';
import { Exchange } from '../../models/enums/enums';
import formatting from '../../models/how/formatting';
import WhatIfSimulator from '../../models/how/whatif-simulator';
import { ResponseViewModel } from '../../view-models/responses/response-viewmodel';
import { Combination } from './../../models/how/combination';

export const tradingStrategySelectedStrategyState = atom<string | undefined>({
  key: 'tradingStrategySelectedStrategyStateKey',
  default: undefined,
});

export const tradingStrategiesSentimentAtom = atom<String | undefined>({
  key: 'tradingStrategiesSentimentStateKey',
  default: undefined,
});

export const embedderSentimentAtom = atom<string | undefined>({
  key: 'embedderSentimentAtomKey',
  default: undefined,
});

const whatIfSimulatorAtom = atom<WhatIfSimulator | undefined>({
  key: 'whatIfSimulatorstateKey',
  default: undefined,
});

export const isOnGoesToThisPriceChangedAtom = atom<boolean>({
  key: 'checkOnGoesToThisPriceStateKey',
  default: false,
});

export const tradingRangeSimulatorAtom = atom<TradingRangeSimulator | undefined>({
  key: 'tradingRangeSimulatorStateKey',
  default: undefined,
});

//Combination state for the strategy constructor widget.
export const strategyModifyState = atom<Combination | undefined>({
  key: 'tradingStrategyModifyStateKey',
  default: undefined,
});

/*
 * Combination state for trade ticket widget.
 * Performance note: If memory footprint becomes an issue,
 * then we should use HOC to have different version of TradeTicket widget which respond to selected recoil atoms
 */
export const tradeTicketCombinationState = atom<
  | {
      combination: Combination | IncomeCombination | PortfolioManagementCombination | undefined;
      resultingCombination?: PortfolioManagementCombination;
    }
  | undefined
>({
  key: 'tradeTicketCombinationStateKey',
  default: undefined,
});

export const viewState = atom<'trade' | 'income' | 'portfolioManagement'>({
  key: 'viewStateKey',
  default: 'trade',
});

//TODO: @Vinay as per discussion, please make viewState as `Stack` datastructure to maintain navigation history.
export const subViewState = atom<'expand' | 'tradeTicket' | undefined>({
  key: 'subViewStateKey',
  default: undefined,
});

/**
 * @deprecated Use `howDataState` instead.
 */
export const tradingStrategiesHowState = selector<ResponseViewModel<HowDataModel | undefined>>({
  key: 'tradingStrategiesHowStateKey',
  get: async ({ get }) => {
    const howData = get(howDataState);
    const result = ResponseViewModel.fromData(howData);
    return result;
  },
});

export const tradingRangeSimulatorState = selector<TradingRangeSimulator | undefined>({
  key: 'tradingRangeSimulatorKey',
  get: async ({ get }) => {
    const tradingRangeSimulator = get(tradingRangeSimulatorAtom);
    return tradingRangeSimulator;
  },
  set: ({ set }, data) => {
    if (guardRecoilDefaultValue(data)) {
      throw new Error('Its default value');
    }
    set(tradingRangeSimulatorAtom, data);
  },
});

export const whatIfSimulatorState = selector<WhatIfSimulator | undefined>({
  key: 'whatIfSimulatorKey',
  get: ({ get }) => {
    const whatIfSimulator = get(whatIfSimulatorAtom);
    return whatIfSimulator;
  },
  set: ({ set, reset }, newWhatIfSimulator) => {
    if (guardRecoilDefaultValue(newWhatIfSimulator)) {
      console.error('newWhatIfSimulator is default');
      reset(whatIfSimulatorAtom);
      return;
    }
    set(whatIfSimulatorAtom, newWhatIfSimulator);
  },
});

export const bestProfitReturnCombinationIdState = selector({
  key: 'bestProfitReturnCombinationIdStateKey',
  get: ({ get }) => {
    const whatIfSimulator = get(whatIfSimulatorState);
    if (!whatIfSimulator) {
      return undefined;
    }
    const allCombinations = get(allCombinationsState);
    if (allCombinations.length === 0) {
      return undefined;
    }
    let highestProfitReturnCombinationId = -1;
    let bestPL = 0;
    for (let index in allCombinations) {
      const combination = allCombinations[index];
      if (!combination) {
        continue;
      }
      const whatifTheoretical = whatIfSimulator.whatifTheoretical(combination);
      const pl = whatifTheoretical.returnValue;
      if (pl > bestPL) {
        bestPL = pl;
        highestProfitReturnCombinationId = isNaN(parseInt(index)) ? -1 : parseInt(index);
      }
    }
    return highestProfitReturnCombinationId;
  },
});

/*=================== Atom Family implementation ================= */
//TODO: Allowing undefined in key of map of atomFamily seems wrong. however, this is legitimat that no strategy is selected. Rethink.
export const tradingStrategyAtomFamilyState = atomFamily<Combination | undefined, string | undefined>({
  key: 'tradingStrategyAtomFamilyStateKey',
  default: undefined,
});

export const tradingStrategiesUpdater = selector<(Combination | undefined)[]>({
  key: 'tradingStrategiesUpdaterStateKey',
  get: ({ get }) => {
    throw new Error('This is only setter');
  },
  set: ({ set }, combinations) => {
    if (guardRecoilDefaultValue(combinations)) {
      return;
      //throw new Error('defaul value');
    }
    for (let i = 0; i < combinations.length; i++) {
      const combination = combinations[i];
      set(tradingStrategyAtomFamilyState(i.toString()), combination);
    }
  },
});

export const allCombinationsState = selector({
  key: 'allCombinationsStateKey',
  get: ({ get }) => {
    const stockCombination = get(tradingStrategyAtomFamilyState('0'));
    const callOrPutCombination = get(tradingStrategyAtomFamilyState('1'));
    const callOrPutVerticalCombination = get(tradingStrategyAtomFamilyState('2'));
    return [stockCombination, callOrPutCombination, callOrPutVerticalCombination] as (Combination | undefined)[];
  },
});

/*
 * Intestringly, recoil calculates the farthest expiration on every run,
 * however do not update if values has not chnaged from last value.
 * Same sort selector is for portfolio as well `portfolioFarthestExpiration`.
 * That can be incorporate in this itself. Not sure how would recoil cache work.
 * Something to check before merging both. Will it call on every change or not. Ideally not if value not changeed.
 */
export const farthestExpirationState = selector<Date | undefined>({
  key: 'farthestExpirationStateKey',
  get: ({ get }) => {
    const combinations = get(allCombinationsState);
    return TradingStrategies.farthestExpiration(combinations);
  },
  set: ({ get, set }, expiry) => {
    if (guardRecoilDefaultValue(expiry) || !expiry) {
      return;
    }
    const tradingRangeSimulator = get(tradingRangeSimulatorAtom);
    if (!tradingRangeSimulator) {
      return;
    }
    const howData = get(howDataState);
    if (!howData) {
      return;
    }
    const clone = TradingRangeSimulator.fromSelf(tradingRangeSimulator);
    clone.turnToNarrow(howData.stdDev, expiry);
    set(tradingRangeSimulatorAtom, clone);
  },
});

export type IStrategyConstructor = {
  name: string;
  template: IStrategyTemplate[];
};

export const strategyConstructorSelectorState = selector<IStrategyConstructor[] | undefined>({
  key: 'strategyConstructorSelectorStateKey',
  get: ({ get }) => {
    const customization = get(customizationState);
    const combinationId = get(tradingStrategySelectedStrategyState);
    if (!customization || !combinationId) {
      return;
    }
    const combination = get(tradingStrategyAtomFamilyState(combinationId));
    if (!combination) {
      return;
    }
    const sentimentOptions = Array.from(StrategiesProvider.sentiments);
    if (!sentimentOptions || !sentimentOptions.length) {
      return;
    }
    const strategyTemplateObj: IStrategyConstructor[] = [];
    sentimentOptions.map((option) => {
      if (!option) {
        return;
      }
      let nestedOptions = StrategiesProvider.strategyTemplatesBySentiment.get(option);
      if (!nestedOptions) {
        return;
      }
      /**
       * In Questrade Embedder for a canadian symbol hide the covered call strategy
       * use allowStockPlusOptionforCASymbol if false hide coveredcall
       */
      const isCanadianSymbol = formatting.getExchange(combination.symbol) === Exchange.XTSE.toString();
      if (isCanadianSymbol && !customization.allowStockPlusOptionforCASymbol) {
        // nestedOptions = nestedOptions.filter(
        //   (o) => o.template.name.toLowerCase().replaceAll(' ', '') !== 'coveredcall',
        // );
        nestedOptions = nestedOptions.filter(
          (o) => formatting.customReplaceAll(o.template.name.toLowerCase(), ' ', '') !== 'coveredcall',
        );
      }
      strategyTemplateObj.push({ name: option, template: nestedOptions });
    });
    return strategyTemplateObj;
  },
});
