import React, { useState } from 'react';
import { Tooltip, useTheme } from '@mui/material';
import { downloadCsvFile } from '@op/shared/src/models';
import { downloadReports } from '@op/shared/src/services/reports-service';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { customizationState } from '@op/shared/src/states';
import { IconComponent } from '../common';
import { OPIconButton } from '../styled';
import LocalizationContext from '../react-i18next/localization-context';

interface DownloadReportProps {
  ideaType: string;
  createdOn: string;
}

export const DownloadReportExcelWidget: React.FC<DownloadReportProps> = ({ ideaType, createdOn }) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const setNotifications = useSetRecoilState(notificationsState);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const customization = useRecoilValue(customizationState);

  const getReportType = (ideaType: string): string => {
    switch (ideaType) {
      case 'creditSpread':
        return 'creditspreads';
      case 'coveredCall':
        return 'coveredcalls';
      case 'shortPut':
        return 'shortputs';
      default:
        return '';
    }
  };

  const downloadReport = async () => {
    const reportType = getReportType(ideaType);
    if (!reportType) {
      setNotifications([{ type: 'error', content: `Invalid report type: ${ideaType}.` }]);
      return;
    }

    try {
      const response = await downloadReports(reportType, createdOn);
      if (response.hasErrors || !response.data) {
        setNotifications([{ type: 'error', content: `Unable to get ${ideaType} reports.` }]);
        return;
      }
      downloadCsvFile(response.data as ArrayBuffer, `${reportType} ${createdOn}.csv`);
      setDownloadSuccess(true);
    } catch (error) {
      setNotifications([{ type: 'error', content: `An error occurred while downloading ${ideaType} report.` }]);
    }
  };

  if (!customization || !customization.showTechnicalReports) {
    return <></>;
  }

  return (
    <Tooltip title={t('app.php.what.tradeIdeas.ideas.downloadExcel')} placement="top" arrow>
      <OPIconButton onClick={downloadReport} success={downloadSuccess}>
        <IconComponent
          name={downloadSuccess ? 'downloadExcelSuccessIcon' : 'downloadExcelIcon'}
          stroke={downloadSuccess ? theme.palette.info.light : theme.palette.primary.main}
        />
        {/* <OPBoldTypograpghy sx={{ pl: 1 }}>Excel</OPBoldTypograpghy> */}
      </OPIconButton>
    </Tooltip>
  );
};
