import { Trade } from '..';
import { HistoricalQuote } from '../how/historical-quote';
import { ISyrahSentimentLongTerm, ISyrahSentimentShortTerm, ISupportResistance, IExDividens } from '.';

export class WhyEntity {
  earningsDate: string | undefined = '';
  earningsTime: string = '';
  exDividends: IExDividens | undefined;
  historicalQuotes: HistoricalQuote[] = [];
  impliedVolatilityRank: number = -1;
  liquidityExplanation: string = '';
  liquidityRank: number = -1;
  sentence: string = '';
  sentiment: string = '';
  supportAndResistance: ISupportResistance = { gapResistance: [], gapSupport: [], resistance: [], support: [] };
  syrahSentimentLongTerm: ISyrahSentimentLongTerm[] = [];
  syrahSentimentShortTerm: ISyrahSentimentShortTerm[] = [];
  technicalRank: number = -1;
  tradeIdeas: Trade[] = [];
}
