import { Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Quote } from '@op/shared/src/models';
import formatting from '@op/shared/src/models/how/formatting';
import { configurationState, watchListNewsFeedState } from '@op/shared/src/states';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ExpandedQuoteHubWidget, IconComponent, SentimentShortTermWidget, TechnicalScoreWidget } from '../common';
import { OpLinkMenus } from '../ideas/op-link-menu';

interface Props {
  data: Quote;
  showDeleteIconIfPossible: boolean;
  onSymbolSelected: () => void;
  setSlideSelect: () => void;
}

export const SliderRowView: React.FC<Props> = ({
  data,
  showDeleteIconIfPossible,
  onSymbolSelected,
  setSlideSelect,
}: Props) => {
  const configuration = useRecoilValue(configurationState);
  const [newsFeedState, setNewsFeedState] = useRecoilState(watchListNewsFeedState);
  const theme = useTheme();
  const navigate = useNavigate();

  const signals = [{ date: '', name: 'TechnicalRank', symbol: data.symbol, value: data.technicalRank }];

  const newsFeedUpdate = () => {
    setNewsFeedState(true);
    onSymbolSelected();
  };

  const redirectToTradeIdea = () => {
    navigate('/');
    onSymbolSelected();
  };

  const slideSelectedView = () => {
    return (
      <Grid item alignItems="center" xs={7} container style={{ backgroundColor: theme.palette.primary.main }}>
        <Grid justifyContent="space-between" alignItems="center" item xs={12} px={1.5} container>
          {/* {selectedWatchList?.type === WatchListsTypes.NONE && (
            <Grid item xs={3}>
              <Tooltip title={isSymbolPinned ? 'Unpin' : 'Pin'} placement="top">
                <IconButton>
                  <div onClick={() => pinUnpinWatchListSymbol()} style={{ marginLeft: 5 }}>
                    <IconComponent
                      name="pinActive"
                      stroke={
                        isSymbolPinned && theme.palette.mode === 'light'
                          ? '#3CA7FC'
                          : isSymbolPinned && theme.palette.mode === 'dark'
                          ? '#0078D4'
                          : 'white'
                      }
                      hoverIcon={!isSymbolPinned && 'pinInactive'}
                      size={15}
                    />
                  </div>
                </IconButton>
              </Tooltip>
            </Grid>
          )} */}
          <Grid item xs={'auto'}>
            <Tooltip title="Trade Ideas" placement="top">
              {/* TODO: gray disabled icon when not symbol available in trade-ideas */}
              <IconButton>
                <div
                  onClick={() => {
                    data.isTradeIdea && redirectToTradeIdea();
                  }}
                  style={{ marginTop: 2 }}>
                  <IconComponent
                    name={'tradeIdea'}
                    stroke={!data.isTradeIdea ? '#585D61' : 'white'}
                    hoverIcon="tradeHover"
                    size={24}
                  />
                </div>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={'auto'}>
            <Tooltip title="Portfolio" placement="top">
              <OpLinkMenus
                data={data.portfolios.map((p) => {
                  return {
                    id: p.id,
                    symbol: p.symbol,
                    name: p.name,
                  };
                })}
                title="Available in Portfolio"
                noTitle="No Portfolios under this symbol"
                icon={'portfolioClosed'}
                hoverIcon={'portfolioOpen'}
                stroke={data.portfolios.length > 0 ? '#ffffff' : '#585D61'}
                showDefault={data.portfolios.length === 0}
                filterType="portfolio"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={'auto'}>
            {configuration && configuration.showNewsTab && (
              <Tooltip title="News Feed" placement="top">
                <IconButton>
                  <div onClick={newsFeedUpdate} style={{ marginTop: 8 }}>
                    <IconComponent
                      name={newsFeedState ? 'newsHover' : 'newFeed'}
                      stroke={
                        newsFeedState && theme.palette.mode === 'light'
                          ? '#3CA7FC'
                          : newsFeedState && theme.palette.mode === 'dark'
                          ? '#0078D4'
                          : 'white'
                      }
                      hoverIcon={'newsHover'}
                      size={24}
                    />
                  </div>
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSliderView = () => {
    return (
      <Grid container>
        <Grid
          item
          xs={4.5}
          py={0.5}
          container
          justifyContent="space-between"
          alignItems={'center'}
          onClick={() => data.symbol && onSymbolSelected()}>
          <Grid item xs={12} container justifyContent="space-between" pr={1}>
            <Grid item xs={11}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                lineHeight={1}
                className="js-trade-idea-symbol-dot-exchange-symbol">
                {formatting.symbolDotExchangeToSymbol(data.symbol)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <SentimentShortTermWidget symbol={data.symbol} isIconOnly />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TechnicalScoreWidget data={signals} isOnlyScore />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={showDeleteIconIfPossible ? 7 : 'auto'}>
              <ExpandedQuoteHubWidget data={data.symbol} showOnlyLastPrice={true} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={0.5}
          justifyContent="right"
          style={{ backgroundColor: theme.palette.primary.main, borderWidth: 3 }}
          onClick={setSlideSelect}>
          <IconButton
            sx={{ justifyContent: 'center', alignItems: 'center', transform: 'rotate(-180deg)', height: '100%' }}>
            <IconComponent name="sliderOpenIcon" />
          </IconButton>
        </Grid>
        {slideSelectedView()}
      </Grid>
    );
  };

  return renderSliderView();
};
