import { PortfolioCombination } from '.';
import { CombinationTextGeneration } from '..';
import { ClosePositionType, CombinationType, PriceCalculationMethod } from '../enums/enums';
import Combination from '../how/combination';
import formatting from '../how/formatting';
import HowDataModel from '../how/how-data-model';
import { Leg } from '../how/leg';
import { Position } from '../how/position-model';

export class PortfolioManagementCombination extends Combination {
  portfolioTypeStatus = '';
  private constructor() {
    super();
  }

  originalCombination: PortfolioCombination | undefined;
  howDataModel: HowDataModel | undefined;

  static fromLegsData = (
    legs: Leg[] | undefined,
    combinationContext: {
      howDataModel: HowDataModel;
      computedLegs: Leg[] | undefined;
      combinationType: CombinationType;
      defaultPriceCalculationMethod: PriceCalculationMethod;
      originalCombination: PortfolioCombination;
    },
  ) => {
    const combination = new PortfolioManagementCombination();
    combination.fromCombinationContext(
      legs,
      combinationContext.howDataModel,
      combinationContext.defaultPriceCalculationMethod,
    );
    //TODO: combinationContext is not required. Remove it.
    combination.howDataModel = combinationContext.howDataModel; //HowDataModel.fromSelf(combinationContext.howDataModel);
    combination.stdDev = combinationContext.howDataModel.stdDev;
    combination.originalCombination = combinationContext.originalCombination;
    combination.combinationType = combinationContext.combinationType;
    combination.allowNegativeCostBasis = true;
    combination.priceCalculationMethod = PriceCalculationMethod.MID;
    combination.portfolioTypeStatus = combinationContext.originalCombination.portfolioTypeStatus;
    combination.initPositions(legs);
    combination.updatePositions(combinationContext.originalCombination);
    combination.profileSentiments(); //TODO: auto called from combination.ts initialize.
    //combination.initialize(legs);
    return combination;
  };

  static fromSelf(self: PortfolioManagementCombination) {
    const clone = new PortfolioManagementCombination();
    clone.fromSelf(self);
    clone.positions = self.positions.map((p) => Position.fromSelf(p));
    clone.options = self.options;
    clone.predictions = self.predictions;
    clone.stdDev = self.stdDev;
    clone.priceCalculationMethod = self.priceCalculationMethod;
    clone.originalCombination = self.originalCombination;
    clone.isRoll = self.isRoll;
    clone.implementation = self.implementation;
    clone.lastHighersChanged = self.lastHighersChanged;
    clone.lastHigherChanged = self.lastHigherChanged;
    clone.combinationType = self.combinationType;
    clone.sentiments = self.sentiments.map((s) => s);
    clone.sentimentProfile = self.sentimentProfile;
    clone.portfolioTypeStatus = self.portfolioTypeStatus;
    return clone;
  }

  //overwritten of combinaton.ts
  protected initPositions = (originalLegs: Leg[] | undefined) => {
    if (!originalLegs || !originalLegs.length) {
      this.positions = [];
      return;
    }
    let positions = originalLegs.map((leg) =>
      Position.fromLeg(leg, this.chain, this.quote, this.optionType, this.priceCalculationMethod, true),
    );
    this.positions = positions;
  };

  get combinationName() {
    if (this.isCurrent) {
      return `Current ${formatting.symbolDotExchangeToSymbol(this.symbol)} Position`;
    }

    if (this.isResulting) {
      return `Resulting ${formatting.symbolDotExchangeToSymbol(this.symbol)} Position`;
    }

    if (this.isAdjustment) {
      return `Adjusted ${formatting.symbolDotExchangeToSymbol(this.symbol)} Position`;
    }

    //TODO: Closed this.symbol Positions
    return 'Unknown';
  }

  get adjustmentNameInPortfolio() {
    if (!this.isAdjustment) {
      throw new Error('Combination is not adjustment type');
    }
    const titles = CombinationTextGeneration.getAdjustmentCombinationName(this, false, false);
    if (titles.length === 0) {
      return '';
    }
    return titles;
  }

  private updatePositions = (originalCombination: PortfolioCombination) => {
    if (!originalCombination) {
      return;
    }
    const originalPositions = originalCombination.extractedPositions;
    // ReCheck sort by expiry date is not working
    this.positions.sort((p1: any, p2: any) => {
      return p1.expiry - p2.expiry;
    });
    this.positions.forEach((p) => {
      p.closePositionType = undefined;
    });
    for (let originalPos of originalPositions) {
      var fullQty = originalPos.quantity;
      var closedQty = 0;
      for (let pos of this.positions) {
        var isEquvalent = pos.isEquivalentPosition(originalPos);
        var posQty = pos.quantity;
        if (
          !isEquvalent ||
          Math.sign(posQty) === Math.sign(fullQty) ||
          closedQty + Math.abs(posQty) > Math.abs(fullQty)
        ) {
          continue;
        }
        closedQty += Math.abs(posQty);
        pos.closePositionType =
          Math.abs(posQty) === Math.abs(fullQty) ? ClosePositionType.FULL_CLOSE : ClosePositionType.PARTIAL_CLOSE;
      }
    }
  };

  // This is used to generate name of strategy in trade of portfolio.
  _buildCombinationFromExistingPositions = (positionFilter: (position: Position) => boolean) => {
    //var self = this;
    let newCombinationContext = {
      predictions: this.predictions,
      chain: this.chain,
      stdDev: this.stdDev,
      quote: this.quote,
      optionType: this.optionType,
    };
    let newCombination = undefined;
    var rawPositions = this.positions.filter(positionFilter).map((p: Position) => {
      return p.getRawPosition();
    });

    if (rawPositions.length && this.howDataModel) {
      newCombination = Combination.fromLegs(rawPositions, this.howDataModel as HowDataModel);
    } else {
      newCombination = undefined;
    }
    return newCombination;
  };

  openningPositionsCombination = () => {
    if (!this.originalCombination) {
      return this;
    }
    return this._buildCombinationFromExistingPositions((p: Position) => {
      return !this.isClosePosition(p, true);
    });
  };

  closingPositionsCombination = () => {
    if (!this.originalCombination) {
      return undefined;
    }
    return this._buildCombinationFromExistingPositions((p: Position) => {
      return this.isClosePosition(p, true);
    });
  };

  // Moved from combination.ts
  adjustedCostBasis = (isClosed: boolean = false) => {
    var result = this.positions.reduce((acc, pos) => {
      if (isClosed) {
        //if we close position, we just calculating cost basis of original position. Only original legs will have cost basis
        return acc + (pos.costBasis || 0) * Math.sign(pos.quantity);
      }
      return acc + (pos.costBasis || pos.price()) * Math.sign(pos.quantity);
    }, 0);

    if (!this.allowNegativeCostBasis) {
      result = Math.abs(result);
    }

    return result;
  };

  // daysToExpiry = () => {
  //   const buyPositions = this.positions.filter((p) => p.isBuyPosition);
  //   let expiries = this.getExpiries(buyPositions);
  //   if (buyPositions.length < 1 || this.hasOnlyStx()) {
  //     return -999999;
  //   }
  //   let days = formatting.daysFromNow(expiries[0]);
  //   return days;
  // };
}
