import { Grid, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { HeightWidget, PageContainerWidget, NavigationWidget } from '.';
import { HowTabsWidget } from '../how';
import { QuoteBarWidget } from '../quoteBar/quote-bar-widget';
import { PortfolioAccountSummaryWidget } from '../portfolio/portfolio-filter-summary-widget';
import { PortfolioWhatWidget } from '../portfolio/portfolio-what-widget';
import { PortfolioControlsWidget } from '../portfolio/portfolio-controls-widget';
import { viewState } from '@op/shared/src/states';
import { useSetRecoilState } from 'recoil';

export const PortfolioHomePageWidget: React.FC = () => {
  const setView = useSetRecoilState(viewState);
  useEffect(() => {
    document.title = 'OptionsPlay Portfolio | OptionsPlay';
    return () => setView('trade');
  }, []);

  return (
    <PageContainerWidget>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              {/* border transparent is a hack to keep container sync with quotebar. */}
              <Paper sx={{ height: '100%', border: '1px solid transparent' }}>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <NavigationWidget />
                  </Grid>
                  <Grid item xs={12}>
                    <PortfolioControlsWidget />
                  </Grid>
                  <Grid item xs={12}>
                    <PortfolioAccountSummaryWidget />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <QuoteBarWidget />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <HeightWidget height={700} noScroll sx={{ backgroundColor: 'transparent' }}>
                <PortfolioWhatWidget />
              </HeightWidget>
            </Grid>
            <Grid item xs={6}>
              <HeightWidget height={700}>
                <HowTabsWidget />
              </HeightWidget>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainerWidget>
  );
};
