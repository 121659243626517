import { Grid, Link, Paper, Typography } from '@mui/material';
import { isGlobal } from '@op/shared/src/models';
import { configurationState } from '@op/shared/src/states';
import React, { useContext, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { LoginSignupContainer, LoginWidget } from './login-widget';
import { useOPNavigate } from '../states/use-navigate-search';

export const LoginPageWidget: React.FC<{}> = () => {
  const navigate = useOPNavigate();
  const { t } = useContext(LocalizationContext);
  const configuration = useRecoilValue(configurationState);

  useEffect(() => {
    document.title = 'Sign In | OptionsPlay';
  }, []);

  const onForgot = async (): Promise<void> => {
    navigate('/forgot');
  };
  const onRegistration = async (): Promise<void> => {
    navigate('/registration');
  };
  const renderRedirectionText = () => {
    if (!configuration || !configuration.redirectionWebsiteRegion || !configuration.redirectionWebsiteUrl) {
      return;
    }
    if (isGlobal()) {
      return <></>;
    }
    return (
      <Grid item xs={12}>
        <Typography sx={{ color: '#fafafa', fontSize: 18 }}>
          {`For OptionsPlay ${configuration.redirectionWebsiteRegion.toUpperCase()} `}
          <Link href={configuration.redirectionWebsiteUrl} variant="body1" sx={{ color: '#fafafa' }}>
            {'click here'}
          </Link>
        </Typography>
      </Grid>
    );
  };

  return (
    <LoginSignupContainer direction="column" alignItems="center" container justifyContent="center">
      <Grid direction="column" alignItems="center" container justifyContent="center" sx={{ minHeight: '100vh' }}>
        <Grid item>
          <Paper>
            <LoginWidget />
          </Paper>
          <Grid container sx={{ display: 'flex', flexDirection: 'row', pt: 1 }}>
            <Grid item xs={6} sx={{ textAlign: 'left', cursor: 'pointer' }}>
              <Link tabIndex={0} variant="body1" sx={{ color: '#fafafa' }} onClick={onForgot}>
                {t('app.php.signIn.labels.forgotYourPassword')}
              </Link>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', cursor: 'pointer' }}>
              <Link tabIndex={0} variant="body1" sx={{ color: '#fafafa' }} onClick={onRegistration}>
                {t('app.php.signIn.labels.dontHaveAccount')}
              </Link>
            </Grid>
          </Grid>
          <Grid container sx={{ display: 'flex', flexDirection: 'row', py: 1 }}>
            {renderRedirectionText()}
          </Grid>
        </Grid>
      </Grid>
    </LoginSignupContainer>
  );
};
