import { Button, Grid, Link, Typography, useTheme } from '@mui/material';
import {
  IBuilderData,
  ILeg,
  IncomeBuilder,
  IncomeCombination,
  IncomeStrategies,
  Position,
  TradeTicket,
} from '@op/shared/src/models';
import {
  ActionTypes,
  EmbedderEventType,
  EventType,
  LegType,
  OptionType,
  Permissions,
  PriceCalculationMethod,
  tradeSimulatorOperationType,
} from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import SingleTrade from '@op/shared/src/models/how/single-trade';
import {
  accountState,
  configurationState,
  customizationState,
  expandedQuoteAtomFamily,
  howDataState,
  incomeCombinationSelectedIdState,
  isDataLoadingState,
  selectedSymbolState,
  strategyModifyState,
  strategyShareDataState,
  subViewState,
  tradeSimulatorOperationTypeState,
  tradeTicketCombinationState,
} from '@op/shared/src/states';
import {
  costBasisState,
  incomeCombinationAtomFamilyState,
  incomeStrategiesState,
  selectedCombinationPriorityState,
  sharesState,
} from '@op/shared/src/states/how/income-how-states';
import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SingleShareWidget } from '../common';
import { SimulatorOperationsWidget } from '../how/simulator-operations-widget';
import { SimulatorWidget } from '../how/simulator-widget';
import LocalizationContext from '../react-i18next/localization-context';
import { GuideItem } from '../side-menu';
import { RiskAnalysisWidget } from '../single-trade';
import { CompanyNameWidget } from '../single-trade/company-name-widget';
import { StrategyDataWidget } from '../single-trade/strategy-data-widget';
import { OptionsPlayStrategyWidgetGrid } from '../styled';

export interface IIncomeStrategyWidgetProps {}

export const IncomeStrategyWidget: React.FC<IIncomeStrategyWidgetProps> = ({}: // canupdateCostBasis,
IIncomeStrategyWidgetProps) => {
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const customization = useRecoilValue(customizationState);
  const configuration = useRecoilValue(configurationState);
  const costBasis = useRecoilValue(costBasisState);
  const shares = useRecoilValue(sharesState);
  const incomeCombinationSelectedId = useRecoilValue(incomeCombinationSelectedIdState);
  const howData = useRecoilValue(howDataState);
  const [combination, setCombination] = useRecoilState(
    incomeCombinationAtomFamilyState(incomeCombinationSelectedId.toString()),
  );
  const setPriority = useSetRecoilState(selectedCombinationPriorityState(incomeCombinationSelectedId));
  const isDataLoading = useRecoilValue(isDataLoadingState);
  const incomeStrategies = useRecoilValue<IncomeStrategies | undefined>(incomeStrategiesState);
  const expandedQuote = useRecoilValue(expandedQuoteAtomFamily(combination?.symbol.toUpperCase() || ''));
  const [simulatorOperation, setSimulatorOperation] = useRecoilState(tradeSimulatorOperationTypeState);
  const shareRoute = useMatch('/share/*');
  const { t } = React.useContext(LocalizationContext);
  const setStrategyModify = useSetRecoilState(strategyModifyState);
  const setTradeTicket = useSetRecoilState(tradeTicketCombinationState);
  const setSubView = useSetRecoilState(subViewState);
  const account = useRecoilValue(accountState);
  const strategyShareData = useRecoilValue(strategyShareDataState);
  const theme = useTheme();
  const [creationDate, setCreationDate] = useState<Date | undefined>();
  const [sharedBy, setSharedBy] = useState<string | undefined>();

  useEffect(() => {
    if (shareRoute && (shareRoute.params || window.location.search)) {
      setSimulatorOperation(tradeSimulatorOperationType.OptionsGrid);
      return;
    }
    setSimulatorOperation(tradeSimulatorOperationType.OptionsGrid);
    return () => setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
  }, []);

  useEffect(() => {
    if (!strategyShareData) {
      return;
    }
    const shareBy = strategyShareData.sharedBy as string;
    const creationDate = new Date(strategyShareData.creationDate || new Date());
    setCreationDate(creationDate);
    setSharedBy(shareBy);
  }, [strategyShareData]);

  const hasShares = incomeCombinationSelectedId === 0;
  //TODO: how share is able to render strategy, i guess it loads expanded view.
  if (!account || !account.securityModel || !howData || isDataLoading) {
    return <></>;
  }
  setStrategyModify(combination);
  const icomeSettingsPermission = account.securityModel.hasPermission(Permissions.MANAGE_COVERED_CALL_SETTINGS);

  const generateActiveIncomeCombText = () => {
    return (
      <Typography style={{ padding: 10 }}>
        <span>{t('app.php.how.incomeStrategies.text.currentlyAvailableOptions')}</span>
        <b>{formatting.symbolDotExchangeToSymbol(selectedSymbol || '')}</b>,
        <span> {t('app.php.how.incomeStrategies.text.noOptimalSolution')}</span>
        <b>{hasShares ? LegType.CALL : LegType.PUT}</b>
        <span>{`${t('app.php.how.incomeStrategies.text.goodBalance')} `}</span>
        {hasShares ? getCallTypeString() : getPutTypeString()}
      </Typography>
    );
  };

  /**
   * NOTE:
   * To Create Aggressive strategy for CALL Type howData.alternativecall should be checked.
   * To Create Aggressive strategy for PUT Type howData.alternativePut should be checked.
   * If condition satisfies then only click here text should be visible
   */
  const getCallTypeString = () => {
    return (
      <>
        <span>{t('app.php.how.incomeStrategies.text.losingTheStock')}</span>
        {howData.alternativeCall ? (
          <>
            <span>{` ${t('app.php.how.incomeStrategies.text.viewMore')} `}</span>
            <b>
              <span>{`${t('app.php.how.incomeStrategies.text.aggresiveText')} `}</span>
            </b>
            <span> {`${t('app.php.how.incomeStrategies.text.strategiesText')} `}</span>
            <b>
              <Link href="#" onClick={createAggressiveStrategy}>
                <span>{t('app.php.how.incomeStrategies.text.clickHereText')}</span>
              </Link>
            </b>
          </>
        ) : null}
      </>
    );
  };

  const getPutTypeString = () => {
    return (
      <>
        <span>{t('app.php.how.incomeStrategies.text.purchasingTheStock')}</span>
        {howData.alternativePut ? (
          <>
            <span>{` ${t('app.php.how.incomeStrategies.text.viewMore')}`}</span>
            <b>
              <span>{t('app.php.how.incomeStrategies.text.aggresiveText')}</span>
            </b>
            <span> {t('app.php.how.incomeStrategies.text.strategiesText')}</span>
            <span>,&nbsp;</span>
            <b>
              <Link href="#" onClick={createAggressiveStrategy}>
                <span>{t('app.php.how.incomeStrategies.text.clickHereText')}</span>
              </Link>
            </b>
          </>
        ) : null}
      </>
    );
  };

  //TODO: validate it that alt comb is setting correctly.
  const createAggressiveStrategy = () => {
    if (!incomeStrategies) {
      return;
    }
    const type = incomeCombinationSelectedId === 0 ? OptionType.CALL : OptionType.PUT;
    const altCombination = incomeStrategies.altOptionsPair.get(type);
    setCombination(altCombination);
    //setIncomeCombinations(incomeStrategies.combinations);
  };

  if (!combination) {
    return <>{generateActiveIncomeCombText()}</>;
  }

  let singleTrade = new SingleTrade(combination);

  const postTradeTicketToHostIfPossible = () => {
    if (!customization?.isEmbeddingPlatform || customization.tradeTicketTriggerPoint !== 'strategy') {
      return;
    }
    let tradeTicket = new TradeTicket();
    tradeTicket.refreshTrade(combination, undefined);
    const options = (tradeTicket.optionLegs || []).map((p) => {
      return {
        ask: p.ask,
        bid: p.bid,
        price: p.price,
        quantity: p.quantity,
        expiry: DateTimeHelper.resolveExpiry(p.expiry).toISOString(),
        strike: p.strike,
        actionName: p.getActionName(),
        isToClose: p.isToClose,
        type: p.type,
        showShortCodeInTradeTicket: p.showShortCodeInTradeTicket,
      };
    });
    const securities = (tradeTicket.securityLegs || []).map((p) => {
      return {
        ask: p.ask,
        bid: p.bid,
        price: p.price,
        quantity: p.quantity,
        actionName: p.getActionName(),
        isToClose: p.isToClose,
        type: p.type,
        showShortCodeInTradeTicket: p.showShortCodeInTradeTicket,
      };
    });
    /**
     * !Important
     * Condition for Income covered call
     * If canadian / US symbol loaded in covered call
     * strategy name should be sent as "Call" as from OP only call leg is sent in post event.
     * Reason is in post event Call option is only sent as
     * in OP asuumption is shares are owned.
     */
    let strategyName = tradeTicket.strategyName;
    if (
      !customization.allowStockPlusOptionforCASymbol &&
      tradeTicket.combination.isIncome &&
      tradeTicket.strategyName === 'Covered Call'
    ) {
      strategyName = 'Call';
    }

    const price = tradeTicket.combination.costWithoutOwned();

    const tradeTicketObj = {
      symbol: tradeTicket.combination.symbol,
      optionLegs: options,
      securityLegs: securities,
      strategyName: strategyName,
      price: price,
      priceSign: Math.sign(price),
    };
    const message = { event: EmbedderEventType.TradeTicketExecute.toString(), data: tradeTicketObj };
    window.parent.postMessage(JSON.stringify(message), '*');
  };
  const logActivity = (controlType: string, controlName: string, value?: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };
  const onTrade = () => {
    postTradeTicketToHostIfPossible();
    if (!customization?.showTradeTicket) {
      return;
    }
    setTradeTicket({ combination: combination });
    setSubView('tradeTicket');
  };

  const onModify = () => {
    setSimulatorOperation(tradeSimulatorOperationType.Modify);
    logActivity('BUTTON', 'incomeStrategiesEditCombinationButton');
  };

  const showCurrencySymbol = (value: number | null) => {
    return NumberFormatHelper.toCurrency(value);
  };

  // const renderCurrencySymbol = () => {
  //   let currencySymbol = '';
  //   if (isNordic()) {
  //     if (expandedQuote) {
  //       currencySymbol = expandedQuote.currency;
  //     } else {
  //       currencySymbol = '';
  //     }
  //   } else {
  //     currencySymbol = '';
  //   }
  //   return currencySymbol ? currencySymbol : '';
  // };

  const generateSentence = (
    pos: {
      expiry: Date | null | undefined;
      strikePrice: number | null;
      legType: string;
      price: () => number;
    },
    premium: number | null,
    ar: string,
  ) => {
    // TODO : Add below object for localization at "app.php.how.incomeCombinationModel.generateSentence"
    // let obj = {
    //   sellingThe: 'Selling the',
    //   covered: 'covered',
    //   onYour: 'on your',
    //   positionWill: 'position would generate',
    //   ofIncome: 'of income',
    //   providingA: ' providing a static yield of',
    //   in: 'in',
    //   days: 'days',
    //   annualized: 'annualized',
    //   comparedTo: ', compared to the',
    //   dividend: 'dividend yield.',
    //   youWill: ' You will keep',
    //   ofIncomePerContract: 'of income per contract if',
    //   closesBelow: 'closes below',
    //   on: 'on',
    //   thereIs: 'There is',
    //   probabilityOfHappen: 'probability that this will happen.',
    // };
    let sentence = '';
    sentence += `Selling the <b>${DateTimeHelper.format(pos.expiry)} ${showCurrencySymbol(
      pos.strikePrice,
    )}</b> covered ${pos.legType.toLowerCase()} on your <b>${formatting.symbolDotExchangeToSymbol(
      selectedSymbol || '',
    )}</b>
    position would generate <b>${showCurrencySymbol(premium)}</b> of income, 
     providing a static yield of <b>${NumberFormatHelper.toPercentage(
       combination.rawReturn(),
     )}</b> in <b>${combination.daysToExpiry()}</b> days (<b>${NumberFormatHelper.toPercentage(
      combination.annualizedReturn(),
    )}</b> annualized)`;
    if (howData.quote.yield === 0) {
      sentence += `.`;
    } else {
      sentence += `, compared to the <b>${NumberFormatHelper.toPercentage(howData.quote.yield)}</b> dividend yield.`;
    }
    sentence += '<br/>';
    sentence += `You will keep <b>${NumberFormatHelper.toCurrency(
      pos.price() * 100,
    )}</b> of income per contract if <b>${formatting.symbolDotExchangeToSymbol(selectedSymbol || '')}</b> 
    closes below <b>${showCurrencySymbol(pos.strikePrice)}</b> on <b>${DateTimeHelper.format(
      pos.expiry,
    )}</b>. There is a 
    <b>${NumberFormatHelper.toPercentage(combination.worthlessProb() * 100)}</b> probability that this will happen.`;
    if (
      configuration &&
      configuration.divideIndexQuantityByHundred &&
      combination.isIndex &&
      incomeCombinationSelectedId === 0
    ) {
      sentence += `You need to buy another <b>99 shares</b> to write this covered call.`;
    }
    return sentence;
  };

  const generatePutSentence = (
    pos: { expiry: Date | null | undefined; strikePrice: number | null; legType: string; quantity: number },
    premium: number | null,
    ar: string,
  ) => {
    var sentence = '';
    sentence = ` Consider selling the <b>${DateTimeHelper.format(pos.expiry)} ${NumberFormatHelper.toCurrency(
      pos.strikePrice,
    )}</b> ${pos.legType.toLowerCase()} option on <b>${formatting.symbolDotExchangeToSymbol(selectedSymbol || '')}</b>
  to potentially acquire the stock with a <b>${NumberFormatHelper.toPercentage(combination.rawReturn())}</b> discount.
  You will collect <b>${NumberFormatHelper.toCurrency(premium)}</b> in premium per contract and
  have an obligation to
  purchase <b>${
    Math.abs(pos.quantity) * 100
  }</b> shares of the stock at a net price of <b>${combination.breakevenDisplayed()}</b>
  if the stock closes below <b>${showCurrencySymbol(
    pos.strikePrice,
  )}</b> and the ${pos.legType.toLowerCase()} option is exercised by the buyer.`;
    return sentence;
  };

  const generateMerrillSentence = (
    pos: { expiry: Date | null | undefined; strikePrice: number | null; legType: string; quantity: number },
    premium: number | null,
  ) => {
    var sentence = '';
    sentence +=
      'Selling the <b>' +
      DateTimeHelper.format(pos.expiry) +
      ' ' +
      NumberFormatHelper.toCurrency(pos.strikePrice) +
      ' ' +
      pos.legType +
      (Math.abs(pos.quantity) > 1 ? 'S' : '') +
      '</b>';
    sentence +=
      ' will generate <b>' +
      NumberFormatHelper.toCurrency(premium) +
      '</b> in premium' +
      (Math.abs(pos.quantity) > 1 ? '. ' : ' for each contract sold. ');
    sentence +=
      'Based on historical volatility have ' +
      formatting.aOrAn(parseFloat((combination.worthlessProb() * 100).toString())) +
      ' ';
    sentence +=
      '<b>' + NumberFormatHelper.toPercentage(combination.worthlessProb() * 100) + '</b> chance of expiring worthless.';
    return sentence;
  };

  const incomeReturnSentence = () => {
    let ar = NumberFormatHelper.toPercentage(combination.annualizedReturn());
    let sentence = '';
    let positions = combination.extractedPositions;
    let pos: any = null;
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].legType !== LegType.SECURITY && positions[i].quantity < 0) {
        pos = positions[i];
        break;
      }
    }
    if (pos) {
      const premium = Math.abs(pos.price() * pos.quantity * pos.multiplier);
      if (customization?.isMerrill) {
        sentence = generateMerrillSentence(pos, premium);
      } else {
        sentence =
          incomeCombinationSelectedId === 0
            ? generateSentence(pos, premium, ar)
            : generatePutSentence(pos, premium, ar);
      }
    }
    return sentence;
  };

  const renderSimulatorOperations = () => {
    let operations = [
      {
        name: tradeSimulatorOperationType.OptionsGrid,
        title: 'app.php.how.incomeStrategies.buttons.optionsGrid',
      },
      {
        name: tradeSimulatorOperationType.IncomeProfitAndLossTarget,
        title: 'app.php.how.singleTrade.buttons.showRisks',
      },

      {
        name: tradeSimulatorOperationType.ExplainStrategyandGreeks,
        title: 'app.php.how.singleTrade.buttons.explainStrategy',
      },
    ];
    if (icomeSettingsPermission) {
      operations.push({
        name: tradeSimulatorOperationType.IncomeSettings,
        title: 'app.php.how.incomeStrategies.buttons.defaultSettings',
      });
    }
    return <SimulatorOperationsWidget data={operations} />;
  };

  const resetCombination = () => {
    if (!shares || !costBasis || !configuration) {
      return;
    }
    const config = configuration.applicationConfiguration.coveredCall;
    const priorities = configuration.coveredCallOptimalPriorityMapping;
    let timeframe = config.call.timeFrame as 'Short' | 'Medium' | 'Long';
    let aggressive = config.call.aggressiveness as 'Conservative' | 'Optimal' | 'Aggressive';
    const isCall = incomeCombinationSelectedId === 0;
    const defaultShares = isCall && shares ? shares : 100;
    let builder = new IncomeBuilder(howData as IBuilderData)
      .withShares(defaultShares)
      .withCostBasisPerUnit(costBasis)
      .withTimeframe(timeframe)
      .withAggressiveness(aggressive)
      .withPriorities(priorities)
      .withPriceCalculationMethod(combination.priceCalculationMethod);

    const strategy = isCall
      ? builder.withOptimals(howData.callOptimals).buildCall()
      : builder.withOptimals(howData.putOptimals).buildPut();
    if (!strategy) {
      return;
    }
    updateIncomeStrategies(strategy);
  };

  const onEditCombinationCallback = (action: string, legs?: ILeg[]) => {
    if (!combination) {
      return;
    }
    const newCombination = IncomeCombination.fromSelf(combination);

    if (action === ActionTypes.Change_Price_Bid_Ask) {
      changePriceBidAsk(newCombination);
      return;
    }
    if (action === ActionTypes.Change_Price_Mid) {
      changePriceMid(newCombination);
      return;
    }
    if (action === ActionTypes.Reset) {
      resetCombination();
      return;
    }
    const priority = newCombination.priority;
    if (legs) {
      const newCombination = new IncomeBuilder(howData as IBuilderData)
        .withLegs(legs)
        .withPriceCalculationMethod(combination.priceCalculationMethod)
        .build(priority as number);
      updateIncomeStrategies(newCombination);
    }
  };

  const changePriceBidAsk = (combination: IncomeCombination) => {
    combination.priceCalculationMethod = PriceCalculationMethod.BID_ASK;
    combination.positions.map((p) => {
      Position.fromSelf(p);
      p.selectedPrice = PriceCalculationMethod.BID_ASK;
    });
    updateIncomeStrategies(combination);
  };

  const changePriceMid = (combination: IncomeCombination) => {
    combination.priceCalculationMethod = PriceCalculationMethod.MID;
    combination.positions.map((p) => {
      Position.fromSelf(p);
      p.selectedPrice = PriceCalculationMethod.MID;
    });
    updateIncomeStrategies(combination);
  };

  const updateIncomeStrategies = (combination: IncomeCombination) => {
    combination.profileSentiments();
    setStrategyModify(combination);
    setCombination(combination);
    setPriority(combination.priority);
  };

  const showSingleShareIfPossible = () => {
    if (
      simulatorOperation === tradeSimulatorOperationType.IncomeProfitAndLossTarget ||
      simulatorOperation === tradeSimulatorOperationType.ExplainStrategyandGreeks
    ) {
      return <SingleShareWidget comb={combination} data={singleTrade} />;
    }
    return;
  };

  const shouldShowSingleShare = () => {
    const singleShareContent = showSingleShareIfPossible();
    return Boolean(singleShareContent);
  };

  const tradeShareView = () => {
    return (
      <Grid container justifyContent="center" columnSpacing={2}>
        <Grid item container xs={12}>
          <OptionsPlayStrategyWidgetGrid item xs={12} whiteSpace="nowrap">
            <StrategyDataWidget data={combination} />
          </OptionsPlayStrategyWidgetGrid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          pt={1}
          columnSpacing={1}>
          <Grid item xs={6}>
            <GuideItem selector=".tradeButton_helpPinPlaceholder" />
            <Button variant="contained" fullWidth color="primary" onClick={() => onTrade()} name="trade">
              <Typography sx={{ color: theme.palette.selectAndTextField.dark }} variant="body1">
                {t(customization.tradeLabel)}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <GuideItem selector=".modifyButton_helpPinPlaceholder" />
            <Button variant={'outlined'} name="modify" onClick={() => onModify()} fullWidth>
              <Typography variant="body1">{t('app.php.common.buttons.modify')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container id="income_screenshot">
      <Grid item xs={12}>
        <CompanyNameWidget creationDate={creationDate} data={singleTrade} sharedBy={sharedBy} canRenderClose={false} />
      </Grid>
      <Grid
        item
        xs={12}
        pt={0.5}
        container
        style={{ borderRadius: theme.shape.borderRadius }}
        sx={{ backgroundColor: theme.palette.info.dark }}
        justifyContent="space-between"
        alignItems={'center'}>
        <Grid item xs={shouldShowSingleShare() ? 11 : 12}>
          <Typography variant="subtitle1" id="income-symbol-text" sx={{ fontWeight: 'bold', p: 1 }}>
            {combination.fullNameWithoutSymbol()}
          </Typography>
        </Grid>
        {shouldShowSingleShare() && (
          <Grid item xs={1}>
            {showSingleShareIfPossible()}
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <RiskAnalysisWidget data={combination} />
        </Grid>
        <Grid item xs={6}>
          {tradeShareView()}
        </Grid>
      </Grid>
      <Grid item xs={12} py={0.5} px={0.5}>
        <GuideItem selector=".plainEnglish_helpPinPlaceholder" />
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: incomeReturnSentence(),
          }}></Typography>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          {renderSimulatorOperations()}
        </Grid>
        <Grid item xs={12}>
          <SimulatorWidget combination={combination} onEditCombinationCallback={onEditCombinationCallback} />
        </Grid>
      </Grid>
    </Grid>
  );
};
