import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { Grid, Typography, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import { scanFilterState1, selectedSymbolState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  MenuProps,
  OPBoldTypograpghy,
  OPSelectDropDownHeader,
  OPSelectField,
  StyledDropdownItemWithBorder,
} from '../../styled';
import { GuideItem } from '../../side-menu';
import LocalizationContext from '../../react-i18next/localization-context';

export interface Props {
  filter: IFilterGroup;
  onClick: (filter: IFilterGroup) => void;
}

const dropdown = {
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const ScansFilterWidget2: React.FC = () => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const [scanFilter1, setScanFilter1] = useRecoilState(scanFilterState1);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  if (!scanFilter1) {
    return <></>;
  }

  const onScanChange = (scanValue: any) => {
    const clone = cloneDeep(scanFilter1);
    for (const f of clone.filters) {
      f.selected = scanValue.includes(f.value as string);
    }
    setScanFilter1(clone);
    logActivity(scanValue);
  };

  const logActivity = (scanValue: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      'reasonFilter',
      'reasonFilter',
      scanValue,
      'click',
      `${selectedSymbol}`,
    );
  };

  const getValue = (): string[] => {
    const sector = scanFilter1.filters.map((item) => {
      if (item.selected) {
        return item.value as string;
      }
    });
    return sector;
  };

  const onReset = () => {
    const clone = cloneDeep(scanFilter1);
    for (const x of clone.filters) {
      x.selected = true;
    }
    setScanFilter1(clone);
  };

  return (
    <Grid item xs={12} container justifyContent="space-between">
      <GuideItem selector=".reasonsDropdown_helpPinPlaceholder" />
      <Grid item xs={12}>
        <OPBoldTypograpghy>{t(scanFilter1.translationKey)}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item p={0.5} xs={11} sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <OPSelectField
            multiple
            variant="outlined"
            value={getValue()}
            renderValue={() => (
              <Typography ml={0.5} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                {getValue().join(',')}
              </Typography>
            )}
            sx={{
              ...dropdown,
              width: '100%',
              height: 35,
              '& .MuiSvgIcon-root': {
                color: theme.palette.selectAndTextField.main,
              },
            }}
            MenuProps={MenuProps}
            onChange={(event) => onScanChange(event.target.value as string)}>
            <OPSelectDropDownHeader type="scans" />
            {scanFilter1.filters.map((scan) => {
              return (
                <StyledDropdownItemWithBorder
                  sx={{ whiteSpace: 'normal' }}
                  id="reasonFilter"
                  className="reasonFilter"
                  key={scan.value.toString()}
                  value={scan.value}
                  data-name="reasonFilter"
                  data-value={`selected ${scan.value} from FilterIdeas`}
                  color="default">
                  {scan.value}
                </StyledDropdownItemWithBorder>
              );
            })}
          </OPSelectField>
        </Grid>
        <Grid item xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item onClick={onReset}>
            <ClearSharpIcon fontSize="small" sx={{ '&:hover': { cursor: 'pointer' } }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
