import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, Card, Grid, IconButton, Menu, Typography, useTheme } from '@mui/material';
import { IdeaType, types } from '@op/shared/src/models/enums/enums';
import { accountState, reportSearchState } from '@op/shared/src/states';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OPGrid, OPLightTypograpghy, StyledDropdownItemWithBorder } from '../styled';
import { AvatarWithText } from '../watchlists/watchlist-common-layouts';

interface props {
  count: number;
  ideaType: IdeaType;
}

export const IdeaDropDownWidget: React.FC<props> = ({ count, ideaType }: props) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const setTextSearchFieldInput = useSetRecoilState(reportSearchState);
  const [selected, setSelected] = useState<IdeaType>(ideaType);
  const navigate = useNavigate();
  const [showDropDown, setshowDropDown] = React.useState<null | HTMLElement>(null);
  const account = useRecoilValue(accountState);

  const resetSearchValues = () => {
    setTextSearchFieldInput('');
  };

  const onClose = (event: { currentTarget: any }) => {
    setshowDropDown(showDropDown ? null : event.currentTarget);
  };

  const dropdownArrowIcon = () => {
    if (!account || !account.canWorkWithReports) {
      return;
    }
    return (
      <Grid item xs={0.5} style={{ height: '100%' }}>
        <ArrowDropDownIcon
          onClick={onClose}
          style={{
            color: theme.palette.selectAndTextField.main,
            transform: showDropDown ? 'rotate(-180deg)' : 'rotate(0deg)',
            margin: 5,
          }}
        />
      </Grid>
    );
  };

  const renderFirstRow = () => {
    const ideaType = types.find((t) => t.name === selected.name);
    if (!ideaType) {
      return;
    }
    let image = 'TechnicalIdeasIcon';
    switch (ideaType.name) {
      case 'technicalIdea':
        image = 'TechnicalIdeasIcon';
        break;
      case 'coveredCall':
        image = 'CoveredCallIcon';
        break;
      case 'creditSpread':
        image = 'CreditSpreadIcon';
        break;
      case 'shortPut':
        image = 'CoveredCallIcon';
        break;
      default:
        image = 'TechnicalIdeasIcon';
        break;
    }

    return (
      <OPGrid
        container
        alignItems={'center'}
        sx={{
          width: '100%',
          height: 35,
          borderColor: showDropDown ? theme.palette.primary.main : theme.palette.secondary.dark,
        }}>
        <Grid item xs={1.6}>
          <div
            style={{
              backgroundColor: theme.palette.info.dark,
              height: 33, // Height maintaine as 33 bcz total height - 2 * border width
              width: 35,
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              cursor: 'pointer',
            }}>
            <IconComponent
              name="tradeHover"
              stroke={theme.palette.info.light}
              size={28}
              style={{ padding: '6px 0 0 4px' }}
            />
          </div>
        </Grid>
        <Grid item xs={1.4} container style={{ height: '100%', alignItems: 'center' }}>
          <IconComponent name={image} stroke={theme.palette.globalSelect.light} size={25} />
        </Grid>
        <Grid item xs={!account || !account.canWorkWithReports ? 6.7 : 6}>
          <Typography style={{ width: '100%', color: theme.palette.globalSelect.main }}>
            <OPLightTypograpghy>{t(`app.php.what.tradeIdeas.ideas.${ideaType.name}`)}</OPLightTypograpghy>
          </Typography>
        </Grid>
        <Grid item xs={!account || !account.canWorkWithReports ? 2 : 1.7}>
          <AvatarWithText backgroundColor={theme.palette.globalSelect.dark} text={count} />
        </Grid>
        {dropdownArrowIcon()}
      </OPGrid>
    );
  };

  const renderGridItem = (ideaType: IdeaType) => {
    let image = 'TechnicalIdeasIcon';
    switch (ideaType.name) {
      case 'technicalIdea':
        image = 'TechnicalIdeasIcon';
        break;
      case 'coveredCall':
        image = 'CoveredCallIcon';
        break;
      case 'creditSpread':
        image = 'CreditSpreadIcon';
        break;
      case 'shortPut':
        image = 'CoveredCallIcon';
        break;
      default:
        image = 'TechnicalIdeasIcon';
        break;
    }
    return (
      <Grid
        container
        item
        justifyContent={'space-between'}
        alignItems="center"
        xs={12}
        onClick={() => {
          resetSearchValues();
          setSelected(ideaType);
          navigate(ideaType.to);
        }}>
        <Grid item xs={3}>
          <IconButton size="medium">
            <IconComponent
              name={image}
              stroke={theme.palette.mode === 'dark' ? theme.palette.primary.dark : '#999999'}
            />
          </IconButton>
        </Grid>
        <Grid item xs={7}>
          <Typography className={'global-label-value'}>
            {t(`app.php.what.tradeIdeas.ideas.${ideaType.name}`)}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          {/* <Typography className={'global-count-value'}>{'N/A'}</Typography> */}
        </Grid>
      </Grid>
    );
  };

  const renderDropDown = () => {
    if (!showDropDown) {
      return null;
    }
    return (
      <Grid item xs={12}>
        <Accordion expanded={!showDropDown}>
          <Card
            sx={{
              width: '100%',
              mt: 1,
              maxWidth: 275,
              minWidth: 275,
            }}>
            {types.map((t) => {
              if (t.name === selected.name) {
                return null;
              }
              return (
                <StyledDropdownItemWithBorder color="default" key={t.name} value={t.name}>
                  {renderGridItem(t)}
                </StyledDropdownItemWithBorder>
              );
            })}
          </Card>
        </Accordion>
      </Grid>
    );
  };

  return (
    <Grid container xs={12}>
      {renderFirstRow()}
      <Menu
        anchorEl={showDropDown}
        open={Boolean(showDropDown)}
        onClose={onClose}
        PaperProps={{
          sx: { '.MuiMenu-paper': { padding: 0 }, backgroundColor: 'transparent', overflow: 'visible' },
        }}
        MenuListProps={{ sx: { px: 0 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        elevation={0}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {renderDropDown()}
      </Menu>
    </Grid>
  );
};
