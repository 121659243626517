import { Container, Grid, Link, Paper, useTheme } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import LocalizationContext from '../react-i18next/localization-context';
import { LoginSignupContainer } from './login-widget';
import { RegistrationWidget } from './registration-widget';
import { useOPNavigate } from '../states/use-navigate-search';

export const RegistrationPageWidget: React.FC = () => {
  const theme = useTheme();
  const navigate = useOPNavigate();
  const { t } = useContext(LocalizationContext);

  useEffect(() => {
    document.title = 'Sign Up | OptionsPlay';
  }, []);
  const onSignIn = async (): Promise<void> => {
    navigate('/login');
  };
  const onHelp = (): void => {
    window.open('https://www.optionsplay.com/media/about.png#contactUs');
  };
  const onForgot = async (): Promise<void> => {
    navigate('/forgot');
  };

  return (
    <LoginSignupContainer direction="column" alignItems="center" container justifyContent="center">
      <Container
        sx={{
          py: 5,
          px: 7,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
        maxWidth="md">
        <Grid xs={10} direction="column" container justifyContent="center" sx={{ minHeight: '88vh' }}>
          <Grid item container>
            <Paper
              sx={{
                mx: 7,
                p: 2,
              }}>
              <RegistrationWidget />
            </Paper>
            <Grid container>
              <Grid xs={4} sx={{ textAlign: 'left', pt: 1 }}>
                <Link
                  variant="body1"
                  onClick={onSignIn}
                  sx={{
                    ml: 7,
                    color: theme.palette.background.default,
                    background: 'none',
                    cursor: 'pointer',
                  }}>
                  {t('app.php.password.forgot.buttons.backToSignIn')}
                </Link>
              </Grid>
              <Grid xs={4} sx={{ textAlign: 'center', pt: 1 }}>
                <Link
                  variant="body1"
                  onClick={onHelp}
                  sx={{
                    ml: 7,
                    color: theme.palette.background.default,
                    background: 'none',
                    cursor: 'pointer',
                  }}>
                  {t('app.php.signUp.labels.needHelp')}
                </Link>
              </Grid>
              <Grid xs={4} sx={{ textAlign: 'right', pt: 1 }}>
                <Link
                  variant="body1"
                  onClick={onForgot}
                  sx={{
                    mr: 7,
                    background: 'none',
                    color: theme.palette.background.default,
                    cursor: 'pointer',
                  }}>
                  {t('app.php.signUp.labels.forgotYourPassword')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </LoginSignupContainer>
  );
};
