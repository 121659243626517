import { SaveFilter } from '..';
import { ICollection } from '..';

export class SaveFilters {
  opSelector: SaveFilter[] = [];

  public constructor(opSelector: SaveFilter[]) {
    this.opSelector = [...opSelector];
  }
}
