import { Grid, Menu, Tooltip, useTheme } from '@mui/material';
import { IdeaMenuProps, OPBoldTypograpghy, OPIconButton, OrangeButton } from '../styled';
import React, { ReactNode } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { DownloadReportExcelWidget } from './download-report-excel-widget';
import { IconComponent } from '../common/icons';
import LocalizationContext from '../react-i18next/localization-context';

interface Props {
  children: ReactNode;
  ideaType: string;
  createdOn?: string;
  title: string;
  data: ReactNode;
  refreshReport?: () => void;
}

export const ThirdRowContainer: React.FC<Props> = ({
  children,
  ideaType,
  createdOn,
  title,
  data,
  refreshReport,
}: Props) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const [showInformationToolTip, setShowInformationToolTip] = React.useState<null | HTMLElement>(null);

  const handleInformationClose = () => {
    setShowInformationToolTip(null);
  };

  const handleInformationToggle = (event: React.MouseEvent<HTMLElement>) => {
    setShowInformationToolTip(showInformationToolTip ? null : event.currentTarget);
  };

  const renderInformation = () => {
    return (
      <Menu
        anchorEl={showInformationToolTip}
        open={Boolean(showInformationToolTip)}
        onClose={handleInformationClose}
        PaperProps={IdeaMenuProps(theme)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={12} container>
            <Grid item xs={11}>
              <OPBoldTypograpghy style={{ fontSize: '18px' }}>{title}</OPBoldTypograpghy>
            </Grid>
            <Grid item xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
              <ClearSharpIcon
                fontSize="small"
                sx={{ color: theme.palette.primary.main, '&:hover': { cursor: 'pointer' } }}
                onClick={handleInformationClose}
              />
            </Grid>
          </Grid>
          <Grid>{data}</Grid>
        </Grid>
      </Menu>
    );
  };

  return (
    <Grid item xs={12} container justifyContent={'space-between'}>
      <>{children}</>
      <Grid item xs={'auto'}>
        <Tooltip title={`${t('app.php.what.tradeIdeas.ideas.information')}`} placement="top" arrow>
          <OrangeButton
            onClick={handleInformationToggle}
            disableRipple={Boolean(showInformationToolTip)}
            isSelected={showInformationToolTip}>
            <IconComponent name="infoIcon" stroke={theme.palette.info.light} />
          </OrangeButton>
        </Tooltip>
        {renderInformation()}
      </Grid>
      <Grid item xs={'auto'}>
        <DownloadReportExcelWidget ideaType={ideaType} createdOn={createdOn} />
      </Grid>
      <Grid item xs={1.6}>
        <OPIconButton id={`js-${ideaType}-refresh-icon`} onClick={refreshReport}>
          <CachedIcon sx={{ color: theme.palette.info.light }} fontSize={'medium'} />
        </OPIconButton>
      </Grid>
    </Grid>
  );
};
