import { cloneDeep } from 'lodash';
import { atom, selector } from 'recoil';
import { sortsWatchlistState } from '..';
import { Quote, WatchList, WatchLists } from '../../models';
import { WatchListsOperationTypes, WatchListsTypes } from '../../models/enums/enums';
import NumberFormatHelper from '../../models/how/number-format-helper';
import { IFilterGroup } from '../../models/what/i-filter-group';

/* =================== FILTERS ================= */
const linkedPortfoliosFilter = {
  name: 'linkedPortfolios',
  title: 'Portfolio',
  filters: [
    {
      name: 'linkedPortfolios',
      value: 0,
      title: '',
      selected: false,
    },
  ],
} as IFilterGroup;

const filterByLinkedPortfolios = (groups: IFilterGroup[], data: WatchList) => {
  const group = groups.find((g) => g.name === 'linkedPortfolios');
  if (!group) {
    return data;
  }
  let filteredQuotes: Quote[] = [];
  for (const f of group.filters) {
    const items = f.selected ? data.quotes.filter((t) => t.portfolios.length > 0) : data.quotes;
    filteredQuotes = filteredQuotes.concat(items);
  }
  const clone = cloneDeep(data);
  clone.quotes = filteredQuotes;
  return clone;
};

const linkedIdeasFilter = {
  name: 'linkedIdeas',
  title: 'Trade Idea',
  filters: [
    {
      name: 'linkedIdeas',
      value: 0,
      title: '',
      selected: false,
    },
  ],
} as IFilterGroup;

const filterByLinkedIdeas = (groups: IFilterGroup[], data: WatchList) => {
  const group = groups.find((g) => g.name === 'linkedIdeas');
  if (!group) {
    return data;
  }
  let filteredQuotes: Quote[] = [];
  for (const f of group.filters) {
    const items = f.selected ? data.quotes.filter((t) => t.isTradeIdea) : data.quotes;
    filteredQuotes = filteredQuotes.concat(items);
  }
  const clone = cloneDeep(data);
  clone.quotes = filteredQuotes;
  return clone;
};

export const watchListFilters = [
  linkedPortfoliosFilter,
  linkedIdeasFilter,
] as IFilterGroup[];

/* END of Filters */

export const watchListsCachedState = atom<WatchLists | undefined>({
  key: 'watchListsCachedStateKey',
  default: undefined,
});

export const filtersWatchListState = atom({
  key: 'filtersWatchListStateKey',
  default: watchListFilters,
});

export const selectedWatchListIdState = atom<number | undefined>({
  key: 'selectedWatchListIdStateKey',
  default: undefined,
});

export const deletedWatchlistItemState = atom<string[]>({
  key: 'deletedWatchListItemsStateKey',
  default: [],
});

export const watchListCrudOperationState = atom({
  key: 'watchListCrudOperationStateKey',
  default: WatchListsOperationTypes.NONE,
});

export const watchListNewsFeedState = atom({
  key: 'watchListNewsFeedStateKey',
  default: false,
});

export const watchListSearchTextState = atom({
  key: 'watchListSearchTextStateKey',
  default: '',
});

export const originalWatchListState = atom({
  key: 'originalWatchListStateKey',
  default: new WatchList(),
});

export const watchListsState = selector<WatchLists | undefined>({
  key: 'watchListsStateKey',
  get: async ({ get }) => {
    const watchLists = get(watchListsCachedState);
    return watchLists;
  },
  set: ({ set }, newWatchLists) => {
    if (!newWatchLists) {
      set(watchListsCachedState, WatchLists.fromEmpty());
    } else {
      (newWatchLists as WatchLists).sort();
      set(watchListsCachedState, newWatchLists);
    }
  },
});

export const selectedWatchListState = selector<WatchList | undefined>({
  key: 'selectedWatchListStateKey',
  get: ({ get }) => {
    const selectedWatchListId = get(selectedWatchListIdState);
    const watchLists = get(watchListsState);
    const filters = get(filtersWatchListState);
    const sorts = get(sortsWatchlistState);
    if (!watchLists) {
      return undefined;
    }
    if (watchLists.data.length == 0) {
      return new WatchList();
    }
    /*
      1st Pref: If no selected watchList and has bookmarked watchList, return it
    */
    const bookmarkedWatchList = watchLists.data.find((w) => w.isBookmarked === true && w.type === WatchListsTypes.NONE);
    if (!selectedWatchListId && bookmarkedWatchList) {
      const newSelectedWatchList = WatchList.fromSelf(bookmarkedWatchList);
      let filteredSelectedWatchList = filterByLinkedPortfolios(filters, newSelectedWatchList);
      filteredSelectedWatchList = filterByLinkedIdeas(filters, filteredSelectedWatchList);
      const sortedSelectedWatchList = WatchList.fromSelf(filteredSelectedWatchList).sort(sorts);
      return sortedSelectedWatchList;
    }
    /*
      2nd Pref: If no selected watchList and has global watchLists, return default one
    */
    const hasGlobalWatchList = watchLists.data.some((w) => w.type !== WatchListsTypes.NONE);
    if (!selectedWatchListId && hasGlobalWatchList) {
      const defaultGlobalWatchList = WatchList.getDefaultWatchList(watchLists.data);
      const newSelectedWatchList = WatchList.fromSelf(defaultGlobalWatchList);
      let filteredSelectedWatchList = filterByLinkedPortfolios(filters, newSelectedWatchList);
      filteredSelectedWatchList = filterByLinkedIdeas(filters, filteredSelectedWatchList);
      const sortedSelectedWatchList = WatchList.fromSelf(filteredSelectedWatchList).sort(sorts);
      return sortedSelectedWatchList;
    }
    const selectedWatchList = watchLists.data.find((w) => w.id === selectedWatchListId);
    /*
      3rd Pref: Neither selected watchList nor bookmarked & global watchLists, return 0th watchList from list
    */
    if (!selectedWatchList) {
      return watchLists.data[0];
    }
    /*
      4th Pref: If selected watchList, return it
    */
    const newSelectedWatchList = WatchList.fromSelf(selectedWatchList);
    let filteredSelectedWatchList = filterByLinkedPortfolios(filters, newSelectedWatchList);
    filteredSelectedWatchList = filterByLinkedIdeas(filters, filteredSelectedWatchList);
    const sortedSelectedWatchList = WatchList.fromSelf(filteredSelectedWatchList).sort(sorts);
    return sortedSelectedWatchList;
  },
  set: ({ set, get }, newWatchList) => {
    if (!(newWatchList instanceof WatchList)) {
      return;
    }
    if (!newWatchList) {
      return;
    }
    const watchLists = get(watchListsCachedState);
    if (!watchLists) {
      return;
    }
    let clonedWatchLists = WatchLists.fromSelf(watchLists);
    let watchListToUpdateOrAdd = clonedWatchLists.data.find((wl) => wl.id === newWatchList.id);
    if (!watchListToUpdateOrAdd) {
      clonedWatchLists.data.push(newWatchList);
      set(watchListsCachedState, clonedWatchLists);
      return;
    }
    clonedWatchLists.data = clonedWatchLists.data.filter((wl) => wl.id !== newWatchList.id);
    clonedWatchLists.data.push(newWatchList);
    set(selectedWatchListIdState, newWatchList.id);
    set(watchListsCachedState, clonedWatchLists);
  },
});

export const watchListPortfolioPositionsCountState = selector<number>({
  key: 'tradePortfolioPositionsCountStateKey',
  get: ({ get }) => {
    const watchLists = get(selectedWatchListState);
    if (!watchLists) {
      return 0;
    }
    const items = watchLists.quotes;
    const count = items
      .filter((e) => e.portfolios && e.portfolios.length > 0)
      .reduce((a, i) => a + i.portfolios.length, 0);
    return count;
  },
});

export const watchListIdeaAvailableCountState = selector<number>({
  key: 'tradeWatchlistQuotesCountStateKey',
  get: ({ get }) => {
    const watchLists = get(selectedWatchListState);
    if (!watchLists) {
      return 0;
    }
    const items = watchLists.quotes;
    const count = items
      .filter((e) => e.isTradeIdea)
      .reduce((a, i) => a + 1, 0);
    return count;
  },
});

export const processUrl = (watchlists: WatchLists | undefined, id: string | null, symbol: string | null) => {
  if (!watchlists || !watchlists.data || watchlists.data.length === 0) {
    return undefined;
  }
  const watchlistId = NumberFormatHelper.parseNumberOrReturnNull(id);
  if (!watchlistId) {
    return undefined;
  }
  const watchlist = watchlists.data.find((w) => w.id === watchlistId);
  if (!watchlist) {
    return undefined;
  }
  const searchQuote = watchlist.quotes.find((q) => q.symbol === symbol);
  return { id: watchlist.id, symbol: searchQuote ? searchQuote.symbol : undefined };
};
