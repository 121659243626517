import React, { Suspense, useEffect, useState } from 'react';
import {
  filtersWatchListState,
  isDataLoadingState,
  processUrl,
  selectedSymbolState,
  selectedWatchListIdState,
  strategyShareDataState,
  subViewState,
  tradeSimulatorOperationTypeState,
  viewState,
  watchListSearchTextState,
  watchListsState,
} from '@op/shared/src/states';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { WatchListWidget } from './watch-list-widget';
import { WatchLists } from '@op/shared/src/models';
import { WatchListsManager } from './watch-lists-manager';
import { WatchlistService } from '@op/shared/src/services';
import { WhatLoaderWidget } from '../ideas/what-loader-widget';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';
import { useSearchParams } from 'react-router-dom';
import { EmptyRowWidget } from '../common';

export const WatchListsWidgetContainer: React.FC = () => {
  const [watchLists, setWatchLists] = useRecoilState(watchListsState);
  const resetWatchListSearchText = useResetRecoilState(watchListSearchTextState);
  const resetWatchListGlobalFilters = useResetRecoilState(filtersWatchListState);
  const setSelectedWatchListId = useSetRecoilState(selectedWatchListIdState);
  const fetchAndSetData = useFetchAndSetData();
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const [view, setView] = useRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const [isDataLoading, setIsDataLoading] = useRecoilState(isDataLoadingState);
  const resetStrategyShareData = useResetRecoilState(strategyShareDataState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [searchParams] = useSearchParams();
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  useEffect(() => {
    if (!watchLists) {
      (async () => {
        const watchLists = await fetchWatchLists();
        setWatchLists(watchLists);
        setDataLoading(false);
      })();
    }
    const searchWatchListId = searchParams.get('id');
    const searchSymbolId = searchParams.get('symbol');
    if (searchWatchListId === null || searchSymbolId === null) {
      return;
    }
    const urlData = processUrl(watchLists, searchWatchListId, searchSymbolId);
    if (!urlData && !dataLoading && selectedSymbol) {
      setNotifications([{ type: 'error', content: 'Unknown error occurred' }]);
      onSymbolSelected(selectedSymbol);
    }
    if (urlData) {
      setSelectedWatchListId(urlData.id);
      urlData.symbol && onSymbolSelected(urlData.symbol);
    }
  }, [watchLists, dataLoading]);

  useEffect(() => {
    return () => {
      resetWatchListSearchText();
      resetWatchListGlobalFilters();
    };
  }, []);

  const onSymbolSelected = async (symbol: string) => {
    if (isDataLoading) {
      return;
    }
    setIsDataLoading(true);
    resetStrategyShareData();
    const isSuccessful = await fetchAndSetData(symbol, undefined);
    if (!isSuccessful) {
      setIsDataLoading(false);
      return;
    }
    setSubView(undefined);
    setView(view);
    setSimulatorOperation(
      view === 'income' ? tradeSimulatorOperationType.OptionsGrid : tradeSimulatorOperationType.PLSimulator,
    );
    ApplicationContext.userActivityHub?.logActivity(
      'watchListSelectSymbol',
      'watchListSelectSymbol',
      symbol,
      'click',
      symbol,
    );
    setIsDataLoading(false);
  };

  const fetchWatchLists = async () => {
    const response = await WatchlistService.instance.getWatchLists();
    if (response.hasErrors || !response.data) {
      return undefined;
    }
    return WatchLists.fromData(response.data);
  };

  if (!watchLists) {
    return <WhatLoaderWidget />;
  }

  if (watchLists.data.length === 0) {
    return <EmptyRowWidget message={'No Watchlist Found'} />;
  }

  /**
   * TODO:
   * Need to handle the UI what to be shown when watchlists data is empty.
   */

  return (
    <Suspense fallback={<WhatLoaderWidget />}>
      <WatchListWidget />
    </Suspense>
  );
};

export const WatchListsWidget: React.FC = () => {
  return (
    <Suspense fallback={<WhatLoaderWidget />}>
      <WatchListsManager />
      <WatchListsWidgetContainer />
    </Suspense>
  );
};
