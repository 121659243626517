import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const NotificationWidget: React.FC = () => {
  const notifications = useRecoilValue(notificationsState);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!notifications) {
      setOpen(false);
      return;
    }
    setOpen(notifications.length > 0);
  }, [notifications]);
  if (!notifications) {
    return null;
  }
  return (
    <>
      {notifications.map((alert, index) => (
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          key={index}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert
            key={alert.type}
            severity={alert.type}
            variant="filled"
            sx={{ opacity: '0.8!important', width: '100%', pl: 2, pr: 5, py: 1 }}>
            {alert.content}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
