import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { SubscriptionPaymentForms } from '@op/shared/src/models';
import { EventType, SubscriptionStatus } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { addSubscription, getPaymentPlans } from '@op/shared/src/services';
import {
  SubscriptionPaymentFormsState,
  currentSubscriptionState,
  selectedSymbolState,
  isSubscriptionRenewedState,
  currentSubscriptionScreenState,
} from '@op/shared/src/states';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CreditCardTypeWidget, SubscriptionCardDetails } from '.';
import { LanguageSelectorWidget } from '../../header';
import LocalizationContext from '../../react-i18next/localization-context';
import { OPBoldTypograpghy, OptionsPlayDivider } from '../../styled';
import { SubscriptionCardLayout } from './subscription-card-layout';
import { SubscriptionHeaderWidget } from './subscription-footer-widget';
import { SubscriptionSummarySkeleton } from './subscription-skeleton-widget';
import { ISubscriptionWidgetProps } from './subscription-widget';
import { useRefreshToken } from '../../states/use-fetch-and-set-refresh-token';
import { notificationsState } from '@op/shared/src/states/notification-states';

export const SubscriptionSummaryWidget: React.FC<ISubscriptionWidgetProps> = ({
  activeStep,
  setActiveStep,
  setIsError,
}: ISubscriptionWidgetProps) => {
  const fetchAndSetRefreshToken = useRefreshToken();
  const [CurrentSubscriptionData, setCurrentSubscriptionData] = useRecoilState(currentSubscriptionState);
  const [cardData, setCardData] = useRecoilState(SubscriptionPaymentFormsState);
  const { t } = React.useContext(LocalizationContext);
  const [loading, setLoading] = React.useState(false);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setIsSubscriptionRenewed = useSetRecoilState(isSubscriptionRenewedState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setCurrentSubscriptionScreen = useSetRecoilState(currentSubscriptionScreenState);

  let currentDate = new Date();
  const subscriptionStatus = CurrentSubscriptionData.currentSubscription?.subscriptionStatus;
  const theme = useTheme();
  if (!CurrentSubscriptionData) {
    return null;
  }

  const handleBack = () => {
    logActivity('BUTTON', 'paymentsAddSummaryBackButton');
    setActiveStep(activeStep - 1);
  };

  const handleNext = async () => {
    logActivity('BUTTON', 'paymentsAddSummaryPayButton');
    setLoading(true);
    const data = {
      billingAddress: {
        address: cardData.address,
        city: cardData.city,
        state: cardData.state,
        postalCode: cardData.postalCode,
        country: cardData.country,
        phone: cardData.phone,
      },
      creditCard: {
        cardHolder: cardData.cardName,
        cardNumber: cardData.cardNumber,
        cardSecurityCode: cardData.cardCvv,
        expirationMonth: cardData.cardMonth,
        expirationYear: cardData.cardYear,
      },
      plan: cardData.plan,
      pricingModelCode: cardData.pricingModelCode,
    };
    const response = await addSubscription(data);
    if (response.hasErrors) {
      setIsError(true);
      setLoading(false);
      setActiveStep(activeStep + 1);
      return;
    }
    setIsSubscriptionRenewed(true);
    const isSuccess = await fetchAndSetRefreshToken();
    //TODO: when we cancel the subscription from UI, then getRefreshToken API using the previous user is throwing error.
    if (!isSuccess) {
      console.log('Payment successful but error fetching accound details');
      //setIsError(true);
    }
    const getPaymentResponse = await getPaymentPlans();
    if (getPaymentResponse.hasErrors) {
      //setIsError(true);
      setNotifications([{ type: 'error', content: 'Unexpected error occured. Please reload the page.' }]);
      setLoading(false);
      setCurrentSubscriptionScreen('currentsubscription');
      //setActiveStep(activeStep + 1);
      return;
    }
    setCurrentSubscriptionData(getPaymentResponse.data);
    setLoading(false);
    setActiveStep(activeStep + 1);
    setCardData({ ...new SubscriptionPaymentForms(), plan: cardData.plan });
  };

  const getDiscountPrice = (type: string) => {
    if (CurrentSubscriptionData.currentPricingModel) {
      if (type === 'Monthly') {
        return CurrentSubscriptionData.currentPricingModel?.userMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.currentPricingModel?.userAnnualPrice.toFixed(2);
    }
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.pricingModels &&
      CurrentSubscriptionData.pricingModels.length > 0
    ) {
      if (type === 'Monthly') {
        return CurrentSubscriptionData.pricingModels[0].userMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.pricingModels[0].userAnnualPrice.toFixed(2);
    }
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };
  const getActualPrice = () => {
    if (CurrentSubscriptionData.currentPricingModel) {
      if (cardData.plan === 'Monthly') {
        return CurrentSubscriptionData.currentPricingModel?.regularMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.currentPricingModel?.regularAnnualPrice.toFixed(2);
    }
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.pricingModels &&
      CurrentSubscriptionData.pricingModels.length > 0
    ) {
      if (cardData.plan === 'Monthly') {
        return CurrentSubscriptionData.pricingModels[0].regularMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.pricingModels[0].regularAnnualPrice.toFixed(2);
    }
  };

  const billSummary = () => {
    const pricingModel =
      subscriptionStatus === SubscriptionStatus.ACTIVE_TRIAL
        ? CurrentSubscriptionData.pricingModels[0]
        : CurrentSubscriptionData.currentPricingModel;
    const discountPercentage =
      cardData.plan === 'Monthly' ? pricingModel?.discountMonthlyRate : pricingModel?.discountAnnualRate;
    const totalPayment = getDiscountPrice(cardData.plan);
    const discountPrice = NumberFormatHelper.toCurrency(Number(getActualPrice()) - Number(totalPayment));

    return (
      <Grid container rowSpacing={1.5}>
        <SubscriptionCardDetails label={t('app.php.subscriptions.typeOfPlan')} value={cardData.plan} />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.region')}
          value={<LanguageSelectorWidget iconView={'subscription'} />}
        />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.paymentDate')}
          value={DateTimeHelper.format(currentDate)}
        />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.cardPay')}
          value={<CreditCardTypeWidget cardNumber={cardData.cardNumber} textView={'typeWithNumber'} />}
        />
        <SubscriptionCardDetails label={t('app.php.subscriptions.originalPrice')} value={`$${getActualPrice()}`} />
        {discountPercentage > 0 && (
          <SubscriptionCardDetails
            label={t('app.php.subscriptions.discount')}
            value={`${discountPercentage}%     -${discountPrice}`}
          />
        )}

        <Grid item xs={12}>
          <OptionsPlayDivider />
        </Grid>
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.totalPayment')}
          value={`${NumberFormatHelper.toCurrency(totalPayment)}`}
        />
      </Grid>
    );
  };

  const renderSummaryButtons = () => {
    return (
      <Grid container>
        <Grid item container columnSpacing={1} xs={12}>
          <Grid item xs={4}>
            <Button
              onClick={handleBack}
              sx={{ color: theme.palette.primary.light }}
              size="large"
              variant="outlined"
              fullWidth
              startIcon={<ArrowBackIosIcon sx={{ color: theme.palette.primary.light }} />}>
              <Typography variant="button" sx={{ color: theme.palette.text.primary }}>
                {t('app.php.common.buttons.back')}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button onClick={handleNext} id="pw-subscribe" color="primary" size="large" variant="contained" fullWidth>
              <OPBoldTypograpghy variant="button">{t('app.php.subscriptions.makePayment')}</OPBoldTypograpghy>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const renderHeader = () => {
    return (
      <Grid container justifyContent="space-between" alignContent="center" style={{ color: '#ffffff' }}>
        <Grid item xs={8}>
          <OPBoldTypograpghy variant="subtitle1">
            {t('app.php.subscriptions.subscriptionBillSummary')}
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={4} container alignContent="center" justifyContent="right">
          <PhoneAndroidOutlinedIcon sx={{ marginX: '8px' }} fontSize="small" />
          <DesktopWindowsOutlinedIcon fontSize="small" />
        </Grid>
      </Grid>
    );
  };
  if (loading) {
    return <SubscriptionSummarySkeleton />;
  }

  return (
    <Grid container justifyContent={'center'} rowSpacing={2}>
      <Grid item xs={12}>
        <SubscriptionHeaderWidget label="" />
      </Grid>
      <Grid item container xs={6} rowSpacing={1}>
        <Grid item xs={12}>
          <SubscriptionCardLayout title={renderHeader()} content={billSummary()} />
        </Grid>
        <Grid item xs={12}>
          {renderSummaryButtons()}
        </Grid>
      </Grid>
    </Grid>
  );
};
