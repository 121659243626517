import { Grid, Typography } from '@mui/material';

import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import LocalizationContext from '../../../src/react-i18next/localization-context';
import React from 'react';
import formatting from '@op/shared/src/models/how/formatting';
import { howWhyRanksDataState } from '@op/shared/src/states';
import { useRecoilValue } from 'recoil';

export const EarningsDateWidget: React.FC = () => {
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  const { t } = React.useContext(LocalizationContext);
  if (!howWhyRanksData.why) {
    return <>No quote data</>;
  }

  const getEarningsDate = () => {
    let earningsDate: Date | undefined;
    let earningsTime: string = '';
    if (howWhyRanksData.why && howWhyRanksData.why.earningsDate && howWhyRanksData.why.earningsDate.trim() !== '') {
      earningsDate = DateTimeHelper.resolveDate(howWhyRanksData.why.earningsDate);
    }
    if (howWhyRanksData.why) {
      earningsTime = howWhyRanksData.why.earningsTime === 'AfterMarket' ? 'PM' : 'AM';
    }
    if (!earningsDate) {
      return formatting.NOT_AVAILABLE;
    }
    const days = DateTimeHelper.daysFromNow(earningsDate);
    const dateString = DateTimeHelper.format(earningsDate);
    return `${dateString} (${days < 0 ? 0 : days}) ${earningsTime}`;
  };

  return (
    <>
      <Grid item xs={4}>
        <Typography variant="captionSmall">{t('app.php.why.labels.earnings')}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography component="div" variant="captionSmall" fontWeight="bold" textAlign="right" lineHeight={1.2}>
          {getEarningsDate()}
        </Typography>
      </Grid>
    </>
  );
};
