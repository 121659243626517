import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Box, Grid, Theme, Typography, styled, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import optionPlayMainLogo from '@op/shared/assets/images/optionPlayMainLogo.png';
import { SentimentModel } from '@op/shared/src/hubs/sentiment-model';
import { Combination, OpScore, PortfolioManagementCombination, StrategyHelpers } from '@op/shared/src/models';
import BasicCombination from '@op/shared/src/models/how/basic-combination';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { customizationState, howDataState, whyDataState } from '@op/shared/src/states';
import {
  allCombinationsState,
  tradingRangeSimulatorState,
  tradingStrategyAtomFamilyState,
} from '@op/shared/src/states/how/how-states';
import { portfolioAllCombinationsState } from '@op/shared/src/states/portfolio-management-states';
import { sentimentAtomFamily } from '@op/shared/src/states/sentiment-hub-states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { GuideItem } from '../side-menu';

const useStyles = (theme: Theme) => {
  return {
    chipSize: {
      width: '100%',
    },
    doubleCheck: {
      color: theme.palette.success.main,
    },
    green: {
      borderColor: theme.palette.success.main,
      borderRadius: 30,
      borderWidth: 3,
      color: theme.palette.text.primary,
      margin: '5px 0px 5px 0px',
    },
    red: {
      borderColor: theme.palette.error.main,
      borderRadius: 30,
      borderWidth: 3,
      color: theme.palette.text.primary,
      margin: '5px 0px 5px 0px',
    },
    orange: {
      borderColor: theme.palette.warning.main,
      borderRadius: 30,
      borderWidth: 3,
      color: theme.palette.text.primary,
      margin: '5px 0px 5px 0px',
      backgroundColor: theme.palette.background.paper,
    },
  };
};

const OpScoreImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: 18,
    height: 18,
  },
  [theme.breakpoints.up('sm')]: {
    width: 15,
    height: 15,
  },
  [theme.breakpoints.up('md')]: {
    width: 15,
    height: 15,
  },
  [theme.breakpoints.up('lg')]: {
    width: 15,
    height: 15,
  },
  [theme.breakpoints.up('xl')]: {
    width: 20,
    height: 20,
  },
}));

export interface IOpScoreWidgetProps {
  index: number;
  isPortfolio?: boolean;
}

export interface IOptionsPlayScoreProps {
  combinations: (Combination | undefined)[];
  combination?: Combination;
  index: number;
  isPortfolio?: boolean;
}

const OptionsPlayScoreWidget: React.FC<IOptionsPlayScoreProps> = ({
  combinations,
  combination,
  index,
  isPortfolio,
}: IOptionsPlayScoreProps) => {
  const whyData = useRecoilValue(whyDataState);
  const howData = useRecoilValue(howDataState);
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const hubSentiment = useRecoilValue<SentimentModel | undefined>(sentimentAtomFamily(combination?.symbol || ''));
  const customization = useRecoilValue(customizationState);
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme();
  const classes = useStyles(theme);
  if (!tradingRangeSimulator || !combination || !howData) {
    return null;
  }

  if (customization && !customization.showOptionsPlayScore) {
    return null;
  }

  const getOpScores = () => {
    const outlookPercentBounds = tradingRangeSimulator.outlookPercentBoundsForPlChart(howData);
    const outlookPriceBounds = tradingRangeSimulator.outlookPriceBoundsForPlChart();
    const opScores = (combinations as (BasicCombination | undefined)[])
      .filter((c): c is BasicCombination => c !== undefined)
      .map((c: BasicCombination) => OpScore.fromData(c, outlookPercentBounds, outlookPriceBounds));

    let bestScore: OpScore | undefined;
    for (let opScore of opScores) {
      if (!combination.expiry() && combination.isSellCombination()) {
        continue;
      }
      if (opScore.score < 80) {
        continue;
      }
      if ((bestScore?.score || -1) > opScore.score) {
        continue;
      }
      bestScore = opScore;
    }
    if (bestScore !== undefined) {
      bestScore.isBestOpScore = true;
    }
    return opScores;
  };

  const scoresOfAllCombinations = getOpScores();
  const thisScore = index === -1 ? undefined : scoresOfAllCombinations[index];
  if (!thisScore) {
    return null;
  }

  const bestOPCombCheckListAllGreen = () => {
    // This combination id is -1 which is not valid. This should not happen. May be not executed code ever.
    if (!thisScore) {
      return false;
    }
    let earningsDate: Date | undefined;
    if (whyData && whyData.earningsDate && whyData.earningsDate.trim() !== '') {
      earningsDate = DateTimeHelper.resolveDate(whyData.earningsDate);
    }
    if (hubSentiment) {
      const checkList = StrategyHelpers.checkCombination(
        howData,
        combination,
        tradingRangeSimulator,
        earningsDate,
        hubSentiment,
      );
      const isAllGreen = checkList.every((item) => {
        return item.color.trim() === 'green';
      });
      return isAllGreen;
    }
    return false;
  };

  const OpScoreLabel = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h6">
            <strong>{thisScore?.scoreFormatted || ''}</strong>
            <span>{renderCheckMark()}</span>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pr: 1 }}>
          {renderOptionsPlayLogo()}
        </Grid>
      </Grid>
    );
  };

  const renderCheckMark = () => {
    if (isPortfolio || !thisScore) {
      return null;
    }
    if (thisScore.isBestOpScore && !bestOPCombCheckListAllGreen()) {
      return <DoneAllIcon fontSize="inherit" style={classes.doubleCheck} />;
    }
    return null;
  };

  const renderOptionsPlayLogo = () => {
    if (isPortfolio || !thisScore) {
      return null;
    }

    if (thisScore.isBestOpScore && bestOPCombCheckListAllGreen()) {
      return (
        <Box sx={{ pt: 1, pl: '4px' }}>
          <OpScoreImg src={optionPlayMainLogo} alt="" />
        </Box>
      );
    }

    return null;
  };

  return (
    <Grid container justifyContent="center" alignItems="center" columnSpacing={1}>
      <Grid item container xs={6}>
        <GuideItem selector=".optionsplayScore_helpPinPlaceholder" canShow={index === 0} />
        <Typography variant="body1" lineHeight={1.2} textAlign={{ md: 'center', xs: 'right', lg: 'right' }}>
          {t('app.php.common.titles.optionsPlayScore')}
        </Typography>
      </Grid>
      <Grid item container xs={6}>
        <Chip
          label={<OpScoreLabel />}
          variant="outlined"
          style={{ ...classes[thisScore.css || 'red'], ...classes.chipSize }}
          id="card-score"
        />
      </Grid>
    </Grid>
  );
};

export const PortfolioOptionsPlayScoreWidget: React.FC<{ index: number }> = ({ index }: { index: number }) => {
  const combinations = useRecoilValue<(PortfolioManagementCombination | undefined)[]>(portfolioAllCombinationsState);
  const combination = combinations[index];

  return <OptionsPlayScoreWidget combinations={combinations} combination={combination} index={index} isPortfolio />;
};

export const TradeOptionsPlayScoreWidget: React.FC<IOpScoreWidgetProps> = ({ index }: IOpScoreWidgetProps) => {
  const tradingStrategiesAllCombinations = useRecoilValue<(Combination | undefined)[]>(allCombinationsState);
  const tradingStrategyCombination = useRecoilValue<Combination | undefined>(
    tradingStrategyAtomFamilyState(index.toString()),
  );

  return (
    <OptionsPlayScoreWidget
      combinations={tradingStrategiesAllCombinations}
      combination={tradingStrategyCombination}
      index={index}
    />
  );
};
