import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { Grid, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { sectorFilterState1, selectedSymbolState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  MenuProps,
  OPBoldTypograpghy,
  OPLightTypograpghy,
  OPSelectDropDownHeader,
  OPSelectField,
  OPToggleButton,
  StyledDropdownItemWithBorder,
} from '../../styled';
import { GuideItem } from '../../side-menu';
import LocalizationContext from '../../react-i18next/localization-context';

export const SectorsFilterWidget2: React.FC = () => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const [sectorFilter1, setSectorFilter1] = useRecoilState(sectorFilterState1);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  if (!sectorFilter1) {
    return <></>;
  }

  const onSectorChange = (sectorValue: string): void => {
    const clone = cloneDeep(sectorFilter1);
    for (const f of clone.filters) {
      f.selected = f.value === sectorValue;
    }
    setSectorFilter1(clone);
    logActivity(sectorValue);
  };

  const logActivity = (sectorValue: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      'sectorFilter',
      'sectorFilter',
      sectorValue,
      'click',
      `${selectedSymbol}`,
    );
  };

  const getValue = (): string => {
    const sector = sectorFilter1.filters.find((s) => s.selected);
    if (!sector) {
      return '';
    }
    return sector.value as string;
  };

  const onReset = () => {
    const clone = cloneDeep(sectorFilter1);
    for (const x of clone.filters) {
      x.selected = x.name === 'allSectors';
    }
    setSectorFilter1(clone);
  };

  return (
    <Grid item xs={12} container justifyContent="space-between">
      <GuideItem selector=".sectorsDropdown_helpPinPlaceholder" />
      <Grid item xs={12}>
        <OPBoldTypograpghy>{t(sectorFilter1.translationKey)}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item p={0.5} xs={11} sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <OPSelectField
            className="sectorFilter"
            variant="outlined"
            value={getValue()}
            renderValue={(selected: any) => {
              return (
                <OPToggleButton selected={true} value={undefined}>
                  <OPLightTypograpghy>{selected}</OPLightTypograpghy>
                </OPToggleButton>
              );
            }}
            sx={{
              width: '100%',
              height: 40,
              '& .MuiSvgIcon-root': {
                color: theme.palette.selectAndTextField.main,
              },
            }}
            MenuProps={MenuProps}
            onChange={(event) => onSectorChange(event.target.value as string)}>
            <OPSelectDropDownHeader type="sectors" />
            {sectorFilter1.filters.map((sector) => {
              return (
                <StyledDropdownItemWithBorder
                  id="sectorFilter"
                  className="sectorFilter"
                  key={sector.value as string}
                  value={sector.value}
                  data-name="sectorFilter"
                  data-value={`selected ${sector.value} from FilterIdeas`}
                  color="default">
                  {sector.title}
                </StyledDropdownItemWithBorder>
              );
            })}
          </OPSelectField>
        </Grid>
        <Grid item xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item onClick={onReset}>
            <ClearSharpIcon fontSize="small" sx={{ '&:hover': { cursor: 'pointer' } }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
