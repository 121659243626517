import { Box, Grid, Tabs, Zoom } from '@mui/material';
import srcLogo from '@op/shared/assets/images/opLogo.png';
import { tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import {
  customizationState,
  isSelectedRecommendedStateOpenState,
  selectedSymbolState,
  subViewState,
  tradeSimulatorOperationTypeState,
  viewState,
} from '@op/shared/src/states';
import React, { Suspense, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { HowWidget } from '.';
import { TabPanel } from '../common';
import { IncomeLoaderWidget, IncomeSingleStrategyWidget, IncomeWidget } from '../income';
import { PortfolioManagementWidget } from '../portfolio';
import LocalizationContext from '../react-i18next/localization-context';
import { TradeSingleStrategyWidget } from '../single-trade/trade-single-strategy-widget';
import { StyledTab } from '../styled';
import { TradeTicketWidget } from '../trade-ticket';
import { HowSkeletonWidget } from './how-skeleton-widget';
import { PortfolioManagementSkeletonWidget } from '../portfolio/portfolio-management-skeleton-widget';
import { GuideItem } from '../side-menu';

export const HowTabsWidget: React.FC = () => {
  const portfolioRoute = useMatch('/portfolio');
  const customization = useRecoilValue(customizationState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [view, setView] = useRecoilState(viewState);
  const [subView, setSubView] = useRecoilState(subViewState);
  const { t } = React.useContext(LocalizationContext);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setIsSelectedRecommendedStateOpen = useSetRecoilState(isSelectedRecommendedStateOpenState);

  /*!On fast changing of route, sometime trade, watchlist get `porftolioManagement` view.
   * This useEffect try to mitigate this scenarios
   * Subview should be undefined when view changes.(auto close)
   */
  useEffect(() => {
    if (!portfolioRoute && view === 'portfolioManagement') {
      setView('trade');
      setSubView(undefined);
      return;
    }
  }, [view]);

  const onTabChange = (name: 'trade' | 'income' | 'portfolioManagement') => {
    if (portfolioRoute) {
      setIsSelectedRecommendedStateOpen(true);
    }
    if (name === 'trade') {
      setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
      logActivity('tradingStrategiesTab');
    } else if (name === 'income') {
      setSimulatorOperation(tradeSimulatorOperationType.OptionsGrid);
      logActivity('incomeStrategiesTab');
    } else {
      setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
      logActivity('positionsManagementTab');
    }
    setView(name);
    setSubView(undefined);
  };

  const logActivity = (name: string) => {
    ApplicationContext.userActivityHub?.logActivity(name, name, '', 'click', selectedSymbol);
  };

  if (subView === 'expand') {
    return (
      <Zoom in={subView === 'expand'} unmountOnExit>
        <Box>{view === 'trade' ? <TradeSingleStrategyWidget /> : <IncomeSingleStrategyWidget />}</Box>
      </Zoom>
    );
  }

  if (subView === 'tradeTicket') {
    return (
      <Zoom in={subView === 'tradeTicket'} unmountOnExit>
        <Box sx={{ pt: '7px' }}>
          <TradeTicketWidget />
        </Box>
      </Zoom>
    );
  }

  return (
    <>
      <GuideItem selector=".tradingStrategiesTab_helpPinPlaceholder" />
      <div style={{ paddingLeft: '12%' }}>
        <GuideItem selector=".incomeStrategiesTab_helpPinPlaceholder" />
      </div>
      <Grid container>
        <Grid item xs>
          <Tabs
            value={view}
            data-value={view}
            onChange={(_, value) => onTabChange(value)}
            textColor={customization?.isNasdaq ? 'inherit' : 'primary'}>
            <StyledTab
              id="tab-trade"
              value="trade"
              label={t('app.php.common.buttons.trade')}
              isselected={(view === 'trade').toString()}
            />
            {customization?.showIncomeStrategies ? (
              <StyledTab
                id="tab-income"
                value="income"
                label={t('app.php.common.buttons.income')}
                isselected={(view === 'income').toString()}
              />
            ) : undefined}
            {portfolioRoute ? (
              <StyledTab
                id="tab-portfolio"
                value="portfolioManagement"
                label={t('app.php.common.buttons.portfolioManagement')}
                isselected={(view === 'portfolioManagement').toString()}
              />
            ) : undefined}
          </Tabs>
        </Grid>
        <Grid item xs="auto">
          <img
            alt="optionsplay-logo"
            src={srcLogo}
            style={{
              height: '30px',
            }}
          />
        </Grid>
      </Grid>
      <TabPanel selectedIndex={view} index="trade">
        <Suspense fallback={<HowSkeletonWidget />}>
          <HowWidget />
        </Suspense>
      </TabPanel>
      {customization?.showIncomeStrategies && (
        <TabPanel selectedIndex={view} index="income">
          <Suspense fallback={<IncomeLoaderWidget />}>
            <IncomeWidget />
          </Suspense>
        </TabPanel>
      )}
      {portfolioRoute && (
        <TabPanel selectedIndex={view} index="portfolioManagement">
          <Suspense fallback={<PortfolioManagementSkeletonWidget />}>
            <PortfolioManagementWidget />
          </Suspense>
        </TabPanel>
      )}
    </>
  );
};
