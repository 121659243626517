import { PhpHubEvents } from '../models/enums/enums';
import { PowerhouseProHubConnection } from './powerhouse-pro-hub-connection';

export abstract class HubSubscription<T> {
  private subscribedSymbolsMap = new Map<string, string>();
  protected readonly ANONYMOUS_SUFFIX = 'Anonymous';
  protected getMethodName: string | undefined;
  protected subscribeMethodName: string | undefined;
  protected unsubscribeMethodName: string | undefined;
  protected _powerhouseProHubConnection: PowerhouseProHubConnection | undefined;
  protected shareId: string | undefined;
  protected accessToken: string | undefined;
  eventType = '';

  protected constructor() {}

  protected fromSocketConnection = (
    powerhouseProHubConnection: PowerhouseProHubConnection,
    subscribeMethodName: string,
    unsubscribeMethodName: string,
    getMethodName: string | undefined,
    eventType: PhpHubEvents,
    shareId?: string | undefined,
    accessToken?: string | undefined,
  ) => {
    this.powerhouseProHubConnection = powerhouseProHubConnection;
    this.subscribeMethodName = subscribeMethodName;
    this.unsubscribeMethodName = unsubscribeMethodName;
    this.getMethodName = getMethodName;
    this.eventType = eventType.toString().toUpperCase();
    this.shareId = shareId;
    this.accessToken = accessToken;
  };

  get powerhouseProHubConnection() {
    if (!this._powerhouseProHubConnection) {
      throw new Error('PowerhouseProHub is undefined');
    }
    return this._powerhouseProHubConnection;
  }

  set powerhouseProHubConnection(value: PowerhouseProHubConnection) {
    this._powerhouseProHubConnection = value;
  }

  getItems = async (itemsKeys: string[]) => {
    if (!this.getMethodName) {
      return undefined;
    }
    if (itemsKeys.length === 0) {
      return [];
    }
    let results: any;
    if (this.accessToken && !this.shareId) {
      results = await this.powerhouseProHubConnection.invoke(this.getMethodName, itemsKeys);
    }
    if (!this.accessToken && this.shareId) {
      results = await this.powerhouseProHubConnection.invoke(
        this.getMethodName + this.ANONYMOUS_SUFFIX,
        itemsKeys,
        this.shareId,
      );
    }
    const items = this.mapItemsFromServer(results);
    return items;
  };

  abstract mapItemsFromServer(items: any[]): T[];

  /*
   * Subscribes current client from updates.
   * @param {Array} items - items to subscribe.
   */
  subscribe = async (items: any) => {
    if (!this.subscribeMethodName) {
      throw new Error('subcribe method is undefined');
    }
    await this.powerhouseProHubConnection.invoke(this.subscribeMethodName, items);
  };

  /*
   * Unsubscribes current client from updates.
   * @param {Array} items - items to unsubscribe from.
   */
  unsubscribe = async (items: any) => {
    if (!this.unsubscribeMethodName) {
      throw new Error('unsubscribeMethodName method is undefined');
    }
    await this.powerhouseProHubConnection.invoke(this.unsubscribeMethodName, items);
  };
}
