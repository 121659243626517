import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Grid, Link } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { cloneDeep, isNordic, isTmx } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { ProfileDetailsService } from '@op/shared/src/services';
import { accountState, configurationState, customizationState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { CommonListItemIcon } from './common-list-item-icon-widget';
import { OPDialogWidget } from '../common';
import { OutlinedButton } from '../styled/options-play-components';
import { ImplementationType } from '@op/shared/src/models/enums/enums';
import { BncDisclaimerWidget } from '../embedded/bnc-disclaimer-widget';

export interface DisclaimerWidgetProps {
  showTabName: boolean;
}

export const DisclaimerWidget: React.FC<DisclaimerWidgetProps> = ({ showTabName }: DisclaimerWidgetProps) => {
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const account = useRecoilValue(accountState);
  const [open, setOpen] = React.useState(false);
  const { t } = React.useContext(LocalizationContext);

  useEffect(() => {
    if (!configuration || !account || !configuration.userSettings || !customization || !customization.showDisclaimer) {
      return;
    }

    // //TODO: use customization instead of configuration
    // if (configuration.isEmbeddingPlatform) {
    //   return;
    // }
    const hasDisclaimerBeenAccepted = ApplicationContext.configuration.userSettings?.hasDisclaimerBeenAccepted;
    if (!hasDisclaimerBeenAccepted) {
      handleClickOpen();
    }
  }, [account, configuration]);

  if (!account || !configuration || !customization || !customization.showDisclaimer) {
    return null;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderNordicDisclaimer = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>
          OptionsPlay Nordic is available free of charge only for non professional users. By agreeing to this form, I
          hereby confirm I am a non professional user. For more information about this distinction, it can be found on
          the Nasdaq Global Subscriber Agreement (on page 9).
        </Typography>
        <Typography gutterBottom>
          <b>A non-professional users is defined by:</b>
        </Typography>
        <Typography gutterBottom>
          A natural person for the purpose of managing the Subscriber’s own personal investments and not for any
          business purpose, nor for the purpose of giving any form of advice to any other person.
        </Typography>
        <Typography gutterBottom>
          <b>A Private Use Subscriber may not:</b>
        </Typography>
        <Typography gutterBottom>
          (a) contract for, receive or use Information for the purpose of Private Use on behalf of any other person or
          any corporation, partnership, limited liability company, trust, association or other form of entity,
        </Typography>
        <Typography gutterBottom>
          (b) contract for, receive or use Information for the purpose of Private Use in any Service that is paid for by
          another person or any corporation, partnership, limited liability company, trust, association or other form of
          entity.
        </Typography>
        <Typography gutterBottom>
          <b>A Private Use Subscriber shall, notwithstanding the above:</b>
        </Typography>
        <Typography gutterBottom>
          (c) be permitted to contract for, receive or use Information on behalf of or paid for by another natural
          person (person B) provided that (1) its for the purpose of managing person B’s own personal investments and
          not for any business purpose, and (2) person B have filed a power of attorney or equivalent documentation
          accordingly with Licensee,
        </Typography>
        <Typography gutterBottom>
          (d) be permitted to contract for, receive or use Information for Private Use on behalf of and/or paid for by a
          legal entity or other form of non-natural Person in which the Private Use Subscriber has full (100%) ownership
          and exercises full (100%)control,
        </Typography>
        <Typography gutterBottom>
          (e) Section (c) and (d) may not be combined. A professional subscriber can still receive Options Education and
          OptionsPlay’s weekly newsletters on options related news, and reports. A professional subscriber cannot
          receive free access to OptionsPlay Nordic.
        </Typography>
      </DialogContent>
    );
  };

  const renderTMXDisclaimer = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>
          The information provided by OptionsPlay is for general informational and educational purposes only and is not
          intended to be, and should not be construed as, investment advice, a recommendation, or an offer or
          solicitation to buy or sell any securities, financial instruments or other investments. OptionsPlay is not
          registered with the Investment Industry Regulatory Organization of Canada (IIROC).
        </Typography>
        <Typography gutterBottom>
          Trading involves substantial risk and is not suitable for all investors. You should carefully consider your
          financial objectives, level of experience, risk tolerance, and investment horizon before making any investment
          decisions. We strongly encourage you to seek the advice of a qualified financial professional before making
          any investment decisions based on the trading signals or any other information provided by our service.
        </Typography>
        <Typography gutterBottom>
          We make no representations, warranties or guarantees of any kind, express or implied, regarding the accuracy,
          reliability, completeness, or timeliness of the information provided through our service. You acknowledge and
          agree that any reliance on such information is solely at your own risk, and we expressly disclaim any and all
          liability for any errors, omissions, or inaccuracies in the information or for any actions taken in reliance
          on the information.
        </Typography>
        <Typography gutterBottom>
          By using OptionsPlay, you acknowledge that you have read, understood, and agree to be bound by this
          disclaimer, and you assume full responsibility for your own investment decisions and any resulting outcomes.
        </Typography>
      </DialogContent>
    );
  };

  const regalDisclaimer = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>
          OptionsPlay provides equity and option investing tools, daily trading ideas and strategies, and technical
          analysis for informational purposes only and eOption cannot attest to its reliability, performance, accuracy
          or completeness. eOption makes no representation or warranty that any data or information supplied to or by
          OptionsPlay is complete or free from errors, omissions, or defects. No information contained in OptionsPlay is
          intended to constitute a recommendation by eOption to buy, sell, or hold any stock, option, or a particular
          investment. eOption makes no investment recommendations and does not provide financial, tax, or legal advice.
        </Typography>
        <Typography gutterBottom>
          With your use of OptionsPlay, you are solely responsible for your own investment and trading decisions, and
          any losses damages or costs resulting from your reliance on any data or information that is provided in
          connection with your use of OptionsPlay. eOption is not responsible for determining whether any transaction
          you may enter into is suitable, appropriate or advisable. The research and investment strategies contained in
          OptionsPlay may not be appropriate for you.
        </Typography>
        <Typography gutterBottom>
          Options involve risk and are not suitable for all investors. Prior to trading options, you must be approved
          for options trading and read the
          <Link href="http://www.optionsclearing.com/about/publications/character-risks.jsp" target="_blank">
            Characteristics and Risks of Standardized Options
          </Link>
          . A copy may also be requested via email at
          <Link href="mailto:support@eoption.com" target="_blank">
            support@eoption.com
          </Link>
          or via mail to eOption, 950 Milwaukee Ave., Ste. 102, Glenview, IL 60025. Online trading has inherent risks
          due to loss of online services or delays from system performance, risk parameters, market conditions, and
          erroneous or unavailable market data.
        </Typography>
      </DialogContent>
    );
  };

  const merrillDisclaimer = () => {
    return (
      <DialogContent className="scrollbar-content">
        <p className="bold">
          IMPORTANT: The calculations or other information generated by OptionsPlay regarding the likelihood of various
          investment outcomes are hypothetical in nature, do not reflect actual investment results and are not
          guarantees of future results. The calculations and other information generated by OptionsPlay may vary with
          each use and over time.
        </p>
        <p className="bold">
          The calculations and other information presented in OptionsPlay do not incorporate or reflect the transaction
          costs and fees you may incur, such as commissions and contract, exercise, or assignment fees. Orders which
          involve multiple options transactions will be charged separate commissions and fees on each leg of the options
          order. Transaction costs can be significant and you should consider them when evaluating any options trade or
          strategy.
        </p>
        <p className="bold">
          Options involve risk and are not suitable for all investors. Certain requirements must be met to trade
          options. Before engaging in the purchase or sale of options, investors should understand the nature of and
          extent of their rights and obligations and be aware of the risks involved in investing with options. Prior to
          buying or selling an option, clients must receive the options disclosure document —{' '}
          <Link
            href="https://olui2.fs.ml.com/Publish/Content/application/pdf/GWMOL/riskstoc_supplement.pdf"
            target="_blank">
            Characteristics and Risks of Standardized Options
          </Link>
          . Click this link or call the Investment Center at 1.877.653.4732 for a copy. A separate client agreement is
          needed to trade options.
        </p>
        <p className="bold">
          The calculations and other information generated by OptionsPlay are based solely on inputs that you enter into
          OptionsPlay. You are solely responsible for determining the reasonableness of the inputs that you enter into
          OptionsPlay. Neither Merrill nor OptionsPlay expresses any view on the reasonableness of any inputs you enter
          into OptionsPlay, or of any assumption that you have made to form the basis of any such inputs. As such, users
          of OptionsPlay should be aware that OptionsPlay is not designed to as a tool to assess the reasonableness,
          validity or accuracy of the user-defined inputs used for OptionsPlay. Furthermore, OptionsPlay does not
          predict or assure the performance of any investment.
        </p>
        <p>
          Supporting documentation for any claims (including any claims made on behalf of options programs or the
          options expertise of salespersons), comparisons, recommendations, statistics, or other technical data, will be
          supplied on request.
        </p>
        <p>
          OptionsPlay is a tool that is provided for educational purposes only. It provides technical analyses of
          options data based on user-defined inputs by the investor. Option strategies discussed herein do not take into
          consideration clients currently approved permissions or eligibility. Contact the investment Center for more
          information on how to obtain approval for various strategies.
        </p>
        <p>
          OptionsPlay calculations are based on the most recent reported price of the option or the underlying security.{' '}
          <span className="bold">
            Commissions and middle-of-the-month assignments are not considered in any calculations.
          </span>{' '}
          Commission information is available upon request from the Merrill Edge Investment Center.
        </p>
        <p>
          OptionsPlay is simply a tool that can perform analytics based on the limited data that it allows you to input
          for analysis. Before using OptionsPlay, you should carefully review the meanings of the terms used by
          OptionsPlay, which is contained in the Guide provided to you. The data that OptionsPlay permits you to input
          for analysis does not capture all available scenarios relevant to you or to any transaction or strategy.
          Consequently, before transacting in any options transaction, we urge you to evaluate any data generated by
          OptionsPlay together with all other information available to you, including those contained in the options
          disclosure document —{' '}
          <Link
            href="https://olui2.fs.ml.com/Publish/Content/application/pdf/GWMOL/riskstoc_supplement.pdf"
            target="_blank">
            Characteristics and Risks of Standardized Options
          </Link>
          — provided to you. You should not transact in any options transaction based solely on data generated by
          OptionsPlay.
        </p>
        <p>
          OptionsPlay cannot take into account your specific investment objectives, financial situation, risk tolerance,
          liquidity needs, investment time horizon, investment experience, or tax status. It also cannot take into
          consideration the future performance of any stock or strategy or market conditions. You should not construe
          any result, probability of any outcome, score or other data generated by OptionsPlay as a recommendation by
          Merrill or OptionsPlay to transact or not transact in any particular strategy or transaction.
        </p>
        <p>
          You may incur significant losses by transacting in options. Certain options strategies may be risky and/or
          speculative. You should carefully review the information contained in the options disclosure document before
          transacting in options.
        </p>
        <p className="bold">
          For individuals that trade on Margin, please review our Margin Risks Disclosure Statement.
        </p>
        <p>
          When you purchase securities, you may pay for the securities in full, or if your account has been established
          as a margin account with the margin lending program, you may borrow part of the purchase price from Merrill,
          thereby leveraging your investment. If you choose to borrow funds for your purchase, Merrills collateral for
          the loan will be the securities purchased, other assets in your margin account, and your assets in any other
          accounts at Merrill other than retirement accounts (such as IRAs). If the securities in your margin account
          decline in value, so does the value of the collateral supporting your loan, and, as a result, we can take
          action, such as to issue a margin call and/or sell securities in any of your accounts held with us, in order
          to maintain the required equity in your account. If your account has a Visa® card and/or checks, you may also
          create a margin debit if your withdrawals (by Visa card, checks, preauthorized debits, FTS or other transfers)
          exceed the sum of any available free credit balances plus available money account balances (such as bank
          deposit balances or money market funds). Please refer to your account documents for more information.
        </p>
        <p>
          Also note that it may be more advantageous to pay cash than to use margin for smaller securities purchases. On
          smaller securities purchases, a higher percentage of the transaction costs goes to commissions and interest
          charges, which are generally higher on smaller balances. The commissions plus interest charges could equal or
          exceed any appreciation in your securities.
        </p>
        <p>
          Clients investing on margin should carefully review the terms governing margin loans. The margin terms are
          contained in the Margin Lending Program Client Agreement and/or your account agreement and disclosures. It is
          important that you fully understand the risks involved in using margin. These risks including the following:
        </p>
        <p>
          <ul>
            <li>
              <span className="bold">You can lose more funds than you deposit in the margin account.</span> A decline in
              the value of securities that are purchased on margin may require you to provide additional funds to us to
              avoid the forced sale of those securities or other securities or assets in your account(s).
            </li>
            <li>
              <span className="bold">We can force the sale of securities or other assets in your account(s).</span> If
              the equity in your account falls below the maintenance margin requirements or Merrills higher house
              requirements, we can sell the securities or other assets in any of your accounts held by us to cover the
              margin deficiency. You also will be responsible for any shortfall in the account after such a sale.
            </li>
            <li>
              <span className="bold">We can sell your securities or other assets without contacting you.</span> Some
              investors mistakenly believe that they must be contacted for a margin call to be valid, and that
              securities or other assets in their accounts cannot be liquidated to meet the call unless they are
              contacted first. This is not the case. We will attempt to notify you of margin calls, but we are not
              required to do so. Even if we have contacted you and provided a specific date by which you can meet a
              margin call, we can still take necessary steps to protect our financial interests, including immediately
              selling the securities or other assets without notice to you.
            </li>
            <li>
              <span className="bold">
                You are not entitled to choose which securities or other assets in your account(s) are liquidated or
                sold to meet a margin call.
              </span>{' '}
              Because the securities or other assets are collateral for the margin loan, we have the right to decide
              which securities or other assets to sell in order to protect our interests.
            </li>
            <li>
              <span className="bold">
                We can increase our house maintenance margin requirements at any time, including on specific securities
                experiencing significant volatility, and are not required to provide you advance written notice.
              </span>{' '}
              These changes in our policy may take effect immediately and may result in the issuance of a maintenance
              margin call. Your failure to satisfy the call may cause us to liquidate or sell securities in your
              account(s).
            </li>
            <li>
              <span className="bold">You are not entitled to an extension of time on a margin call.</span> While an
              extension of time to meet margin requirements may be available to you under certain conditions, you do not
              have a right to the extension.
            </li>
          </ul>
        </p>
        <p>
          If you have any questions or concerns about margin and the margin lending program, please contact your Merrill
          advisor, the Merrill Advisory Center or the Merrill Investment Center.
        </p>
        <p>
          By clicking [I Agree] below, I acknowledge that (i) I have read the options disclosure document —
          <Link
            href="https://olui2.fs.ml.com/Publish/Content/application/pdf/GWMOL/riskstoc_supplement.pdf"
            target="_blank">
            Characteristics and Risks of Standardized Options
          </Link>
          , (ii) I am knowledgeable about options transactions and the risks associated with transacting in options,
          (iii) none of the results, probabilities, scores, or any other data or information presented in or generated
          by OptionsPlay constitute or should be construed as a recommendation by Merrill or OptionsPlay to transact or
          not transact in any particular strategy or transaction, (iv) Merrill is not making a recommendation to me to
          transact or not to transact in any particular strategy or transaction by making the OptionsPlay tool available
          for me and is not assessing, evaluating or otherwise expressing any view on the reliability, accuracy or
          reasonableness of any input that I may enter into OptionsPlay, or any assumption on which any such input is
          based and iv) I have read and understood the Important Notice above.
        </p>
      </DialogContent>
    );
  };

  const renderDefaultDisclaimer = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>
          There can be significant risk of losses in trading securities and options. Customers must consider all
          relevant risk elements including their individual financial circumstances prior to trading. Options involve
          risks and are not suitable for every investor. Prior to buying or selling an option, an investor must receive
          a copy of Characteristics and Risks of Standardized Options. Also please note on December 17, 2009 the
          Securities and Exchange Commission (SEC) approved a{' '}
          <span className="underline">Supplement to the Options Disclosure Document (ODD)</span> providing information
          relating to options on indexes and the adjustment of stock option contracts. Any results posted are unique and
          are not indicative of any future results.
        </Typography>
        <Typography gutterBottom>
          Copies may be obtained from your broker, one of the exchanges or the Options Clearing Corporation. Historical
          results do not indicate future performance. OptionsPlay does not guarantee in writing or verbally that
          material on this website or that taught in the videos or written material contained in the associated files
          that accompany the videos will result in a profit. Investors need a broker to trade options, and must meet
          suitability requirements.
        </Typography>
        <Typography gutterBottom>
          For ease of simplicity, all examples used on this website and in the videos exclude commissions, interest and
          dividend and other transaction fees, tax considerations, or margin requirements, which are factors that may
          significantly affect the economic consequences of trades illustrated. Please contact a tax advisor for the tax
          implications involved with trading specific spread types and read the Options Disclosure Document (ODD) prior
          to investing in options. You can obtain a copy of the ODD at{' '}
          <Link href="http://www.theocc.com/about/publications/character-risks.jsp" target="_blank">
            http://www.theocc.com/about/publications/character-risks.jsp
          </Link>
          ; or, from the Options Clearing Corporation (1888-options), One North Wacker Drive, Suite 500, Chicago IL
          60606. Stock/Index/ETF and option symbols and price data shown are for illustrative purposes only.
        </Typography>
        <Typography gutterBottom>
          The information and data contained in this website (www.OptionsPlay.com) was obtained from sources believed to
          be reliable, but accuracy is not guaranteed. Neither the information, nor any opinion expressed, constitutes a
          recommendation to purchase or sell a security, or to provide investment advice.
        </Typography>
        <Typography gutterBottom>
          The information contained on this site is provided for general informational purposes, as a convenience to
          clients. The materials are not a substitute for obtaining professional advice from a qualified person, firm or
          corporation. Consult the appropriate professional advisor for more complete and current information.
          OptionsPlay is not engaged in rendering any legal or professional services by placing these general
          informational materials on this website.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay specifically disclaims any liability, whether based in contract, tort, strict liability or
          otherwise, for any direct, indirect, incidental, consequential, or special damages arising out of or in any
          way connected with access to or use of the site, even if OptionsPlay has been advised of the possibility of
          such damages, including liability in connection with mistakes or omissions in, or delays in transmission of,
          information to or from the user, interruptions in telecommunications connections to the site or viruses.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay makes no representations or warranties about the accuracy or completeness of the information
          contained on this website. Any links provided to other server sites are offered as a matter of convenience and
          in no way are meant to imply that OptionsPlay endorses, sponsors, promotes or is affiliated with the owners of
          or participants in those sites, or endorses any information contained on those sites, unless expressly stated.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay is the copyright owner of all text and graphics contained on this website, except as otherwise
          indicated. Other parties trademarks and service marks that may be referred to herein are the property of their
          respective owners. You may print a copy of the information contained herein for your personal use only, but
          you may not reproduce or distribute the text or graphics to others or substantially copy the information on
          your own server, or link to this website, without prior written permission of OptionsPlay.
        </Typography>
        <Typography gutterBottom>
          Permission to use and reproduce documents and related graphics available from this website is granted,
          provided that: 1. the below copyright notice appears in all copies and that both the copyright and this
          permission notice appear; 2. use and reproduction of documents and related graphics available from this
          website is limited to personal, non-commercial use; 3. no documents or related graphics, including logos,
          available from this website are modified in any way; and 4. no graphics, including logos, available from this
          website are used separate from accompanying text. Use or reproduction for any other purpose is expressly
          prohibited by law, and may result in civil and criminal penalties.
        </Typography>
      </DialogContent>
    );
  };

  const renderFirsTrade = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
          Options trading entails significant risk and is not appropriate for all investors. Certain complex options
          strategies carry additional risk. There is always the potential of losing money when you invest in securities
          or other financial products. Investors should consider their investment objectives and risks carefully before
          investing. To learn more about the risks associated with options, please read the Characteristics and Risks of
          Standardized Options before you begin trading options. Supporting documentation for any claims, if applicable,
          will be furnished upon request.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay, LLC and Syrah Trading Technologies, LLC (together, “OptionsPlay”) are independent third-party
          providers and have no affiliation with Firstrade. OptionsPlay is the sole party responsible for the software,
          data, and services (“OptionsPlay Services”) they offer via Firstrade. Firstrade has not verified the accuracy
          of the OptionsPlay Services and does not guarantee its accuracy or completeness. Firstrade will not be liable
          for any issues arising from your use of or reliance on the OptionsPlay Services.
        </Typography>
        <Typography gutterBottom>
          Past performance of the probability of profit (POP) metric doesn't guarantee future results. Options contracts
          carry inherent risks, including the risk of assignment even if the contract is out of the money or before its
          expiration. Calculations for maximum risk and reward don't account for exercise or assignment. Changes to an
          options strategy, like trading or rolling, can alter its maximum loss, gain, and breakeven values. This
          applies to all options strategies, including long and short options as well as spreads.
        </Typography>
        <Typography gutterBottom>
          Using historical data to compare Annualized Return doesn't guarantee future outcomes.
        </Typography>
        <Typography gutterBottom>
          Copies may be obtained from your broker, one of the exchanges or the Options Clearing Corporation. Historical
          results do not indicate future performance. OptionsPlay does not guarantee in writing or verbally that
          material on this website or that taught in the videos or written material contained in the associated files
          that accompany the videos will result in a profit. Investors need a broker to trade options, and must meet
          suitability requirements.
        </Typography>
        <Typography gutterBottom>
          {`For ease of simplicity, all examples used on this website and in the videos exclude commissions, interest and
          dividend and other transaction fees, tax considerations, or margin requirements, which are factors that may
          significantly affect the economic consequences of trades illustrated. Please contact a tax advisor for the tax
          implications involved with trading specific spread types and read the Options Disclosure Document (ODD) prior
          to investing in options. You can obtain a copy of the ODD at `}
          <Link
            href="https://www.theocc.com/getmedia/a151a9ae-d784-4a15-bdeb-23a029f50b70/riskstoc.pdf"
            target="_blank">
            {`https://www.theocc.com/getmedia/a151a9ae-d784-4a15-bdeb-23a029f50b70/riskstoc.pdf or, from the Options `}
          </Link>
          {`Clearing Corporation (1888-options), One North Wacker Drive, Suite 500, Chicago IL 60606. Stock/Index/ETF and
          option symbols and price data shown are for illustrative purposes only.`}
        </Typography>
        <Typography gutterBottom>
          The information and data contained in this website (www.OptionsPlay.com) was obtained from sources believed to
          be reliable, but accuracy is not guaranteed. Neither the information, nor any opinion expressed, constitutes a
          recommendation to purchase or sell a security, or to provide investment advice.
        </Typography>
        <Typography gutterBottom>
          The information contained on this site is provided for general informational purposes, as a convenience to
          clients. The materials are not a substitute for obtaining professional advice from a qualified person, firm or
          corporation. Consult the appropriate professional advisor for more complete and current information.
          OptionsPlay is not engaged in rendering any legal or professional services by placing these general
          informational materials on this website.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay specifically disclaims any liability, whether based in contract, tort, strict liability or
          otherwise, for any direct, indirect, incidental, consequential, or special damages arising out of or in any
          way connected with access to or use of the site, even if OptionsPlay has been advised of the possibility of
          such damages, including liability in connection with mistakes or omissions in, or delays in transmission of,
          information to or from the user, interruptions in telecommunications connections to the site or viruses.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay makes no representations or warranties about the accuracy or completeness of the information
          contained on this website. Any links provided to other server sites are offered as a matter of convenience and
          in no way are meant to imply that OptionsPlay endorses, sponsors, promotes or is affiliated with the owners of
          or participants in those sites, or endorses any information contained on those sites, unless expressly stated.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay is the copyright owner of all text and graphics contained on this website, except as otherwise
          indicated. Other parties' trademarks and service marks that may be referred to herein are the property of
          their respective owners. You may print a copy of the information contained herein for your personal use only,
          but you may not reproduce or distribute the text or graphics to others or substantially copy the information
          on your own server, or link to this website, without prior written permission of OptionsPlay.
        </Typography>
        <Typography gutterBottom>
          Permission to use and reproduce documents and related graphics available from this website is granted,
          provided that: 1. the below copyright notice appears in all copies and that both the copyright and this
          permission notice appear; 2. use and reproduction of documents and related graphics available from this
          website is limited to personal, non-commercial use; 3. no documents or related graphics, including logos,
          available from this website are modified in any way; and 4. no graphics, including logos, available from this
          website are used separate from accompanying text. Use or reproduction for any other purpose is expressly
          prohibited by law, and may result in civil and criminal penalties.
        </Typography>
      </DialogContent>
    );
  };

  const renderBncTrade = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>© 2020 OptionsPlay. All Rights Reserved.</Typography>
        <Typography gutterBottom>
          OptionsPlay have one goal: To show you how options can unlock investment opportunities for everyone. They
          guide investors of all experience levels through the risks and rewards of options trading using clear, concise
          language that anyone can understand.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay tool is the property of OptionsPlay which is not affiliated with National Bank Direct Brokerage
          (NBDB), a division of National Bank Financial Inc., or any of the companies in the National Bank of Canada
          group.
        </Typography>
        <Typography gutterBottom>
          The content and information provided in the OptionsPlay tool have been independently prepared by OptionsPlay
          and/or its content providers and are neither endorsed nor approved by NBDB which does not accept any
          responsibility in this regard.
        </Typography>
        <Typography gutterBottom>
          The information contained herein: (1) is proprietary to OptionsPlay and/or its content providers; (2) may not
          be copied or distributed; (3) is not warranted to be accurate, complete or timely; and, (4) does not
          constitute advice or a recommendation by NBDB, OptionsPlay or its content providers in respect of the
          investment in financial instruments.
        </Typography>
        <Typography gutterBottom>
          Neither NBDB, nor OptionsPlay and its content providers are responsible for any damages or losses arising from
          any use of this information. Past performance is no guarantee of future results.
        </Typography>
      </DialogContent>
    );
  };

  const renderDialogContent = () => {
    if (customization && customization.isEmbeddingPlatform) {
      if (customization.implementation === ImplementationType.FIRSTRADE) {
        return renderFirsTrade();
      }
      if (customization.implementation === ImplementationType.BNC) {
        return renderBncTrade();
      }
    }
    if (isNordic()) {
      return renderNordicDisclaimer();
    }
    if (isTmx()) {
      return renderTMXDisclaimer();
    }
    return renderDefaultDisclaimer();
  };

  const onClickButton = async (isAccepted: boolean) => {
    const res = await acceptDisclaimer(isAccepted);
    if (!res) {
      return;
    }
    if (!isAccepted && !customization.isEmbeddingPlatform) {
      if (isNordic()) {
        window.location.href = 'https://optionsplay.lpages.co/nordic-edu/';
      } else {
        window.location.href = 'http://www.optionsplay.com/';
      }
    }
    const clonedConfiguration = cloneDeep(configuration);
    if (clonedConfiguration.userSettings !== undefined) {
      clonedConfiguration.userSettings.hasDisclaimerBeenAccepted = isAccepted;
      ApplicationContext.configuration = clonedConfiguration;
    }
    handleClose();
  };

  const acceptDisclaimer = async (value: boolean) => {
    const response = await ProfileDetailsService.instance.AcceptDisclaimer(value);
    if (response.hasErrors) {
      return undefined;
    }
    return response.data;
  };

  const renderTabName = () => {
    if (!showTabName) {
      return null;
    }
    return (
      <Typography>
        <CommonListItemIcon
          icon={<ErrorOutlineIcon />}
          text={t('app.php.footer.labels.disclaimer')}
          callback={handleClickOpen}
          key={'disclaimer'}
        />
      </Typography>
    );
  };
  const renderDialogTitle = () => {
    return (
      <Grid id="customized-dialog-title" onClick={handleClose}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Online Options Trading
          <br />
          Legal Notice & Disclaimer
        </Typography>
      </Grid>
    );
  };
  const renderDialogAction = () => {
    return (
      <Grid container justifyContent={'flex-end'} spacing={2}>
        <Grid item>
          <OutlinedButton autoFocus size="small" onClick={() => onClickButton(false)}>
            <Typography variant="button">{t('app.php.modals.disclaimer.buttons.doNotAgree')}</Typography>
          </OutlinedButton>
        </Grid>
        <Grid item>
          <OutlinedButton autoFocus size="small" onClick={() => onClickButton(true)}>
            <Typography variant="button">{t('app.php.modals.disclaimer.buttons.agree')}</Typography>
          </OutlinedButton>
        </Grid>
      </Grid>
    );
  };

  const renderDisclaimer = () => {
    if (!configuration) {
      return;
    }
    if (customization && customization.isEmbeddingPlatform) {
      if (customization.implementation === ImplementationType.BNC) {
        return <BncDisclaimerWidget onHandleClose={handleClose} open={open} onClickButton={onClickButton} />
      }
    }
    return (
      <OPDialogWidget
        open={open}
        onHandleClose={handleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
      />
    )
  }

  return (
    <Grid>
      {renderTabName()}
      {renderDisclaimer()}
    </Grid>
  );
};
