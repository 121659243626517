import ClearIcon from '@mui/icons-material/Clear';
import { Grid } from '@mui/material';
import React from 'react';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPLightTypograpghy, OPToggleButton } from '../styled';

interface ISelectClearProps {
  isSelectedAll: boolean;
  onReset: () => void;
  onSelectAll?: () => void;
}

export const SelectClearWidget: React.FC<ISelectClearProps> = ({
  isSelectedAll,
  onReset,
  onSelectAll,
}: ISelectClearProps) => {
  const { t } = React.useContext(LocalizationContext);

  return (
    <Grid item xs={12} container alignItems={'center'} justifyContent="space-between">
      <Grid item xs={2.5}>
        <OPBoldTypograpghy>{t('app.php.what.tradeIdeas.ideas.filters')}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={9.5} container columnSpacing={0.5} justifyContent="flex-end">
        <Grid item>
          <OPToggleButton value={undefined} selected={isSelectedAll} onClick={onSelectAll}>
            <OPLightTypograpghy>{t('app.php.what.tradeIdeas.ideas.selectAll')}</OPLightTypograpghy>
          </OPToggleButton>
        </Grid>
        <Grid item>
          <OPToggleButton value={undefined} onClick={onReset}>
            <ClearIcon fontSize="small" />
            <OPLightTypograpghy>{t('app.php.what.tradeIdeas.ideas.clearAll')}</OPLightTypograpghy>
          </OPToggleButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
