import { Sector, Sectors } from '../../models';

import { selector } from 'recoil';
import { tradesCachedState } from '.';

export const sectorsStates = selector({
  key: 'sectorsState',
  get: ({ get }) => {
    const trades = get(tradesCachedState);
    if (!trades) {
      return new Sectors([]);
    }
    const sectors: Sector[] = [];
    trades.sectors.forEach((s) => {
      s && sectors.push(Sector.fromText(s));
    });
    return new Sectors(sectors);
  },
});
