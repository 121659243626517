import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Account } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { ProfileDetailsService } from '@op/shared/src/services';
import { accountState, fetchProfile, profileCachedState, selectedSymbolState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayDivider, OptionsPlayTextField, OptionsPlayLebal } from '../styled';

export const ProfileTabsWidget: React.FC = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [account, setAccount] = useRecoilState(accountState);
  const [profile, setProfile] = useRecoilState(profileCachedState);
  const { t } = React.useContext(LocalizationContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setProfile({ ...profile, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    (async () => {
      const profile = await fetchProfile();
      if (profile) {
        setProfile(profile);
      }
    })();
  }, []);

  const logActivity = (controlType: string, controlName: string, value?: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Submit,
      selectedSymbol as string,
    );
  };

  const handleSubmit = async (): Promise<void> => {
    if (!profile.displayName.trim()) {
      setNotifications([{ type: 'error', content: 'Display Name Required' }]);
    } else if (!profile.firstName.trim()) {
      setNotifications([{ type: 'error', content: 'First Name Required' }]);
    } else if (!profile.lastName.trim()) {
      setNotifications([{ type: 'error', content: 'Last Name Required' }]);
    } else if (!profile.status.trim()) {
      setNotifications([{ type: 'error', content: 'Status Name Required' }]);
    } else if (!profile.email.trim()) {
      setNotifications([{ type: 'error', content: 'Email Name Required' }]);
    } else {
      logActivity('FORM', 'profileSettingsUpdateButton', profile);
      const response = await ProfileDetailsService.instance.UpdateProfile(profile);
      if (response) {
        const clonedAccount = Account.fromSelf(account);
        if (!clonedAccount || !clonedAccount.securityModel) {
          return;
        }
        clonedAccount.securityModel.displayName = response.displayName || clonedAccount.securityModel.displayName;
        setAccount(clonedAccount);
        setNotifications([{ type: 'success', content: 'Profile updated successfully' }]);
      }
    }
  };

  return (
    <Card>
      <CardHeader title={t('app.php.profile.general.titles.general')} style={{ paddingBottom: 0 }} />
      <OptionsPlayDivider />
      <CardContent>
        <Grid container spacing={2}>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('app.php.profile.general.labels.displayName')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              id="displayName"
              name="displayName"
              onChange={handleChange}
              value={profile.displayName}
              variant="outlined"
              size="small"
            />
          </Grid>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('app.php.common.inputs.firstName')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              id="firstName"
              name="firstName"
              onChange={handleChange}
              value={profile.firstName}
              variant="outlined"
              size="small"
            />
          </Grid>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('app.php.common.inputs.lastName')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              id="lastName"
              name="lastName"
              onChange={handleChange}
              value={profile.lastName}
              variant="outlined"
              size="small"
            />
          </Grid>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('app.php.profile.general.labels.status')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              name="status"
              id="status"
              onChange={handleChange}
              value={profile.status}
              variant="outlined"
              size="small"
              disabled={true}
            />
          </Grid>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('app.php.profile.general.labels.registered')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              label={t('app.php.profile.general.labels.registered')}
              id="registered"
              name="registered"
              onChange={handleChange}
              value={profile.registrationDate || ''}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardHeader title={t('app.php.profile.general.titles.contact')} style={{ paddingBottom: 0 }} />
      <OptionsPlayDivider />
      <CardContent>
        <Grid container spacing={2}>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('app.php.common.inputs.email')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              name="email"
              id="email"
              onChange={handleChange}
              value={profile.email}
              variant="outlined"
              size="small"
              disabled={true}
            />
          </Grid>
          {/* TODO:Phone number will be uncommented after DB Changes */}
          {/* <OptionsPlayLebal item xs={3}>
            <Typography>{t('app.php.common.inputs.phone')}</Typography>
          </OptionsPlayLebal> */}
          {/* <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              name="phone"
              id="phone"
              onChange={handleChange}
              variant="outlined"
              size="small"
            />
          </Grid> */}
        </Grid>
      </CardContent>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button id="pw-update" onClick={handleSubmit} color="primary" variant="contained">
          <Typography variant="button">{t('app.php.common.buttons.update')}</Typography>
        </Button>
      </Box>
    </Card>
  );
};
