import { Grid } from '@mui/material';
import React from 'react';
import { OPSemiBoldTypograpghy } from '../../styled';
import { ICreditSpreadsProps, IdeaProps } from '../pager';
import LocalizationContext from '../../react-i18next/localization-context';

export const StrategyTypeWidget: React.FC<IdeaProps<ICreditSpreadsProps>> = ({
  data,
}: IdeaProps<ICreditSpreadsProps>) => {
  const { t } = React.useContext(LocalizationContext);

  return (
    <Grid container>
      <OPSemiBoldTypograpghy>{t(`app.php.what.tradeIdeas.ideas.${data.strategy.toLowerCase()}${data.strategyType}`)}</OPSemiBoldTypograpghy>
    </Grid>
  );
};
