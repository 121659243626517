import { CircularProgress, Grid, IconButton, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { CachedSecurities, CachedSecurity, ICompanyLookup } from '@op/shared/src/models';
import { companyLookup, getCachedSecurities } from '@op/shared/src/services';
import { accountState, cachedSecuritiesDataState, configurationState } from '@op/shared/src/states';
import axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FlagWidget, IconComponent } from '../common';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import discardSymbol from '@op/shared/assets/images/discardSymbol.svg';
import selectedsymbol from '@op/shared/assets/images/selectedsymbol.svg';
import { WatchList } from '@op/shared/src/models';
import formatting from '@op/shared/src/models/how/formatting';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { debounce } from 'lodash';
import { OptionsPlayDivider, OptionsPlayTextField } from '../styled/options-play-components';

interface ICompanySearchProps {
  onSymbolSelected: (value: string) => void;
  isQuoteFound?: (value: boolean) => void;
  sx?: SxProps<Theme>;
  message?: string;
  removeWatchList?: (symbol: string | undefined) => void;
  watchList?: WatchList;
}

export const CompanySearchWidget: React.FC<ICompanySearchProps> = ({
  onSymbolSelected,
  isQuoteFound,
  sx,
  message,
  removeWatchList,
  watchList,
}: ICompanySearchProps) => {
  const theme = useTheme();
  const account = useRecoilValue(accountState);
  const configuration = useRecoilValue(configurationState);
  const [cachedSecuritiesData, setCachedSecuritiesData] = useRecoilState(cachedSecuritiesDataState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [searchResults, setSearchResults] = useState<ICompanyLookup[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const isWatchlist = watchList && watchList.id ? true : false;

  let autoCompleteCancelToken: CancelTokenSource | undefined = undefined;

  const fetchCachedSecurities = async () => {
    const cachedSecuritiesRes = await getCachedSecurities(configuration?.additionalData?.allowedSecurities);
    if (cachedSecuritiesRes.hasErrors || !cachedSecuritiesRes.data) {
      return;
    }
    const cachedSecuritiesData = CachedSecurities.fromData(cachedSecuritiesRes.data);
    return cachedSecuritiesData;
  };

  useEffect(() => {
    if (!account || cachedSecuritiesData) {
      setEmbeddedSearchResults();
      return;
    }
    (async () => {
      const fetchedCachedSecuritesData = await fetchCachedSecurities();
      if (!fetchedCachedSecuritesData) {
        return;
      }
      setCachedSecuritiesData(fetchedCachedSecuritesData);
      setEmbeddedSearchResults();
    })();
  }, [account]);

  const setEmbeddedSearchResults = () => {
    //this logic should move to customization
    if (
      !configuration ||
      !configuration.isEmbeddingPlatform ||
      !cachedSecuritiesData ||
      !configuration.additionalData?.allowedSecurities
    ) {
      return;
    }
    setSearchResults(cachedSecuritiesData.data as unknown as ICompanyLookup[]);
  };

  const onChangeOfAutoCompleteTextbox = async (searchTerm: string) => {
    //Check if there are any previous pending requests
    if (typeof autoCompleteCancelToken != typeof undefined) {
      autoCompleteCancelToken?.cancel('Operation canceled due to new request.');
    }
    //Save the cancel token for the current request
    autoCompleteCancelToken = axios.CancelToken.source();
    setLoading(true);
    const companies = await fetchCompanies(searchTerm);
    if (companies === undefined) {
      setSearchResults([]);
      setLoading(false);
      return;
    }
    if (!cachedSecuritiesData) {
      setSearchResults(companies as unknown as ICompanyLookup[]);
      setLoading(false);
      return;
    }
    if (searchTerm.trim().length === 0 && configuration?.isEmbeddingPlatform) {
      setSearchResults(cachedSecuritiesData.data as unknown as ICompanyLookup[]);
      setLoading(false);
      return;
    }
    const result = CachedSecurities.fuzzySearch(searchTerm);
    if (!result) {
      findSymbolFromCachedSecurities(inputValue);
      setSearchResults([]);
      setLoading(false);
      return;
    }
    setSearchResults(result as unknown as ICompanyLookup[]);
    setLoading(false);
  };

  const debouncedOnChangeOfAutoCompleteTextbox = useMemo(
    () => debounce(onChangeOfAutoCompleteTextbox, 200),
    [cachedSecuritiesData],
  );

  const fetchSecuritiesByCompanyLookup = async (searchTerm: string) => {
    const fetchedCompanyLookup = await companyLookup(searchTerm, { cancelToken: autoCompleteCancelToken?.token });
    if (fetchedCompanyLookup.hasErrors || !fetchedCompanyLookup.data) {
      return undefined;
    }
    return fetchedCompanyLookup.data;
  };

  const fetchCompanies = async (searchTerm: string) => {
    if (!cachedSecuritiesData) {
      const values = await fetchSecuritiesByCompanyLookup(searchTerm);
      return values;
    }
    return cachedSecuritiesData.data;
  };

  const onSymbolSelect = async (symbol: string) => {
    isQuoteFound(true);
    setInputValue('');
    onSymbolSelected(symbol);
  };

  const onSelectSymbolWithExchangeCode = (item: CachedSecurity) => {
    /**
     * TODO : In GetSymbolDotExchange added the condition for appending the exchange code.
     * Need to move the code to some other place.
     */
    const symbolExchangeCode = formatting.getSymbolDotExchange(item.symbol, item.exchangeCode);
    onSymbolSelect(symbolExchangeCode);
  };

  const onChangeOfAutoCompleteSelection = (companyLookupItem: string | ICompanyLookup | null) => {
    if (typeof companyLookupItem === 'string' || companyLookupItem instanceof String) {
      return;
    }
    if (companyLookupItem && companyLookupItem.symbol) {
      onSelectSymbolWithExchangeCode(companyLookupItem as CachedSecurity);
      return;
    }
    isQuoteFound(false);
  };

  const onPressEnter = async () => {
    if (!inputValue || inputValue.trim() === '') {
      return;
    }
    setInputValue(inputValue);
    findSymbolFromCachedSecurities(inputValue);
  };

  const findSymbolFromCachedSecurities = (value: string) => {
    if (!cachedSecuritiesData || !cachedSecuritiesData.data) {
      return;
    }
    const item = cachedSecuritiesData.data.find((i) => i.symbol.toLocaleUpperCase() === value.toUpperCase());
    if (!item) {
      //OnEnter functionality for not listed can be just return
      setNotifications([{ type: 'error', content: 'Quote Not Found' }]);
      return;
    }
    const symbolExchangeCode = formatting.getSymbolDotExchange(item.symbol, item.exchangeCode);
    onSymbolSelect(symbolExchangeCode);
  };

  const clearState = () => {
    isQuoteFound(true);
    setInputValue('');
    setSearchResults([]);
  };

  const renderWatchListSearchItem = (option: ICompanyLookup) => {
    if (!watchList) {
      return;
    }
    const isQuoteExist = watchList.quotes.find(
      (q) =>
        q.symbol.trim().toUpperCase() ===
        formatting.getSymbolDotExchange(option.symbol, option.exchangeCode).trim().toUpperCase(),
    );
    return (
      <Grid
        container
        id={option.symbol}
        data-name="SymbolInput"
        xs={12}
        style={{ width: '100%' }}
        data-value={`${option.symbol} selected from Quotebar`}
        justifyContent={'space-between'}
        alignItems="center">
        <Grid container item xs={8} justifyContent={'space-between'} alignItems="center">
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item xs={7}>
              <Typography variant="body1" fontWeight="bold" lineHeight={1}>
                {option.symbol}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <FlagWidget exchange={option.exchangeCode} variant="square" />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            xs={11}
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
              {option.name}
            </Typography>
          </Grid>
        </Grid>
        {isQuoteExist ? (
          <Grid item xs={4} container justifyContent={'space-between'} alignItems="center">
            <Grid item xs={6}>
              <IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }} onClick={() => {}} size="small">
                <img src={selectedsymbol} alt="selectedSymbol" />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  removeWatchList && removeWatchList(isQuoteExist.symbol);
                }}
                size="small">
                <img src={discardSymbol} alt="discardSymbol" />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs={2}
            container
            onClick={(e) => {
              e.stopPropagation();
              onChangeOfAutoCompleteSelection(option);
            }}>
            <IconComponent name="defaultAddIcon" hoverIcon="blueFilledAddIcon" size={20} />
          </Grid>
        )}
      </Grid>
    );
  };

  const renderSearchItem = (option: ICompanyLookup) => {
    if (!isWatchlist) {
      return (
        <Grid
          container
          id={option.symbol}
          data-name="SymbolInput"
          data-value={`${option.symbol} selected from Quotebar`}>
          <Grid item container xs={12} justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold" lineHeight={1}>
              {option.symbol}
            </Typography>
            <FlagWidget exchange={option.exchangeCode} variant="square" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" sx={{ textTransform: 'uppercase' }} lineHeight={1}>
              {option.name}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return renderWatchListSearchItem(option);
  };

  const renderEditableViewIfPossible = () => {
    const openVal = configuration?.additionalData?.allowedSecurities?.length > 0 || inputValue.length >= 1;
    return (
      <Autocomplete
        id="search-text"
        // disableClearable
        // freeSolo
        fullWidth
        // clearOnBlur
        value={inputValue}
        autoHighlight
        size="small"
        open={openVal}
        options={searchResults}
        getOptionLabel={(option: any) =>
          // company + symbol name requried for searching company name as well.
          option && option.symbol && `${option.symbol}+${option.name}` ? `${option.symbol}+${option.name}` : inputValue
        }
        onChange={(_event, value, reason) => {
          onChangeOfAutoCompleteSelection(value);
        }}
        onInputChange={(e, value, reason) => {
          /**
           * Below code automatically clears the text field on symbol select through local state
           */
          if (reason === 'reset') {
            setInputValue('');
            return;
          } else {
            setInputValue(value);
            debouncedOnChangeOfAutoCompleteTextbox(value);
          }
        }}
        loading={loading}
        loadingText={'Loading symbols...'}
        noOptionsText={'No symbol found'}
        sx={sx}
        renderOption={(props, option, _state) => {
          return (
            <li {...props} key={_state.index}>
              {renderSearchItem(option)}
            </li>
          );
        }}
        renderInput={(params) => {
          const variant = isWatchlist ? 'outlined' : 'standard';
          return (
            <OptionsPlayTextField
              {...params}
              autoFocus
              variant={variant as any}
              onBlur={clearState}
              placeholder={isWatchlist ? '+ Search and add symbol' : message ?? 'Search symbol'}
              name="searchname"
              // On Click Enter Symbol should load before Autocomplete open
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onPressEnter();
                }
              }}
              style={{ height: '100%' }}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                style: {
                  padding: 0,
                  paddingLeft: 4,
                  height: isWatchlist ? 35 : '100%',
                  color: theme.palette.selectAndTextField.main,
                },
                endAdornment: (
                  //  params.InputProps.endAdornment
                  <React.Fragment>
                    <IconButton size="small" onClick={() => clearState()}>
                      {loading ? (
                        <CircularProgress color="inherit" size={18} />
                      ) : inputValue?.length >= 1 ? (
                        <ClearOutlinedIcon sx={{ fontSize: 18, color: theme.palette.info.light }} />
                      ) : null}
                    </IconButton>
                  </React.Fragment>
                ),
                disableUnderline: true,
              }}
              inputProps={{
                ...params.inputProps,
                style: { textTransform: 'uppercase' },
              }}
            />
          );
        }}
        ListboxProps={{
          style: {
            maxHeight: '450px',
          },
        }}
      />
    );
  };

  return (
    <div id="quotebar-search" style={{ height: '100%' }}>
      {renderEditableViewIfPossible()}
    </div>
  );
};
