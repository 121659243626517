import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { OPDialogWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { useOPNavigate } from '../states/use-navigate-search';

export const RegistrationDialogue: React.FC = () => {
  const [open, setOpen] = React.useState(true);
  const { t } = React.useContext(LocalizationContext);
  const navigate = useOPNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const onContinue = () => {
    setOpen(false);
    navigate('/login');
  };

  const renderDialogAction = () => {
    return (
      <div>
        <Button
          autoFocus
          onClick={() => onContinue()}
          variant="contained"
          color="primary"
          sx={{ alignItems: 'center', backgroundColor: '#7ac043' }}>
          <Typography variant="button">{t('app.php.common.buttons.continue')}</Typography>
        </Button>
      </div>
    );
  };
  const renderDialogContent = () => {
    return (
      <div>
        <Typography gutterBottom>
          Your account has been created. Please check your email for the verification link we just sent to you.
        </Typography>
        <Typography gutterBottom>
          <strong>Click that link to get started!</strong> As a member of our community, you’ll have access all the
          trade ideas, expert guidance and income generation strategies available through OptionsPlay Ideas.
        </Typography>
        <Typography gutterBottom>We look forward to seeing you!</Typography>
      </div>
    );
  };
  const renderDialogTitle = () => {
    return (
      <Typography variant="h6" id="customized-dialog-title">
        You&#39;re almost there!
      </Typography>
    );
  };
  return (
    <div>
      <OPDialogWidget
        open={open}
        onHandleClose={handleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
      />
    </div>
  );
};
