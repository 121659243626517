import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import CalendarIcon from '@op/shared/assets/images/calendar.svg';
import ChechIcon from '@op/shared/assets/images/checkIcon.svg';
import CreditCard from '@op/shared/assets/images/credit-card-blank.png';
import { EventType, SubscriptionStatus } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { currentSubscriptionScreenState, currentSubscriptionState, selectedSymbolState } from '@op/shared/src/states';
import React, { ReactNode } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent } from '../../common';
import { LanguageSelectorWidget } from '../../header';
import LocalizationContext from '../../react-i18next/localization-context';
import { OPBoldTypograpghy, OPLightTypograpghy, OptionsPlayDivider } from '../../styled';
import { BillingHistory } from './billing-history';
import { CreditCardTypeWidget } from './credit-card-type-widget';
import { SubscriptionCardLayout } from './subscription-card-layout';
import { SubscriptionFooterWidget, SubscriptionScannerFooter } from './subscription-footer-widget';

export const SubscriptionCardDetails: React.FC<{
  label: any;
  value: ReactNode;
  cancelled?: boolean;
}> = ({ label, value, cancelled }: { label: string; value: ReactNode; cancelled?: boolean }) => {
  const theme = useTheme();

  const colorValue = () => {
    if (cancelled) {
      return theme.palette.error.main;
    }
    if (label === 'Discount') {
      return theme.palette.success.main;
    }
    return theme.palette.mode === 'light' ? theme.palette.grey[600] : '#999999';
  };

  return (
    <Grid item container justifyContent="space-between" alignItems={'center'}>
      <Grid item xs={6}>
        <OPLightTypograpghy style={{ color: colorValue(), fontSize: '14px' }}>{label}</OPLightTypograpghy>
      </Grid>
      <Grid item xs={6} textAlign="right">
        <OPBoldTypograpghy style={{ color: colorValue(), fontSize: '14px' }}>{value}</OPBoldTypograpghy>
      </Grid>
    </Grid>
  );
};
export const SubscriptionCurrentWidget: React.FC = () => {
  const CurrentSubscriptionData = useRecoilValue(currentSubscriptionState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setCurrentSubscriptionScreen = useSetRecoilState(currentSubscriptionScreenState);
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);

  if (!CurrentSubscriptionData) {
    return null;
  }

  let expiryDate: Date = new Date();

  if (
    CurrentSubscriptionData &&
    CurrentSubscriptionData.currentSubscription &&
    CurrentSubscriptionData.currentSubscription?.expirationDate
  ) {
    expiryDate = new Date(CurrentSubscriptionData.currentSubscription?.expirationDate);
  }
  const billingModel = CurrentSubscriptionData.currentSubscription?.billingModel;
  const price = CurrentSubscriptionData.currentSubscription?.price;
  const subscriptionstatus = CurrentSubscriptionData.currentSubscription?.subscriptionStatus;
  const startDate = CurrentSubscriptionData.currentSubscription.startDate;
  const paymentDate = CurrentSubscriptionData.currentSubscription.createdDate;
  const endDate = CurrentSubscriptionData.currentSubscription.expirationDate;
  const activeCardHeader = `${t('app.php.subscriptions.activePlan')} (${t('app.php.subscriptions.subscription')})`;

  const managePayment = () => {
    logActivity('BUTTON', 'paymentsChangeSubscriptionButton');
    setCurrentSubscriptionScreen('changeInformation');
  };
  const cancelPlan = () => {
    logActivity('BUTTON', 'paymentsCancelSubscriptionButton');
    setCurrentSubscriptionScreen('cancelsubscription');
  };
  const subscribePlan = () => {
    logActivity('BUTTON', 'paymentsAddSubscriptionButton');
    setCurrentSubscriptionScreen('SubscriptionWidget');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const renderCardHeader = (type: string) => {
    return (
      <Grid container justifyContent="space-between" alignItems="center" style={{ color: '#ffffff' }}>
        <Grid item xs={8}>
          {type === 'active' ? (
            <OPBoldTypograpghy style={{ fontSize: '16px' }}>{activeCardHeader}</OPBoldTypograpghy>
          ) : (
            <OPBoldTypograpghy style={{ fontSize: '16px' }}>{t('app.php.subscriptions.nextPayment')}</OPBoldTypograpghy>
          )}
        </Grid>
        <Grid item xs={4} container alignItems="center" justifyContent="right">
          <OPBoldTypograpghy sx={{ marginRight: '3px' }}>
            {type === 'active' ? `${t('app.php.subscriptions.cancelled')}` : `${t('app.php.subscriptions.stopped')}`}
          </OPBoldTypograpghy>
          <IconComponent name="warning" stroke={'#ffffff'} />
        </Grid>
      </Grid>
    );
  };

  const activePlanContent = () => {
    return (
      <Grid container rowSpacing={1}>
        <SubscriptionCardDetails label={t('app.php.subscriptions.typeOfPlan')} value={billingModel} />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.region')}
          value={<LanguageSelectorWidget iconView={'subscription'} />}
        />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.startDate')}
          value={DateTimeHelper.format(startDate)}
        />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.endDate')}
          value={DateTimeHelper.format(endDate)}
          cancelled={subscriptionstatus === SubscriptionStatus.CANCELED_NOT_EXPIRED}
        />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.paymentDate')}
          value={DateTimeHelper.format(paymentDate)}
        />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.cardPay')}
          value={
            <CreditCardTypeWidget
              cardNumber={CurrentSubscriptionData.currentSubscription?.billingInfo?.cardNumber}
              textView={'onlyNumber'}
            />
          }
        />
        <Grid item xs={12}>
          <OptionsPlayDivider />
        </Grid>
        <Grid item container justifyContent="space-between" alignItems={'center'}>
          <Grid item xs={6}>
            <OPBoldTypograpghy
              style={{ color: theme.palette.mode === 'light' ? theme.palette.grey[800] : '#fff', fontSize: '14px' }}>
              {t('app.php.subscriptions.totalPayment')}
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <OPBoldTypograpghy
              style={{ color: theme.palette.mode === 'light' ? theme.palette.grey[800] : '#fff', fontSize: '14px' }}>
              {NumberFormatHelper.toCurrency(price)}
            </OPBoldTypograpghy>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const nextPaymentContent = () => {
    const cardNumber = CurrentSubscriptionData.currentSubscription?.billingInfo?.cardNumber;
    if (!cardNumber) {
      return;
    }
    const lastDigitsCard = cardNumber.substring(cardNumber.length - 4);
    return (
      <Grid container>
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.dueDate')}
          value={
            <Grid item container xs={12} justifyContent="right">
              <img src={CalendarIcon} alt="" style={{ marginRight: '8px', width: '18px', height: '18px' }} />
              <Typography>{DateTimeHelper.format(endDate)}</Typography>
            </Grid>
          }
          // value={DateTimeHelper.format(endDate)}
          cancelled={subscriptionstatus === SubscriptionStatus.CANCELED_NOT_EXPIRED}
        />
        <Grid item container justifyContent="space-between" pt={1}>
          <Grid item xs={6} alignItems="flex-start">
            <OPLightTypograpghy
              style={{ color: theme.palette.mode === 'light' ? theme.palette.grey[600] : '#999999', fontSize: '14px' }}>
              {t('app.php.subscriptions.activeCard')}
            </OPLightTypograpghy>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Grid item>
              <div style={{ position: 'relative' }}>
                <img src={CreditCard} alt="" style={{ height: '65px', width: '120px', marginTop: '8px' }} />
                <OPBoldTypograpghy
                  style={{ position: 'absolute', bottom: ' 15px', right: ' 10px', fontSize: '8px', color: '#fff' }}>
                  {` **${lastDigitsCard}`}
                </OPBoldTypograpghy>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderCurrentSubscriptionView = () => {
    return (
      <Grid container rowSpacing={6} padding={2}>
        <Grid container item rowSpacing={2} padding={2}>
          <Grid item xs={12} container columnSpacing={2} justifyContent="space-between">
            <Grid item xs={7}>
              <SubscriptionCardLayout
                title={
                  <Grid item container xs={12} justifyContent="space-between">
                    <OPBoldTypograpghy style={{ fontSize: '16px' }}>{activeCardHeader}</OPBoldTypograpghy>
                    <img src={ChechIcon} alt="" />
                  </Grid>
                }
                content={activePlanContent()}
              />
            </Grid>
            <Grid item xs={5}>
              <Box
                sx={{
                  backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.light : '#11181e',
                  borderRadius: 2,
                }}>
                <SubscriptionCardLayout
                  title={
                    <Grid item container xs={12} justifyContent="space-between">
                      <OPBoldTypograpghy style={{ fontSize: '16px' }}>
                        {t('app.php.subscriptions.nextPayment')}
                      </OPBoldTypograpghy>
                    </Grid>
                  }
                  content={nextPaymentContent()}
                  sx={{
                    boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
                    '&:last-child': {
                      paddingBottom: 2,
                    },
                  }}
                />
                <Typography
                  style={{
                    padding: '10px',
                    fontWeight: 400,
                    fontSize: '12px',
                    color: theme.palette.mode === 'light' ? theme.palette.grey[600] : '#999999',
                  }}>
                  {`${t('app.php.subscriptions.noteToKnowAutomaticRenew')} ${DateTimeHelper.format(endDate)}. ${t(
                    'app.php.subscriptions.ensuresUninterreptedAccess',
                  )}`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} container columnSpacing={3}>
            <Grid item xs={7} container columnSpacing={2}>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  onClick={subscribePlan}
                  id="pw-subscribe"
                  color="primary"
                  variant="contained"
                  size="large">
                  <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                    {t('app.php.subscriptions.changePlan')}
                  </OPBoldTypograpghy>
                </Button>
              </Grid>
              <Grid item xs={7}>
                <Button
                  fullWidth
                  sx={{ '&:hover': { backgroundColor: theme.palette.error.main } }}
                  onClick={cancelPlan}
                  id="pw-subscribe"
                  color="primary"
                  size="large"
                  variant="contained">
                  <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                    {t('app.php.subscriptions.cancelSubscription')}
                  </OPBoldTypograpghy>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={5} container>
              <Button
                fullWidth
                onClick={managePayment}
                size="large"
                id="pw-subscribe"
                color="primary"
                variant="contained">
                <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                  {t('app.php.subscriptions.manageBillingAddress')}
                </OPBoldTypograpghy>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container rowSpacing={1} marginX={2}>
          <Grid item container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: 16 }}>
                {t('app.php.subscriptions.billingHistory')}
              </OPBoldTypograpghy>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BillingHistory />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const getDiscountString = () => {
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.currentPricingModel &&
      (CurrentSubscriptionData.currentPricingModel.discountMonthlyRate > 0 ||
        CurrentSubscriptionData.currentPricingModel.discountAnnualRate > 0)
    ) {
      return `${CurrentSubscriptionData.currentPricingModel.discountAnnualRate}% off`;
    }
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.pricingModels &&
      CurrentSubscriptionData.pricingModels.length > 0 &&
      (CurrentSubscriptionData.pricingModels[0].discountMonthlyRate > 0 ||
        CurrentSubscriptionData.pricingModels[0].discountAnnualRate > 0)
    ) {
      return `${CurrentSubscriptionData.pricingModels[0].discountAnnualRate}s% off`;
    }
    return '';
  };
  const renderCanceledNotExpired = () => {
    return (
      <Grid container rowSpacing={6} padding={2}>
        <Grid container item xs={12} rowSpacing={2}>
          <Grid item container columnSpacing={2} xs={12}>
            <Grid item xs={8}>
              <OPBoldTypograpghy
                style={{
                  color: theme.palette.error.main,
                  fontSize: '14px',
                  fontWeight: 800,
                }}>
                {t('app.php.subscriptions.chosenToCancelSubscription')}
              </OPBoldTypograpghy>
              <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                {t('app.php.subscriptions.whichEndsOn')} {DateTimeHelper.format(expiryDate)}.{' '}
                {t('app.php.subscriptions.uninterruptedService')}
              </OPBoldTypograpghy>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                onClick={subscribePlan}
                id="pw-subscribe"
                color="success"
                size="large"
                variant="contained">
                <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                  {`${t('app.php.subscriptions.subscribeNow')} ${getDiscountString()}`}
                </OPBoldTypograpghy>
              </Button>
            </Grid>
          </Grid>
          <Grid item container columnSpacing={3} xs={12}>
            <Grid item xs={7}>
              <SubscriptionCardLayout
                sx={{ borderRadius: 2 }}
                title={renderCardHeader('active')}
                content={activePlanContent()}
                cancelled
              />
            </Grid>
            <Grid item container rowSpacing={2} xs={5}>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.light : '#11181e',
                    borderRadius: 2,
                  }}>
                  <SubscriptionCardLayout
                    title={renderCardHeader('next')}
                    content={nextPaymentContent()}
                    cancelled
                    sx={{
                      boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
                      '&:last-child': {
                        paddingBottom: 2,
                      },
                    }}
                  />
                  <Typography
                    style={{
                      padding: '10px',
                      fontWeight: 400,
                      fontSize: '12px',
                      color: theme.palette.mode === 'light' ? theme.palette.grey[600] : '#999999',
                    }}>
                    {`${t('app.php.subscriptions.noteToKnowAutomaticRenew')} ${DateTimeHelper.format(endDate)}. ${t(
                      'app.php.subscriptions.ensuresUninterreptedAccess',
                    )}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container columnSpacing={3}>
            <Grid item xs={7} container columnSpacing={2}>
              <Grid item xs={6}>
                <Button fullWidth onClick={subscribePlan} id="pw-subscribe" size="large" variant="contained">
                  <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                    {t('app.php.subscriptions.changePlan')}
                  </OPBoldTypograpghy>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  onClick={subscribePlan}
                  id="pw-subscribe"
                  color="success"
                  size="large"
                  variant="contained">
                  <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                    {`${t('app.php.subscriptions.subscribeNow')} `}
                  </OPBoldTypograpghy>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={5} container>
              <Button
                fullWidth
                id="pw-subscribe"
                onClick={managePayment}
                color="primary"
                variant="contained"
                size="large"
                disabled>
                <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                  {t('app.php.subscriptions.manageBillingAddress')}
                </OPBoldTypograpghy>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container spacing={2} xs={12}>
          <Grid item container xs={12} justifyContent="space-between">
            <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: 16, marginLeft: '16px' }}>
              {t('app.php.subscriptions.billingHistory')}
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={12} width="100%">
            <BillingHistory />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderActiveSuspendedSubscription = () => {
    return (
      <Grid container p={1}>
        <Grid item xs={12}>
          <Typography>
            Your current <b>{billingModel}</b> subscription is suspended, and your access to OptionsPlay ends on{' '}
            {DateTimeHelper.format(expiryDate)}.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {`This might be because your credit card on file has expired or we couldn't complete the transaction with your
            credit card provider. To restore your service, please contact us or add a new subscription.`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button onClick={subscribePlan} id="pw-subscribe" size="large" color="primary" variant="contained">
              <Typography variant="button">{t('app.php.profile.payments.buttons.subscribe')}</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderNoSubscriptionView = () => {
    return (
      <Typography>
        You might not have permission to access this feature. Please contact{' '}
        <a href="mailto:support@optionsplay.com">support@optionsplay.com</a>
      </Typography>
    );
  };

  const renderView = () => {
    switch (subscriptionstatus) {
      case SubscriptionStatus.ACTIVE:
        return renderCurrentSubscriptionView();
      case SubscriptionStatus.CANCELED_NOT_EXPIRED:
        return renderCanceledNotExpired();
      // For the expired user and activeTrial User, need to show choose-plan widget directly
      case SubscriptionStatus.EXPIRED:
        setCurrentSubscriptionScreen('SubscriptionWidget');
        break;
      case SubscriptionStatus.ACTIVE_TRIAL:
        setCurrentSubscriptionScreen('SubscriptionWidget');
        break;
      case SubscriptionStatus.EXPIRED_TRIAL:
      case SubscriptionStatus.EXPIRED_EXTENDED_TRIAL:
        setCurrentSubscriptionScreen('SubscriptionWidget');
        break;
      case SubscriptionStatus.SUSPENDED_NOT_EXPIRED:
      case SubscriptionStatus.SUSPENDED:
        return renderActiveSuspendedSubscription();
      default:
        return renderNoSubscriptionView();
    }
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: 16, padding: 5 }}>
          {t('app.php.subscriptions.subscription')}
        </OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12}>
        {renderView()}
      </Grid>
      <Grid item xs={12}>
        <SubscriptionFooterWidget />
      </Grid>
      <Grid item xs={12}>
        <SubscriptionScannerFooter />
      </Grid>
    </Grid>
  );
};
