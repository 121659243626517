import { PortfolioAlert } from '../../hubs/portfolio-alert';
import { IAlertTitle, IPortfolioAlertResource } from '../configuration';
import { LegType, RollPositionWhen, StrategyType } from '../enums/enums';
import { ICallOptimal, IPutOptimal, Leg } from '../how';
import ApplicationContext from '../how/application-context';
import { BasicPositionModel } from '../how/basic-position-model';
import { CoveredCallExtensions } from '../how/coveredCallExtensions';
import DateTimeHelper from '../how/date-time-helper';
import formatting from '../how/formatting';
import HowDataModel from '../how/how-data-model';
import { PortfolioCombination } from './portfolio-combination-model';

export class PortfolioAlertModel {
  private static generateOptimalCall = (howData: HowDataModel, portfolioCombination: PortfolioCombination) => {
    const callOptimal = PortfolioAlertModel.findOptimalCoveredCallByPriority(howData);
    if (!callOptimal) {
      return [];
    }
    return PortfolioAlertModel.generateOptimalCallLeg(callOptimal, portfolioCombination);
  };

  private static findOptimalCoveredCallByPriority = (howData: HowDataModel) => {
    if (!howData) {
      throw new Error('configuration or howData is undefined');
    }
    const defaultTimeFrameForCall =
      ApplicationContext.configuration.applicationConfiguration.coveredCall.call.timeFrame;
    const defaultAggressivenessForCall =
      ApplicationContext.configuration.applicationConfiguration.coveredCall.call.aggressiveness;
    const priorityForCall = CoveredCallExtensions.getPriorityByTimeFrameAndAggressiveness(
      defaultTimeFrameForCall,
      defaultAggressivenessForCall,
    );

    return CoveredCallExtensions.findOptimal(howData.callOptimals, priorityForCall);
  };

  private static generateOptimalCallLeg = (callOptimal: ICallOptimal, portfolioCombination: PortfolioCombination) => {
    if (!callOptimal || !portfolioCombination) {
      return undefined;
    }
    const multiplier = portfolioCombination.originalMultiplier;
    const quantity = !portfolioCombination.hasOnlyStx()
      ? portfolioCombination.absQuantity()
      : Math.floor(portfolioCombination.absQuantity() / multiplier) || 1;
    const leg: any = {
      quantity: -quantity,
      legType: callOptimal.legType,
      strikePrice: callOptimal.strike,
      premium: callOptimal.premium,
      expiry: new Date(callOptimal.expiry),
      bid: callOptimal.bid,
      ask: callOptimal.ask,
    };
    return [leg];
  };

  private static getNashvillePositionAlert = (
    alert: PortfolioAlert,
    howData: HowDataModel,
    portfolioCombination: PortfolioCombination,
  ) => {
    const additionalAlertData = alert.additionalAlertData;
    if (additionalAlertData.hasOptimalCall) {
      return PortfolioAlertModel.getRollPositionLegsForOptimalCall(howData, portfolioCombination);
    }
    if (additionalAlertData.hasAlternativeCall) {
      if (additionalAlertData.rollPositionWhen === RollPositionWhen.OPTIMAL_ONLY) {
        return PortfolioAlertModel.getLegsToClosePositionAlert(portfolioCombination);
      } else {
        return PortfolioAlertModel.getRollPositionLegsForOptimalCall(howData, portfolioCombination);
      }
    }
    return PortfolioAlertModel.getLegsToClosePositionAlert(portfolioCombination);
  };

  static getOptimalLeg = (
    howData: HowDataModel,
    portfolioCombination: PortfolioCombination,
    rollOptimal: ICallOptimal | IPutOptimal | undefined,
    result: Leg[],
  ) => {
    if (!rollOptimal || !howData.chain) {
      return;
    }
    const row = howData.chain.findRow(
      rollOptimal.strike.toString(),
      DateTimeHelper.resolveDate(rollOptimal.expiry),
      portfolioCombination.optionType,
    );
    if (!row) {
      return;
    }
    const template = portfolioCombination.matchedTemplate();
    if (!template) {
      return;
    }
    const legType =
      template.template.name === StrategyType.CoveredCall
        ? LegType.CALL
        : template.template.name === StrategyType.Put
        ? LegType.PUT
        : undefined;
    /**
     * For Sign value need to take opposite of invertedLeg
     * for negative quantity => it should be BUY(+ sign)
     * for Positive quantity => it should be SELL(- sign)
     */
    const sign = Math.sign(result[0].quantity) == -1 ? 1 : -1; //portfolioCombination.nameInPortfolio.includes('Short') ? -1 : 1;
    const quantity = portfolioCombination.absQuantity();
    /**
     * Portfolio uses only Mid Prices
     * Thus, taking only CallMid and PutMid instead of Bid/Ask
     */
    return {
      legType: legType,
      quantity: sign * quantity,
      expiry: row.expiry,
      strikePrice: row.strike,
      costBasis: undefined,
      hash: undefined,
      costBasisPerUnit: undefined,
      premium: legType === LegType.CALL ? row.callMid : row.putMid,
    } as Leg;
  };

  /**
   * TODO: This function gives roll legs for this combination. Similar as close legs.
   * The function should exist in PorfolioCombination.ts because it operates on combination data to get requried legs.
   * NOTE: PorfolioCombination presently do not filling OptionChain (which it should). Hence not moved today.
   * Please fix the OptionChain population and move this function in PorfolioCombination.
   */
  static getRollPositionLegsForOptimalCall = (
    howData: HowDataModel,
    portfolioCombination: PortfolioCombination,
    rollOptimal?: ICallOptimal | IPutOptimal | undefined,
  ) => {
    if (!howData.chain || !portfolioCombination.hasOption()) {
      return [];
    }

    if (!howData.chain || !portfolioCombination.hasOption()) {
      return [];
    }
    const optionPositions = portfolioCombination.positions.filter((p) => {
      return !p.isSecurityType();
    });

    const result = optionPositions.map((position) => {
      return position.getInvertedRawLeg();
    });
    if (rollOptimal && result && result.length == 1) {
      const optimalLeg = this.getOptimalLeg(howData, portfolioCombination, rollOptimal, result);
      if (optimalLeg) {
        const newLeg = Leg.fromSelf(optimalLeg);
        result.push(newLeg);
        return result;
      }
    }
    const expiryList = howData.chain.getFullExpiryList(portfolioCombination.optionType);
    let deltaExpiriesFound = false;
    let deltaExpiries = 0;
    let pos: BasicPositionModel;
    while (!deltaExpiriesFound && deltaExpiries < expiryList.length) {
      deltaExpiries += 1;
      deltaExpiriesFound = true;
      for (let i = 0; i < optionPositions.length; i++) {
        pos = optionPositions[i];
        const followingExpiry = howData.chain.findExpiry(pos.expiry, deltaExpiries);
        if (followingExpiry === pos.expiry) {
          deltaExpiriesFound = false;
          break;
        }
        const strikes = howData.chain.getStrikeListForExpiry(followingExpiry, portfolioCombination.optionType);
        if (!pos.strikePrice) {
          throw new Error('Strike price is undefined');
        }
        if (!strikes || strikes.indexOf(pos.strikePrice) === -1) {
          deltaExpiriesFound = false;
          break;
        }
      }
    }
    if (!deltaExpiriesFound) {
      deltaExpiries = 1;
      const expiries = portfolioCombination.expiries();
      if (expiries[expiries.length - 1].valueOf() === expiryList[expiryList.length - 1].valueOf()) {
        return [];
      }
    }

    for (let i = 0; i < optionPositions.length; i++) {
      const rawPos = optionPositions[i].getRawPositionWithoutCostBasis();
      rawPos.expiry = howData.chain.findExpiry(rawPos.expiry, deltaExpiries);
      const oldStrike = rawPos.strikePrice;
      if (!oldStrike) {
        throw new Error('oldStrike is undefined');
      }
      rawPos.strikePrice = howData.chain.findStrike(oldStrike, rawPos.expiry);
      result.push(rawPos);
    }
    return result || [];
  };

  /**
   * ARLINGTON : Optimal Covered Call Found
   * HOUSTON : Optimal Call Found
   * EDISON : Reduce Your Risk
   * CHARLOTTE : Stop Loss
   * DALLAS : Take Profit
   * LOUISVILLE : Take Profit
   * MINNEAPOLIS : Stop Loss
   */
  static getAlertLegs = (alert: PortfolioAlert, howData: HowDataModel, portfolioCombination: PortfolioCombination) => {
    try {
      const name = alert.key.trim().toUpperCase();
      if (name === '') {
        throw new Error('Alert name is required');
      }

      if (name === 'ARLINGTON' || name === 'HOUSTON') {
        return PortfolioAlertModel.generateOptimalCall(howData, portfolioCombination);
      }

      if (name === 'EDISON') {
        return PortfolioAlertModel.edisonBuildAdjustmentLegs(howData, portfolioCombination);
      }

      if (
        name === 'CHARLOTTE' ||
        name === 'DALLAS' ||
        name === 'LOUISVILLE' ||
        name === 'MINNEAPOLIS' ||
        name === 'PHOENIX'
      ) {
        return PortfolioAlertModel.getLegsToClosePositionAlert(portfolioCombination);
      }

      if (name === 'NASHVILLE' || name === 'PARKCITY' || name === 'ANCHORAGE') {
        return PortfolioAlertModel.getNashvillePositionAlert(alert, howData, portfolioCombination);
      }

      if (name === 'FRESNO') {
        return undefined;
      }

      if (name === 'ITHACA') {
        return undefined;
      }
      //     : {
      //       getBuildAdjustmentLegsMethod: function () {
      //         return null;
      //       },
      //       getTitle: function (alertResources, portfolioCombination, position, additionalData) {
      //         var result = formatting.formatStr(alertResources.title, additionalData);
      //         return result;
      //       },
      //       getFormattingObject: function (context) {
      //         return this;
      //       },
      //     },
      return undefined;
    } catch (error) {
      console.error(error);
    }
    return undefined;
  };

  static getTitle = (
    alert: PortfolioAlert,
    resources: IPortfolioAlertResource[],
    portfolioCombination: PortfolioCombination,
    t: (key: string) => any,
  ) => {
    try {
      const name = alert.key.trim().toUpperCase();
      const resource = resources.find((r) => r.key.trim().toUpperCase() === name);
      if (!resource) {
        return undefined;
      }
      const additionalAlertData = alert.additionalAlertData;

      // Expiration in {NUMBER_OF_DAYS_TO_EXPIRY} Day || Expiration Today
      if (name === 'JACKSONVILLE') {
        let daysToExpiry = portfolioCombination.daysToExpiry();
        if (additionalAlertData?.numberOfDaysToExpiry) {
          daysToExpiry = additionalAlertData.numberOfDaysToExpiry;
        }
        if (daysToExpiry === -999999) {
          return undefined;
        }
        if (daysToExpiry === 0) {
          return (resource.title as IAlertTitle).expirationToday;
        }
        const title = (resource.title as IAlertTitle).expirationInDays;
        return (title || '').replace('{NUMBER_OF_DAYS_TO_EXPIRY}', daysToExpiry.toString());
      }

      if (name === 'ITHACA') {
        return PortfolioAlertModel.titleIthaca(alert, resource, portfolioCombination, t);
      }

      // Earnings Report on {EARNINGS_DATE}
      if (name === 'GLENDALE') {
        return (resource.title as string).replace(
          '{EARNINGS_DATE}',
          formatting.formatDate(portfolioCombination.earningsDate) || '',
        );
      }

      // phoneix : Trend is turning {SENTIMENT}
      // Boston: {SENTIMENT} Technical Event
      if (name === 'PHOENIX' || name === 'BOSTON') {
        return (resource.title as string).replace(
          '{SENTIMENT}',
          t(`app.php.how.tradeIdeaPanel.${portfolioCombination.sentiment()}`),
        );
      }
      // Arlington : Optimal Covered Call Found
      // Edison : Reduce Your Risk
      // kingston : Exercise Risk
      if (
        name === 'EDISON' ||
        name === 'KINGSTON' ||
        name === 'CHARLOTTE' ||
        name === 'DALLAS' ||
        name === 'LOUISVILLE' ||
        name === 'MINNEAPOLIS' ||
        name === 'HOUSTON'
      ) {
        return resource.title as string;
      }

      if (name === 'ARLINGTON') {
        if (typeof resource.title === 'string' || resource.title instanceof String) {
          if (resource.title.indexOf('_') >= 0) {
            return resource.title.split('_')[1]; //for Arlington: Arlington_Optimal Covered Call Found;
          }
          return resource.title;
        }
      }

      if (name === 'NASHVILLE' || name === 'PARKCITY' || name === 'ANCHORAGE') {
        return PortfolioAlertModel.getCoveredCallSpecificTitle(resource, additionalAlertData);
      }

      return undefined;
    } catch (error) {
      console.error(error);
    }
    return undefined;
  };

  static getCoveredCallSpecificTitle = (alertResources: any, additionalAlertData: any) => {
    if (additionalAlertData.hasOptimalCall) {
      return alertResources.title.coveredCallOptimalFound;
    }
    if (additionalAlertData.hasAlternativeCall) {
      if (additionalAlertData.rollPositionWhen === RollPositionWhen.OPTIMAL_ONLY) {
        return alertResources.title.coveredCallAlternativeNotFound;
      } else {
        return alertResources.title.coveredCallOptimalNotFound;
      }
    }
    return alertResources.title.coveredCallAlternativeNotFound;
  };

  private static titleIthaca = (
    alert: PortfolioAlert,
    resource: IPortfolioAlertResource,
    portfolioCombination: PortfolioCombination,
    t: (key: string) => any,
  ) => {
    //"{SENTIMENT} Trade Idea today"
    const title = (resource.title as string) || '';
    return title.replace('{SENTIMENT}', t(`app.php.common.labels.${portfolioCombination.sentiment()}`) as string);
  };

  private static edisonBuildAdjustmentLegs = (howData: HowDataModel, portfolioCombination: PortfolioCombination) => {
    const context = {
      chain: howData.chain,
      portfolioCombination: portfolioCombination,
      quote: howData.quote,
      stdDev: howData.stdDev,
    };

    let combination = context.portfolioCombination;
    if (!combination) {
      return undefined;
    }
    let legCheck = combination.getLegsSummary();
    let noOfCall = legCheck.noOfCall;
    let noOfPut = legCheck.noOfPut;
    let suggestedLegs = [];
    let chain = context.chain;
    let quote = context.quote;
    if (!chain) {
      throw new Error('chain is undefined');
    }
    let highestCall = Math.max(legCheck.maxCallStrike || 0, quote.last);
    let lowestPut = Math.min(legCheck.maxCallStrike || quote.last, quote.last);
    let strike;
    let expiry = chain.findExpiry(combination.getExpiryOrDefault(), undefined);
    if (noOfCall < 0) {
      let sdUp: any = context.stdDev.getStdDevUp(combination.expiry());
      strike =
        highestCall < sdUp
          ? chain.findStrike(sdUp, combination.expiry(), 0, combination.optionType)
          : chain.findStrike(highestCall, expiry, 1, combination.optionType);
      suggestedLegs.push({
        legType: LegType.CALL,
        expiry: expiry,
        strikePrice: strike,
        quantity: Math.abs(noOfCall),
      });
    }
    if (noOfPut < 0) {
      let sdDown: any = context.stdDev.getStdDevDown(combination.expiry());
      strike =
        lowestPut <= sdDown
          ? chain.findStrike(lowestPut, expiry, -1, combination.optionType)
          : chain.findStrike(sdDown, expiry, -1, combination.optionType);
      suggestedLegs.push({
        legType: LegType.PUT,
        expiry: expiry,
        strikePrice: strike,
        quantity: Math.abs(noOfPut),
      });
    }
    if (suggestedLegs.length === 0) {
      return undefined;
    }
    return suggestedLegs;
  };

  private static getLegsToClosePositionAlert = (portfolioCombination: PortfolioCombination) => {
    return portfolioCombination.getLegsToClosePosition();
  };
}

//     Phoenix: {
//       getBuildAdjustmentLegsMethod: function () {
//         return getLegsToClosePositionAlert;
//       },
//       getTitle: function (alertResources, portfolioCombination, position, additionalAlertData) {
//         var todaySentiment = additionalAlertData.todaySentiment;
//         var yesterdaySentiment = additionalAlertData.yesterdaySentiment;
//         var sentiment = '';
//         if (
//           todaySentiment.syrahLongSentiment !== yesterdaySentiment.syrahLongSentiment &&
//           todaySentiment.syrahShortSentiment !== yesterdaySentiment.syrahShortSentiment
//         ) {
//           sentiment = portfolioCombination.quoteLongTermSentiment();
//         } else if (todaySentiment.syrahLongSentiment !== yesterdaySentiment.syrahLongSentiment) {
//           sentiment = portfolioCombination.quoteLongTermSentiment();
//         } else if (todaySentiment.syrahShortSentiment !== yesterdaySentiment.syrahShortSentiment) {
//           sentiment = portfolioCombination.quoteShortTermSentiment();
//         }
//         return {
//           sentiment: sentiment,
//         };
//       },
//       getAdjustmentText: getCoveredCallSpecificAdjustmentText,
//       getFormattingObject: function (context, additionalAlertData) {
//         var todaySentiment = additionalAlertData.todaySentiment;
//         var yesterdaySentiment = additionalAlertData.yesterdaySentiment;
//         var changedPeriod = '';
//         var sentiment = '';
//         if (
//           todaySentiment.syrahLongSentiment !== yesterdaySentiment.syrahLongSentiment &&
//           todaySentiment.syrahShortSentiment !== yesterdaySentiment.syrahShortSentiment
//         ) {
//           changedPeriod = '1m & 6m';
//           sentiment = context.currentCombination.originalCombination.quoteLongTermSentiment();
//         } else if (todaySentiment.syrahLongSentiment !== yesterdaySentiment.syrahLongSentiment) {
//           changedPeriod = '6m';
//           sentiment = context.currentCombination.originalCombination.quoteLongTermSentiment();
//         } else if (todaySentiment.syrahShortSentiment !== yesterdaySentiment.syrahShortSentiment) {
//           changedPeriod = '1m';
//           sentiment = context.currentCombination.originalCombination.quoteShortTermSentiment();
//         }

//         return {
//           symbol: context.currentCombination.symbol,
//           positionSentiment: context.currentCombination.sentiment(),
//           changedPeriod: changedPeriod,
//           sentiment: sentiment,
//         };
//       },
//     },

//     UserCreated: {
//       getBuildAdjustmentLegsMethod: function () {
//         return genereteOptimalCallLeg;
//       },
//       getFormattingObject: function (context) {
//         return {
//           invertedCost: context.recommendationCombination.invertedCost,
//           nameInAdjustmentPanel: context.currentCombination.nameInPortfolio,
//         };
//       },
//     },
//   };

//   function getDefaultTitle(alertResources) {
//     return alertResources.title;
//   }

//   function getDefaultAdjustmentText(alertResources) {
//     return alertResources.adjustmentText;
//   }

//   function getDefaultPlainEnglish(alertResources) {
//     return alertResources.plainEnglish;
//   }

//   function getCoveredCallSpecificTitle(alertResources, portfolioCombination, position, additionalAlertData) {
//     return getCoveredCallSpecificResource(alertResources, additionalAlertData, 'title');
//   }

//   function getCoveredCallSpecificAdjustmentText(alertResources, additionalAlertData) {
//     return getCoveredCallSpecificResource(alertResources, additionalAlertData, 'adjustmentText');
//   }

//   function getCoveredCallSpecificPlainEnglish(alertResources, additionalAlertData) {
//     return getCoveredCallSpecificResource(alertResources, additionalAlertData, 'plainEnglish');
//   }

//   function getCoveredCallSpecificResource(alertResources, additionalAlertData, key) {
//     if (additionalAlertData.hasOptimalCall) {
//       return alertResources[key].coveredCallOptimalFound;
//     }
//     if (additionalAlertData.hasAlternativeCall) {
//       if (additionalAlertData.rollPositionWhen === enums.RollPositionWhen.OPTIMAL_ONLY) {
//         return alertResources[key].coveredCallAlternativeNotFound;
//       } else {
//         return alertResources[key].coveredCallOptimalNotFound;
//       }
//     }
//     return alertResources[key].coveredCallAlternativeNotFound;
//   }

//   function getCoveredCallAlertBuildAdjustmentMethod(additionalAlertData) {
//     if (additionalAlertData.hasOptimalCall) {
//       return getRollPositionLegsForOptimalCall;
//     }
//     if (additionalAlertData.hasAlternativeCall) {
//       if (additionalAlertData.rollPositionWhen === enums.RollPositionWhen.OPTIMAL_ONLY) {
//         return getLegsToClosePositionAlert;
//       } else {
//         return getRollPositionLegsForOptimalCall;
//       }
//     }
//     return getLegsToCloseOptionPositions;
//   }

//   function getLegsToCloseOptionPositions(context) {
//     if (!context.portfolioCombination) {
//       return null;
//     }
//     var optionPositions = context.portfolioCombination.positions().filter(function (p) {
//       return !p.isSecurityType();
//     });
//     var result = optionPositions.map(function (position) {
//       return position.getInvertedRawLeg();
//     });
//     return result;
//   }
