export class Sector {
  value = '';
  text = '';
  isSelected = false;

  static fromJson = (data: Sector): Sector => {
    const sector = new Sector();
    sector.isSelected = false;
    sector.text = data.text;
    sector.value = data.value.trim().toLocaleLowerCase();
    return sector;
  };

  static fromText = (text: string): Sector => {
    const sector = new Sector();
    sector.isSelected = false;
    sector.text = text;
    sector.value = text?.trim().toLocaleLowerCase();
    return sector;
  };

  static empty = (): Sector => {
    return new Sector();
  };

  isEmpty = (): boolean => {
    return this.value === '';
  };
}
