import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { SentimentModel } from '@op/shared/src/hubs/sentiment-model';
import { accountState, globalizationState, isHubInitiatedState, selectedSymbolState } from '@op/shared/src/states';
import {
  fetchSentiments,
  sentimentAtomFamily,
  sentimentsUpdater,
  subscribeToSentiments,
  unsubscribeFromSentiments,
} from '@op/shared/src/states/sentiment-hub-states';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { RedGreenWidget } from './red-green-widget';

export interface ISentimentLongTermWidgetProps {
  symbol: string;
  isQuoteBar?: boolean;
}

export const SentimentLongTermWidget: React.FC<ISentimentLongTermWidgetProps> = ({
  symbol,
  isQuoteBar,
}: ISentimentLongTermWidgetProps) => {
  const hubSentiment = useRecoilValue<SentimentModel | undefined>(sentimentAtomFamily(symbol));
  const setSentiments = useSetRecoilState(sentimentsUpdater);
  const isHubInitiated = useRecoilValue(isHubInitiatedState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [delay, setDelay] = useState(2000);
  const globalization = useRecoilValue(globalizationState);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ref, inView, entry] = useInView({
    threshold: 1,
    delay: delay,
  });
  const account = useRecoilValue(accountState);
  const { t } = React.useContext(LocalizationContext);

  const fetchSentiment = async () => {
    const fetchedSentiments = await fetchSentiments([symbol]);
    if (!fetchedSentiments || fetchedSentiments.length === 0) {
      return;
    }
    setSentiments(fetchedSentiments);
  };

  React.useEffect(() => {
    if (!isHubInitiated || !symbol || !inView) {
      return;
    }
    (async () => {
      // setDelay(5000);
      if (!hubSentiment) {
        await fetchSentiment();
      }
      await subscribeToSentiments([symbol]);
    })();
  }, [isHubInitiated, inView]);

  useEffect(() => {
    if (inView || !hubSentiment) {
      return;
    }
    //If symbol is selected and loaded in other panels like quotebar, how panel etc. we should not unsubscribe the quotes.
    if (selectedSymbol?.toLowerCase() === hubSentiment.symbol.toLowerCase()) {
      return;
    }
    (async () => {
      await unsubscribeFromSentiments([symbol]);
    })();
  }, [inView, hubSentiment]);

  if (account === null) {
    return null;
  }

  if (!globalization || !globalization.active) {
    return;
  }
  const renderLongTermSentiment = () => {
    if (!hubSentiment?.syrahLongSentiment) {
      return <></>;
    }
    const longTermSentiment = hubSentiment.syrahLongSentiment;

    const textStyle =
      globalization.french && isQuoteBar && longTermSentiment.name.length <= 7
        ? 10
        : globalization.french && isQuoteBar && longTermSentiment.name.length > 7
        ? 8
        : isQuoteBar && longTermSentiment.name.length > 7
        ? 8
        : undefined;

    const renderQuoteBarView = () => {
      return (
        <>
          <Grid item xs={1} pl={1}>
            <Typography variant="captionSmall">6M</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography
              component="div"
              variant="captionSmall"
              textAlign="right"
              lineHeight={1.2}
              sx={{ paddingLeft: 1 }}>
              <RedGreenWidget value={longTermSentiment.name}>
                <b>{t(`app.php.common.labels.${longTermSentiment.name}`)}</b>
              </RedGreenWidget>
            </Typography>
          </Grid>
        </>
      );
    };

    const renderDefaultView = () => {
      return (
        <Grid container title={longTermSentiment.secondary} style={{ flexDirection: 'row', width: '100%' }}>
          <Grid item>
            <Typography variant="body1" sx={{ pr: 1 }}>
              {t('app.php.header.js.sentimentModel.sentimentLong')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body1'}>
              <RedGreenWidget value={longTermSentiment.name}>
                <b>{t(`app.php.common.labels.${longTermSentiment.name}`)}</b>
              </RedGreenWidget>
            </Typography>
          </Grid>
        </Grid>
      );
    };

    const renderSentimentLong = () => {
      if (isQuoteBar) {
        return renderQuoteBarView();
      }
      return renderDefaultView();
    };

    return renderSentimentLong();
  };

  return (
    <span ref={ref} style={{ display: 'contents' }}>
      {renderLongTermSentiment()}
    </span>
  );
};
