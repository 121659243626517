import { SaveFilter, SaveFilters } from '@op/shared/src/models';
import { atom, selector } from 'recoil';
import { PowerhouseProService } from '@op/shared/src/services';
import { ResponseViewModel } from '@op/shared/src/view-models/responses/response-viewmodel';

export const saveFilterState = atom<SaveFilters>({
  key: 'saveFilterState',
  default: new SaveFilters([]),
});

export const savedFilterState = selector({
  key: 'saveFilterStateSelector',
  get: async ({ get }) => {
    let response = new ResponseViewModel<SaveFilters>();
    response = await PowerhouseProService.instance.getSavedFilter();

    if (response.hasErrors) {
      return response;
    }
    if (!response.data) {
      return response;
    }
    var model = new SaveFilters(response.data.opSelector);
    const opSelectorKeys: SaveFilter[] = [];
    response.data?.opSelector.forEach((s) => {
      opSelectorKeys.push(SaveFilter.fromJson(s));
    });

    return new SaveFilters(opSelectorKeys);
  },
});
