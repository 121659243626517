import { CardContent, CardHeader, SxProps, Theme, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

export interface ISubscriptionCardLayoutProps {
  sx?: SxProps<Theme>;
  title: ReactNode;
  content: ReactNode;
  monthlyPlan?: boolean;
  yearlyPlan?: boolean;
  cancelled?: boolean;
}

export const SubscriptionCardLayout: React.FC<ISubscriptionCardLayoutProps> = ({
  sx,
  title,
  content,
  monthlyPlan,
  yearlyPlan,
  cancelled,
}: ISubscriptionCardLayoutProps) => {
  const theme = useTheme();

  const backgroundColor = () => {
    if (cancelled) {
      return theme.palette.error.main;
    }
    if (yearlyPlan) {
      return `linear-gradient(to right,#0078D4, #77BF45)`;
    }
    if (monthlyPlan) {
      return theme.palette.primary.main;
    }

    return theme.palette.success.main;
  };

  return (
    <div>
      <CardHeader
        style={{
          height: 40,
          background: backgroundColor(),
          width: '100%',
          borderTopRightRadius: theme.shape.borderRadius,
          borderTopLeftRadius: theme.shape.borderRadius,
          color: 'whitesmoke',
          alignItems: 'center',
        }}
        title={<>{title}</>}
      />
      <CardContent
        sx={sx}
        style={{ backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : '#2E3439' }}>
        {content}
      </CardContent>
    </div>
  );
};
