import {
  AccordionDetails,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  Select,
  Slider,
  Tab,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { Theme, keyframes, styled } from '@mui/material/styles';

import React from 'react';

type StyledHoverItemProps = {
  isselected?: boolean;
};
//Constants
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 240,
      width: 220,
    },
  },
  MenuListProps: {
    style: {
      padding: 0,
    },
  },
};

// Divider Componet
export const OPStyledDivider = styled(Divider)(({ theme }) => ({
  border: '0.5px dashed rgba(0, 0, 0, 0.3)',
}));

/**
 * Below Extraprops used to pass a component prop to custom styledTypograpghy
 */
type TypographyExtraProps = {
  component?: React.ElementType;
};

// Typograpghy Styles
export const OPLightTypograpghy = styled(Typography)<TypographyExtraProps>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 400,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const OPSemiBoldTypograpghy = styled(Typography)<TypographyExtraProps>(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: 500,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const OPBoldTypograpghy = styled(Typography)<TypographyExtraProps>(({ theme }) => ({
  // fontSize: theme.typography.body1.fontSize,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
export const OPBoldTypograpghyforCreate = styled(Typography)<TypographyExtraProps>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 400,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    fontWeight: 600,
  },
}));

export const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#666666' : '#999999',
  fontWeight: 600,
  fontSize: '12px',
  width: 200,
}));
export const TableBodyCell = styled(TableCell)(() => ({
  color: '#999999',
  fontWeight: 700,
  fontSize: '12px',
}));
export const GridItemWithRightBorder = styled(Grid)(({ theme }) => ({
  borderRight: '1px dashed lightgrey',
}));
const breatheAnimation = keyframes`
 0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translatex(0);
		-ms-transform:     translatex(0);
		transform:         translatex(0);
    -moz-transform:translatex(-8px);
	}
	40% {
		-webkit-transform: translatex(-8px);
		-ms-transform:     translatex(-8px);
		transform:         translatex(-8px);
    -moz-transform:translatex(-8px);
	}
	60% {
		-webkit-transform: translatex(-2px);
		-ms-transform:     translatex(-2px);
    -moz-transform:translatex(-2px);
		transform:         translatex(-2px)
	}
 `;
export const GridHoverBorder = styled(Grid)<StyledHoverItemProps>(({ theme, isselected }) => ({
  animationDuration: '1.5s',
  animationFillMode: 'both',
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: '3',
  animationName: isselected ? breatheAnimation : 'none',
}));

export const OPSelectField = styled(Select)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.selectAndTextField.light,
  color: theme.palette.selectAndTextField.main,
  paddingLeft: 0,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.secondary.dark,
  },
  '& .MuiSelect-outlined': {
    padding: 0,
  },
  '& .MuiInputBase-input': {
    paddingLeft: 0,
  },
  '& .MuiInputAdornment-root': {
    backgroundColor: theme.palette.info.dark,
    padding: '18px 8px',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  // '& .MuiMenu-list': {
  //   padding: 0,
  // },
  '& .MuiSelect-icon': {
    color: theme.palette.info.light,
  },
  '.MuiList-root .MuiList-padding .MuiMenu-list': {
    padding: 0,
  },
  ':hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      // borderColor: theme.palette.primary.main,
      border: `1.5px solid ${theme.palette.primary.main}`,
    },
  },
}));

// TODO : Overrite hover color
type BorderColorProps = {
  color: 'green' | 'blue' | 'default';
  selected?: boolean;
};

export const GridItemWithBorder = styled(Grid)<BorderColorProps>(({ color, theme }) => ({
  border: `1px solid ${
    color === 'green'
      ? theme.palette.success.main
      : color === 'blue'
      ? theme.palette.primary.main
      : theme.palette.grey[400]
  }`,
  padding: 4,
  borderRadius: theme.shape.borderRadius,
}));
export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  root: {
    minHeight: 15,
    maxHeight: 15,
    backgroundColor: '#a5a5a5',
    '&.Mui-expanded': {
      minHeight: 15,
      maxHeight: 15,
      backgroundColor: '#a5a5a5',
    },
  },
  expandIcon: {
    order: -1,
  },
}));
export const OPListItem = styled(ListItem)(({ theme }) => ({
  '&.MuiListItem-padding': { padding: '2px ' },
  fontWeight: 500,
  color: theme.palette.text.secondary,
  display: 'list-item',
  fontSize: '12px',
}));
export const OPMenuItemWithBorder = styled(MenuItem)<BorderColorProps>(({ color, selected, theme }) => ({
  borderBottom: '0.5px dashed rgba(0, 0, 0, 0.5)',
  ':hover': {
    backgroundColor: theme.palette.secondary.light,
    '& .count-value': {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.text.primary,
    },
  },
  '& .count-value': {
    border: selected ? `1px solid ${theme.palette.success.main}` : `1px solid ${theme.palette.primary.main}`,
    backgroundColor: selected ? theme.palette.success.main : 'inherit',
    color: selected ? '#ffffff' : 'inherit',
    padding: '1px 6px 1px 6px',
    borderRadius: theme.shape.borderRadius,
  },
}));

export const OPGrid = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.light}`,
  ':hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  cursor: 'pointer',
  borderRadius: 5,
  // padding: 5,
  backgroundColor: theme.palette.selectAndTextField.light,
  color: theme.palette.primary.main,
}));

type OPIconButtonProps = {
  success?: boolean;
};

export const OPIconButton = styled(IconButton)<OPIconButtonProps>(({ theme, success }) => ({
  border: success ? `1px solid ${theme.palette.success.main}` : `1px solid ${theme.palette.secondary.dark}`,
  ':hover': {
    border: success ? `1px solid ${theme.palette.success.main}` : `1px solid ${theme.palette.primary.main}`,
  },
  cursor: 'pointer',
  borderRadius: 5,
  padding: 5,
  color: success ? theme.palette.success.main : theme.palette.primary.main,
}));

export const OPPrefixIconButton = styled(IconButton)(({ theme }) => ({
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  padding: 5,
  color: theme.palette.primary.main,
  left: -8,
}));

//Tooltip componet
const ToBeStyledTooltip = ({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

export const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: 'rgba(0, 0, 0, 0.87)',
  maxWidth: 220,
  fontSize: theme.typography.pxToRem(12),
  border: `1px solid ${theme.palette.primary.main}`,
}));

//Tab Componet
type StyledTabProps = {
  isselected?: string;
};

export const StyledTab = styled(Tab)<StyledTabProps>(({ isselected, theme }) => ({
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 6,
  paddingBottom: 4,
  whiteSpace: 'nowrap',
  fontWeight: isselected === 'true' ? 'bold' : 'normal',
}));

export const watchListPortfolioMenuProps = (theme: Theme) => {
  return {
    elevation: 0,
    sx: {
      backgroundColor: theme.palette.mode === 'light' ? '#D9D9D9' : 'rgba(153, 153, 153, 0.8)',
      overflow: 'visible',
      ml: 1.5,
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 10,
        left: 14,
        width: 10,
        height: 10,
        bgcolor: theme.palette.mode === 'light' ? '#D9D9D9' : 'rgba(153, 153, 153, 0.8)',
        transform: 'translateX(-190%) rotate(45deg)',
      },
    },
  };
};

export const IdeaMenuProps = (theme: Theme) => {
  return {
    elevation: 0,
    sx: {
      backgroundColor: theme.palette.info.dark,
      boxShadow: theme.palette.mode === 'dark' && `2px 0px 2px 5px ${theme.palette.info.dark}`,
      overflow: 'visible',
      mt: 1.5,
      width: '304px',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 150,
        width: 10,
        height: 10,
        bgcolor: theme.palette.info.dark,
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  };
};

export const OPSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.secondary.light,
  height: 3,
  padding: '10px 0',
  marginTop: '2px',
  '& .MuiSlider-thumb': {
    display: 'none',
    '&:hover': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : theme.palette.secondary.light,
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
    borderRadius: 100,
  },
  '& .MuiSlider-mark': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.light : '#fff',
    height: 8,
    width: 2,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: '#fff',
    },
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  // '& .MuiToggleButtonGroup-grouped': {
  //   // border: 0,
  //   '&:not(:first-of-type)': {
  //     margin: 1,
  //     borderRadius: theme.shape.borderRadius,
  //     borderLeft: '2px solid red',
  //   },
  //   '&:not(:last-of-type)': {
  //     margin: 1,
  //     borderLeft: '2px solid red',
  //   },
  //   '&:first-of-type': {
  //     margin: 1,
  //     borderRadius: theme.shape.borderRadius,
  //   },
  //   '&.MuiToggleButtonGroup-grouped.Mui-selected+.Mui-selected': {
  //     margin: 1,
  //     borderLeft: '2px solid red',
  //   },
  // },
}));

type StyledToggleButtonProps = {
  isSentiment?: boolean;
};

export const OPToggleButton = styled(ToggleButton)<StyledToggleButtonProps>(({ isSentiment, theme }) => ({
  // marginRight: 8,
  // marginTop: isSentiment ? 'inherit' : 4,
  // outlineColor: theme.palette.divider,
  // outlineWidth: 1,
  // outlineStyle: 'solid',
  // ':hover': {
  //   outlineColor: theme.palette.primary.main,
  //   outlineWidth: 1,
  //   outlineStyle: 'solid',
  // },
  // '&.Mui-selected, &.Mui-selected:hover': {
  //   color: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
  //   backgroundColor: theme.palette.primary.main,
  // },
}));

export const CircularLoading = styled(CircularProgress)(() => ({
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const RowWithoutBorder = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[50],
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type StyledButtonProps = {
  isSelected?: any;
};
export const OrangeButton = styled(OPIconButton)<StyledButtonProps>(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.palette.orangeButton.main : 'inherit',
  borderColor: isSelected ? theme.palette.orangeButton.light : theme.palette.orangeButton.dark,
  ':hover': {
    backgroundColor: isSelected ? theme.palette.orangeButton.main : 'inherit',
    borderColor: isSelected ? theme.palette.orangeButton.light : 'inherit',
  },
  ':disabled': {
    backgroundColor: theme.palette.optionGridDisabled.main,
  },
}));
