import { IConfiguration, IHelpPin, OptionsChains } from '@op/shared/src/models/configuration';
import formatting from '@op/shared/src/models/how/formatting';
import { ApiService, powerhouseProApiServiceUrl } from '@op/shared/src/services';
import {
  CachedSecurity,
  IHow,
  IPortfolioAlertResponseProps,
  LanguageType,
  PortfolioAlertResponse,
  SaveFilters,
  SaveFiltersResponse,
} from '../models';
import { PortfolioCombinationAccounts } from '../models/portfolio';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { BaseApiService } from './base-api-service';

export const getConfiguration = async (): Promise<ResponseViewModel<IConfiguration>> => {
  const url = `${powerhouseProApiServiceUrl}/configuration`;
  const response = await ApiService.instance.get<IConfiguration>(url);
  return ResponseViewModel.fromData(response.data as unknown as IConfiguration);
};

export const getCachedSecurities = async (
  allowedSecurities: string | undefined,
): Promise<ResponseViewModel<CachedSecurity[]>> => {
  const url = `${powerhouseProApiServiceUrl}/getAllCachedSecurities`;
  const response = await ApiService.instance.get<CachedSecurity[]>(url);
  const vm = new ResponseViewModel<CachedSecurity[]>();
  vm.data = response.data as unknown as CachedSecurity[];
  if (vm.data !== undefined && allowedSecurities && allowedSecurities.length > 0) {
    const securities = [];
    for (const security of allowedSecurities.split(',')) {
      const result = vm.data.find((c) => c.symbol.trim().toUpperCase() === security.trim().toUpperCase());
      securities.push(result);
    }
    vm.data = securities.filter((s): s is CachedSecurity => s !== undefined);
  }
  vm.errors = response.errors;
  return vm;
};

export const getQuotesAndSentiments = async (
  symbols: string[],
): Promise<{ quotes: any[]; sentiments: any[] } | undefined> => {
  const response = await ApiService.instance.post<string[], { quotes: any[]; sentiments: any[] }>(
    `${powerhouseProApiServiceUrl}/how/getQuotesAndSentiments`,
    symbols,
  );
  if (response.hasErrors || !response.data) {
    return undefined;
  }
  return response.data;
};

export const getHelpPins = async (locale: LanguageType): Promise<ResponseViewModel<IHelpPin[]>> => {
  const url = `${powerhouseProApiServiceUrl}/helpPins/${locale}`;
  const response = await ApiService.instance.get<IHelpPin[]>(url);
  return ResponseViewModel.fromData(response.data as unknown as IHelpPin[]);
};

export class PowerhouseProService extends BaseApiService {
  private static _instance: PowerhouseProService;

  private constructor() {
    super('/Powerhouse/Pro');
    ApiService.instance.init(PowerhouseProService.onError, PowerhouseProService.onLoading);
  }

  public static get instance(): PowerhouseProService {
    if (PowerhouseProService._instance == null) {
      PowerhouseProService._instance = new PowerhouseProService();
    }
    return PowerhouseProService._instance;
  }

  private static onError = (errorCode: number): void => {
    console.error('Error in powerhouse-pro-service: ', errorCode);
  };

  private static onLoading = (isLoading: boolean): void => {};

  public getPortfolio = async (): Promise<ResponseViewModel<PortfolioCombinationAccounts>> => {
    const response = await ApiService.instance.get<PortfolioCombinationAccounts>(this.getUrlPath('portfolio'));
    if (response.hasErrors || !response.data) {
      return response;
    }
    const portfolioAccounts = PortfolioCombinationAccounts.fromData(response.data);
    return ResponseViewModel.fromData(portfolioAccounts);
  };

  public PortfolioAlert = async (request: any): Promise<PortfolioAlertResponse | null> => {
    const url = this.getUrlPath('saveConfigurations');
    const response = await ApiService.instance.post<any, PortfolioAlertResponse>(url, request);
    const editReponse = PortfolioAlertResponse.fromJson(response.data as unknown as IPortfolioAlertResponseProps);
    if (response.errors.length > 0) {
      return null;
    }
    return editReponse;
  };

  public saveFilters = async (saveFilters: SaveFilters): Promise<ResponseViewModel<SaveFiltersResponse>> => {
    const response = { errorCode: 'None', formattedMessage: 'None', isSuccess: true };
    const sessionResponse = window.localStorage.setItem('opSelector', JSON.stringify(saveFilters.opSelector));
    const vm = new ResponseViewModel<SaveFiltersResponse>();
    vm.data = response as unknown as SaveFiltersResponse;
    return vm;
  };

  public getSavedFilter = async (): Promise<ResponseViewModel<SaveFilters>> => {
    const response = await ApiService.instance.get<IHow>(
      'https://run.mocky.io/v3/e904bb2e-2151-4111-b80d-06d23f5209ae',
    );

    const vm = new ResponseViewModel<SaveFilters>();
    vm.data = response.data as unknown as SaveFilters;
    return vm;
  };

  public SaveConfigurations = async (request: any): Promise<ResponseViewModel<PortfolioAlertResponse | null>> => {
    const url = this.getUrlPath('saveConfigurations');
    const response = await ApiService.instance.post<any, PortfolioAlertResponse>(url, request);
    const vm = new ResponseViewModel<PortfolioAlertResponse>();
    vm.data = response.data as unknown as PortfolioAlertResponse;
    vm.errors = response.errors;
    return vm;
  };

  public Configuration = async (): Promise<ResponseViewModel<IConfiguration>> => {
    const response = await ApiService.instance.get<IConfiguration>(this.getUrlPath('configuration'));
    const vm = new ResponseViewModel<IConfiguration>();
    vm.data = response.data as unknown as IConfiguration;
    return vm;
  };

  public AddPortfolioPosition = async (symbol: string): Promise<ResponseViewModel<OptionsChains[] | null>> => {
    const parsedSymbol = formatting.replaceSlashtoUnderscore(symbol);
    const response = await ApiService.instance.get<OptionsChains>(this.getUrlPath(`optionChains/${parsedSymbol}`));
    const vm = new ResponseViewModel<OptionsChains[]>();
    vm.data = response.data as unknown as OptionsChains[];
    vm.errors = response.errors;
    return vm;
  };

  public GetCachedSecurities = async (): Promise<ResponseViewModel<CachedSecurity[]>> => {
    const response = await ApiService.instance.get<CachedSecurity[]>(this.getUrlPath(`/getAllCachedSecurities`));
    const vm = new ResponseViewModel<CachedSecurity[]>();
    vm.data = response.data as unknown as CachedSecurity[];
    vm.errors = response.errors;
    return vm;
  };

  public WebinarData = async (data: {
    category: string;
    webinarType: string;
    searchString: string;
    startDateTime: string;
    endDateTime: string;
    start?: number;
    length?: number;
    tag: any;
  }): Promise<ResponseViewModel<unknown>> => {
    return await ApiService.instance.post('/powerhouse/pro/lightbox', data);
  };

  public WebinarDetailData = async (id: string): Promise<ResponseViewModel<any>> => {
    const response = await ApiService.instance.get(`/powerhouse/pro/lightbox/${id}`);
    const vm = new ResponseViewModel<any>();
    vm.data = response.data as unknown as any;
    vm.errors = response.errors;
    return vm;
  };

  public WebinarDashboardData = async (): Promise<ResponseViewModel<any>> => {
    const response = await ApiService.instance.get('/powerhouse/pro/lightbox/dashboardData');
    const vm = new ResponseViewModel<any>();
    vm.data = response.data as unknown as any;
    vm.errors = response.errors;
    return vm;
  };

  public getIncomeOptimals = async (
    symbol: string,
    timeframe?: string,
    aggressiveness?: string,
    legType?: string,
  ): Promise<ResponseViewModel<any | null>> => {
    const parsedSymbol = formatting.replaceSlashtoUnderscore(symbol);
    let url = `how/income/${parsedSymbol}`;
    if (timeframe) {
      url += `?timeFrame=${timeframe}`;
    }
    if (aggressiveness) {
      url += `&riskProfile=${aggressiveness}`;
    }
    if (legType) {
      url += `&legType=${legType}`;
    }
    const response = await ApiService.instance.get<any>(this.getUrlPath(url));
    const vm = new ResponseViewModel<any>();
    vm.data = response.data;
    vm.errors = response.errors;
    return vm;
  };
}
