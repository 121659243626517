import {
  Avatar,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import China from '@op/shared/assets/images/china.png';
import french from '@op/shared/assets/images/france.png';
import sweden from '@op/shared/assets/images/sweden.png';
import Us from '@op/shared/assets/images/us.png';
import { cloneDeep } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import languageType, {
  Globalization,
  IGlobalization,
  LanguageType,
  getLanguageType,
} from '@op/shared/src/models/languge-type';
import { configurationState, customizationState, isConfigurationUpdatedState } from '@op/shared/src/states';
import { globalizationState } from '@op/shared/src/states/language-state';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import LocalizationContext from '../react-i18next/localization-context';

interface ILanguageSelectorWidgetProps {
  iconView: 'header' | 'profile' | 'subscription';
}

const useStyles = (theme: Theme) => {
  return {
    flagStyle: {
      width: '29px',
      height: '15px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 10,
    },
  };
};
export const LanguageSelectorWidget: React.FC<ILanguageSelectorWidgetProps> = ({
  iconView,
}: ILanguageSelectorWidgetProps) => {
  const configuration = useRecoilValue(configurationState);
  const [isConfigurationChanged, setIsConfigurationChanged] = useRecoilState(isConfigurationUpdatedState);
  const customization = useRecoilValue(customizationState);
  const [globalization, setGlobalization] = useRecoilState(globalizationState);
  const { i18n } = React.useContext(LocalizationContext);
  const [localeItems, setLocaleItems] = useState<IGlobalization[] | undefined>();
  const theme = useTheme<Theme>();
  const classes = useStyles(theme);

  useEffect(() => {
    if (!globalization) {
      return;
    }
    setLocaleItems(globalization.items);
  }, [globalization]);

  if (
    !customization?.showLocalizationOptions ||
    !globalization ||
    !localeItems ||
    !globalization.active ||
    !configuration ||
    !configuration.showLocalizationDropdown
  ) {
    return <></>;
  }

  const handleChange = async (event: SelectChangeEvent<string>, child: React.ReactNode): Promise<void> => {
    const value = (event.target as HTMLSelectElement).value;
    const language = getLanguageType(value);
    const selectedValue = globalization.items.find((i) => i.name === (language as LanguageType));
    saveLanguage(selectedValue, language);
  };

  const saveLanguage = async (locale: IGlobalization | undefined, language: LanguageType) => {
    if (!configuration || !locale) return;
    //TODO : Instead of cloning total configuration clone only usersettings
    const clonedConfiguration = cloneDeep(configuration);
    const cloneduserSettings = cloneDeep(clonedConfiguration.userSettings);
    if (!cloneduserSettings) {
      return;
    }
    cloneduserSettings.localeId = locale.id;
    clonedConfiguration.userSettings = cloneduserSettings;
    ApplicationContext.configuration = clonedConfiguration;
    setIsConfigurationChanged(isConfigurationChanged + 1);
    // Await keyword removed because of no Loader should be maintained on change of language selection
    globalization.active = locale;
    i18n.changeLanguage(language);
    const clone = Globalization.fromData(globalization.items, language);
    setGlobalization(clone);
  };

  const getSourceImage = (value: any): string => {
    let srcImg = Us;
    switch (value) {
      case languageType.English:
        srcImg = Us;
        break;
      case languageType.Chinese:
        srcImg = China;
        break;
      case languageType.FrenchCanadian:
        srcImg = french;
        break;
      case languageType.Swedish:
        srcImg = sweden;
        break;
      default:
        srcImg = Us;
        break;
    }
    return srcImg;
  };

  const renderValue = (value: any) => {
    if (!globalization || !globalization.active) {
      return;
    }
    return iconView === 'header' ? (
      <Avatar alt="flag" src={getSourceImage(value)} style={{ width: 25, height: 25 }} />
    ) : (
      <Typography variant="body1">{globalization.active.fullName}</Typography>
    );
  };

  const renderSubscriptionregion = (value: string) => {
    let srcImg = 'US';
    switch (value) {
      case languageType.English:
        srcImg = 'US';
        break;
      default:
        srcImg = 'US';
        break;
    }
    return srcImg;
  };

  const renderView = () => {
    if (iconView === 'subscription') {
      return (
        <Grid container columnSpacing={1} alignItems="center" justifyContent="right">
          <Grid item xs={8}></Grid>

          <Grid item container xs={4} justifyContent="right" columnSpacing={1}>
            <Grid item xs={2}>
              <Avatar alt="flag" src={getSourceImage(localeItems[0].name)} style={{ width: 20, height: 20 }} />
            </Grid>
            <Typography variant="body1" sx={{ marginLeft: 3 }}>
              {renderSubscriptionregion(localeItems[0].name)}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <FormControl style={classes.formControl}>
        <Select
          variant={iconView === 'header' ? 'standard' : 'outlined'}
          disableUnderline
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={globalization.active.name} //window.localStorage.i18nextLng
          renderValue={(value: any) => renderValue(value)}
          onChange={handleChange}>
          {localeItems.map((v) => {
            return (
              <MenuItem value={v.name} key={v.id}>
                {iconView === 'header' ? (
                  <img alt={`${v.name}-flag`} src={getSourceImage(v.name)} style={classes.flagStyle} />
                ) : (
                  <Typography variant="body1">{v.fullName}</Typography>
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return renderView();
};
