import { Button, Grid, Typography, useTheme } from '@mui/material';
import { tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import Combination from '@op/shared/src/models/how/combination';
import { IncomeCombination } from '@op/shared/src/models/how/income-combination';
import SingleTrade from '@op/shared/src/models/how/single-trade';
import {
  customizationState,
  isDataLoadingState,
  selectedSymbolState,
  tradeSimulatorOperationTypeState,
} from '@op/shared/src/states';
import {
  strategyModifyState,
  subViewState,
  tradeTicketCombinationState,
  viewState,
} from '@op/shared/src/states/how/how-states';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { RiskAnalysisWidget, SingleTradeCompanyInformationWidget } from '.';
import { HowSkeletonWidget } from '../how/how-skeleton-widget';
import { SimulatorOperationsWidget } from '../how/simulator-operations-widget';
import { SimulatorWidget } from '../how/simulator-widget';
import LocalizationContext from '../react-i18next/localization-context';
import { StrategyDataWidget } from './strategy-data-widget';

export interface ISingleStrategyWidgetProps {
  combination: Combination | IncomeCombination | undefined;
  shareView: 'trade' | 'income';
  sharedBy?: string;
  creationDate?: Date;
}

export const SingleStrategyWidget: React.FC<ISingleStrategyWidgetProps> = ({
  combination,
  shareView,
  sharedBy,
  creationDate,
}: ISingleStrategyWidgetProps) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const customization = useRecoilValue(customizationState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setStrategyModify = useSetRecoilState(strategyModifyState);
  const setView = useSetRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setTradeTicketCombination = useSetRecoilState(tradeTicketCombinationState);
  const isDataLoading = useRecoilValue(isDataLoadingState);
  const [searchParams] = useSearchParams();

  /*
   * When single strategy opens operation set to ShowRisksandCheckilst.
   * When it closes set operation to PlSimulator.
   */
  useEffect(() => {
    setSimulatorOperation(tradeSimulatorOperationType.ShowRisksandCheckilst);
    return () => {
      const hash = searchParams.get('hash');
      if (!hash || hash === null || hash.trim() === '') {
        return;
      }
      setSimulatorOperation(tradeSimulatorOperationType.Modify);
      // setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
    };
  }, []);

  if (!combination) {
    return <HowSkeletonWidget />;
  }
  const singleTrade = new SingleTrade(combination);

  const onTrade = () => {
    setTradeTicketCombination({ combination: combination });
    setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
    setSubView('tradeTicket');
  };

  const onClose = () => {
    setView(shareView);
    setSubView(undefined);
    setStrategyModify(combination);
    /**
     * TODO:
     * Below code dulicated in useeffect return statement as well,
     * Re written this one as first time failing to load the modify tab
     */
    const hash = searchParams.get('hash');
    if (hash && hash !== null && hash.trim() !== '') {
      setSimulatorOperation(tradeSimulatorOperationType.Modify);
      return;
    }
    setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
  };

  const onModify = () => {
    setStrategyModify(combination);
    setSimulatorOperation(tradeSimulatorOperationType.Modify);
    setView(shareView);
    setSubView(undefined);
    ApplicationContext.userActivityHub?.logActivity(
      combination.isIncome ? 'incomeStrategiesEditCombinationButton' : 'modifyButtonInSingleTradeView',
      combination.isIncome ? 'incomeStrategiesEditCombinationButton' : 'modifyButtonInSingleTradeView',
      '',
      'click',
      selectedSymbol,
    );
  };

  const renderTradeDataPoints = () => {
    return (
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <StrategyDataWidget data={combination} />
        </Grid>
        <Grid item container xs={12} justifyContent="center" columnSpacing={2}>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth color="primary" onClick={() => onTrade()} name="trade">
              <Typography sx={{ color: theme.palette.selectAndTextField.dark }} variant="button">
                {t(customization.tradeLabel)}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={() => onModify()} name="modify">
              <Typography variant="button">{t('app.php.common.buttons.modify')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSimulatorOperations = () => {
    const operations = [
      {
        name: tradeSimulatorOperationType.ShowRisksandCheckilst,
        title: 'app.php.how.singleTrade.titles.plTarget',
      },
      {
        name: tradeSimulatorOperationType.ExplainStrategyandGreeks,
        title: 'app.php.how.singleTrade.buttons.explainStrategy',
      },
    ];
    return <SimulatorOperationsWidget data={operations} />;
  };

  /**
   * Below skelton added because of the single stratgey widget is loading two times on change of symbol
   */

  if (isDataLoading) {
    return <HowSkeletonWidget />;
  }

  return (
    <Grid container id="screenshot" justifyContent="space-between" spacing={1} style={{ padding: '0 10px' }}>
      <Grid item xs={12}>
        <SingleTradeCompanyInformationWidget
          onClose={onClose}
          creationDate={creationDate}
          isShared={true}
          data={singleTrade}
          sharedBy={sharedBy}
          combination={combination}
        />
      </Grid>
      <Grid item xs={12} container sx={{ pr: 2 }} columnSpacing={2}>
        <Grid item xs={6}>
          <RiskAnalysisWidget data={combination} />
        </Grid>
        <Grid item xs={6} sx={{ marginTop: 0.5 }}>
          {renderTradeDataPoints()}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {renderSimulatorOperations()}
      </Grid>
      <Grid item xs={12}>
        <SimulatorWidget combination={combination} />
      </Grid>
    </Grid>
  );
};
