import MenuBookIcon from '@mui/icons-material/MenuBook';
import { customizationState, globalizationState, guideCachedState, guideStartState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { CommonListItemIcon } from './common-list-item-icon-widget';
import { getHelpPins } from '@op/shared/src/services';

const steps = [
  {
    target: '.trade_ideas_and_watch_lists_step',
    content: <div>Click here to alternate between trade ideas and your watch lists. </div>,
    title: 'Trade Ideas and Watch Lists',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.tradeIdeas_helpPinPlaceholder',
    content: (
      <div>
        <div>
          After market close, OptionsPlay analyzes thousands of US stocks and ETFs using our proprietary scans to
          identify stocks poised for an imminent move up or down.
        </div>
      </div>
    ),
    title: 'Trade Ideas',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.six_month_historical_trend_step',
    content: (
      <div>
        <div>
          The colored vertical bar indicates the 6-month historical trend.
          <span style={{ color: 'green' }}>Green</span> represents a Bullish 6 month trend.
          <span style={{ color: 'red' }}>Red</span> represents a Bearish 6 month trend and yellow represents a
          <span style={{ color: 'orange' }}> Neutral</span> 6 month trend. The 6 month historical trend data represents
          past performance and is not indicative of future results.
        </div>
      </div>
    ),
    title: '6 Month Historical Trend',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.grid_technicalRank_helpPinPlaceholder',
    content: (
      <div>
        <div>
          Technical Score is a numerical indicator of a security&apos;s short, medium and long-term technical indicators
          used to evaluate the performance. It is measured on a scale of 1-10 with 10 being the strongest candidate and
          1 being the weakest. Security&apos;s with values: 1-3 are weak stocks, generally in a Bearish trend that are
          potential sell candidates. 4-6 lack a strong trend and exhibit weak momentum. 7-10 are strong stocks,
          generally in a Bullish trend that are potential buy candidates.
        </div>
      </div>
    ),
    title: 'Technical Score',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.company_column_step',
    content: (
      <div>
        <div>
          Each of these symbols triggered one or more OptionsPlay scans, creating a Trade Idea. The colored vertical bar
          indicates the 6-month trend.
          <span style={{ color: 'green' }}>Green</span> represents a Bullish trend,
          <span style={{ color: 'red' }}>red</span> represents a Bearish trend and
          <span style={{ color: 'orange' }}>yellow</span> represents a Neutral trend. An OptionsPlay Logo next to the
          symbol indicates the security triggered multiple OptionsPlay scans.
        </div>
      </div>
    ),
    title: 'Company Column',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.sentiment_helpPinPlaceholder',
    content: (
      <div>
        The colored vertical bar indicates the 6-month trend. <span style={{ color: 'green' }}>Green</span> represents a
        Bullish trend, <span style={{ color: 'red' }}>red</span> represents a Bearish trend and{' '}
        <span style={{ color: 'yellow' }}>yellow</span> represents a Neutral trend.
      </div>
    ),
    title: 'Sentiment',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.symbolInput',
    content: (
      <div>
        Enter the ticker symbol of any listed stock or ETF and OptionsPlay recommends trading strategies based on our
        analysis of this security. Symbols that are generated as a Trade Idea will be highlighted in the list on the
        left panel.
      </div>
    ),
    title: 'Symbol Box',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.trend_terms_step',
    content: (
      <div>
        This is the 1 month and 6 month historical trend sentiment for the individual security. The trend terms are key
        indicators in the performance analysis. The 1 month and 6 month historical trend data represents past
        performance and is not indicative of future results.
      </div>
    ),
    title: 'Trend Terms',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.monthControl',
    content: (
      <div>
        Choose between 1, 3, and 6-month historical stock performance. You may also zoom in or zoom out, as well as
        expand the chart.
      </div>
    ),
    title: 'Chart Timespans',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.supportAndResistance_helpPinPlaceholder',
    content: (
      <div>
        Support is the price level of a stock where demand is thought to be strong enough to prevent the price from
        declining further. Resistance is the price level of a stock where selling is thought to be strong enough to
        prevent the price from rising further. A stock trading near its support level with an OptionsPlay sentiment of
        Bullish is a good candidate for a Bullish trade.
      </div>
    ),
    title: 'Support and Resistance',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.priceAction',
    content: (
      <div>
        OptionsPlay uses Plain English to interpret technical analysis and price action. Technical Analysis: Interprets
        the scan that triggered this security as a Trade Idea. Price Action: Interprets the historical chart to indicate
        key events such as new highs and lows or a security nearing its resistance or support levels.
      </div>
    ),
    title: 'Price Action and Scan Description',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.tradingStrategiesTab_helpPinPlaceholder',
    content: (
      <div>
        This tab displays OptionsPlay&apos;s recommended trading strategies. From here, you can examine, compare, edit,
        and execute trades.
      </div>
    ),
    title: 'Trading Tab',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.incomeStrategiesTab_helpPinPlaceholder',
    content: (
      <div>
        If you own or are interested in buying a stock or ETF, you can view alternative strategies to generate
        additional income.
      </div>
    ),
    title: 'Income Tab',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.bullish_and_bearish_strategies_step',
    content: (
      <div>
        OptionsPlay suggests strategies based on our sentiment. You may choose to view strategies of either sentiment.
      </div>
    ),
    title: 'Bullish and Bearish Strategies',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.pl_Chart_step',
    content: (
      <div>
        These graphs visually illustrate the trade&apos;s profit and loss curve. The area <em>above</em> the horizontal
        axis represents profit. The area <em>below</em> represents loss. The intersection of the vertical and horizontal
        axes is the <em>current</em>
        price of the stock. The area to the <em>right</em> of the vertical line depicts
        <em>upward</em> stock movement. The area to the <em>left</em> is <em>downward</em>
        stock movement. Note how the profit (<span style={{ color: 'green' }}>green</span>) or loss (
        <span style={{ color: 'red' }}>red</span>) increases or decreases with the stock&apos;s movement in either
        direction.
      </div>
    ),
    title: 'P/L Chart',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.trade_button_step',
    content: (
      <div>
        Clicking these buttons will present you with the Trade Ticket window from MarketPro. If the Trade Ticket window
        is not presented contact the Investment Center to obtain approval for the strategy.
      </div>
    ),
    title: 'Trade',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.modify_button_step',
    content: (
      <div>
        Click here to change the number of shares or contracts, add additional legs, adjust the strike price or modify
        expiries on the option legs of your trade.
      </div>
    ),
    title: 'Modify Trade',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.price_and_date_sliders_step',
    content: (
      <div>
        Use the sliders to move the <em>hypothetical</em> stock price and expiry date forwards or backwards.
        Alternatively, type in a target price and date to see how the Profit or Loss of each trade strategy would change
        in those conditions.
      </div>
    ),
    title: 'Price and Date Sliders',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.volatility_slider_step',
    content: (
      <div>
        This allows you to make adjustments to the expected volatility of the underlying security to forcast your
        hypothetical profit or loss for each trade.
      </div>
    ),
    title: 'Volatility Slider',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.plain_english_step',
    content: (
      <div>
        This explains the type of strategy, your risk, when you would start earning profits (relative to the stock
        price), and your chances of being profitable.
        <br />
        Click on the <strong>Methodology</strong> link on bottom of window for important information about the
        probability calculations.
      </div>
    ),
    title: 'Plain English',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.investment_amount_step',
    content: (
      <div>
        Enter the amount of money you want to invest. The graphs will adjust the shares you can buy and adjust the
        strategies to match the shares.{' '}
        <strong>
          Commissions and fees are not considered in the calculation. Commission and fees will increase potential loss
          and lower potential profits.
        </strong>
      </div>
    ),
    title: 'Investment Amount',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.risk_amount_step',
    content: (
      <div>
        Enter the amount of money you are willing to risk. The number of shares and contracts of each strategy will
        reflect how many contracts you can trade for the amount of risk you are willing to take.
      </div>
    ),
    title: 'Risk Amount',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.expiry_dropdown_box_step',
    content: (
      <div>This dropdown allows you to quickly see the expected trading range for different expiration dates.</div>
    ),
    title: 'Expiry Dropdown Box',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.trading_range_slider_step',
    content: (
      <div>
        Specify your expected trading range for the stock which is illustrated in the graphs above. In general, a normal
        trading range (1 standard deviation in either direction from the current price) is more <em>probable</em> than a
        wide one.
      </div>
    ),
    title: 'Trading Range Slider',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.support_and_resistance_markers_step',
    content: (
      <div>
        Select your trading range based on pre-calculated support and resistance levels. These levels are illustrated in
        the chart and signified by the
        <span style={{ color: 'green' }}>green</span> and
        <span style={{ color: 'red' }}>red</span> lines.
      </div>
    ),
    title: 'Support and Resistance Markers',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.risk_panel_cost_step',
    content: (
      <div>
        Reflects the costs of transaction excluding commissions and fees.{' '}
        <strong>
          Commissions and fees are not considered in the calculation. Commission and fees will increase potential loss
          and lower potential profits.
        </strong>
      </div>
    ),
    title: 'Cost',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.risk_panel_max_reward_step',
    content: (
      <div>
        The maximum potential amount of profit for a specific strategy viewed. //{' '}
        <strong>
          Commissions and fees are not considered in the calculation. Commission and fees will increase potential loss
          and lower potential profits. //{' '}
        </strong>
      </div>
    ),
    title: 'Maximum Reward',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.risk_panel_max_risk_step',
    content: (
      <div>
        The maximum potential amount of losses that can be incurred for a specific strategy viewed.{' '}
        <strong>
          Commissions and fees are not considered in the calculation. Commission and fees will increase potential loss
          and lower potential profits.
        </strong>{' '}
        If an option position is exercised, this will increase the maximum risk for the position. Please be advised that
        options can be exercised at any point up to and including on expiration date.
      </div>
    ),
    title: 'Maximum Risk',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.risk_panel_pop_step',
    content: (
      <div>
        Probability of Profit is the probability of an options strategy resulting in at least $0.01 of profit on expiry.
        <strong>
          Commissions and fees are not considered in the calculation. Commission and fees will increase potential loss
          and lower potential profits.
        </strong>
        OptionPlay uses a probability tree derived from the current implied volatility of each underlying equity or ETF
        in determining the probability of an options strategy being above/below its breakeven price on the expiration
        date. It can also be interpreted as the probability of being in the green-shaded area on the P&amp;L chart of
        the option strategy.
        <br />
        Because commissions and fees are not considered in the calculation of POP, it is possible that certain outcomes
        that are considered by the calculation as having a profit on expiry of at least $0.01 may in actuality result in
        a loss.
        <br />
        Click on the <strong>Methodology</strong> link on bottom of window for important information about the
        probability calculations.
      </div>
    ),
    title: 'POP',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.risk_panel_pow_step',
    content: (
      <div>
        Probability of Expiring Worthless is the probability of an options strategy trading at or below the strike price
        sold for the covered call at expiration.{' '}
        <strong>
          Commissions and fees are not considered in the calculation. Commission and fees will increase potential loss
          and lower potential profits.
        </strong>{' '}
        For short put strategies, the POW is the probability of the stock trading at or above the strike price of the
        put sold at expiration. This is accomplished by using a probability tree derived from the current implied
        volatility of each underlying stock or ETF.
        <br />
        Because commissions and fees are not considered in the calculation of POW, it is possible that certain outcomes
        that are not considered by the calculation as resulting in an option expiring worthless may in actuality result
        in a loss to the option writer.
        <br />
        Click on the <strong>Methodology</strong> link on bottom of window for important information about the
        probability calculations.
      </div>
    ),
    title: 'POW',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.risk_panel_breakeven_step',
    content: (
      <div>
        The stock price at which an option position generates neither a profit nor a loss. An option positions
        break-even points are generally calculated for the options expiration date.{' '}
        <strong>
          Commissions and fees are not considered in the calculation. Commission and fees will increase potential loss
          and lower potential profits.
        </strong>
      </div>
    ),
    title: 'Breakeven',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.risk_panel_days_to_expiry_step',
    content: <div>Days to Expiration refers to the number of days until an option expires.</div>,
    title: 'Days to Expiry',
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.what_if_return_panels_step',
    content: (
      <div>
        These panels depict how much money you will make, or lose, <strong>IF</strong> the stock were to move to the{' '}
        <em>hypothetical</em> price you indicate on the Price Slider, <strong>BY</strong> the hypothetical date on the
        Expiry Slider. This tool illustrates how your actual return percentage changes as the stock price fluctuates.
      </div>
    ),
    title: `"What If" Return Panels`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.vertical_spread_panel_step',
    content: (
      <div>
        {`An options trading strategy using the simultaneous purchase and sale of two options with the same underlying
        equity or ETF, the same type (Call or Put), and that have the same expiration dates but different strike prices.`}
      </div>
    ),
    title: `Vertical Spread panel`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.term_analysis_step',
    content: (
      <div>
        This is the 1 month and 6 month historical trend sentiment for the individual security. The trend terms are key
        indicators in the performance analysis. The 1 month and 6 month historical trend data represents past
        performance and is not indicative of future results.
      </div>
    ),
    title: `Trend Terms`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.owned_share_prompt_step',
    content: (
      <div>
        {`If you own shares of this symbol, click "Yes" then enter the number of shares you own, and your cost per share.
        If you don't own shares of this symbol, but have equity in your account click "No".`}
      </div>
    ),
    title: `Owned Shares Prompt`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.income_panel_default_settings_step',
    content: (
      <div>
        OptionsPlay allows you to control the income strategies that you want to see, based on your outlook and risk
        tolerance
      </div>
    ),
    title: `Default Settings`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.timeframe_setup',
    content: (
      <div>
        Use this slider to select your desired time horizon
        <br />
        Short Term - Expiration dates from 20-49 Days (suitable for short term and active investors)
        <br />
        Medium Term - Expiration dates from 50-89 Days (suitable for casual investors)
        <br />
        Long Term - Expiration dates from 90-180 Days (suitable for long term investors)
        <br />
      </div>
    ),
    title: `Time Frame`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.riskTolerance_setup',
    content: (
      <div>
        Use this slider to select your desired risk tolerance
        <br />
        Conservative - Strike price that is closest to 0.1 Delta (90% Probability of Expiring Worthless)
        <br />
        Optimal - Strike price that is closest to 0.2 Delta (80% Probability of Expiring Worthless)
        <br />
        Aggressive - Strike price that is closest to 0.3 Delta (70% Probability of Expiring Worthless)
        <br />
      </div>
    ),
    title: `Risk Tolerance`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.income_panel_current_settings_setup',
    content: <div>These are your current settings. Changes can be made for Covered Calls and Puts separately</div>,
    title: `Current Settings`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.income_panel_options_grid_step',
    content: (
      <div>
        Pick one of nine possible strategies that OptionsPlay found for you that matches your preferences in the default
        settings
      </div>
    ),
    title: `Options Grid`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  //
  {
    target: '.strategy_constructor_setup',
    content: (
      <div>
        The Strategy Constructor pre-loads commonly traded options strategies. The default strategies are At-The-Money
        allowing you to adjust them to fit your risk preferences.
      </div>
    ),
    title: `Strategy Constructor`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.price_by_setup',
    content: <div>Click here to view the price of the option by the Bid/Ask spread or the Mid-price.</div>,
    title: `Price By`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.combination_editor_add_leg_setup',
    content: (
      <div>
        If you want to build more complex option strategies, click here to add legs to the current trade. You may add a
        total of five legs: One security leg, plus four option legs.
      </div>
    ),
    title: `Add Leg`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.combination_editor_reset_legs_setup',
    content: (
      <div>
        If you want to build more complex option strategies, click here to add legs to the current trade. You may add a
        total of five legs: One security leg, plus four option legs.
      </div>
    ),
    title: `Reset Legs`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.combination_editor_action_setup',
    content: <div>Click here to toggle between Buy and Sell.</div>,
    title: `Action Button`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.combination_editor_quantity_setup',
    content: (
      <div>
        Click the vertical arrows to adjust the number of shares or contracts, or directly enter the number. The
        horizontal arrows in the column header will increase or decrease the number of shares and contracts of all legs
        in the entire strategy.
      </div>
    ),
    title: `Quantity`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.combination_editor_expiry_setup',
    content: (
      <div>
        Click the vertical arrows to view the full range of expiration dates for the current option strike price. You
        may also use the horizontal arrows on the column header to move all legs to the next expiry in the list.
      </div>
    ),
    title: `Expiry`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.combination_editor_strike_setup',
    content: (
      <div>
        Click the vertical arrows to view all the strike prices for this expiry. You may also use the horizontal arrows
        on the column header to move all legs to the next strike in the list.
      </div>
    ),
    title: `Strike`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.combination_editor_type_setup',
    content: (
      <div>
        This area is a description of what type of leg you are trading. Click here to toggle between Call, Put or
        Security.
      </div>
    ),
    title: `Option Type`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.combination_editor_premium_setup',
    content: (
      <div>
        This is how much the specific leg costs to purchase, or how much you receive for selling it.{' '}
        <strong>
          Commissions and fees are not considered in the calculation. Commission and fees will increase potential loss
          and lower potential profits.
        </strong>{' '}
        If this is an option leg, multiply this amount by 100 to determine the total dollar cost of the leg. If this is
        a security, it is simply the cost of one share.
      </div>
    ),
    title: `Premium`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.combination_editor_width_and_wingspan_setup',
    content: (
      <div>
        Width allows you to adjust the distance between the two strike prices on a two-legged strategy. On a four-legged
        strategy, it adjusts the distance between the inside strike prices. There is no width on a three-legged
        strategy. The wingspan allows you to adjust the distance between the outer legs in comparison to the inner legs
        on a four-legged and three-legged strategy. There is no wingspan on a two-legged strategy.
      </div>
    ),
    title: `Width & Wingspan`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
  {
    target: '.optionsplayScore_helpPinPlaceholder',
    content: (
      <div>
        The OptionsPlay Score is our proprietary indicator evaluating the strategy and letting you know whether the
        level of risk you are taking is appropriate for the reward you may receive. An OptionsPlay score below 100 means
        you are potentially taking too much risk. Adjusting the expected trading range of the stock may adjust the
        score.
      </div>
    ),
    title: `OptionsPlay Score`,
    spotlightPadding: 3,
    disableBeacon: true,
  },
];

interface Props {
  type?: 'link' | 'menu';
}

export const GuideWidget: React.FC<Props> = ({ type = 'menu' }) => {
  const { t } = React.useContext(LocalizationContext);
  const customization = useRecoilValue(customizationState);
  const [guidePins, setGuidePins] = useRecoilState(guideCachedState);
  const [guideStart, setGuideStart] = useRecoilState(guideStartState);
  const globalization = useRecoilValue(globalizationState);

  const link = {
    cursor: 'pointer',
    marginRight: '10px',
    verticalAlign: 'middle',
    '&:hover': {
      textDecoration: 'underline !important',
    },
  };

  useEffect(() => {
    let newGuidePins = { ...guidePins };
    newGuidePins.isShowing = false;
    newGuidePins.helpPins = [];
    setGuidePins(newGuidePins);
    setGuideStart(false);
  }, [globalization.active]);

  if (!customization || !customization.showGuide) {
    return;
  }

  const onClickGuide = async () => {
    if (guideStart) {
      let newGuidePins = { ...guidePins };
      newGuidePins.isShowing = false;
      setGuidePins(newGuidePins);
      setGuideStart(false);
      return;
    }
    const response = await getHelpPins(globalization.active.name);
    if (response.hasErrors || !response.data) {
      return;
    }
    let newGuidePins = { ...guidePins };
    newGuidePins.isShowing = true;
    newGuidePins.helpPins = response.data;
    setGuidePins(newGuidePins);
    setGuideStart(true);
  };

  const renderGuideItem = () => {
    if (type === 'link') {
      return (
        <a style={link} onClick={onClickGuide}>
          {t('app.php.header.labels.guide')}
        </a>
      );
    }
    return (
      <CommonListItemIcon
        icon={<MenuBookIcon />}
        text={t('app.php.header.labels.guide')}
        callback={onClickGuide}
        key={'Guide'}
      />
    );
  };

  return renderGuideItem();
};
