import { Box, Button, Card, CardContent, CardHeader, Grid, Link, Typography } from '@mui/material';
import { ProfileDetails } from '@op/shared/src/models';
import { ProfileDetailsService } from '@op/shared/src/services';
import { profileCachedState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayDivider } from '../styled/options-play-components';

export const PrivacyPolicy: React.FC = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const [profile, setProfile] = useRecoilState(profileCachedState);
  const { t } = useContext(LocalizationContext);

  const handleSubmit = async () => {
    const updatedPrivacyPolicy = {
      privacyPolicyAccepted: profile.privacyPolicyAccepted,
    };
    const response = await ProfileDetailsService.instance.UpdatePrivacyPolicy(updatedPrivacyPolicy);
    if (!response) {
      setNotifications([{ type: 'error', content: 'Privacy policy updated unsuccessfull' }]);
      return;
    }
    const clonedProfileDetails = ProfileDetails.fromJson(response);
    setProfile(clonedProfileDetails);
    setNotifications([{ type: 'success', content: 'Privacy policy updated successfully' }]);
  };

  const getTitle = () => {
    let isOpted = profile.privacyPolicyAccepted
      ? `${t('app.php.profile.privacyPolicy.titles.currentlyOptedIn')}`
      : `${t('app.php.profile.privacyPolicy.titles.currentlyOptedOut')}`;
    return `${t('app.php.profile.menu.titles.privacyPolicy')} ${isOpted}`;
  };

  const renderContent = () => {
    return (
      <Grid item container xs={12} spacing={1} p={2}>
        {!profile.privacyPolicyAccepted ? (
          <>
            <Grid item>
              <Typography variant="body1" lineHeight={1.6}>
                {`I understand that OptionsPlay might provide my email address to Bourse de Montréal Inc. and expressly
                agree to receive electronic communications from`}
                <Link href="https://www.m-x.ca" target="_blank">
                  {' '}
                  {`Bourse de Montréal Inc.`}
                </Link>{' '}
                {`in accordance with the Bourse de Montréal Inc.'s`}
                <Link href="https://m-x.ca/accueil_politique_en.php" target="_blank">
                  {' '}
                  {`Privacy Policy`}
                </Link>
                {`, notably for marketing, promotional and other purposes, and understand that I can
                unsubscribe from such communications at any time.`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {`Please note that any information (including personal information) you may provide to OptionsPlay by
                logging in or using the OptionsPlay Web site may be shared with Bourse de Montréal Inc. in order to,
                among other reasons, establish the standard profile of users of OptionsPlay Web site, to learn about
                your needs and preferences, to inform you about new products and/or services offered by Bourse de
                Montréal Inc., and to answer your questions and enquiries, the whole in accordance with the terms and
                conditions of Bourse de Montréal Inc.'s privacy policy available `}
                <Link href="https://m-x.ca/accueil_politique_en.php" target="_blank">
                  {'here.'}
                </Link>
              </Typography>
            </Grid>
          </>
        ) : (
          <Typography variant="body1">
            Please remove my email address from any marketing and promotional communications from Bourse de Montréal
            Inc.
          </Typography>
        )}
      </Grid>
    );
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography
            variant="h6"
            dangerouslySetInnerHTML={{
              __html: getTitle(),
            }}></Typography>
        }
        style={{ paddingBottom: 0 }}
      />
      <OptionsPlayDivider />
      <CardContent>{renderContent()}</CardContent>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button id="pw-update" onClick={handleSubmit} color="primary" variant="contained">
          <Typography variant="button">
            {profile.privacyPolicyAccepted
              ? t('app.php.profile.privacyPolicy.buttons.optOut')
              : t('app.php.profile.privacyPolicy.buttons.optIn')}
          </Typography>
        </Button>
      </Box>
    </Card>
  );
};
