import { Grid, Step, StepLabel, Stepper, useTheme } from '@mui/material';
import { SubscriptionPaymentFormsState, currentSubscriptionState, selectedSymbolState } from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import LocalizationContext from '../../react-i18next/localization-context';
import { CardInfoWidget } from './card-info-widget';
import { ChoosePlanWidget } from './choose-plan';
import { SubscriptionFinalStep } from './subscription-final-step';
import { SubscriptionSummaryWidget } from './subscription-summary';

export const UpgradeSubscriptionWidget: React.FC = () => {
  const CurrentSubscriptionData = useRecoilValue(currentSubscriptionState);
  const [cardData, setCardData] = useRecoilState(SubscriptionPaymentFormsState);
  const [activeStep, setActiveStep] = useState(0);
  const [isError, setIsError] = useState(false);
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme();
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  const steps = [
    `${t('app.php.subscriptions.choosePlan')}`,
    `${t('app.php.subscriptions.enterCardInfoAndBillingAdd')}`,
    `${t('app.php.subscriptions.summary')}`,
  ];

  useEffect(() => {
    getPlan();
  }, []);

  if (!CurrentSubscriptionData) {
    return null;
  }

  const getPlan = () => {
    const billingModel = CurrentSubscriptionData.currentSubscription?.billingModel;
    setCardData({ ...cardData, plan: billingModel || cardData.plan });
  };

  const onClickTryAnotherCard = () => {
    setIsError(false);
    setActiveStep(1);
    logActivity('BUTTON', 'paymentsTryAnotherCardButton');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };
  const getStepsContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <ChoosePlanWidget activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 1:
        return <CardInfoWidget activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 2:
        return (
          <SubscriptionSummaryWidget
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setIsError={(v) => setIsError(v)}
          />
        );
      case 3:
        return <SubscriptionFinalStep isError={isError} onClickTryAnotherCard={onClickTryAnotherCard} />;
      default:
        throw new Error('Unknown screen');
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          sx={{
            backgroundColor: theme.palette.mode === 'light' ? '#F2F9FE' : '#585D61',
            paddingY: '8px',
          }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {getStepsContent(activeStep)}
      </Grid>
    </Grid>
  );
};
