import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { Grid, useTheme } from '@mui/material';
import { IFilter2, cloneDeep } from '@op/shared/src/models';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import React from 'react';
import { OPBoldTypograpghy, OPToggleButton } from '../styled';
import LocalizationContext from '../react-i18next/localization-context';

export interface Props {
  filter: IFilterGroup;
  onClick: (group: IFilterGroup) => void;
}

export const HasEarningsFilterWidget2: React.FC<Props> = ({ filter, onClick }: Props) => {
  const theme = useTheme();
  const { t  } = React.useContext(LocalizationContext);

  const onSelect = (f: IFilter2) => {
    const clone = cloneDeep(filter);
    for (const x of clone.filters) {
      if (x.name !== f.name) {
        continue;
      }
      x.selected = !f.selected;
    }
    onClick(clone);
  };

  const onReset = () => {
    const clone = cloneDeep(filter);
    for (const x of clone.filters) {
      x.selected = true;
    }
    onClick(clone);
  };

  return (
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={12}>
        <OPBoldTypograpghy>{t(filter.translationKey)}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={11} sx={{ border: `1.5px solid ${theme.palette.secondary.dark}`, borderRadius: '5px' }}>
          <Grid item p={0.5} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {filter.filters.map((f) => {
              return (
                <OPToggleButton key={f.name} value={f.value} selected={f.selected} onClick={() => onSelect(f)}>
                  <OPBoldTypograpghy>{t(f.translationKey)}</OPBoldTypograpghy>
                </OPToggleButton>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item onClick={onReset}>
            <ClearSharpIcon fontSize="small" sx={{ '&:hover': { cursor: 'pointer' } }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
