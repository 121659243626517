import { Grid, Theme, useTheme } from '@mui/material';
import { Permissions, SubscriptionStatus } from '@op/shared/src/models/enums/enums';
import React, { useEffect } from 'react';
import {
  accountState,
  configurationState,
  currentSubscriptionState,
  customizationState,
  isConfigurationUpdatedState,
} from '@op/shared/src/states';
import { useRecoilState, useRecoilValue } from 'recoil';

import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import LocalizationContext from '../react-i18next/localization-context';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { OPBoldTypograpghy } from '../styled';
import { getPaymentPlans } from '@op/shared/src/services/subscription-service';
import { useNavigate } from 'react-router-dom';

export const SubscriptionStatusWidget: React.FC = () => {
  const account = useRecoilValue(accountState);
  const configuration = useRecoilValue(configurationState);
  const [currentSubscription, setCurrentSubscription] = useRecoilState(currentSubscriptionState);
  const theme = useTheme<Theme>();
  const navigate = useNavigate();
  const { t } = React.useContext(LocalizationContext);
  const isConfigurationUpdated = useRecoilValue(isConfigurationUpdatedState);
  const customization = useRecoilValue(customizationState);

  // Only for logged user and allowed routes
  useEffect(() => {
    if (!account || !customization || customization.isEmbeddingPlatform) {
      return;
    }
    // isConfigurationUpdated === 1 means its updated only once. which is initial load.
    // The configuration ensures that we have default symbol to pull how and why data.
    // TODO: instead of number, lets have fixed enum values to denote that.
    if (isConfigurationUpdated > 2 || isConfigurationUpdated < 1) {
      return;
    }
    (async () => {
      const response = await getPaymentPlans();
      if (response.hasErrors || response.data === undefined) {
        return;
      }
      setCurrentSubscription(response.data);
    })();
  }, [account, isConfigurationUpdated]);

  if (!account || !currentSubscription || !configuration) {
    return null;
  }

  const profileSubscriptionPermission = account.securityModel.hasPermission(Permissions.MANAGE_PROFILE_SUBSCRIPTIONS);
  const individualSubscriptionEnabled = configuration.individualSubscriptionEnabled;

  if (!profileSubscriptionPermission || !individualSubscriptionEnabled) {
    return;
  }

  const endDate = DateTimeHelper.resolveDate(currentSubscription.currentSubscription?.expirationDate);
  const daysRemaining = NumberFormatHelper.floor(DateTimeHelper.daysFromNow(endDate));
  const subscriptionstatus = currentSubscription.currentSubscription?.subscriptionStatus;
  const getDiscountString = () => {
    if (
      currentSubscription.currentPricingModel &&
      (currentSubscription.currentPricingModel.discountMonthlyRate > 0 ||
        currentSubscription.currentPricingModel.discountAnnualRate > 0)
    ) {
      return ` ${currentSubscription.currentPricingModel.discountAnnualRate}%  off`;
    }
    if (
      currentSubscription &&
      currentSubscription.pricingModels &&
      currentSubscription.pricingModels.length > 0 &&
      (currentSubscription.pricingModels[0].discountMonthlyRate > 0 ||
        currentSubscription.pricingModels[0].discountAnnualRate > 0)
    ) {
      return ` ${currentSubscription.pricingModels[0].discountAnnualRate}%  off`;
    }
    return '';
  };
  const renderTrialExpiry = () => {
    if (daysRemaining === 0) {
      return (
        <OPBoldTypograpghy variant="body1" textAlign="center" sx={{ color: theme.palette.error.main }}>
          {t('app.php.subscriptions.freeTrialEndsToday')}
        </OPBoldTypograpghy>
      );
    }
    if (daysRemaining === 1) {
      return (
        <OPBoldTypograpghy variant="body1" textAlign="center" sx={{ color: theme.palette.error.main }}>
          {`${t('app.php.subscriptions.freeTrialEndsin')} ${daysRemaining} ${t('app.php.subscriptions.day')}`}
        </OPBoldTypograpghy>
      );
    }
    if (daysRemaining <= 7 && daysRemaining > 1) {
      return (
        <OPBoldTypograpghy variant="body1" textAlign="center" sx={{ color: theme.palette.error.main }}>
          {`${t('app.php.subscriptions.freeTrialEndsin')} ${daysRemaining} ${t('app.php.subscriptions.days')}`}
        </OPBoldTypograpghy>
      );
    }

    return (
      <OPBoldTypograpghy variant="body1" textAlign="center" sx={{ color: theme.palette.success.main }}>
        {t('app.php.subscriptions.freeTrial')}
      </OPBoldTypograpghy>
    );
  };

  const renderActiveTrailView = () => {
    return (
      <Grid item xs="auto">
        <Grid
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/profile', { state: 2 });
          }}>
          <OPBoldTypograpghy
            textAlign="center"
            px={1}
            sx={{
              color: '#fff',
              backgroundColor: theme.palette.success.main,
              borderRadius: 1,
            }}>
            {`${t('app.php.subscriptions.subscribeNow')}${getDiscountString()}`}
          </OPBoldTypograpghy>
        </Grid>
      </Grid>
    );
  };

  const renderViews = () => {
    switch (subscriptionstatus) {
      case SubscriptionStatus.ACTIVE_TRIAL:
        return (
          <Grid item>
            {renderTrialExpiry()}
            {renderActiveTrailView()}
          </Grid>
        );
      case SubscriptionStatus.CANCELED:
      case SubscriptionStatus.CANCELED_NOT_EXPIRED:
        return (
          <Grid>
            <OPBoldTypograpghy variant="body1" textAlign="center" sx={{ color: theme.palette.error.main }}>
              {t('app.php.subscriptions.subscriptionCancelled')}
            </OPBoldTypograpghy>
            {renderActiveTrailView()}
          </Grid>
        );
      case SubscriptionStatus.EXPIRED:
        return (
          <Grid>
            <OPBoldTypograpghy variant="body1" textAlign="center" sx={{ color: theme.palette.error.main }}>
              {t('app.php.subscriptions.subscriptionExpired')}
            </OPBoldTypograpghy>
            {renderActiveTrailView()}
          </Grid>
        );
      default:
        return null;
    }
  };

  return renderViews();
};
