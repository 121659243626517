import { SubscriptionPaymentForms } from '@op/shared/src/models';
export const CardInfo = (values: SubscriptionPaymentForms, cardtype: string) => {
  let errors = {} as SubscriptionPaymentForms;
  if (!values.cardName) {
    errors.cardName = 'Card Holder Name is required';
  } else if (values.cardName.length < 2) {
    errors.cardName = 'Card Holder Name Must be more than 2 chracters';
  } else if (values.cardName.trim() === '') {
    errors.cardName = 'Name cannot be empty';
  }
  if (!values.cardNumber) {
    errors.cardNumber = 'Card Number is required';
  }
  if (!values.cardMonth) {
    errors.cardMonth = 'Month is required';
  }
  if (!values.cardYear) {
    errors.cardYear = 'Year is required';
  }
  // merged BillingInfo as it is combined in one screen

  // app-dev is not doing the expiry check which will be coming from api response
  // if (expiryDate < new Date()) {
  //   errors.cardYear = 'Your Card Expired';
  //}
  if (!values.cardCvv) {
    errors.cardCvv = 'CVV is required';
  }
  if (!luhnCheck(values.cardNumber)) {
    errors.cardNumber = 'Card Number is invalid';
  }
  if (cardtype === 'AMEX' && values.cardNumber.length < 15) {
    errors.cardNumber = 'Card Number is invalid';
  }
  if (cardtype === 'AMEX' && values.cardNumber.length > 15) {
    errors.cardNumber = 'Card Number should not be greater than 15 numbers';
  }
  if (cardtype === 'DINERS' && values.cardNumber.length < 15) {
    errors.cardNumber = 'Card Number is invalid';
  }
  if (cardtype === 'DINERS' && values.cardNumber.length > 15) {
    errors.cardNumber = 'Card Number should not be greater than 15 numbers';
  }
  if (cardtype === 'DISCOVER' && values.cardNumber.length < 16) {
    errors.cardNumber = 'Card Number is invalid';
  }
  if (cardtype === 'DISCOVER' && values.cardNumber.length > 16) {
    errors.cardNumber = 'Card Number should not be greater than 16 numbers';
  }
  if (cardtype === 'ENROUTE' && values.cardNumber.length < 16) {
    errors.cardNumber = 'Card Number is invalid';
    if (cardtype === 'ENROUTE' && values.cardNumber.length > 16) {
      errors.cardNumber = 'Card Number should not be greater than 16 numbers';
    }
  }
  if (cardtype === 'MASTERCARD' && values.cardNumber.length < 16) {
    errors.cardNumber = 'Card Number is invalid';
  }
  if (cardtype === 'MASTERCARD' && values.cardNumber.length > 16) {
    errors.cardNumber = 'Card Number should not be greater than 16 numbers';
  }
  if (cardtype === 'VISA' && values.cardNumber.length < 16) {
    errors.cardNumber = 'Card Number is invalid';
  }
  if (cardtype === 'VISA' && values.cardNumber.length > 16) {
    errors.cardNumber = 'Card Number should not be greater than 16 numbers';
  }
  if (cardtype === 'AMEX') {
    if (values.cardCvv.length !== 4) {
      errors.cardCvv = 'Please enter exactly 4 numbers';
    }
  }
  if (
    cardtype === 'VISA' ||
    cardtype === 'DINERS' ||
    cardtype === 'DISCOVER' ||
    cardtype === 'JCB' ||
    cardtype === 'MASTERCARD' ||
    cardtype === 'ENROUTE'
  ) {
    if (values.cardCvv.length !== 3) {
      errors.cardCvv = 'Please enter exactly 3 numbers';
    }
  }
  return errors;
};

const luhnCheck = (cardnumber: string) => {
  // Accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(cardnumber)) return false;

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    bEven = false;
  cardnumber = cardnumber.replace(/\D/g, '');

  for (var n = cardnumber.length - 1; n >= 0; n--) {
    var cDigit = cardnumber.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};

export const BillingInfo = (values: SubscriptionPaymentForms) => {
  let errors = {} as SubscriptionPaymentForms;
  if (!values.country) {
    errors.country = 'Country Name is required';
  }
  if (!values.address) {
    errors.address = 'Address required';
  } else if (values.address.trim() === '') {
    errors.address = 'Address cannot be empty';
  }
  if (!values.city) {
    errors.city = 'City  is required';
  } else if (values.city.trim() === '') {
    errors.city = 'City cannot be empty';
  }
  if (!values.state) {
    errors.state = 'State  is required';
  }
  if (!values.postalCode) {
    errors.postalCode = 'Postal Code is required';
  }
  return errors;
};
