import { Paper } from '@mui/material';
import { Permissions } from '@op/shared/src/models/enums/enums';
import { getPaymentPlans } from '@op/shared/src/services';
import {
  accountState,
  configurationState,
  currentSubscriptionScreenState,
  currentSubscriptionState,
  customizationState,
} from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ChangeSubscriptionInformationWidget } from './change-subscription-information';
import SubscriptionCancel from './subscription-cancel';
import { SubscriptionCurrentWidget } from './subscription-current-widget';
import { SubscriptionSkeletonWidget } from './subscription-skeleton-widget';
import { UpgradeSubscriptionWidget } from './upgrade-subscription-widget';

export interface ISubscriptionWidgetProps {
  activeStep: number;
  setActiveStep: (value: number) => void;
  setIsError?: (value: boolean) => void;
}

export const SubscriptionWidget: React.FC = () => {
  const account = useRecoilValue(accountState);
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const [currentSubscription, setCurrentSubscription] = useRecoilState(currentSubscriptionState);
  const [currentSubscriptionScreen, setCurrentSubscriptionScreen] = useRecoilState(currentSubscriptionScreenState);
  const [loading, setLoading] = React.useState(false);

  const fetchPaymentPlans = async () => {
    setLoading(true);
    const response = await getPaymentPlans();
    if (response.hasErrors) {
      return;
    }
    // TODO: if API is already called, restrict the duplicate call
    // if (response.data === undefined) {
    setCurrentSubscription(response.data);
    //   return;
    // }

    setLoading(false);
  };

  useEffect(() => {
    setCurrentSubscriptionScreen('currentsubscription');
  }, []);

  useEffect(() => {
    if (currentSubscriptionScreen === 'currentsubscription') {
      fetchPaymentPlans();
    }
  }, [currentSubscriptionScreen]);

  if (!account || !configuration || !customization) {
    return <></>;
  }

  const profileSubscriptionPermission = account.securityModel.hasPermission(Permissions.MANAGE_PROFILE_SUBSCRIPTIONS);
  const individualSubscriptionEnabled = configuration.individualSubscriptionEnabled;

  const renderSubscriptionContent = () => {
    if (!profileSubscriptionPermission || !individualSubscriptionEnabled || !customization.showSubscription) {
      return <>Subscription is not available</>;
    }
    switch (currentSubscriptionScreen) {
      case 'currentsubscription':
        return <SubscriptionCurrentWidget />;
      case 'SubscriptionWidget':
        return <UpgradeSubscriptionWidget />;
      case 'changeInformation':
        return <ChangeSubscriptionInformationWidget />;
      case 'cancelsubscription':
        return <SubscriptionCancel />;
      default:
        throw new Error('Unknown screen');
    }
  };

  if (loading || !currentSubscription) {
    return <SubscriptionSkeletonWidget viewFooter={true} />;
  }

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      {renderSubscriptionContent()}
    </Paper>
  );
};
