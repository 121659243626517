import { Box, Button, Grid, Link, Typography, useTheme } from '@mui/material';
import MobileImg from '@op/shared/assets/images/mobile.svg';
import AppStoreLink from '@op/shared/assets/images/appStoreImage.svg';
import AppStoreQR from '@op/shared/assets/images/appStoreLink.svg';
import AuthroizeLogo from '@op/shared/assets/images/authroizelogo.png';
import PlayStoreLink from '@op/shared/assets/images/googlePlayImage.svg';
import OPLogo from '@op/shared/assets/images/optionPlayMainLogo.png';
import PlayStoreQR from '@op/shared/assets/images/playStoreLink.svg';
import React, { ReactNode } from 'react';
import { OPBoldTypograpghy } from '../../styled';
import LocalizationContext from '../../react-i18next/localization-context';

export const SubscriptionFooterWidget: React.FC = () => {
  const theme = useTheme();
  const email = `customer.support@op.com`;
  const { t } = React.useContext(LocalizationContext);

  const renderEmail = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <Grid container>
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Typography style={{ fontSize: '10px', fontWeight: 400 }}>{t('app.php.subscriptions.securedBy')}</Typography>
        <AuthorizeSymbolWidget />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : '#2E3439',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography style={{ fontSize: '12px', fontWeight: 600 }}>{t('app.php.subscriptions.contactUs')}:</Typography>
        <Link target="_blank" rel="noreferrer">
          <Button
            style={{ fontSize: '12px' }}
            onClick={renderEmail}
            sx={{
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.primary.main,
              },
              '&.MuiButton-text': {
                textTransform: 'none',
              },
            }}>
            customer.support@op.com
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export const SubscriptionScannerFooter: React.FC = () => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  return (
    <Grid
      container
      xs={12}
      sx={{ backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : '#2E3439' }}>
      <Grid item container xs={3} justifyContent="right" position="relative">
        <img style={{ width: '130px', height: '134px' }} src={MobileImg} alt="" />
        <img
          style={{ position: 'absolute', right: '52px', top: '48px', width: '30px', height: '30px' }}
          src={OPLogo}
          alt=""
        />
      </Grid>
      <Grid item container xs={9}>
        <Grid xs={12} alignItems="flex-end" paddingTop={4}>
          <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontWeight: 800 }}>
            {t('app.php.subscriptions.startSyncYourPortfolio')}
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={5.9} container alignItems="center" columnSpacing={1}>
            <Grid item xs={3}>
              <img src={PlayStoreQR} alt="" style={{ height: '55px', width: '55px' }} />
            </Grid>
            <Grid item xs={9}>
              <Link
                href="https://play.google.com/store/apps/details?id=com.optionsplay.app"
                target="_blank"
                rel="noreferrer">
                <img src={PlayStoreLink} alt="" style={{ height: '35px', width: '121px' }} />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={6} container alignItems="center" columnSpacing={1}>
            <Grid item xs={3}>
              <img src={AppStoreQR} alt="" style={{ height: '55px', width: '55px' }} />
            </Grid>
            <Grid item xs={9}>
              <Link href="https://apps.apple.com/us/app/optionsplay/id1589738059" target="_blank" rel="noreferrer">
                <img src={AppStoreLink} alt="" style={{ height: '35px', width: '121px' }} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const SubscriptionHeaderWidget: React.FC<{ label: ReactNode }> = ({ label }) => {
  const { t } = React.useContext(LocalizationContext);
  return (
    <Grid container justifyContent="space-between" marginTop={1}>
      <Grid item xs={10}>
        {label}
      </Grid>
      <Grid item xs={2} justifyContent="flex-end" alignItems="baseline">
        <Box style={{ textAlign: 'center' }}>
          <Typography style={{ fontSize: '10px', fontWeight: 400 }}>{t('app.php.subscriptions.securedBy')}</Typography>
          <AuthorizeSymbolWidget />
        </Box>
      </Grid>
    </Grid>
  );
};

export const AuthorizeSymbolWidget: React.FC = () => {
  const AUTHORIZE_NET_SEAL_CUSTOMSER_ID = 'ac1e3f45-11d7-4988-b40e-c1c20eebb4c3';
  const pathname = window.location.href;
  return (
    <Box style={{ textAlign: 'center', margin: '8px' }}>
      <Link
        href={`https://verify.authorize.net/anetseal/?pid=${AUTHORIZE_NET_SEAL_CUSTOMSER_ID}&amp;rurl=${pathname}`}
        target="_blank"
        rel="noreferrer">
        <Box
          component="img"
          src={AuthroizeLogo}
          alt="Logo"
          sx={{
            display: 'block',
            mx: 'auto',
            width: '68px',
            height: '55px',
          }}></Box>
      </Link>
    </Box>
  );
};
