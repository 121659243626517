import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { OptionsPlayTextField } from './options-play-components';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';

interface IOptionsPlayDatePickerWidgetProps {
  value: string | Date;
  onChangeDate: (e: any) => void;
  onBlur: (e: any) => void;
  min?: Date | undefined;
  max?: Date | undefined;
}
export const OptionsPlayDatePickerWidget: React.FC<IOptionsPlayDatePickerWidgetProps> = ({
  value,
  onChangeDate,
  onBlur,
  min,
  max,
}: IOptionsPlayDatePickerWidgetProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        /**
         * For Value condition has been written due to value is coming undefined in intial load
         * Thus field shows red border.
         * So,show the current date if value is undefined.
         */
        value={value ? DateTimeHelper.resolveDate(value) : DateTimeHelper.getCurrentDate()}
        onChange={(e) => onChangeDate(e)}
        format="MM/dd/yyyy"
        minDate={min}
        maxDate={max}
        slotProps={{
          textField: {
            variant: 'outlined',
            sx: {
              button: { ml: 0, p: 0 },
              svg: { width: '18px', height: '18px' },
            },
          },
        }}
        slots={{
          textField: OptionsPlayTextField,
        }}
      />
    </LocalizationProvider>
  );
};
