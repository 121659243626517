import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Grid, Typography, useTheme } from '@mui/material';
import { Aggressiveness, TimeFrame } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { defaultAggressivenessPutState, defaultTimeFramePutState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { TimeFramePaper, TimeframeDescTypography, IncomeSettingsTypography } from './calls';
import { GuideItem } from '../side-menu';

export interface PutsProps {
  callback: (defaultTimeFramePut: TimeFrame, defaultAggressivenessPut: Aggressiveness, isChanged: boolean) => void;
  isCanceled: number;
}

const Puts: React.FC<PutsProps> = ({ callback, isCanceled }: PutsProps) => {
  const [defaultTimeFramePut, setDefaultTimeFramePut] = useRecoilState<TimeFrame>(defaultTimeFramePutState);
  const [defaultAggressivenessPut, setDefaultAggressivenessPut] =
    useRecoilState<Aggressiveness>(defaultAggressivenessPutState);
  const [changed, setChanged] = React.useState(false);
  const configuration = ApplicationContext.configuration;
  const timeFramePut = configuration.applicationConfiguration?.coveredCall.put;
  const aggressivenessPut = configuration.applicationConfiguration?.coveredCall.put.aggressiveness;
  const theme = useTheme();

  const UpdatingApiValue = () => {
    setDefaultTimeFramePut(timeFramePut.timeFrame as TimeFrame);
    setDefaultAggressivenessPut(aggressivenessPut as Aggressiveness);
  };

  useEffect(() => {
    UpdatingApiValue();
  }, [isCanceled]);

  const { t } = React.useContext(LocalizationContext);

  callback(defaultTimeFramePut, defaultAggressivenessPut, changed);

  const handleShort = (): void => {
    setDefaultTimeFramePut(TimeFrame.SHORT);
    setChanged(true);
  };

  const handleMedium = (): void => {
    setDefaultTimeFramePut(TimeFrame.MEDIUM);
    setChanged(true);
  };
  const handleLong = (): void => {
    setDefaultTimeFramePut(TimeFrame.LONG);
    setChanged(true);
  };

  const handleConservativeActive = (): void => {
    setDefaultAggressivenessPut(Aggressiveness.CONSERVATIVE);
    setChanged(true);
  };

  const handleOptimal = (): void => {
    setDefaultAggressivenessPut(Aggressiveness.OPTIMAL);
    setChanged(true);
  };

  const handleAggresive = (): void => {
    setDefaultAggressivenessPut(Aggressiveness.AGGRESSIVE);
    setChanged(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 0.5 }}>
          <GuideItem selector=".timeFrame_helpPinPlaceholder" />
          {t('app.php.profile.incomeSettings.labels.chooseYourDesired')}{' '}
          <strong>{t('app.php.profile.incomeSettings.labels.timeframe')}</strong>
        </Typography>
        <Grid container direction="row">
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.timeFrame.light} onClick={handleShort} variant="outlined" square>
              <IncomeSettingsTypography>
                Short <br />
                {`[${timeFramePut.shortTimeFrameLowerBound}-${timeFramePut.shortTimeFrameHigherBound} days]`}
              </IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultTimeFramePut === TimeFrame.SHORT ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.timeFrame.main} onClick={handleMedium} variant="outlined" square>
              <IncomeSettingsTypography>
                Medium
                <br />
                {`[${timeFramePut.mediumTimeFrameLowerBound}-${timeFramePut.mediumTimeFrameHigherBound} days]`}
              </IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultTimeFramePut === TimeFrame.MEDIUM ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.timeFrame.dark} onClick={handleLong} variant="outlined" square>
              <IncomeSettingsTypography>
                Long
                <br />
                {`[${timeFramePut.longTimeFrameLowerBound}-${timeFramePut.longTimeFrameHigherBound} days]`}
              </IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultTimeFramePut === TimeFrame.LONG ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          {defaultTimeFramePut === TimeFrame.SHORT ? (
            <Typography variant="body1">
              <strong>{t('app.php.how.incomeStrategies.titles.shortTerm')}</strong> -{' '}
              {`${t('app.php.how.incomeStrategies.descriptions.activeInvestors')}`}
            </Typography>
          ) : null}
          {defaultTimeFramePut === TimeFrame.MEDIUM ? (
            <Typography variant="body1">
              <strong>{t('app.php.how.incomeStrategies.titles.mediumTerm')}</strong> -{' '}
              {`${t('app.php.how.incomeStrategies.descriptions.casualInvestors')}`}
            </Typography>
          ) : null}
          {defaultTimeFramePut === TimeFrame.LONG ? (
            <Typography variant="body1">
              <strong>{t('app.php.how.incomeStrategies.titles.longTerm')}</strong> -{' '}
              {`${t('app.php.how.incomeStrategies.descriptions.lessActiveInvestors')}`}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 0.5 }}>
          <GuideItem selector=".riskTolerance_helpPinPlaceholder" />
          {t('app.php.profile.incomeSettings.labels.chooseYourDesired')}{' '}
          <strong>{t('app.php.profile.incomeSettings.labels.riskTolerance')}</strong>
        </Typography>
        <Grid container direction="row">
          <Grid item xs={4}>
            <TimeFramePaper
              color={theme.palette.tolerance.light}
              onClick={handleConservativeActive}
              variant="outlined"
              square>
              <IncomeSettingsTypography>Conservative</IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultAggressivenessPut === Aggressiveness.CONSERVATIVE ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.tolerance.main} onClick={handleOptimal} variant="outlined" square>
              <IncomeSettingsTypography>Optimal</IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultAggressivenessPut === Aggressiveness.OPTIMAL ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.tolerance.dark} onClick={handleAggresive} variant="outlined" square>
              <IncomeSettingsTypography>Aggressive</IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultAggressivenessPut === Aggressiveness.AGGRESSIVE ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
        </Grid>
        {defaultAggressivenessPut === Aggressiveness.CONSERVATIVE ? (
          <Typography variant="body1">
            <strong>{t('app.php.how.incomeStrategies.titles.conservative')}</strong> -{' '}
            {`${t('app.php.how.incomeStrategies.descriptions.minimumRiskForStock')}`}
          </Typography>
        ) : null}
        {defaultAggressivenessPut === Aggressiveness.OPTIMAL ? (
          <Typography variant="body1">
            <strong>{t('app.php.how.incomeStrategies.titles.optimal')}</strong> -{' '}
            {`${t('app.php.how.incomeStrategies.descriptions.balancedRisk')}`}
          </Typography>
        ) : null}
        {defaultAggressivenessPut === Aggressiveness.AGGRESSIVE ? (
          <Typography variant="body1">
            <strong>{t('app.php.how.incomeStrategies.titles.aggressive')}</strong> -{' '}
            {`${t('app.php.how.incomeStrategies.descriptions.shortputMaximumReturn')}`}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Puts;
