import { Box, Button, Collapse, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { CombinationSentiment, EventType, PortfolioType, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import { ExpandedQuoteHubWidget, FlagWidget, IconComponent, TrendingWidget } from '../common';
import { GridItemWithRightBorder, OPBoldTypograpghy, OPLightTypograpghy, OPSemiBoldTypograpghy } from '../styled';
import { PortfolioCombinationEntity, isNordic, isTmx } from '@op/shared/src/models';
import React, { useEffect, useState } from 'react';
import {
  expandedQuotesState,
  isDataLoadingState,
  subViewState,
  tradeSimulatorOperationTypeState,
  viewState,
} from '@op/shared/src/states';
import {
  isSelectedRecommendedStateOpenState,
  portfolioManagementSelectedCombinationState,
  portfolioSelectedEntityState,
} from '@op/shared/src/states/portfolio-states';
import {
  portfolioManagementCombinationsUpdaterState,
  selectedRecommendationState,
} from '@op/shared/src/states/portfolio-management-states';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { CompanyTitleWidget } from './company-title-widget';
import { IData } from '@op/shared/src/models/what/data-props';
import LocalizationContext from '../react-i18next/localization-context';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { PortfolioCombination } from '@op/shared/src/models/portfolio/portfolio-combination-model';
import { PortfolioCombinationAlertWidget } from './portfolio-combination-alert-widget';
import { PortfolioCombinationPositionsWidget } from './portfolio-combination-positions-widget';
import { PortfolioPopWidget } from './portfolio-pop-widget';
import TDAmeritradeIcon from '@op/shared/assets/images/TDAmeritrade.png';
import { ToExtendedChange2 } from '../common/to-extended-change';
import formatting from '@op/shared/src/models/how/formatting';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';
import ApplicationContext from '@op/shared/src/models/how/application-context';

export interface IPortfolioCombinationDetailsWidgetProps extends IData<PortfolioCombination> {
  entity: PortfolioCombinationEntity;
  index: number;
  hasQuotes: boolean;
}

export const PortfolioCombinationDetailsWidget: React.FC<IPortfolioCombinationDetailsWidgetProps> = ({
  data,
  entity,
  index,
  hasQuotes,
}: IPortfolioCombinationDetailsWidgetProps) => {
  const fetchAndSetData = useFetchAndSetData();
  const navigate = useNavigate();
  const resetPortfolioManagementSelectedCombination = useResetRecoilState(portfolioManagementSelectedCombinationState);
  const [portfolioSelectedEntity, setportfolioSelectedEntity] = useRecoilState(portfolioSelectedEntityState);
  const resetPortfolioManagementSelectedEntity = useResetRecoilState(portfolioSelectedEntityState);
  const resetSelectedRecommendation = useResetRecoilState(selectedRecommendationState);
  const resetportfolioManagementCombinationsUpdate = useResetRecoilState(portfolioManagementCombinationsUpdaterState);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setView = useSetRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const [isDataLoading, setIsDataLoading] = useRecoilState(isDataLoadingState);
  const { t } = React.useContext(LocalizationContext);
  const [shouldShowPositions, setShouldShowPositions] = useState(false);
  // const [isAutoSelectCombination, setIsAutoSelectCombination] = useState(true);
  const setIsSelectedRecommendedStateOpen = useSetRecoilState(isSelectedRecommendedStateOpenState);
  const quotes = useRecoilValue(expandedQuotesState(data.symbolsToSubscribe()));
  const { pathname }: any = useLocation();

  useEffect(() => {
    for (let position of data.positions) {
      const quote = quotes.find((q) => q?.symbol === position.symbol);
      if (quote) {
        position.underlyingQuote = quote;
      }
      const positionQuote = quotes.find((q) => q?.symbol === position.ulSymbol);
      if (positionQuote) {
        position.positionQuote = positionQuote;
      }
    }
  }, [quotes]);

  /**
   * Below useeffect is required for auto selection
   * when the list order changes
   */
  useEffect(() => {
    if (index !== 0) {
      return;
    }
    (async () => {
      await onPortfolioSelected(entity);
    })();
  }, [entity]);

  /*
    Redirection from Trade Idea Or Watchlist
    state !== null (this condition need to be used in above useEffect to select the particular symbol)
  */

  // useEffect(() => {
  //   if (entity.portfolioId === state?.id && entity.symbol === state?.symbol) {
  //     (async () => {
  //       resetPortfolioManagementSelectedCombination();
  //       resetPortfolioManagementSelectedEntity();
  //       resetSelectedRecommendation();
  //       resetportfolioManagementCombinationsUpdate();
  //       await onPortfolioSelected(entity);
  //     })();
  //   }
  // }, [state, entity]);

  const selectedPortfolioPositionLogObj = () => {
    const obj = {
      symbol: entity.symbol.trim().toUpperCase(),
      combinationName: data.positions.length > 0 ? data.positions[0].positionName() : '',
      portfolioName: entity.portfolioName,
      pottfolioId: entity.portfolioId,
      actionItems: [],
    };
    return obj;
  }

  const onPortfolioSelected = async (entity: PortfolioCombinationEntity) => {
    if (isDataLoading) {
      return false;
    }
    setIsDataLoading(true);
    const symbol = entity.symbol.trim().toUpperCase();
    const isSucessful = await fetchAndSetData(symbol, undefined);
    if (!isSucessful) {
      setIsDataLoading(false);
      return false;
    }
    setSubView(undefined);
    setView('portfolioManagement');
    setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
    resetPortfolioManagementSelectedCombination();
    resetPortfolioManagementSelectedEntity();
    resetSelectedRecommendation();
    resetportfolioManagementCombinationsUpdate();
    setportfolioSelectedEntity(entity);
    setIsSelectedRecommendedStateOpen(false);
    setIsDataLoading(false);
    /**
     * Below line is way of resetting navigation state with in the same route.
     */
    ApplicationContext.userActivityHub?.logActivity(
      'positionsManagementSelectedPortfolioPosition',
      'positionsManagementSelectedPortfolioPosition',
      selectedPortfolioPositionLogObj(),
      EventType.Click,
      symbol as string,
    );
    return true;
  };

  // useEffect(() => {
  //   if (!portfolioSelectedEntity || !isAutoSelectCombination || isDataLoading) {
  //     return;
  //   }
  //   // TODO: recheck data
  //   if (portfolioSelectedEntity.hash !== data.hash) {
  //     return;
  //   }
  //   (async () => {
  //     console.log('this executes2');
  //     const isSuccessful = await onPortfolioSelected();
  //     setIsAutoSelectCombination(!isSuccessful);
  //   })();
  // }, [isAutoSelectCombination]);

  const theme = useTheme();
  const sentiment = data.sentiment();

  const renderCostBasis = () => {
    return (
      <Grid container className="js-porfolio-accounts-cost-basis" justifyContent={'end'}>
        <OPLightTypograpghy sx={{ pr: 1 }}>{t('app.php.common.labels.costBasis')}</OPLightTypograpghy>
        <OPBoldTypograpghy>{data.costBasis().toFixed(2)}</OPBoldTypograpghy>
      </Grid>
    );
  };

  const renderMark = () => {
    return (
      <Grid container className="js-porfolio-accounts-dtl-mark" alignItems={'center'}>
        <OPLightTypograpghy sx={{ pr: 1 }}>{t('app.php.what.portfolio.labels.mark')}</OPLightTypograpghy>
        {data.mark === 0 && hasQuotes ? (
          <Skeleton animation="wave" variant="rectangular" height={15} width={50} />
        ) : (
          <OPBoldTypograpghy>{hasQuotes ? NumberFormatHelper.toFractionalString(data.mark) : 'N/A'}</OPBoldTypograpghy>
        )}
      </Grid>
    );
  };

  const renderUnrealizedPL = () => {
    return (
      <Grid container className="js-porfolio-accounts-unrealize-pl" justifyContent={'end'}>
        <OPLightTypograpghy sx={{ pr: 1 }}>{t('app.php.what.portfolio.labels.unrealizedPL')}</OPLightTypograpghy>
        <OPBoldTypograpghy>
          <ToExtendedChange2
            change={data.unrealizedProfitAndLoss()}
            percentageChange={data.unrealizedProfitAndLossPercentage()}
            asIntFractional={true}
            numberOfFractionalDigits={0}
            language="en-US"
            showCurrencySymbolForNumbers={true}
          />
        </OPBoldTypograpghy>
      </Grid>
    );
  };

  const renderSymbolWithName = () => {
    return (
      <Grid container alignItems={'center'} pr={0.5}>
        <Grid
          item
          container
          xs={11}
          alignItems="center"
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography
            className="js-porfolio-accounts-symbol-icon"
            variant="subtitle1"
            fontWeight="bold"
            component="span"
            sx={{ textTransform: 'capitalize', lineHeight: 0.7, p: 0.8, pl: 0 }}>
            {formatting.symbolDotExchangeToSymbol(data.symbol)}
          </Typography>
          <CompanyTitleWidget symbol={data.symbol} hasQuotes={hasQuotes} />
        </Grid>
        <Grid item xs={1}>
          <FlagWidget exchange={formatting.getExchange(data.symbol)} variant={'square'} />
        </Grid>
      </Grid>
    );
  };

  const renderPortfolioAccountName = () => {
    return (
      <Grid container alignItems="center">
        <Grid item>
          {data.portfolioTypeStatus === PortfolioType.TDAmeritrade ? (
            <img
              alt="TDAmeritrade logo"
              src={TDAmeritradeIcon}
              style={{ width: 17, height: 17, marginTop: 5, marginRight: 3 }}
            />
          ) : data.isGlobal ? (
            <div style={{ marginRight: 3 }}>
              <IconComponent name="globalPortfolio" size={20} />
            </div>
          ) : (
            <div style={{ marginRight: 3 }}>
              <IconComponent name="personalPortfolioIcon" size={20} stroke={theme.palette.primary.main} />
            </div>
          )}
        </Grid>
        <Grid item maxWidth={'80%'}>
          <OPLightTypograpghy>{data.portfolioName}</OPLightTypograpghy>
        </Grid>
      </Grid>
    );
  };

  const portfolioDetails = () => {
    let sentimentBorderColor = '';
    if (sentiment === CombinationSentiment.BULLISH) {
      sentimentBorderColor = 'success.main';
    } else if (sentiment === CombinationSentiment.BEARISH) {
      sentimentBorderColor = 'error.main';
    }

    return (
      <TrendingWidget symbol={data.symbol}>
        <Grid container>
          <Grid item xs={0.5} sx={{ pt: 1, pr: 1, height: 'max-content' }}>
            <Box className="js-porfolio-accounts-strategy-icon">
              <Box
                className={`strategy-icon ${data.strategyIconCss}`}
                component="span"
                sx={{
                  lineHeight: 0.5,
                  borderStyle: 'ridge',
                  borderWidth: '2px',
                  borderColor: sentimentBorderColor,
                }}></Box>
            </Box>
          </Grid>
          <Grid item xs={11.5} container alignItems={'center'} pr={1}>
            <Grid item container xs={12} alignItems={'center'} columnSpacing={1}>
              <GridItemWithRightBorder item xs={4.5}>
                {renderSymbolWithName()}
              </GridItemWithRightBorder>
              <GridItemWithRightBorder item xs={2.5}>
                {renderPortfolioAccountName()}
              </GridItemWithRightBorder>
              <Grid item xs={5}>
                {renderUnrealizedPL()}
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center" columnSpacing={1}>
              <GridItemWithRightBorder item xs={4.5} sx={{ pl: 1 }}>
                <ExpandedQuoteHubWidget data={data.symbol} />
              </GridItemWithRightBorder>
              <GridItemWithRightBorder item xs={2.5}>
                <PortfolioPopWidget
                  data={data.symbol}
                  accountId={data.portfolioId}
                  portfolioCombinationHash={data.hash}
                />
              </GridItemWithRightBorder>
              <GridItemWithRightBorder item xs={2}>
                {renderMark()}
              </GridItemWithRightBorder>
              <Grid item xs={3}>
                {renderCostBasis()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TrendingWidget>
    );
  };

  const renderPositions = () => {
    if (data.positions.length === 0) {
      return <OPSemiBoldTypograpghy sx={{ p: 1 }}>No positions to display</OPSemiBoldTypograpghy>;
    }
    return data.positions.map((positionItem, index) => (
      <Grid
        item
        xs={12}
        key={index}
        px={1}
        pt={1}
        sx={{
          backgroundColor: theme.palette.secondary.light,
          '&:last-child .js-position-container': {
            border: 'none',
          },
        }}>
        <PortfolioCombinationPositionsWidget data={positionItem} hasQuotes={hasQuotes} />
      </Grid>
    ));
  };

  const renderDeatilsButton = () => {
    return (
      <Button
        className="js-porfolio-accounts-details"
        variant="text"
        size="small"
        sx={{ fontWeight: 'bold' }}
        onClick={(e) => {
          e.stopPropagation();
          setShouldShowPositions(!shouldShowPositions);
        }}
        endIcon={shouldShowPositions ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}>
        {t('app.php.why.labels.details')}
      </Button>
    );
  };

  const renderDTEView = () => {
    const DTE = data.daysToExpiry();
    const daysLabel = DTE === 1 ? 'day' : 'days';
    if (DTE === -999999) {
      return null;
    }
    return (
      <GridItemWithRightBorder item container xs={2} columnSpacing={1} justifyContent="flex-start">
        <>
          <Grid item sx={{ pr: 1 }}>
            <OPLightTypograpghy>{t('app.php.common.titles.daysToExpiry')}</OPLightTypograpghy>
          </Grid>
          <Grid item>
            <OPBoldTypograpghy>{`${DTE} ${daysLabel}`}</OPBoldTypograpghy>
          </Grid>
        </>
      </GridItemWithRightBorder>
    );
  };

  const portfolioPositionInfo = () => {
    // xs property has decimal grid number to match the combination details UI lines
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <GridItemWithRightBorder item xs={6.74}>
          <OPBoldTypograpghy noWrap>{data.nameInPortfolio}</OPBoldTypograpghy>
        </GridItemWithRightBorder>
        {renderDTEView()}
        <Grid item xs={3} textAlign={'end'}>
          {renderDeatilsButton()}
        </Grid>
      </Grid>
    );
  };

  const portfolioPositionsDetail = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={1}>
            <Box>
              <PortfolioCombinationAlertWidget data={data} />
              {/* <PortfolioCombinationNotificationWidget data={combination} /> */}
            </Box>
          </Grid>
          <Grid
            className={'js-portfolio-position-info'}
            item
            xs={11}
            sx={{
              boxShadow: '0px 2px 2px rgba(0,0,0,0.1) inset',
              borderLeft: `0.5px solid ${theme.palette.grey[400]}`,
              borderTop: `0.5px solid ${theme.palette.grey[400]}`,
              borderRadius: '8px 0 0 0',
              px: 1,
              py: 0.2,
            }}>
            {portfolioPositionInfo()}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={shouldShowPositions} timeout="auto" unmountOnExit>
            <Grid container>{renderPositions()}</Grid>
          </Collapse>
        </Grid>
      </>
    );
  };

  return (
    <Grid container alignItems={'center'} onClick={async () => await onPortfolioSelected(entity)}>
      <Grid item xs={12}>
        {portfolioDetails()}
      </Grid>
      <Grid item xs={12}>
        {portfolioPositionsDetail()}
      </Grid>
    </Grid>
  );
};
