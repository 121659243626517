import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, Typography, useTheme } from '@mui/material';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { howWhyRanksDataState, isDataLoadingState, whyDataState } from '@op/shared/src/states';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { SkeletonLoadingChartDetails } from './mid-panel-skeleton';
import { GuideItem } from '../side-menu';

export const ChartInformationWidget: React.FC = () => {
  const isDataLoading = useRecoilValue(isDataLoadingState);
  const whyData = useRecoilValue(whyDataState);
  const howWhyRank = useRecoilValue(howWhyRanksDataState);
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme();

  if (isDataLoading || !whyData || !howWhyRank.symbol) {
    return <SkeletonLoadingChartDetails></SkeletonLoadingChartDetails>;
  }
  const dividends = whyData.exDividends;
  const symbol = howWhyRank.symbol;

  const formatDividendsDate = (date: string) => {
    const dividendsDate = DateTimeHelper.format(date);
    const daysFromNow = DateTimeHelper.daysFromNow(date);
    if (daysFromNow < 0) {
      return '';
    }
    return `${dividendsDate} (${daysFromNow})`;
  };

  const getDividends = () => {
    let result = '';
    if (dividends && dividends?.nextPayout) {
      if (dividends?.payoutFrequency) {
        result += dividends.payoutFrequency.localizedName + ' ';
      }
      if (dividends?.nextPayout) {
        result += `${formatDividendsDate(dividends.exDividendDate)} ${NumberFormatHelper.toCurrency(
          dividends.nextPayout,
        )}`;
      }
    } else {
      if (!dividends?.payoutFrequency || dividends?.payoutFrequency?.localizedName === 'Not Specified') {
        result += formatting.symbolDotExchangeToSymbol(symbol as string) + ' ';
        result += t('app.php.why.text.notPayDividend');
      }
    }
    return result;
  };

  const getReasonsDetails = () => {
    if (!whyData.tradeIdeas) {
      return;
    }
    return (
      <Carousel
        autoPlay={false}
        cycleNavigation={whyData.tradeIdeas[0].rules.length > 1 ? true : false}
        navButtonsAlwaysVisible
        indicators={false}
        navButtonsProps={{
          style: {
            backgroundColor: 'transparent',
            borderRadius: theme.shape.borderRadius,
            // height: '100%',
            width: 8,
            margin: 2,
          },
        }}
        // navButtonsWrapperProps={{
        //   style: {
        //     paddingRight: 0,
        //   },
        // }}
        NextIcon={<KeyboardArrowRightIcon sx={{ color: theme.palette.info.light }} />}
        PrevIcon={<KeyboardArrowLeftIcon sx={{ color: theme.palette.info.light }} />}>
        {whyData.tradeIdeas[0].rules.map((item: any, i) => (
          <Grid key={i} container alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body1" px={3}>
                <strong>{item.reason}:</strong>
                <span> {item.sentence}</span>
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Carousel>
    );
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12} pl={3}>
        <Typography variant="body1" component="span" fontWeight="bold" sx={{ pr: 0.5 }}>
          {t('app.php.why.labels.dividends')}:
        </Typography>
        <Typography variant="body1" component="span">
          {getDividends()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {getReasonsDetails()}
      </Grid>
      <Grid item xs={12} pl={3}>
        <GuideItem selector=".priceAction_helpPinPlaceholder" />
        <Typography variant="body1" component="span" fontWeight="bold" sx={{ pr: 0.5 }}>
          {t('app.php.why.labels.priceAction')}:
        </Typography>
        <Typography variant="body1" component="span">
          {whyData.sentence}
        </Typography>
      </Grid>
    </Grid>
  );
};
