import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { SubscriptionPaymentForms } from '@op/shared/src/models';
import { EventType, SubscriptionStatus } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import {
  CardPaymentTypeState,
  SubscriptionPaymentFormsState,
  accountState,
  currentSubscriptionState,
  selectedSymbolState,
} from '@op/shared/src/states';
import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../../styled';
import { EditCardInfoWidget } from './edit-card-info-widget';
import { SubscriptionHeaderWidget } from './subscription-footer-widget';
import { ISubscriptionWidgetProps } from './subscription-widget';
import { BillingInfo, CardInfo } from './validation';

export const CardInfoWidget: React.FC<ISubscriptionWidgetProps> = ({
  activeStep,
  setActiveStep,
}: ISubscriptionWidgetProps) => {
  const CurrentSubscriptionData = useRecoilValue(currentSubscriptionState);
  const cardData = useRecoilValue(SubscriptionPaymentFormsState);
  const cardType = useRecoilValue(CardPaymentTypeState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const billingData = useRecoilValue(SubscriptionPaymentFormsState);
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const [errors, setErrors] = React.useState(new SubscriptionPaymentForms());
  const account = useRecoilValue(accountState);

  if (!CurrentSubscriptionData) {
    return null;
  }

  if (!account || !account.securityModel) {
    return;
  }

  const subscriptionstatus = CurrentSubscriptionData.currentSubscription?.subscriptionStatus;

  const getPrice = (type: string) => {
    if (CurrentSubscriptionData.currentPricingModel) {
      if (type === 'monthly') {
        return CurrentSubscriptionData.currentPricingModel?.userMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.currentPricingModel?.userAnnualPrice.toFixed(2);
    }
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.pricingModels &&
      CurrentSubscriptionData.pricingModels.length > 0
    ) {
      if (type === 'monthly') {
        return CurrentSubscriptionData.pricingModels[0].userMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.pricingModels[0].userAnnualPrice.toFixed(2);
    }
  };

  const getActualPrice = (type: string) => {
    if (CurrentSubscriptionData.currentPricingModel) {
      if (type === 'monthly') {
        return CurrentSubscriptionData.currentPricingModel?.regularMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.currentPricingModel?.regularAnnualPrice.toFixed(2);
    }
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.pricingModels &&
      CurrentSubscriptionData.pricingModels.length > 0
    ) {
      if (type === 'monthly') {
        return CurrentSubscriptionData.pricingModels[0].regularMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.pricingModels[0].regularAnnualPrice.toFixed(2);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    logActivity('BUTTON', 'paymentsAddCreditCardBackButton');
    logActivity('BUTTON', 'paymentsAddBilllingAddressBackButton');
  };

  const handleSubmit = () => {
    const errors = CardInfo(cardData, cardType);
    const billingErrors = BillingInfo(billingData);
    setErrors({ ...errors, ...billingErrors });
    if (Object.keys(errors).length > 0 || Object.keys(billingErrors).length > 0) {
      return;
    }
    setActiveStep(activeStep + 1);
    logActivity('BUTTON', 'paymentsAddCreditCardNextButton');
    logActivity('BUTTON', 'paymentsAddBilllingAddressNextButton');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const renderPlanHeader = () => {
    const renderMonthlySavedAmount = Number(getActualPrice('monthly')) - Number(getPrice('monthly'));
    const renderAnnualSavedAmount = Number(getActualPrice('yearly')) - Number(getPrice('yearly'));
    return (
      <Grid item xs={12} container columnSpacing={1}>
        <Grid item>
          <Button
            sx={{
              cursor: 'text',
              '&:hover': {
                backgroundColor: cardData.plan === 'Monthly' ? '' : theme.palette.primary.main,
              },
            }}
            disableRipple
            variant={cardData.plan === 'Monthly' ? 'text' : 'contained'}>
            {cardData.plan === 'Monthly' ? (
              <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                {`${t('app.php.subscriptions.monthlyPlanChosen')} $${getPrice('monthly')}`}

                {renderMonthlySavedAmount > 0
                  ? ` (${t('app.php.subscriptions.saved')} $${renderMonthlySavedAmount})`
                  : ''}
              </OPBoldTypograpghy>
            ) : (
              <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                {`${t('app.php.subscriptions.annualPlanChosen')}  $${getPrice('yearly')}`}
              </OPBoldTypograpghy>
            )}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={cardData.plan === 'Monthly' ? 'contained' : 'text'}
            onClick={() => setActiveStep(activeStep - 1)}>
            {cardData.plan === 'Monthly' ? (
              <OPBoldTypograpghy style={{ fontSize: '14px' }}>
                {t('app.php.subscriptions.changePlanToYearly')}
                {renderAnnualSavedAmount > 0
                  ? ` ${t('app.php.subscriptions.andSave')} $${renderAnnualSavedAmount} `
                  : ''}
              </OPBoldTypograpghy>
            ) : (
              <OPBoldTypograpghy
                style={{ fontSize: '14px' }}
                sx={{ color: cardData.plan === 'Monthly' ? '#fff' : theme.palette.grey[500] }}>
                {t('app.php.subscriptions.changePlan')}
              </OPBoldTypograpghy>
            )}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderCardDisclaimer = () => {
    return (
      <Grid item xs={12} paddingX={1}>
        <Typography
          style={{
            color: theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[500],
            fontWeight: 500,
            fontSize: 12,
          }}>
          {t('app.php.subscriptions.neverSellPersonalInformation')}
        </Typography>
      </Grid>
    );
  };

  const renderCardInfoView = () => {
    const plan = CurrentSubscriptionData.currentSubscription?.billingModel;
    const expiryDate = CurrentSubscriptionData.currentSubscription?.expirationDate;
    const accountName = account.securityModel.displayName;
    if (
      subscriptionstatus === SubscriptionStatus.ACTIVE ||
      subscriptionstatus === SubscriptionStatus.CANCELED_NOT_EXPIRED
    ) {
      return (
        <SubscriptionHeaderWidget
          label={
            <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: 16, margin: 6 }}>
              {t('app.php.subscriptions.subscriptionManageCardsBillingAdd')}
            </OPBoldTypograpghy>
          }
        />
      );
    } else if (subscriptionstatus === SubscriptionStatus.EXPIRED) {
      return (
        <SubscriptionHeaderWidget
          label={
            <Grid item xs={12} columnSpacing={1}>
              <OPBoldTypograpghy style={{ color: theme.palette.success.main, fontSize: '14px' }}>
                {`${t('app.php.subscriptions.hi')} ${accountName}, ${t('app.php.subscriptions.goodToSeeYouBack')}`}
              </OPBoldTypograpghy>
              <OPBoldTypograpghy style={{ color: theme.palette.error.main, fontSize: '14px' }}>
                {`${t('app.php.subscriptions.your')} ${plan} ${t(
                  'app.php.subscriptions.subscriptionExpiredOn',
                )} ${DateTimeHelper.format(expiryDate)}`}
              </OPBoldTypograpghy>
            </Grid>
          }
        />
      );
    }
    return '';
  };

  return (
    <Grid container justifyContent="center" rowSpacing={1}>
      <Grid item xs={12} columnSpacing={4}>
        {renderCardInfoView()}
      </Grid>

      <Grid item container xs={10} justifyContent="center" rowSpacing={1}>
        <Grid item xs={12}>
          {renderPlanHeader()}
        </Grid>
        <Grid item xs={12}>
          {renderCardDisclaimer()}
        </Grid>
        <Grid item xs={12}>
          <EditCardInfoWidget errors={errors} setErrors={setErrors} />
        </Grid>
      </Grid>

      <Grid item xs={12} container justifyContent="flex-end" p={2} columnSpacing={2}>
        <Grid item>
          <Button onClick={handleBack} sx={{ color: theme.palette.primary.light }} size="large" variant="outlined">
            <ArrowBackIosIcon fontSize="small" sx={{ color: theme.palette.primary.light }} />
            <Typography variant="button" sx={{ color: theme.palette.text.primary }}>
              {t('app.php.common.buttons.back')}
            </Typography>
          </Button>
        </Grid>

        <Grid item>
          <Button variant="contained" size="large" color="primary" onClick={handleSubmit}>
            <OPBoldTypograpghy variant="button"> {t('app.php.common.buttons.continue')}</OPBoldTypograpghy>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
