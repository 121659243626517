import React from 'react';
import { Typography, Grid, Paper, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LocalizationContext from '../react-i18next/localization-context';
import { Aggressiveness, TimeFrame } from '@op/shared/src/models/enums/enums';
import { useRecoilState } from 'recoil';
import { defaultAggressivenessCallState, defaultTimeFrameCallState } from '@op/shared/src/states';
import { useEffect } from 'react';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { GuideItem } from '../side-menu';

export const TimeFramePaper = styled(Paper)(({ theme, color }) => ({
  padding: theme.spacing(0),
  textAlign: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '45px',
  backgroundColor: `${color}`,
  borderColor: theme.palette.secondary.dark,
  borderCollapse: 'collapse',
}));

export const TimeframeDescTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  height: '20px',
}));

export const IncomeSettingsTypography = styled(Typography)(({ theme }) => ({
  // Note : Timeframe and tolerance contrast text is same
  color: theme.palette.timeFrame.contrastText,
  fontSize: theme.typography.body1.fontSize,
}));

export interface CallProps {
  callback: (timeFrame: TimeFrame, aggressiveness: Aggressiveness, isChanged: boolean) => void;
  isCanceled: number;
}

const Calls: React.FC<CallProps> = ({ callback, isCanceled }: CallProps) => {
  const [defaultTimeFrameCall, setDefaultTimeFrameCall] = useRecoilState<TimeFrame>(defaultTimeFrameCallState);
  const [defaultAggressivenessCall, setDefaultAggressivenessCall] =
    useRecoilState<Aggressiveness>(defaultAggressivenessCallState);
  const [changed, setChanged] = React.useState(false);
  const configuration = ApplicationContext.configuration;
  const timeFrameCall = configuration.applicationConfiguration?.coveredCall.call;
  const aggressivenessCallValue = configuration.applicationConfiguration?.coveredCall.call.aggressiveness;
  const theme = useTheme();

  const UpdatingApiValue = () => {
    setDefaultTimeFrameCall(timeFrameCall.timeFrame as TimeFrame);
    setDefaultAggressivenessCall(aggressivenessCallValue as Aggressiveness);
  };

  useEffect(() => {
    UpdatingApiValue();
  }, [isCanceled]);

  const { t } = React.useContext(LocalizationContext);

  callback(defaultTimeFrameCall, defaultAggressivenessCall, changed);

  const handleShort = (): void => {
    setDefaultTimeFrameCall(TimeFrame.SHORT);
    setChanged(true);
  };

  const handleMedium = (): void => {
    setDefaultTimeFrameCall(TimeFrame.MEDIUM);
    setChanged(true);
  };
  const handleLong = (): void => {
    setDefaultTimeFrameCall(TimeFrame.LONG);
    setChanged(true);
  };

  const handleConservativeActive = (): void => {
    setDefaultAggressivenessCall(Aggressiveness.CONSERVATIVE);
    setChanged(true);
  };

  const handleOptimal = (): void => {
    setDefaultAggressivenessCall(Aggressiveness.OPTIMAL);
    setChanged(true);
  };

  const handleAggresive = (): void => {
    setDefaultAggressivenessCall(Aggressiveness.AGGRESSIVE);
    setChanged(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 0.5 }}>
          <GuideItem selector=".timeFrame_helpPinPlaceholder" />
          {t('app.php.profile.incomeSettings.labels.chooseYourDesired')}{' '}
          <strong>{t('app.php.profile.incomeSettings.labels.timeframe')}</strong>
        </Typography>
        <Grid container direction="row">
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.timeFrame.light} onClick={handleShort} variant="outlined" square>
              <IncomeSettingsTypography>
                Short <br />{' '}
                {`[${timeFrameCall.shortTimeFrameLowerBound}-${timeFrameCall.shortTimeFrameHigherBound} days]`}
              </IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultTimeFrameCall === TimeFrame.SHORT ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.timeFrame.main} onClick={handleMedium} variant="outlined" square>
              <IncomeSettingsTypography>
                Medium
                <br />
                {`[${timeFrameCall.mediumTimeFrameLowerBound}-${timeFrameCall.mediumTimeFrameHigherBound} days]`}
              </IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultTimeFrameCall === TimeFrame.MEDIUM ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.timeFrame.dark} onClick={handleLong} variant="outlined" square>
              <IncomeSettingsTypography>
                Long
                <br />
                {`[${timeFrameCall.longTimeFrameLowerBound}-${timeFrameCall.longTimeFrameHigherBound} days]`}
              </IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultTimeFrameCall === TimeFrame.LONG ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          {defaultTimeFrameCall === TimeFrame.SHORT ? (
            <Typography variant="body1">
              <strong>{t('app.php.how.incomeStrategies.titles.shortTerm')}</strong> -{' '}
              {`${t('app.php.how.incomeStrategies.descriptions.activeInvestors')}`}
            </Typography>
          ) : null}
          {defaultTimeFrameCall === TimeFrame.MEDIUM ? (
            <Typography variant="body1">
              <strong>{t('app.php.how.incomeStrategies.titles.mediumTerm')}</strong> -{' '}
              {`${t('app.php.how.incomeStrategies.descriptions.casualInvestors')}`}
            </Typography>
          ) : null}
          {defaultTimeFrameCall === TimeFrame.LONG ? (
            <Typography variant="body1">
              <strong>{t('app.php.how.incomeStrategies.titles.longTerm')}</strong> -{' '}
              {`${t('app.php.how.incomeStrategies.descriptions.lessActiveInvestors')}`}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 0.5 }}>
          <GuideItem selector=".riskTolerance_helpPinPlaceholder" />
          {t('app.php.profile.incomeSettings.labels.chooseYourDesired')}{' '}
          <strong>{t('app.php.profile.incomeSettings.labels.riskTolerance')}</strong>
        </Typography>
        <Grid container direction="row">
          <Grid item xs={4}>
            <TimeFramePaper
              color={theme.palette.tolerance.light}
              onClick={handleConservativeActive}
              variant="outlined"
              square>
              <IncomeSettingsTypography>Conservative</IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultAggressivenessCall === Aggressiveness.CONSERVATIVE ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.tolerance.main} onClick={handleOptimal} variant="outlined" square>
              <IncomeSettingsTypography>Optimal</IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultAggressivenessCall === Aggressiveness.OPTIMAL ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TimeFramePaper color={theme.palette.tolerance.dark} onClick={handleAggresive} variant="outlined" square>
              <IncomeSettingsTypography>Aggressive</IncomeSettingsTypography>
            </TimeFramePaper>
            {defaultAggressivenessCall === Aggressiveness.AGGRESSIVE ? (
              <TimeframeDescTypography>
                <ArrowDropUpIcon />
              </TimeframeDescTypography>
            ) : null}
          </Grid>
        </Grid>
        {defaultAggressivenessCall === Aggressiveness.CONSERVATIVE ? (
          <Typography variant="body1">
            <strong>{t('app.php.how.incomeStrategies.titles.conservative')}</strong> -{' '}
            {`${t('app.php.how.incomeStrategies.descriptions.minimumRisk')}`}
          </Typography>
        ) : null}
        {defaultAggressivenessCall === Aggressiveness.OPTIMAL ? (
          <Typography variant="body1">
            <strong>{t('app.php.how.incomeStrategies.titles.optimal')}</strong> -{' '}
            {`${t('app.php.how.incomeStrategies.descriptions.balancedRisk')}`}
          </Typography>
        ) : null}
        {defaultAggressivenessCall === Aggressiveness.AGGRESSIVE ? (
          <Typography variant="body1">
            <strong>{t('app.php.how.incomeStrategies.titles.aggressive')}</strong> -{' '}
            {`${t('app.php.how.incomeStrategies.descriptions.maximumReturn')}`}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Calls;
