import { Box, Grid } from '@mui/material';
import { IDataProps, ILeg, PortfolioManagementCombination } from '@op/shared/src/models';
import { ActionTypes, CombinationType, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import { PortfolioPositionsManagement } from '@op/shared/src/models/portfolio/portfolio-positions-management';
import {
  howDataState,
  portfolioManagementSelectedCombinationState,
  portfolioSelectedCombinationState,
  strategyModifyState,
  tradingRangeSimulatorState,
} from '@op/shared/src/states';
import {
  portfolioAllCombinationsState,
  portfolioFarthestExpirationState,
  portfolioManagementCombinationState,
  selectedRecommendationState,
} from '@op/shared/src/states/portfolio-management-states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SimulatorWidget } from '../how';
import { SimulatorOperationsWidget } from '../how/simulator-operations-widget';
import { PortfolioManagementStrategyWidget } from './portfolio-management-strategy-widget';

export interface IPortfolioManagementStrategiesWidgetProps extends IDataProps<PortfolioPositionsManagement> {}

export const PortfolioManagementStrategiesWidget: React.FC<IPortfolioManagementStrategiesWidgetProps> = () => {
  const howData = useRecoilValue(howDataState);
  const portfolioSelectedCombination = useRecoilValue(portfolioSelectedCombinationState);
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const [farthestExpiration, setFarthestExpiration] = useRecoilState(portfolioFarthestExpirationState);
  const allCombinations = useRecoilValue(portfolioAllCombinationsState);
  const setCombination1 = useSetRecoilState(portfolioManagementCombinationState(1));
  const setCombination2 = useSetRecoilState(portfolioManagementCombinationState(2));
  const [portfolioManagementSelectedCombination, setPortfolioManagementSelectedCombination] = useRecoilState(
    portfolioManagementSelectedCombinationState,
  );
  const setStrategyModify = useSetRecoilState(strategyModifyState);
  const resetPortfolioManagementSelectedRecommendation = useResetRecoilState(selectedRecommendationState);

  useEffect(() => {
    setFarthestExpiration(farthestExpiration);
  }, [farthestExpiration]);

  if (!howData || !tradingRangeSimulator) {
    return <></>;
  }

  const resultingPositions = (recommendationCombination: PortfolioManagementCombination) => {
    const currentCombination = allCombinations[0];
    if (!currentCombination) {
      return [];
    }
    const currentPositions = currentCombination.positions;
    if (!recommendationCombination) {
      return currentPositions;
    }
    return currentPositions.concat(recommendationCombination.positions);
  };

  const onEditCombination = (action: string, legs?: ILeg[]) => {
    if (!portfolioSelectedCombination) {
      return;
    }
    if (action !== ActionTypes.Recommendation) {
      resetPortfolioManagementSelectedRecommendation();
    }
    if (action === ActionTypes.Reset) {
      const recommendationCombination = PortfolioPositionsManagement.assembleCombination(
        undefined,
        CombinationType.PORTFOLIO_ADJUSTMENT,
        undefined,
        howData,
        portfolioSelectedCombination,
      );
      recommendationCombination.profileSentiments();

      const positions = resultingPositions(recommendationCombination);
      const resultingCombination = PortfolioPositionsManagement.assembleCombination(
        positions,
        CombinationType.PORTFOLIO_RESULTING,
        undefined,
        howData,
        portfolioSelectedCombination,
      );
      resultingCombination.profileSentiments();
      setCombination1(recommendationCombination);
      setCombination2(resultingCombination);
      setPortfolioManagementSelectedCombination(resultingCombination);
      setStrategyModify(recommendationCombination);
      return;
    }
    const recommendationCombination = PortfolioPositionsManagement.assembleCombination(
      legs,
      CombinationType.PORTFOLIO_ADJUSTMENT,
      undefined,
      howData,
      portfolioSelectedCombination,
    );
    recommendationCombination.profileSentiments();

    const positions = resultingPositions(recommendationCombination);
    const resultingCombination = PortfolioPositionsManagement.assembleCombination(
      positions,
      CombinationType.PORTFOLIO_RESULTING,
      undefined,
      howData,
      portfolioSelectedCombination,
    );
    resultingCombination.profileSentiments();
    setPortfolioManagementSelectedCombination(resultingCombination);
    setCombination1(recommendationCombination);
    setCombination2(resultingCombination);
    setStrategyModify(recommendationCombination);
  };

  const renderSimulatorOperations = () => {
    const operations = [
      { name: tradeSimulatorOperationType.PLSimulator, title: 'app.php.how.positionsManagement.js.plSimulator' },
      { name: tradeSimulatorOperationType.PLCalculator, title: 'app.php.how.tradingStrategies.js.plCalculator' },
      {
        name: tradeSimulatorOperationType.TradingRangeSimulator,
        title: 'app.php.how.positionsManagement.js.tradingRangeSimulator',
      },
      { name: tradeSimulatorOperationType.PlainEnglish, title: 'app.php.how.tradingStrategies.js.plainEnglish' },
    ];
    return <SimulatorOperationsWidget data={operations} />;
  };

  return (
    <Box px={1}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container columnSpacing={1}>
            {[0, 1, 2].map((i) => (
              <Grid item xs={4} key={i}>
                <PortfolioManagementStrategyWidget id={i} onEditCombinationCallback={onEditCombination} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {renderSimulatorOperations()}
        </Grid>
        <Grid item xs={12}>
          <SimulatorWidget
            combinations={allCombinations}
            combination={portfolioManagementSelectedCombination}
            onEditCombinationCallback={onEditCombination}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
