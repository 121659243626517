import { Grid, Typography, useTheme } from '@mui/material';
import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { GuideWidget } from '../side-menu/guide-tour';
import { MerrillDisclimerWidgetDialog } from './merrill-disclaimer-widget';

const middlePart = {
  margin: '0 auto',
  fontSize: 12,
};
const link = {
  cursor: 'pointer',
  marginRight: '10px',
  verticalAlign: 'middle',
  '&:hover': {
    textDecoration: 'underline !important',
  },
};
export const EmbeddedFooterWidget: React.FC = () => {
  const customization = useRecoilValue(customizationState);
  const [importantDisclosuresModalOpen, setImportantDisclosuresModalOpen] = React.useState(false);
  const theme = useTheme();

  // const stepdivs = [
  //   { target: '.whatPanelSwitcher_helpPinPlaceholder', stepindex: 1 },
  //   { target: '.tradeIdeas_helpPinPlaceholder', stepindex: 2 },
  //   { target: '.six_month_historical_trend_step', stepindex: 3 },
  //   { target: '.grid_technicalRank_helpPinPlaceholder', stepindex: 3 },
  //   { target: '.grid_companyName_helpPinPlaceholder', stepindex: 4 },
  //   { target: '.sentiment_helpPinPlaceholder', stepindex: 5 },
  //   { target: '.symbolInput_helpPinPlaceholder', stepindex: 5 },
  //   { target: '.trend_terms_step', stepindex: 6 },
  //   { target: '.monthControl_helpPinPlaceholder', stepindex: 7 },
  //   { target: '.supportAndResistance_helpPinPlaceholder', stepindex: 8 },
  //   { target: '.priceAction_helpPinPlaceholder', stepindex: 8 },
  //   { target: '.tradingStrategiesTab_helpPinPlaceholder', stepindex: 9 },
  //   { target: '.incomeStrategiesTab_helpPinPlaceholder', stepindex: 9 },
  //   { target: '.sentimentBtnGroup_helpPinPlaceholder', stepindex: 9 },
  //   { target: '.profitAndLossChart_helpPinPlaceholder', stepindex: 10 },
  //   { target: '.tradeButton_helpPinPlaceholder', stepindex: 11 },
  //   { target: '.modifyButton_helpPinPlaceholder', stepindex: 12 },
  //   { target: '.priceAndDataSlider_helpPinPlaceholder', stepindex: 13 },
  //   { target: '.volatilitySlider_helpPinPlaceholder', stepindex: 14 },
  //   { target: '.plainEnglish_helpPinPlaceholder', stepindex: 15 },
  //   { target: '.investmentAmount_helpPinPlaceholder', stepindex: 16 },
  //   { target: '.riskAmount_helpPinPlaceholder', stepindex: 17 },
  //   { target: '.expiryDropdownBox_helpPinPlaceholder', stepindex: 18 },
  //   { target: '.tradingRangeSlider_helpPinPlaceholder', stepindex: 19 },
  //   { target: '.supportAndResistanceSliderMarkers_helpPinPlaceholder', stepindex: 20 },
  //   { target: '.risk_panel_cost_step', stepindex: 21 },
  //   { target: '.risk_panel_max_reward_step', stepindex: 22 },
  //   { target: '.risk_panel_max_risk_step', stepindex: 23 },
  //   { target: '.risk_panel_breakeven_step', stepindex: 24 },
  //   { target: '.risk_panel_pop_step', stepindex: 25 },
  //   { target: '.risk_panel_days_to_expiry_step', stepindex: 26 },
  //   { target: '.whatIfReturnPanels_helpPinPlaceholder', stepindex: 27 },
  //   { target: '.vertical_spread_panel_step', stepindex: 28 },
  //   { target: '.termAnalysis_helpPinPlaceholder', stepindex: 29 },
  //   { target: '.risk_panel_pow_step', stepindex: 30 },
  //   { target: '.ownedSharesPrompt_helpPinPlaceholder', stepindex: 31 },
  //   { target: '.incomePanelDefaultSettings_helpPinPlaceholder', stepindex: 32 },
  //   { target: '.timeFrame_helpPinPlaceholder', stepindex: 33 },
  //   { target: '.riskTolerance_helpPinPlaceholder', stepindex: 34 },
  //   { target: '.incomePanelCurrentSettings_helpPinPlaceholder', stepindex: 35 },
  //   { target: '.incomePanelOptionsGrid_helpPinPlaceholder', stepindex: 36 },
  //   { target: '.strategyConstructor_helpPinPlaceholder', stepindex: 37 },
  //   { target: '.priceBy_helpPinPlaceholder', stepindex: 38 },
  //   { target: '.combinationEditorAddLeg_helpPinPlaceholder', stepindex: 39 },
  //   { target: '.combinationEditorResetLegs_helpPinPlaceholder', stepindex: 40 },
  //   { target: '.combinationEditorActions_helpPinPlaceholder', stepindex: 41 },
  //   { target: '.combinationEditorQuantity_helpPinPlaceholder', stepindex: 42 },
  //   { target: '.combinationEditorExpiry_helpPinPlaceholder', stepindex: 43 },
  //   { target: '.combinationEditorStrike_helpPinPlaceholder', stepindex: 44 },
  //   { target: '.combinationEditorEype_helpPinPlaceholder', stepindex: 45 },
  //   { target: '.combinationEditorPremium_helpPinPlaceholder', stepindex: 46 },
  //   { target: '.combinationEditorWidthAndWingspan_helpPinPlaceholder', stepindex: 47 },
  //   { target: '.sentimentFilterButtons_helpPinPlaceholder', stepindex: 48 },
  // ];

  if (!customization?.showFooter) {
    return null;
  }

  return (
    <Grid container spacing={2} style={{ backgroundColor: theme.palette.grey[900] }}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Typography style={middlePart} color={theme.palette.text.primary}>
            <GuideWidget type="link" />
            <a
              style={link}
              onClick={() => {
                setImportantDisclosuresModalOpen(true);
              }}>
              Important Disclosures
            </a>
            <a
              style={{ ...link, textDecoration: 'none', color: theme.palette.text.primary }}
              href="/merrill-methodology.html"
              target="_blank"
              rel="noopener noreferrer">
              Methodology
            </a>
          </Typography>
          <Grid item xs={12} style={{ paddingTop: 4 }}>
            <Typography
              color={theme.palette.text.primary}
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                textAlign: 'center',
                fontSize: 10,
              }}>
              Commissions, fees, and middle-of-the-month assignments are not considered in any calculations. Commission
              and fees will increase potential loss and lower potential profits. Commission information is available
              upon request from the Merrill Edge Investment Center (1.877.653.4732).
            </Typography>
          </Grid>
        </Grid>
        {importantDisclosuresModalOpen && (
          <MerrillDisclimerWidgetDialog onHandleClose={() => setImportantDisclosuresModalOpen(false)} />
        )}
      </Grid>
    </Grid>
  );
};
