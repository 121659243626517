import { Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { CachedSecurities } from '@op/shared/src/models';
import {
  accountState,
  cachedSecuritiesDataState,
  configurationState,
  customizationState,
  expandedQuotesUpdater,
  fetchQuotes,
  howDataState,
  isDataLoadingState,
  strategyShareDataState,
  subViewState,
  tradeSimulatorOperationTypeState,
  tradesState,
  viewState,
} from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CurrencyWidget, FlagWidget, IconComponent } from '../common';
import { getChartHeight, historicalChartState } from '../op-chart/chart-state';

import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import { tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import formatting from '@op/shared/src/models/how/formatting';
import { getCachedSecurities, getQuotesAndSentiments } from '@op/shared/src/services';
import { sentimentsUpdater } from '@op/shared/src/states/sentiment-hub-states';
import { ExpandedQuoteHubWidget } from '../common';
import { ChartWidget } from '../op-chart/chart-widget';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';
import { useWindowSize } from '../states/use-window-size';
import { CompanySearchWidget } from './company-search-widget';
import { SentimentModel } from '@op/shared/src/hubs/sentiment-model';

interface IQuotebarSearchProps {
  paddingLeft?: number;
  iconBorder?: boolean;
}

//TODO: Use `Creatable` [https://mui.com/components/autocomplete/#creatable] to show previously searched items to user.
//It can be doubled as cached list.
export const QuotebarSearchWidget: React.FC<IQuotebarSearchProps> = (props: IQuotebarSearchProps) => {
  const windowSize = useWindowSize();
  const fetchAndSetData = useFetchAndSetData();
  const navigate = useNavigate();
  const shareRoute = useMatch('/share/*');
  const portfolioRoute = useMatch('/portfolio');
  const howData = useRecoilValue(howDataState);
  const account = useRecoilValue(accountState);
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const trades = useRecoilValue(tradesState);
  const [cachedSecuritiesData, setCachedSecuritiesData] = useRecoilState(cachedSecuritiesDataState);
  const [isDataLoading, setIsDataLoading] = useRecoilState(isDataLoadingState);
  const [view, setView] = useRecoilState(viewState);
  const setExpandedQuotesHub = useSetRecoilState(expandedQuotesUpdater);
  const setSentiments = useSetRecoilState(sentimentsUpdater);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setSubView = useSetRecoilState(subViewState);
  const [isEditable, setEditable] = useState(false);
  const [open, setOpen] = useRecoilState(historicalChartState);
  const resetStrategyShareData = useResetRecoilState(strategyShareDataState);

  const theme = useTheme();

  const fetchCachedSecurities = async () => {
    const cachedSecuritiesRes = await getCachedSecurities(configuration?.additionalData?.allowedSecurities);
    if (cachedSecuritiesRes.hasErrors || !cachedSecuritiesRes.data) {
      return;
    }
    const cachedSecuritiesData = CachedSecurities.fromData(cachedSecuritiesRes.data);
    return cachedSecuritiesData;
  };

  useEffect(() => {
    if (!account || cachedSecuritiesData) {
      return;
    }
    (async () => {
      const fetchedCachedSecuritesData = await fetchCachedSecurities();
      if (!fetchedCachedSecuritesData) {
        return;
      }
      setCachedSecuritiesData(fetchedCachedSecuritesData);
    })();
  }, [account]);

  if (!customization) {
    return null;
  }

  if (!howData) {
    return <>No quote data</>;
  }
  const quote = howData.quote;
  const { name, symbol, exchange } = quote;
  const isPortfolio = portfolioRoute != null;
  const symbolDisplay = formatting.symbolDotExchangeToSymbol(symbol);

  const onSymbolSelect = async (symbol: string) => {
    if (shareRoute) {
      navigate('/');
    }
    resetStrategyShareData();
    const tradeForRuleMatch = trades?.data.find((t) => t.symbol.trim().toUpperCase() === symbol.trim().toUpperCase());
    let ruleMatch: string | undefined;
    if (tradeForRuleMatch) {
      ruleMatch = tradeForRuleMatch.rules.length > 0 ? tradeForRuleMatch.rules[0].ruleMatch : undefined;
    }
    if (isDataLoading) {
      return;
    }
    setIsDataLoading(true);
    const isSuccessful = await fetchAndSetData(symbol, ruleMatch);
    if (!isSuccessful) {
      setIsDataLoading(false);
      return;
    }
    if (isSuccessful) {
      setSubView(undefined);
      setView(view);
      setSimulatorOperation(
        view === 'income' ? tradeSimulatorOperationType.OptionsGrid : tradeSimulatorOperationType.PLSimulator,
      );
      ApplicationContext.userActivityHub?.logActivity('SymbolInput', 'SymbolInput', symbol, 'change', symbol);
    }
    setIsDataLoading(false);
    const quotesAndSentiments = await getQuotesAndSentiments([symbol]);
    if (!quotesAndSentiments) {
      const fetchedQuotes = await fetchQuotes([symbol]);
      if (fetchedQuotes && fetchedQuotes.length > 0) {
        setExpandedQuotesHub(fetchedQuotes);
      }
      return;
    }
    //Note: This fills into recoil, which also updates the cache.
    setExpandedQuotesHub(quotesAndSentiments.quotes);
    setSentiments(quotesAndSentiments.sentiments.map((s) => SentimentModel.fromData(s)));
  };

  // const getVariantByRegion = () => {
  //   return isNordic() || isTmx() ? 'body1' : 'h6';
  // };

  const renderPortfolioQuotebarViewIfPossible = () => {
    if (!isPortfolio) {
      return undefined;
    }

    const handleExpandOpen = () => {
      onExpand();
    };

    const onExpand = () => {
      const height = open.isExpanded ? 310 : getChartHeight(open.modalSize, windowSize);
      setOpen({ ...open, isExpanded: !open.isExpanded, chartAreaHeight: height });
    };

    return (
      <Grid container alignItems="center" columnSpacing={1}>
        <Grid item xs="auto">
          <Typography variant="body1" textAlign="center">
            <TimelineOutlinedIcon
              sx={{ color: 'primary.main', cursor: 'pointer' }}
              fontSize="large"
              onClick={handleExpandOpen}
            />
            {open.isExpanded && <ChartWidget />}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Typography variant="h6">{symbolDisplay}</Typography>
        </Grid>
        <Grid item xs="auto" zeroMinWidth sx={{ maxWidth: { xs: '35%', lg: '40%' } }}>
          <Tooltip title={name} placement="top">
            <Typography variant="h6" noWrap>
              {name}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item container xs={1} display="flex" justifyContent="center">
          <FlagWidget exchange={exchange} variant="square" />
        </Grid>
        <Grid item container xs={1}>
          <CurrencyWidget exchange={exchange} />
        </Grid>
        <Grid item xs="auto">
          <ExpandedQuoteHubWidget data={symbol} variant="subtitle2" />
        </Grid>
      </Grid>
    );
  };

  const onClickGridView = () => {
    if (!account || !account.isSearchBarClickable) {
      return;
    }
    setEditable(customization.showQuotebarInput);
  };

  // const renderNonEditableViewIfPossible = () => {
  //   if (isEditable) {
  //     return undefined;
  //   }
  //   return (
  //     <Grid container alignItems="center" columnSpacing={1} sx={{ cursor: 'pointer' }} onClick={onClickGridView}>
  //       {customization.showQuotebarInput ? (
  //         <Grid item xs="auto">
  //           <SearchIcon id="search-icon" />
  //         </Grid>
  //       ) : null}
  //       <Grid item xs="auto">
  //         <Typography variant={getVariantByRegion()}>{symbolDisplay} </Typography>
  //       </Grid>
  //       <Grid item xs="auto" zeroMinWidth sx={{ maxWidth: { xs: '30%', sm: '20%', md: '20%', lg: '40%' } }}>
  //         <Tooltip title={name} placement="top">
  //           <Typography variant={getVariantByRegion()} noWrap sx={{ maxWidth: '100%', inlineSize: '150px' }}>
  //             {name}
  //           </Typography>
  //         </Tooltip>
  //       </Grid>
  //       <Grid item xs="auto">
  //         <ExpandedQuoteHubWidget data={symbol} variant={getVariantByRegion()} />
  //       </Grid>
  //       <Grid item container xs={1} display="flex" justifyContent="center">
  //         <FlagWidget exchange={exchange} variant="square" />
  //       </Grid>
  //       <Grid item container xs={1}>
  //         <CurrencyWidget exchange={exchange} />
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const renderEditableViewIfPossible = () => {
    if (!isEditable) {
      return undefined;
    }
    return (
      <Grid container alignItems="center" columnSpacing={1} sx={{ cursor: 'pointer', py: 0.3 }}>
        <Grid
          item
          container
          xs={7}
          sx={{
            height: 30,
          }}>
          <Grid
            item
            container
            xs={1.15}
            style={{
              backgroundColor: theme.palette.info.dark,
              alignItems: 'center',
              justifyContent: 'center',
              border: `1px solid ${theme.palette.secondary.dark}`,
              borderRight: 0,
              borderTopLeftRadius: theme.shape.borderRadius,
              borderBottomLeftRadius: theme.shape.borderRadius,
            }}>
            <IconComponent name="searchIcon" stroke={theme.palette.info.light} size={18} />
          </Grid>
          <Grid item xs={10.8} id="SymbolInput" data-name="SymbolInput">
            <CompanySearchWidget
              onSymbolSelected={(v) => onSymbolSelect(v)}
              isQuoteFound={(v) => setEditable(false)}
              message="Search by Symbol / Company Name"
              sx={{
                height: '100%',
                paddingLeft: props.paddingLeft ?? 0,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderLeft: 0,
                borderRadius: 1,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                backgroundColor: theme.palette.selectAndTextField.light,
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={5} px={1}>
          <ExpandedQuoteHubWidget data={symbol} variant="subtitle2" alignRight />
        </Grid>
      </Grid>
    );
  };

  const renderSelectedView = () => {
    /**xs value has been  given 5 instead of 6 to ensure french text */
    return (
      <Grid container alignItems="center" py={0.3}>
        <Grid item xs={customization.showCurrencyOrExchangeFlags ? 5 : 7} container>
          <Grid
            item
            container
            xs={1.5}
            style={{
              backgroundColor: theme.palette.info.dark,
              alignItems: 'center',
              justifyContent: 'center',
              border: `1px solid ${theme.palette.secondary.dark}`,
              borderRight: 0,
              borderTopLeftRadius: theme.shape.borderRadius,
              borderBottomLeftRadius: theme.shape.borderRadius,
            }}>
            <IconComponent name="searchIcon" stroke={theme.palette.info.light} size={18} />
          </Grid>
          <Grid
            item
            xs={10.5}
            container
            onClick={onClickGridView}
            sx={{
              backgroundColor: theme.palette.selectAndTextField.light,
              border: `1px solid ${theme.palette.secondary.dark}`,
              borderLeft: 0,
              height: '100%',
              cursor: 'pointer',
              borderTopRightRadius: theme.shape.borderRadius,
              borderBottomRightRadius: theme.shape.borderRadius,
              pl: 1,
            }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  width: '100%',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  paddingTop: 2,
                  paddingBottom: 2,
                  fontSize: 16,
                }}>
                <span style={{ fontWeight: '700', paddingRight: 8 }}>{symbolDisplay}</span>
                {name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={customization.showCurrencyOrExchangeFlags ? 7 : 5} px={1}>
          {customization.showCurrencyOrExchangeFlags && (
            <>
              <span
                style={{
                  paddingRight: 4,
                  paddingTop: 2,
                }}>
                <FlagWidget exchange={exchange} variant="square" />
              </span>
              <span style={{ paddingRight: 4 }}>
                <CurrencyWidget exchange={exchange} />
              </span>
            </>
          )}
          <span style={{ display: 'inline-block', margin: 'auto' }}></span>
          <span>
            <ExpandedQuoteHubWidget data={symbol} variant="subtitle2" />
          </span>
        </Grid>
      </Grid>
    );
  };

  const render = () => {
    if (isPortfolio) {
      return renderPortfolioQuotebarViewIfPossible();
    }
    if (isEditable) {
      return renderEditableViewIfPossible();
    }
    if (!isEditable) {
      return renderSelectedView();
    }
  };

  return <div id="quotebar-search">{render()}</div>;
};
