import { Tabs, Typography } from '@mui/material';
import { accountState, selectedSymbolState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import LocalizationContext from '../react-i18next/localization-context';
import { StyledTab } from '../styled';
import { useOPNavigate } from '../states/use-navigate-search';

export const NavigationWidget: React.FC = () => {
  const navigate = useOPNavigate();
  const watchlistRoute = useMatch('/watchlist');
  const portfolioRoute = useMatch('/portfolio');
  const shareRoute = useMatch('/share');
  const [componentNav, setComponentNav] = React.useState('0');
  const { t } = React.useContext(LocalizationContext);
  const account = useRecoilValue(accountState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const location = useLocation();

  useEffect(() => {
    const ideaPatterns = ['/', '/idea', '/idea/covered-call', '/idea/credit-spread', '/idea/short-put'];
    const tradeIdeasRoute = ideaPatterns.find((p) => p === location.pathname);
    if (shareRoute || tradeIdeasRoute) {
      setComponentNav('0');
      return;
    }
    if (watchlistRoute) {
      setComponentNav('1');
      return;
    }
    if (portfolioRoute) {
      setComponentNav('2');
    }
  }, [location]);

  const handleChange = (value: string) => {
    const tab = value;
    setComponentNav(tab);
    if (tab === '0') {
      navigate('/');
      logActivity('tradeIdeasTab', `${t('app.php.what.menu.tradeIdeas')}`);
    } else if (tab === '2') {
      navigate('/portfolio');
      logActivity('portfolioTab', `${t('app.php.what.menu.portfolio')}`);
    } else {
      navigate('/watchlist');
      logActivity('watchListTab', `${t('app.php.what.menu.watchlists')}`);
    }
  };

  const logActivity = (name: string, value: string) => {
    ApplicationContext.userActivityHub?.logActivity(name, name, value, 'click', selectedSymbol);
  };

  const renderTabLabel = (label: string) => {
    return (
      <Typography variant="button" sx={{ pl: 1, pr: 1, textTransform: 'capitalize' }}>
        {t(label)}
      </Typography>
    );
  };

  if (!account) {
    return null;
  }

  return (
    <Tabs
      value={componentNav}
      onChange={(_, value) => handleChange(value)}
      aria-label="Navigation options"
      variant="scrollable"
      scrollButtons="auto">
      {account.canWorkWithTradeIdeas ? (
        <StyledTab
          label={renderTabLabel('app.php.what.menu.tradeIdeas')}
          value="0"
          isselected={(componentNav === '0').toString()}
        />
      ) : null}
      {account.canWorkWithWatchLists ? (
        <StyledTab
          label={renderTabLabel('app.php.what.menu.watchlists')}
          value="1"
          isselected={(componentNav === '1').toString()}
        />
      ) : null}
      {account.canWorkWithPortfolios ? (
        <StyledTab
          label={renderTabLabel('app.php.what.menu.portfolio')}
          value="2"
          isselected={(componentNav === '2').toString()}
        />
      ) : null}
    </Tabs>
  );
};
