import { Typography } from '@mui/material';
import { Combination } from '@op/shared/src/models';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import helpers from '@op/shared/src/models/how/helpers';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { Recommendation } from '@op/shared/src/models/portfolio/recommendation-model';
import { customizationState } from '@op/shared/src/states';
import { selectedRecommendationState } from '@op/shared/src/states/portfolio-management-states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { RedGreenWidget } from './red-green-widget';
import LocalizationContext from '../react-i18next/localization-context';
import { useBreakEvenDescription } from '../states/use-breakeven';

export interface IPlainEnglishSentenceOfCombinationProps {
  combination: Combination;
}

export const PlainEnglishSentenceOfCombinationWidget: React.FC<IPlainEnglishSentenceOfCombinationProps> = ({
  combination,
}: IPlainEnglishSentenceOfCombinationProps) => {
  const customization = useRecoilValue(customizationState);
  const breakEvenDesc = useBreakEvenDescription();
  const selectedRecommendation = useRecoilValue<Recommendation | undefined>(selectedRecommendationState);
  const { t } = React.useContext(LocalizationContext);

  if (!combination) {
    return null;
  }
  const reward = combination.maxReward();
  const risk = combination.maxRisk();

  let rewardDesc: string | undefined;
  if (customization?.isMerrill) {
    rewardDesc = helpers.isNumber(reward)
      ? 'potential reward of ' + NumberFormatHelper.toCurrency(reward)
      : t('app.php.how.howPanel.basicCombinationModel.unlimitedProfitPotential')?.toString();
  } else {
    rewardDesc = helpers.isNumber(reward)
      ? t('app.php.how.howPanel.basicCombinationModel.limitedPotentialReward')?.toString() +
        NumberFormatHelper.toCurrency(reward)
      : t('app.php.how.howPanel.basicCombinationModel.unlimitedProfitPotential')?.toString();
  }

  const riskDesc = helpers.isNumber(risk)
    ? t('app.php.how.howPanel.basicCombinationModel.limitedRiskOf') +
      NumberFormatHelper.toCurrency(Math.abs(risk as number))
    : t('app.php.how.howPanel.basicCombinationModel.unlimitedRisk');

  //TODO: Should remove all formatting.formatStr function and bring the nodes here.
  const tradePlainEnglishSentence = () => {
    if (!combination.sentiments.length || !combination.canTradeStrategy()) {
      return '';
    }
    const expiryKey = 'app.php.how.plainEnglishPanel.combinationText.plainEnglishHtml.expiryPart';
    let commonKey = 'app.php.how.plainEnglishPanel.combinationText.plainEnglishHtml.common';
    let popKey = 'app.php.how.plainEnglishPanel.combinationText.plainEnglishHtml.popSuffix';
    if (customization?.isMerrill) {
      popKey = 'app.php.how.plainEnglishPanel.merrillText.plainEnglishHtml.popSuffix';
      commonKey = 'app.php.how.plainEnglishPanel.merrillText.plainEnglishHtml.common';
    }
    const expiryPartAsString = t(expiryKey)?.toString();
    const expiryPart = combination.expiry()
      ? formatting.formatStr(expiryPartAsString, {
          expiryFormatted: DateTimeHelper.format(combination.expiry()),
        })
      : '';
    let sentimentProfileText = t(
      `app.php.how.sentimentProfile.${combination.sentiment().replace(/ /g, '_').replace(/-/g, '_')}`,
    );
    const commonAsString = t(commonKey)?.toString();
    if (combination.sentiment().toLowerCase() === 'unknown') {
      return '';
    }
    let sentence = formatting.formatStr(commonAsString, {
      sentimentProfile: sentimentProfileText,
      riskDesc: riskDesc,
      rewardDesc: rewardDesc,
      breakevenDesc: breakEvenDesc(combination),
      expiryPart: expiryPart,
    });
    const popSuffixAsString = t(popKey)?.toString();
    if (combination.chain) {
      sentence += formatting.formatStr(popSuffixAsString, {
        probabilityOfProfitDisplayed: combination.probabilityOfProfitDisplayed(),
      });
    }
    return sentence;
  };

  const renderTradePlainEnglishIfPossible = () => {
    return (
      <Typography
        id="plain-english"
        variant="body2"
        lineHeight={1.5}
        dangerouslySetInnerHTML={{ __html: tradePlainEnglishSentence() }}></Typography>
    );
  };

  const renderPortfolioCurrentPlainEnglishIfPossible = () => {
    if (!combination.isCurrent) {
      return null;
    }

    return renderTradePlainEnglishIfPossible();
  };

  const renderPortfolioAdjustmentPlainEnglishIfPossible = () => {
    if (!combination.isAdjustment || !selectedRecommendation || !selectedRecommendation.isAlert) {
      return null;
    }
    let formatted = formatting.formatStr(selectedRecommendation.plainEnglish, combination);
    const limited = t('app.php.how.howPanel.basicCombinationModel.limited')?.toString() || '';
    const unlimited = t('app.php.how.howPanel.basicCombinationModel.unlimited')?.toString() || '';
    formatted = formatted
      .replace('app.php.how.howPanel.basicCombinationModel.limited', limited)
      .replace('app.php.how.howPanel.basicCombinationModel.unlimited', unlimited);
    return (
      <Typography variant="body2" component="span">
        {t(formatted)}
      </Typography>
    );
  };

  const renderPortfolioResultantPlainEnglishIfPossible = () => {
    if (!combination.isResulting) {
      return null;
    }
    if (combination.isClosedPosition()) {
      const profitOrLoss = combination.unrealizedProfitAndLoss();
      return (
        <>
          <Typography variant="body2" component="span">
            At the current mid-price of per option, closing this position would be a profit of
          </Typography>
          <Typography variant="body2" component="span" fontWeight="bold" px={1}>
            {NumberFormatHelper.toFractionalString(combination.midPrice())}
          </Typography>
          <Typography variant="body2" component="span">
            per option, closing this position would be a profit of
          </Typography>
          <Typography variant="body2" component="span" fontWeight="bold" px={1}>
            <RedGreenWidget value={profitOrLoss}>{NumberFormatHelper.toCurrency(profitOrLoss, 'never')}</RedGreenWidget>
          </Typography>
        </>
      );
    }

    return renderTradePlainEnglishIfPossible();
  };

  const renderPlainEnglish = () => {
    if (combination.isPortfolio) {
      return (
        <div id="plain-english">
          {renderPortfolioCurrentPlainEnglishIfPossible()}
          {renderPortfolioAdjustmentPlainEnglishIfPossible()}
          {renderPortfolioResultantPlainEnglishIfPossible()}
        </div>
      );
    }
    if (combination.isTrade || combination.isIncome) {
      return renderTradePlainEnglishIfPossible();
    }
    return null;
  };

  return renderPlainEnglish();
};
