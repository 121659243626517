import { ExpandedQuote, IIdea, SortingType, Sorts, orderByAscending, orderByDescending } from '..';
import { ICollection } from './collection';

export class Trades implements ICollection<IIdea> {
  data: IIdea[] = [];
  //TODO: replace tradeIdeas with data.
  tradeIdeas: IIdea[] = [];
  // returned from server
  originalTradeIdeasCount = 0;
  portfolioPositionsCount = 0;
  watchlistQuotesCount = 0;
  sectors: string[] = [];
  scans: string[] = [];

  public constructor(tradeIdeas: IIdea[], trades?: Trades) {
    this.tradeIdeas = tradeIdeas;
    this.data = tradeIdeas;
    this.originalTradeIdeasCount = trades?.originalTradeIdeasCount;
    this.portfolioPositionsCount = trades?.portfolioPositionsCount;
    this.watchlistQuotesCount = trades?.watchlistQuotesCount;
    this.sectors = trades?.sectors;
    this.scans = trades?.scans;
  }

  public static fromSelf = (trades: Trades): Trades => {
    if (!trades) {
      throw Error('trades is null or undefined');
    }
    const model = new Trades(trades.tradeIdeas, trades);
    return model;
  };

  public get totalIdeas(): number {
    return this.tradeIdeas.length;
  }

  public get tradePortfolioPositionsCount(): number {
    return this.tradeIdeas.filter((e) => e.portfolios.length > 0).reduce((a, i) => a + i.portfolios.length, 0);
  }

  public get tradeWatchlistQuotesCount(): number {
    return this.tradeIdeas.filter((e) => e.watchlists.length > 0).reduce((a, i) => a + i.watchlists.length, 0);
  }

  public fillQuotes = (quotes: ExpandedQuote[] | undefined) => {
    if (!quotes) {
      return;
    }
    const tradeIdeaMap = new Map<string, IIdea>();
    for (let trade of this.data) {
      const symbol = trade.symbol.trim().toUpperCase();
      if (tradeIdeaMap.has(symbol)) {
        continue;
      }
      tradeIdeaMap.set(symbol, trade);
    }

    for (let quote of quotes) {
      const symbol = quote.symbol.trim().toUpperCase();
      const trade = tradeIdeaMap.get(symbol);
      if (!trade) {
        continue;
      }
      trade.expandedQuote = quote;
    }
  };

  public sort = (sorts: Sorts) => {
    if (!sorts) {
      return this;
    }
    const trades = this.sortByCompanyName(sorts).sortBySymbol(sorts).sortBySentiment(sorts).sortByScore(sorts).sortByPrice(sorts);
    return trades;
  };

  private sortByCompanyName = (sorts: Sorts): Trades => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'companyName') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.data.sort((trade1, trade2) => {
          if (trade1.companyName > trade2.companyName) {
            return 1;
          }
          if (trade1.companyName < trade2.companyName) {
            return -1;
          }
          return 0;
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.data.sort((trade1, trade2) => {
          if (trade1.companyName > trade2.companyName) {
            return -1;
          }
          if (trade1.companyName < trade2.companyName) {
            return 1;
          }
          return 0;
        });
      }
    }
    return this;
  };

  private sortBySymbol = (sorts: Sorts): Trades => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'symbol') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.data = orderByAscending(this.data, 'symbol');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.data = orderByDescending(this.data, 'symbol');
      }
    }
    return this;
  };

  private sortBySentiment = (sorts: Sorts): Trades => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'sentiment') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.data.sort((trade1, trade2) => {
          if (trade1.sentiment > trade2.sentiment) {
            return 1;
          }
          if (trade1.sentiment < trade2.sentiment) {
            return -1;
          }
          return 0;
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.data.sort((trade1, trade2) => {
          if (trade1.sentiment > trade2.sentiment) {
            return -1;
          }
          if (trade1.sentiment < trade2.sentiment) {
            return 1;
          }
          return 0;
        });
      }
    }
    return this;
    // if (Trades.sortvalue === 'technicalRank') {
    //   return item1.technicalRank - item2.technicalRank;
    // }
    // return 0;
  };

  private sortByScore = (sorts: Sorts): Trades => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'score') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.data.sort((trade1, trade2) => {
          let signal1 = trade1.signals.find((s) => s?.name?.toUpperCase() === 'TechnicalRank'.toUpperCase());
          let signal2 = trade2.signals.find((s) => s?.name?.toUpperCase() === 'TechnicalRank'.toUpperCase());
          if (signal1 && signal2 && signal1.value > signal2.value) {
            return 1;
          }
          if (signal1 && signal2 && signal1.value < signal2.value) {
            return -1;
          }
          return 0;
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.data.sort((trade1, trade2) => {
          let signal1 = trade1.signals.find((s) => s?.name?.toUpperCase() === 'TechnicalRank'.toUpperCase());
          let signal2 = trade2.signals.find((s) => s?.name?.toUpperCase() === 'TechnicalRank'.toUpperCase());
          if (signal1 && signal2 && signal1.value > signal2.value) {
            return -1;
          }
          if (signal1 && signal2 && signal1.value < signal2.value) {
            return 1;
          }
          return 0;
        });
      }
    }
    return this;
  };

  private sortByPrice = (sorts: Sorts): Trades => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'price') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.data.sort((trade1, trade2) => {
          if (!trade1.price || !trade2.price) {
            return -1;
          }

          if (trade1.price > trade2.price) {
            return 1;
          }
          if (trade1.price < trade2.price) {
            return -1;
          }
          return 0;
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.data.sort((trade1, trade2) => {
          if (!trade1.price || !trade2.price) {
            return 1;
          }

          if (trade1.price > trade2.price) {
            return -1;
          }
          if (trade1.price < trade2.price) {
            return 1;
          }
          return 0;
        });
      }
    }
    return this;
  };
}
