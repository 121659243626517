import { ISupportResistance, Trade } from '@op/shared/src/models';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { CIQ } from 'chartiq/js/advanced';

//@ts-ignore
export const addSupportAndResistance = (engine: CIQ.ChartEngine, data: ISupportResistance | undefined) => {
  if (!data) {
    return;
  }
  addSupportsMarker(engine, data);
  addResistancesMarker(engine, data);
};
//@ts-ignore
const addSupportsMarker = (engine: CIQ.ChartEngine, data: ISupportResistance) => {
  const supports = (data.support || []).slice(0, 2);
  for (const support of supports) {
    drawLine(engine, support.value, support.date, '#00FF00');
  }
};
//@ts-ignore
const addResistancesMarker = (engine: CIQ.ChartEngine, data: ISupportResistance) => {
  const resistances = (data.resistance || []).slice(0, 2);
  for (const resistance of resistances) {
    drawLine(engine, resistance.value, resistance.date, '#FF0000');
  }
};
//@ts-ignore

const drawLine = (stx: CIQ.ChartEngine, value: number, date: string, color: string) => {
  const dateFormat = 'yyyyMMddHHmmss000';
  const d0Date = DateTimeHelper.resolveDate(date);
  const descriptor = {
    pnl: 'chart',
    col: color,
    lw: 1,
    d0: DateTimeHelper.format(d0Date, dateFormat), //DateTime.fromISO(date).toFormat('yyyyMMddHHmmss000'),
    v0: value,
    v1: value,
    d1: DateTimeHelper.format(new Date().toISOString(), dateFormat), //DateTime.fromISO(new Date().toISOString()).toFormat('yyyyMMddHHmmss000'),
  };
  const segment = new CIQ.Drawing['segment']();
  stx.setStyle('stx_bar_chart', 'color', 'red');
  //@ts-ignore
  (segment as CIQ.Drawing).permanent = true;
  // TODO: Vivek - Not ideal, Taken from previous version. Make it better for 8.8 version of chartiq
  // Customizing tooltip of support and resistance
  // @ts-ignore
  CIQ.Drawing.segment.prototype.measure = function () {
    //@ts-ignore
    if (this.p0 && this.p1) {
      //@ts-ignore
      stx.setMeasure(this.p0[1], this.p1[1], this.p0[0], this.p1[0], true, this.name);
    }
  };

  stx.append('setMeasure', (price1: number, price2: number, tick1: any, tick2: any, hover: any, name: string) => {
    if (hover && name === 'segment') {
      //@ts-ignore
      const m = stx.controls.mSticky;
      if (m) m.firstElementChild.innerHTML = `${tick2 - tick1} Days`;
      return false;
    } else {
      return;
    }
  });
  // END if tooltip customization

  stx.removeDrawing(descriptor);
  // @ts-ignore
  segment.reconstruct(stx, descriptor);
  //TODO: Check addDrawing may be present on context or chart opbject
  // @ts-ignore
  stx.addDrawing(segment);
};
export const addTradeIdeaMarker = (
  //@ts-ignore
  stx: CIQ.ChartEngine,
  tick: { Low: number; Date: string; High: number; Close: number; Volume: number; Open: number } | undefined,
  tradeIdea: Trade[] | undefined,
) => {
  if (!tradeIdea || tradeIdea.length === 0 || !tick) {
    return;
  }
  drawArrow(stx, tick, tradeIdea[0].sentiment);
};
export const drawArrow = (
  //@ts-ignore
  stxx: CIQ.ChartEngine,
  tick: { Low: number; Date: string; High: number; Close: number; Volume: number; Open: number } | undefined,
  sentiment: string,
) => {
  if (!tick || !sentiment) {
    return;
  }

  const arrowDescriptor = {
    pnl: 'chart',
    v0: sentiment === 'Bullish' ? tick.Low - 0.5 : tick.High + 0.5,
    d0: tick.Date,
    tzo0: 100,
    a: sentiment === 'Bullish' ? 180 : 0,
    sx: 1.8,
    sy: 3,
    fc: sentiment === 'Bullish' ? '#9FD072' : '#D76C7E',
  };

  // @ts-ignore
  const tradingArrowDrawing = new CIQ.Drawing['arrow']();
  //@ts-ignore
  (tradingArrowDrawing as CIQ.Drawing).permanent = true;
  //stxx.removeDrawing(arrowDescriptor);
  //@ts-ignore
  tradingArrowDrawing.reconstruct(stxx, arrowDescriptor);
  // @ts-ignore
  stxx.addDrawing(tradingArrowDrawing);
};
