import { Paper, Box, Skeleton } from '@mui/material';
import { SubscriptionFooterWidget } from './subscription-footer-widget';
import React from 'react';

interface ISubscriptionSkeletonWidgetProps {
  viewFooter: boolean;
}
export const SubscriptionSkeletonWidget: React.FC<ISubscriptionSkeletonWidgetProps> = ({
  viewFooter,
}: ISubscriptionSkeletonWidgetProps) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Skeleton variant="rectangular" width="25%" height={20} sx={{ mr: 0.5, ml: 0.5 }} />
      <Box display="flex" justifyContent="space-between" p={2} sx={{ m: 1 }}>
        <Skeleton animation="wave" variant="rectangular" height={250} width="50%" />
        <Skeleton animation="wave" variant="rectangular" height={250} width="45%" />
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%" sx={{ m: 1 }}>
        <Box display="flex" justifyContent="space-between" width="50%" sx={{ m: 1 }}>
          <Skeleton variant="rectangular" width="50%" height={40} sx={{ mr: 0.5 }} />
          <Skeleton variant="rectangular" width="45%" height={40} sx={{ mr: 0.5, ml: 0.5 }} />
        </Box>
        <Box justifyContent="space-between" width="45%" sx={{ m: 1 }}>
          <Skeleton variant="rectangular" width="90%" height={40} sx={{ mr: 1, ml: 0.5 }} />
        </Box>
      </Box>

      <Skeleton variant="rectangular" width="25%" height={20} sx={{ mr: 0.5, ml: 1 }} />
      <Box justifyContent="flex-end" p={2}>
        <Skeleton animation="wave" height={60} width="100%" />
        <Skeleton animation="wave" height={50} width="100%" />
      </Box>
      {viewFooter ? (
        <Box display="flex" justifyContent="center">
          <SubscriptionFooterWidget />
        </Box>
      ) : null}
    </Paper>
  );
};

export const SubscriptionSummarySkeleton: React.FC = () => {
  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" justifyContent="center" p={2} sx={{ m: 1 }}>
        <Skeleton variant="rectangular" width={400} height={400} sx={{ mr: 0.5, ml: 0.5 }} />
      </Box>
      <Box display="flex" justifyContent="center" p={2} sx={{ m: 1 }}>
        <Skeleton variant="rectangular" width={100} height={30} sx={{ mr: 0.5, ml: 0.5 }} />
        <Skeleton variant="rectangular" width={300} height={30} sx={{ mr: 0.5, ml: 0.5 }} />
      </Box>
    </Paper>
  );
};

export const SubscriptionCardInfoSkeleton: React.FC = () => {
  return (
    <>
      <Box justifyContent="center" p={2} sx={{ m: 0.5 }}>
        <Skeleton variant="rectangular" width="100%" height={30} sx={{ m: 0.5 }} />
        <Skeleton variant="rectangular" width={'100%'} height={200} sx={{ mr: 0.5, ml: 0.5 }} />
      </Box>
      <Box justifyContent="center" p={2} sx={{ m: 0.5 }}>
        <Skeleton variant="rectangular" width="100%" height={30} sx={{ m: 0.5, mt: 0 }} />
        <Skeleton variant="rectangular" width={'100%'} height={200} sx={{ mr: 0.5, ml: 0.5 }} />
      </Box>
    </>
  );
};
