import { Rules, Scan, Scans } from '../../models';

import { selector } from 'recoil';
import { tradesCachedState } from '.';

export const scansStates = selector({
  key: 'scansState',
  get: ({ get }) => {
    const trades = get(tradesCachedState);
    if (!trades) {
      return new Scans([]);
    }
    const scans: Scan[] = [];
    trades.scans.forEach((r) => scans.push(Scan.fromText(r)));
    return new Scans(scans);
  },
});
