import { Grid, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { Quote, Sorts, WatchList, WatchLists } from '@op/shared/src/models';
import { EventType, Permissions, WatchListsOperationTypes } from '@op/shared/src/models/enums/enums';
import {
  accountState,
  deletedWatchlistItemState,
  filtersWatchListState,
  originalWatchListState,
  selectedSymbolState,
  selectedWatchListIdState,
  selectedWatchListState,
  sortsWatchlistState,
  tradesCachedState,
  watchListCrudOperationState,
  watchListSearchTextState,
  watchListsState,
} from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { IconComponent, SortingWidget } from '../common';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { WatchlistService } from '@op/shared/src/services';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { coveredCallDataCachedState } from '@op/shared/src/states/reports/covered-call-states';
import { creditSpreadDataCachedState } from '@op/shared/src/states/reports/credit-spread-states';
import { shortPutDataCachedState } from '@op/shared/src/states/reports/short-put-states';
import { OPIconButton, OptionsPlayTextField, OrangeButton } from '../styled';
import { ManagerThirdRow } from './manager-third-row';
import { WatchListAccordian } from './watch-list-accordian';
import { SaveChangesView } from './watchlist-common-layouts';

export const WatchListsManager: React.FC = () => {
  const theme = useTheme();
  const account = useRecoilValue(accountState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setSelectedWatchListId = useSetRecoilState(selectedWatchListIdState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [watchSorts, setWatchSorts] = useRecoilState(sortsWatchlistState);
  const [watchLists, setWatchLists] = useRecoilState(watchListsState);
  const resetTradeIdeas = useResetRecoilState(tradesCachedState);
  const resetCreditSpreads = useResetRecoilState(creditSpreadDataCachedState);
  const resetCoveredCalls = useResetRecoilState(coveredCallDataCachedState);
  const resetShortPuts = useResetRecoilState(shortPutDataCachedState);
  const resetGlobalFilters = useResetRecoilState(filtersWatchListState);

  const [watchListSearchText, setWatchListSearchText] = useRecoilState(watchListSearchTextState);
  const [watchListOperation, setWatchListOperation] = useRecoilState(watchListCrudOperationState);
  const [selectedWatchList, setSelectedWatchList] = useRecoilState<WatchList | undefined>(selectedWatchListState);
  const [deletedWatchListItem, setDeletedWatchListItem] = useRecoilState(deletedWatchlistItemState);
  const [originalWatchList, setOriginalWatchList] = useRecoilState(originalWatchListState);

  const [search, setSearch] = useState(false);
  const [newWatchListlabel, setNewWatchListLabel] = useState('');
  const [errorText, setErrorText] = useState<string | undefined>();
  const [watchListName, setWatchListName] = useState('');
  const [canManageGlobalB2BWatchList, setCanManageGlobalB2BWatchList] = useState(false);
  const [allowUserWatchlist, setAllowUserWatchlist] = useState(false);
  const [isDeletingSymbol, setIsDeletingSymbol] = useState(false);
  const [isChangingName, setIsChangingName] = useState(false);

  const onSearch = () => {
    setSearch(false);
    setWatchListSearchText('');
  };

  useEffect(() => {
    if (!account || !account.securityModel) {
      return;
    }
    const canManageB2B = account.securityModel.hasPermission(Permissions.MANAGE_GLOBAL_B2_B_WATCH_LISTS);
    const isAllowUserWatchlist = account.isAllowUserWatchlist;
    setCanManageGlobalB2BWatchList(canManageB2B);
    setAllowUserWatchlist(isAllowUserWatchlist);
  }, [account]);

  useEffect(() => {
    if (isChangingName) {
      return;
    }
    selectedWatchList && setWatchListName(selectedWatchList.name);
    onSearch();
  }, [selectedWatchList, isChangingName]);

  useEffect(() => {
    setIsDeletingSymbol(deletedWatchListItem.length > 0);
  }, [deletedWatchListItem]);

  if (!account || !watchLists || !selectedWatchList) {
    return <></>;
  }

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const isWatchlistExists = (name: string) => {
    const watchlist = watchLists.data.find((watchList) => watchList.name.trim() === name.trim());
    return watchlist !== undefined;
  };

  const warnIfWatchlistExist = (name: string) => {
    const isExists = isWatchlistExists(name);
    if (isExists) {
      setNotifications([{ type: 'error', content: `WatchList ${name} already Exist.` }]);
      setErrorText(`WatchList ${name} already Exist.`);
      return true;
    }
    return false;
  };

  const deleteSymbolsAsync = async () => {
    if (!originalWatchList) {
      return;
    }
    if (deletedWatchListItem.length === 0 || (originalWatchList.quotes || []).length === 0) {
      return;
    }
    const clone = WatchList.fromSelf(originalWatchList);
    const quotes = originalWatchList.quotes.filter((s) => {
      return !deletedWatchListItem.includes(s?.symbol);
    });
    clone.quotes = quotes;
    const saveResponse = await WatchlistService.instance.save({
      list: selectedWatchList.id,
      symbols: quotes.map((s) => s.symbol),
      removed: deletedWatchListItem,
    });
    if (saveResponse.hasErrors) {
      setNotifications([{ type: 'error', content: 'Operation not successful' }]);
      return;
    }
    setNotifications([{ type: 'success', content: 'Operation successful' }]);
    setDeletedWatchListItem([]);
    setOriginalWatchList(clone);
    setWatchListOperation(WatchListsOperationTypes.NONE);
    resetGlobalFilters();
    resetTradeIdeas();
    resetCreditSpreads();
    resetCoveredCalls();
    resetShortPuts();
    return quotes;
  };

  const onDeleteOrNameChangeAsync = async () => {
    const isWatchListNameChange = selectedWatchList.name.trim() !== watchListName.trim();
    let isNameChanged = false;
    if (isWatchListNameChange) {
      isNameChanged = await renameAsync();
    }
    const isAnythingDeleted = deletedWatchListItem.length > 0;
    let quotes: Quote[] | undefined;
    if (isAnythingDeleted) {
      quotes = await deleteSymbolsAsync();
    }
    const updatedWatchList = WatchList.fromData({
      id: selectedWatchList.id,
      name: isNameChanged ? watchListName : selectedWatchList.name,
      type: selectedWatchList.type,
      quotes: quotes !== undefined ? quotes : originalWatchList.quotes,
    });
    setSelectedWatchList(updatedWatchList);
    //TODO: oringina should update here only
    setSelectedWatchListId(updatedWatchList.id);
    setWatchListOperation(WatchListsOperationTypes.NONE);
    setNotifications([{ type: 'success', content: 'Operation successful' }]);
    resetEditingStates();
  };

  /*
    on renaming and saving the selected watchlist name
  */
  const renameAsync = async () => {
    if (!selectedWatchList) {
      return false;
    }
    if (watchListName.trim() === '') {
      setErrorText('Watchlist name can not be empty');
      return false;
    }
    if (warnIfWatchlistExist(watchListName)) {
      return false;
    }
    try {
      const response = await WatchlistService.instance.rename({
        list: selectedWatchList.id,
        name: watchListName,
      });
      if (response.hasErrors || !response.data) {
        setNotifications([{ type: 'error', content: 'Something wrong happened. Please try again.' }]);
        return false;
      }
      return true;
    } catch (error) {
      setNotifications([{ type: 'error', content: 'Something wrong happened. Please try again.' }]);
    }
    return false;
  };

  const deleteAsync = async () => {
    if (!selectedWatchList || !watchLists) {
      return;
    }
    try {
      const deleteResponse = await WatchlistService.instance.remove({ list: selectedWatchList.id });
      if (!deleteResponse) {
        setNotifications([{ type: 'error', content: 'Something bad happened. Please try again.' }]);
        return;
      }
      const watchListData = watchLists.data.filter(function (q) {
        return q.id !== selectedWatchList.id;
      });
      const clonedWatchlist = WatchLists.fromSelf(watchLists);
      clonedWatchlist.data = watchListData;
      setWatchLists(clonedWatchlist);
      setNotifications([{ type: 'success', content: 'Operation successful' }]);
      setSelectedWatchListId(undefined);
      setWatchListOperation(WatchListsOperationTypes.NONE);
      resetTradeIdeas();
      resetCreditSpreads();
      resetCoveredCalls();
      resetShortPuts();
    } catch (error) {
      setNotifications([{ type: 'error', content: 'Something bad happened. Please try again.' }]);
    }
  };

  const onSort = (sorts: any) => {
    logActivity('watchListSort', 'watchListSort');
    setWatchSorts(sorts);
  };

  const resetEditingStates = () => {
    //NOTE: Do not reset setWatchList state here. This is only to reset local states for UI management.
    setIsChangingName(false);
    setIsDeletingSymbol(false);
    setWatchListName('');
    setDeletedWatchListItem([]);
    setNewWatchListLabel('');
    setErrorText(undefined);
    setWatchListOperation(WatchListsOperationTypes.NONE);
  };

  const onReset = () => {
    resetEditingStates();
    setSelectedWatchList(originalWatchList);
    logActivity('watchListCancelWatchListEdit', 'watchListCancelWatchListEdit');
  };

  /*
    If there is any change in selected watchlist name on edit
  */
  const renderSelectedWatchListNameChange = () => {
    if (watchListOperation === WatchListsOperationTypes.EDIT && (isDeletingSymbol || isChangingName)) {
      logActivity('watchListEditWatchList', 'watchListEditWatchList');
      return (
        <SaveChangesView func={onDeleteOrNameChangeAsync} reset={onReset} label="Save Changes?" buttonLabel="Save" />
      );
    }
  };

  const renderSaveNewWatchlist = () => {
    if (watchListOperation === WatchListsOperationTypes.NEW) {
      return <SaveChangesView func={saveNewWatchList} reset={onReset} label="Save Watchlist?" buttonLabel="Save" />;
    }
  };

  const renderSaveNewGlobalWatchlist = () => {
    if (watchListOperation === WatchListsOperationTypes.NEWGLOBAL) {
      return <SaveChangesView func={saveNewWatchList} reset={onReset} label="Save Watchlist?" buttonLabel="Save" />;
    }
  };

  const renderSaveDeleteWatchlist = () => {
    if (watchListOperation !== WatchListsOperationTypes.DELETE) {
      return;
    }
    logActivity('watchListDeleteWatchList', 'watchListDeleteWatchList');
    return <SaveChangesView func={deleteAsync} reset={onReset} label="Delete the watchlist?" buttonLabel="Delete" />;
  };

  const renderErrorField = () => {
    if (!errorText) {
      return;
    }
    return (
      <Typography style={{ color: theme.palette.error.main }} id="js-watchlist-error-text">
        {errorText}
      </Typography>
    );
  };

  const addAsync = async (type: WatchListsOperationTypes) => {
    if (!watchLists || !watchLists.data) {
      return;
    }
    if (warnIfWatchlistExist(newWatchListlabel)) {
      return;
    }
    setErrorText(undefined);
    try {
      const response = await WatchlistService.instance.add({
        name: newWatchListlabel,
        type: type === WatchListsOperationTypes.NEW ? 'None' : 'GlobalB2B',
      });
      if (!response) {
        setNotifications([{ type: 'error', content: 'Something wrong happened. Please try again.' }]);
        return;
      }
      if (response && response.data) {
        const newWatchList = WatchList.fromData({
          id: response.data.id,
          name: response.data.name,
          type: response.data.type,
          quotes: [],
        });
        setNotifications([{ type: 'success', content: 'Operation successful' }]);
        setNewWatchListLabel('');
        setSelectedWatchList(newWatchList);
        setSelectedWatchListId(newWatchList.id);
        setWatchListOperation(WatchListsOperationTypes.NONE);
      }
    } catch (error) {
      setNotifications([{ type: 'error', content: 'Something wrong happened. Please try again.' }]);
    }
  };

  const saveNewWatchList = async () => {
    if (newWatchListlabel.trim() === '') {
      setErrorText('Watchlist name can not be empty');
      return;
    }
    setErrorText(undefined);
    if (
      watchListOperation === WatchListsOperationTypes.NEW ||
      watchListOperation === WatchListsOperationTypes.NEWGLOBAL
    ) {
      await addAsync(watchListOperation);
    }
  };

  const renderSecondRow = () => {
    const isGlobalSelected = selectedWatchList.type !== 'None';
    if (watchListOperation !== WatchListsOperationTypes.NONE) {
      return;
    }
    if (search && isGlobalSelected) {
      return (
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs={10} id={'js-watchlist-global-search-field'}>
            <OptionsPlayTextField
              autoFocus
              variant="outlined"
              size="small"
              onChange={(e) => {
                setWatchListSearchText(e.target.value.toUpperCase());
              }}
              placeholder="Search Symbol"
              name="searchname"
              value={watchListSearchText}
              style={{ width: '100%' }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setWatchListSearchText('')}>
                    {watchListSearchText !== '' ? (
                      <ClearOutlinedIcon sx={{ fontSize: 18, color: theme.palette.info.light }} />
                    ) : (
                      ''
                    )}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item container xs="auto" justifyContent="space-between">
            <OrangeButton id={'js-watchlist-global-search-icon'} isSelected={search} onClick={() => onSearch()}>
              <IconComponent name={'searchIcon'} stroke={theme.palette.info.light} />
            </OrangeButton>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} container justifyContent={'space-between'}>
        <Grid item xs={isGlobalSelected ? 10 : 12}>
          <SortingWidget onSort={onSort} selectedSorts={watchSorts} defaultSortOptions={Sorts.WatchListsDefaultSorts} />
        </Grid>
        <Grid item xs={'auto'}>
          {isGlobalSelected && (
            <OPIconButton onClick={() => setSearch(true)}>
              <IconComponent name={'searchIcon'} stroke={theme.palette.info.light} />
            </OPIconButton>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} py={1}>
        <Paper sx={{ height: '100%', border: '1px solid transparent', borderRadius: 2 }} elevation={4}>
          <Grid container alignItems={'center'} rowSpacing={1} p={1}>
            <Grid item xs={12}>
              <WatchListAccordian
                watchListName={watchListName}
                setWatchListName={(d) => setWatchListName(d)}
                onReset={onReset}
                setWatchListErrorText={(d) => setErrorText(d)}
                canManageGlobalB2BWatchList={canManageGlobalB2BWatchList}
                allowUserWatchlist={allowUserWatchlist}
                isChangingName={isChangingName}
                setIsChangingName={(v) => setIsChangingName(v)}
                newWatchListlabel={newWatchListlabel}
                setNewWatchListLabel={(v) => setNewWatchListLabel(v)}
              />
              {renderErrorField()}
            </Grid>
            <Grid item xs={12}>
              {renderSelectedWatchListNameChange()}
              {/* {renderSaveDeleteSymbol()} */}
              {renderSaveDeleteWatchlist()}
              {renderSaveNewWatchlist()}
              {renderSaveNewGlobalWatchlist()}
              {renderSecondRow()}
            </Grid>
            <Grid item xs={12}>
              <ManagerThirdRow
                canManageGlobalB2BWatchList={canManageGlobalB2BWatchList}
                allowUserWatchlist={allowUserWatchlist}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
