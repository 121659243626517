import { DialogContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { globalizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { OPDialogWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OutlinedButton } from '../styled/options-play-components';
export interface Props {
  open: boolean;
  onHandleClose: () => void;
  onClickButton: (value: boolean) => void;
}

export const BncDisclaimerWidget: React.FC<Props> = ({ open, onHandleClose, onClickButton }: Props) => {
  const globalization = useRecoilValue(globalizationState);
  const { t } = React.useContext(LocalizationContext);

  if (!globalization || !globalization.active) {
    return;
  }

  const renderDialogAction = () => {
    return (
      <Grid container justifyContent={'flex-end'} spacing={2}>
        <Grid item>
          <OutlinedButton autoFocus size="small" onClick={() => onClickButton(false)}>
            <Typography variant="button">{t('app.php.modals.disclaimer.buttons.doNotAgree')}</Typography>
          </OutlinedButton>
        </Grid>
        <Grid item>
          <OutlinedButton autoFocus size="small" onClick={() => onClickButton(true)}>
            <Typography variant="button">{t('app.php.modals.disclaimer.buttons.agree')}</Typography>
          </OutlinedButton>
        </Grid>
      </Grid>
    );
  };

  const renderEnglishDialogContent = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>© 2020 OptionsPlay. All Rights Reserved.</Typography>
        <Typography gutterBottom>
          OptionsPlay have one goal: To show you how options can unlock investment opportunities for everyone. They
          guide investors of all experience levels through the risks and rewards of options trading using clear, concise
          language that anyone can understand.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay tool is the property of OptionsPlay which is not affiliated with National Bank Direct Brokerage
          (NBDB), a division of National Bank Financial Inc., or any of the companies in the National Bank of Canada
          group.
        </Typography>
        <Typography gutterBottom>
          The content and information provided in the OptionsPlay tool have been independently prepared by OptionsPlay
          and/or its content providers and are neither endorsed nor approved by NBDB which does not accept any
          responsibility in this regard.
        </Typography>
        <Typography gutterBottom>
          The information contained herein: (1) is proprietary to OptionsPlay and/or its content providers; (2) may not
          be copied or distributed; (3) is not warranted to be accurate, complete or timely; and, (4) does not
          constitute advice or a recommendation by NBDB, OptionsPlay or its content providers in respect of the
          investment in financial instruments.
        </Typography>
        <Typography gutterBottom>
          Neither NBDB, nor OptionsPlay and its content providers are responsible for any damages or losses arising from
          any use of this information. Past performance is no guarantee of future results.
        </Typography>
      </DialogContent>
    );
  };

  const renderFrenchDialogContent = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>© 2020 OptionsPlay. Tous droits réservés.</Typography>
        <Typography gutterBottom>
          OptionsPlay a un objectif : vous montrer comment les options peuvent débloquer des opportunités
          d’investissement. Ils guident les investisseurs de tous niveaux d’expérience à travers les risques et les
          avantages de la négociation d’options en utilisant un langage clair et concis que tout le monde peut
          comprendre.
        </Typography>
        <Typography gutterBottom>
          La suite d’outils OptionsPlay est la propriété d’OptionsPlay qui n’est pas affiliée à Banque Nationale
          Courtage direct (BNCD), une division de Financière Banque Nationale inc., ni à aucune des sociétés du groupe
          de la Banque Nationale du Canada.
        </Typography>
        <Typography gutterBottom>
          Le contenu et les renseignements fournis dans la suite d’outils OptionsPlay ont été préparés de manière
          indépendante par OptionsPlay et/ou ses fournisseurs de contenu et ne sont ni avalisés ni approuvés par BNCD
          qui n’accepte aucune responsabilité à cet égard.
        </Typography>
        <Typography gutterBottom>
          Les informations contenues aux présentes : (1) sont la propriété d’OptionsPlay et/ou de ses fournisseurs de
          contenu ; (2) ne peuvent être copiées ou distribuées ; (3) ne s'assortissent d'aucune garantie quant à leur
          exactitude, leur exhaustivité ou leur caractère opportun ; et (4) ne constituent ni un conseil, ni une
          recommandation d'investissement dans des instruments financiers de la part de BNCD, OptionsPlay ou de ses
          fournisseurs de contenu.
        </Typography>
        <Typography gutterBottom>
          Ni BNCD, ni OptionsPlay et ni ses fournisseurs de contenu ne sauraient être tenus pour responsables de
          dommages ou de pertes découlant de l'utilisation de ces informations. Les performances passées ne garantissent
          aucunement les résultats futurs.
        </Typography>
      </DialogContent>
    );
  };

  const renderDialogContent = () => {
    if (globalization.french.isActive) {
      return renderFrenchDialogContent();
    }
    return renderEnglishDialogContent();
  };

  const renderDialogTitle = () => {
    if (globalization.french.isActive) {
      return (
        <Grid id="customized-dialog-title" onClick={onHandleClose}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Négociation d'options en ligne
            <br />
            Mentions légales et clause de non-responsabilité
          </Typography>
        </Grid>
      );
    }
    return (
      <Grid id="customized-dialog-title" onClick={onHandleClose}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Online Options Trading
          <br />
          Legal Notice & Disclaimer
        </Typography>
      </Grid>
    );
  };

  return (
    <OPDialogWidget
      open={open}
      onHandleClose={onHandleClose}
      dialogAction={renderDialogAction()}
      dialogContent={renderDialogContent()}
      title={renderDialogTitle()}
    />
  );
};
