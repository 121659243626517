import { Button, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { OPBoldTypograpghy } from '../../styled';

interface ICreditCardTypeWidgetProps {
  cardNumber: string;
  textView?: 'onlyNumber' | 'typeWithNumber' | 'button';
}

export const creditCardType = (cc: string) => {
  let cardType = '';
  let amex = new RegExp('^3[47][0-9]{13}$');
  let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
  let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

  let mastercard = new RegExp('^5[1-5][0-9]{14}$');
  let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

  let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

  let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
  let jcb = new RegExp('^35[0-9]{14}[0-9]*$');

  let enroute1 = new RegExp('^2014[0-9]{12}[0-9]*$');
  let enroute2 = new RegExp('^2149[0-9]{12}[0-9]*$');

  if (visa.test(cc)) {
    cardType = 'VISA';
  }
  if (amex.test(cc)) {
    cardType = 'AMEX';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    cardType = 'MASTERCARD';
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    cardType = 'DISCOVER';
  }
  if (diners.test(cc)) {
    cardType = 'DINERS';
  }
  if (jcb.test(cc)) {
    cardType = 'JCB';
  }
  if (cup1.test(cc) || cup2.test(cc)) {
    cardType = 'CHINA_UNION_PAY';
  }
  if (enroute1.test(cc) || enroute2.test(cc)) {
    cardType = 'ENROUTE';
  }
  return cardType;
};

export const CreditCardTypeWidget: React.FC<ICreditCardTypeWidgetProps> = ({
  cardNumber,
  textView,
}: ICreditCardTypeWidgetProps) => {
  const theme = useTheme();

  if (!cardNumber) {
    return;
  }
  const renderCardType = () => {
    if (cardNumber.length <= 0) {
      return;
    }
    const cardType = creditCardType(cardNumber);
    if (cardType === '') {
      return <Typography variant="caption">{cardType}</Typography>;
    }
    return (
      <Button color="primary" disableElevation disableRipple variant="contained" size="small" onClick={() => {}}>
        {cardType}
      </Button>
    );
  };

  const renderTextView = () => {
    const cardType = creditCardType(cardNumber);
    const lastNumbers = `****${cardNumber.substring(cardNumber.length - 4)}`;
    return (
      <Grid container justifyContent={'flex-end'} columnSpacing={0.5} alignItems={'center'}>
        <Grid item>
          <Button
            variant="contained"
            disableRipple
            size="small"
            onClick={() => {}}
            sx={{
              backgroundColor: theme.palette.grey[500],
              ':hover': {
                backgroundColor: theme.palette.grey[500],
              },
            }}>
            {cardType}
          </Button>
        </Grid>
        <Grid item>
          <OPBoldTypograpghy
            style={{ color: theme.palette.mode === 'light' ? theme.palette.grey[600] : '#999999', fontSize: '14px' }}>
            {lastNumbers}
          </OPBoldTypograpghy>
        </Grid>
      </Grid>
    );
  };

  const renderOnlyNumber = () => {
    const lastNumbers = `****${cardNumber.substring(cardNumber.length - 4)}`;
    return (
      <OPBoldTypograpghy
        style={{ color: theme.palette.mode === 'light' ? theme.palette.grey[600] : '#999999', fontSize: '14px' }}>
        {lastNumbers}
      </OPBoldTypograpghy>
    );
  };

  return textView === 'typeWithNumber'
    ? renderTextView()
    : textView === 'onlyNumber'
    ? renderOnlyNumber()
    : renderCardType();
};
