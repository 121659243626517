import { Card, Grid, Menu, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { OrangeButton } from '../styled';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { IconComponent } from '../common/icons';
import { SelectClearWidget } from './select-clear';

interface Props {
  children: ReactNode;
  isSelectedAll: boolean;
  onReset: () => void;
  onSelectAll: () => void;
}

export const FilterContainer: React.FC<Props> = ({
  children,
  isSelectedAll,
  onSelectAll: onSelectAllFilters,
  onReset,
}: Props) => {
  const theme = useTheme();
  const [showFilter, setShowFilter] = React.useState<null | HTMLElement>(null);
  const onClose = (event: { currentTarget: any }) => {
    setShowFilter(showFilter ? null : event.currentTarget);
  };

  return (
    <>
      <OrangeButton onClick={onClose} disableRipple={!isSelectedAll} isSelected={!isSelectedAll}>
        <IconComponent name="filterIcon" stroke={theme.palette.info.light} />
      </OrangeButton>
      <Menu
        anchorEl={showFilter}
        open={Boolean(showFilter)}
        onClose={onClose}
        MenuListProps={{ sx: { py: 0 } }}
        PaperProps={{ sx: { backgroundColor: 'transparent', width: '280px', overflow: 'visible' } }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Card
          sx={{
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: '5px',
            p: 1,
          }}>
          <Grid container rowSpacing={1}>
            <Grid item container justifyContent="flex-end">
              <ClearSharpIcon onClick={(e) => onClose(e)} fontSize="small" sx={{ '&:hover': { cursor: 'pointer' } }} />
            </Grid>
            <SelectClearWidget isSelectedAll={isSelectedAll} onSelectAll={onSelectAllFilters} onReset={onReset} />
            <>{children}</>
          </Grid>
        </Card>
      </Menu>
    </>
  );
};
