import { OptionsPlayBreakpoints, fontSizeMediaQuery } from './options-play-breakpoints';
import { ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    timeFrame: Palette['primary'];
    tolerance: Palette['primary'];
    collapsible: Palette['primary'];
    selectAndTextField: Palette['primary'];
    selection: Palette['primary'];
    globalSelect: Palette['primary'];
    personalSelect: Palette['primary'];
    legButtons: Palette['primary'];
    orangeButton: Palette['primary'];
    symbolCard: Palette['background'];
    header: Palette['background'];
    highChart?: Palette['primary'];
    slider?: Palette['primary'];
    optionGridDisabled?: Palette['primary'];
  }
  interface PaletteOptions {
    timeFrame?: PaletteOptions['primary'];
    tolerance?: PaletteOptions['primary'];
    collapsible?: PaletteOptions['primary'];
    selectAndTextField?: PaletteOptions['primary'];
    selection?: PaletteOptions['primary'];
    globalSelect: PaletteOptions['primary'];
    personalSelect: PaletteOptions['primary'];
    legButtons?: PaletteOptions['primary'];
    orangeButton?: PaletteOptions['primary'];
    symbolCard?: PaletteOptions['background'];
    header?: PaletteOptions['background'];
    highChart?: PaletteOptions['primary'];
    slider?: PaletteOptions['primary'];
    optionGridDisabled?: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    captionSmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    captionSmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionSmall: true;
  }
}

export const slateTheme: ThemeOptions = {
  breakpoints: {
    values: OptionsPlayBreakpoints,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#3CA7FC', //'#1975b9',
      light: '#585D61',
      dark: '#ffffff',
    },
    secondary: {
      main: '#f50057',
      light: '#11181E',
      dark: '#585D61',
    },
    background: {
      default: '#11181E',
      paper: '#1F262B',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
    },
    success: {
      light: '#b8e0b9',
      main: '#00A94F',
    },
    error: {
      main: '#FF451D',
      light: '#ED004F',
    },
    warning: {
      main: '#DF9641', //'#ffdb8d',
      light: 'rgb(223, 150, 65, 0.2)', //DF9641 with 20% opacity
      dark: '#E6740E',
    },
    info: {
      main: '#575757',
      light: '#3CA7FC',
    },
    timeFrame: {
      light: '#84BDF1',
      main: '#5BA0E0',
      dark: '#3585CE',
      contrastText: '#FFFFFF',
    },
    tolerance: {
      light: '#F39B95',
      main: '#F0776D',
      dark: '#E84429',
      contrastText: '#FFFFFF',
    },
    collapsible: {
      main: '#1A1A1A',
    },
    selectAndTextField: {
      light: '#1F262B',
      main: '#FFFFFF',
    },
    selection: {
      main: '#BFE0FA',
      light: 'rgb(17,24,30)',
      dark: '#9E9E9E',
      contrastText: '#000000',
    },
    globalSelect: {
      main: '#00A94F', //For Selected Global Text
      light: '#00A94F', //For Selected Global Icon
      dark: '#00A94F',
    },
    personalSelect: {
      main: '#3CA7FC', //For Selected Personal Text
      light: '#3CA7FC', //For Selected Persoanl Icon
    },
    legButtons: {
      main: '#3CA7FC', //stock
      light: '#b8e0b9', //call
      dark: '#FF451D', //put
    },
    orangeButton: {
      main: 'rgb(223, 150, 65, 0.2)', // backgroundColr
      light: '#DF9641', //selected border Color
      dark: '#585D61', //  Border color
    },
    symbolCard: {
      default: '#1F262B',
    },
    header: {
      default: '#1F262B',
    },
    highChart: {
      main: '#FFFFFFF2',
      dark: '#FFFFFF80',
      light: '#FFFFFF',
      contrastText: '#000000',
    },
    slider: {
      main: '#585D61',
    },
    optionGridDisabled: {
      main: '#3A4045',
      light: '#585D61',
      contrastText: '#FFFFFF',
    },
    divider: '#585D61',
  },
  typography: {
    captionSmall: {
      fontSize: '0.75rem',
      lineHeight: 2,
    },
    body1: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
    body2: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: '20px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 5,
          minHeight: 20,
          textTransform: 'capitalize',
          fontFamily: '"Lato", "sans-serif"',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#0078D4',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 16px 8px 16px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Lato", "sans-serif"',
        },
        button: {
          textTransform: 'capitalize',
          [fontSizeMediaQuery('xl', false)]: {
            fontSize: '0.80rem',
          },
          [fontSizeMediaQuery('xl')]: {
            fontSize: '0.90rem',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 4,
          textTransform: 'initial',
          margin: 4,
          color: '#FFFFFF',
          backgroundColor: '#1F262B',
          border: '1px solid #585D61',
          '&.Mui-selected,&.Mui-selected:hover': {
            color: '#000000',
            backgroundColor: '#3CA7FC',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: '0.5px dashed #585D61',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
        listbox: {},
        option: {},
      },
    },
  },
};
