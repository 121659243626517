import { EmbedderEventType, EventType, ImplementationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import {
  accessTokenKey,
  accountState,
  configurationState,
  customizationState,
  embedderSentimentAtom,
  fetchHowData,
  fetchRankData,
  fetchWhyData,
  howWhyRanksDataState,
  isHowWhyRanksDataLoading,
  resetIncomeState,
  tradingStrategiesSentimentAtom,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

export const useFetchAndSetData = () => {
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const [isProcessing, setIsProcessing] = useRecoilState(isHowWhyRanksDataLoading);
  const navigate = useNavigate();
  const setHowWhyRanksData = useSetRecoilState(howWhyRanksDataState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [account, setAccount] = useRecoilState(accountState);
  const resetStrategySentiment = useResetRecoilState(tradingStrategiesSentimentAtom);
  const resetIncome = useSetRecoilState(resetIncomeState);
  const resetEmbedderSentiment = useResetRecoilState(embedderSentimentAtom);

  const fetchAndSetData = async (
    symbol: string,
    ruleMatch?: string,
    isAnonymous?: boolean,
    shareId?: string,
    allowedSecurities?: string,
    sentiment?: string,
  ) => {
    if (isProcessing) {
      return true;
    }
    // allowedSecurities = window.localStorage.getItem('embedder-allowedSecurities') || undefined;
    allowedSecurities =
      ApplicationContext.configuration && ApplicationContext.configuration.additionalData
        ? ApplicationContext.configuration.additionalData?.allowedSecurities
        : undefined;
    const canSearchSymbol =
      allowedSecurities === undefined || allowedSecurities.toUpperCase().indexOf(symbol.toUpperCase()) > -1;
    if (!canSearchSymbol) {
      setNotifications([{ type: 'error', content: `Quote not found.` }]);
      return;
    }
    setIsProcessing(true);
    /**
     * slash symbols got replcaed in the api service levels as it is required only to get response
     */
    const fallbackRanksSymbol = getFallbackSymbol();
    const [how, why, ranks, ranksFallback] = await Promise.all([
      fetchHowData(symbol, ruleMatch, isAnonymous, shareId),
      fetchWhyData(symbol, ruleMatch, isAnonymous, shareId),
      fetchRankData(symbol, isAnonymous, shareId),
      fallbackRanksSymbol && fallbackRanksSymbol !== ''
        ? fetchRankData(fallbackRanksSymbol, isAnonymous, shareId)
        : undefined,
    ]);
    if (how.hasErrors || why.hasErrors || ranks.hasErrors || !how.data || !why.data || !ranks.data) {
      setNotifications([{ type: 'error', content: `Quote not found` }]);
      if (how.errorCode === 403) {
        if (customization?.isEmbeddingPlatform) {
          setNotifications([{ type: 'error', content: `Unexpected error has occurred, please reload the page.` }]);
          setIsProcessing(false);
          return;
        }
        localStorage.removeItem('lightbox_state');
        localStorage.removeItem(accessTokenKey);
        setAccount(undefined);
        if (customization?.isEmbeddingPlatform) {
          window.parent.location.reload();
          return;
        }
        navigate('/login', { replace: true });
        window.location.reload();
      }
      setIsProcessing(false);
      return false;
    }
    fillFallbackSignal(ranksFallback, ranks);
    setHowWhyRanksData({
      symbol: symbol.toUpperCase(),
      how: how.data.howData,
      why: why.data,
      ranks: ranks.data,
    });
    resetStrategySentiment();
    if (!sentiment) {
      resetEmbedderSentiment();
    }
    resetIncome({ reason: 'symbolChanged', shouldReset: true });
    if (configuration?.isEmbeddingPlatform) {
      const message = { event: EmbedderEventType.SymbolChanged.toString(), data: symbol.toUpperCase() };
      window.parent.postMessage(JSON.stringify(message), '*');
    }
    const key =
      configuration && configuration.isEmbeddingPlatform ? 'embedSelectedSymbolStateKey' : 'selectedSymbolStateKey';
    window.localStorage.setItem(key, symbol.toUpperCase());
    let payload: any = { quoteType: account && account.hasRealTimeQuotes ? 'realTime' : 'delayed' };
    if (account && account.widgetUserId) {
      payload['userId'] = account.widgetUserId;
    }
    ApplicationContext.userActivityHub?.logActivity(
      'selectedTradeIdeaSymbol',
      'selectedTradeIdeaSymbol',
      JSON.stringify(payload),
      EventType.Click,
      symbol.toUpperCase(),
    );
    setIsProcessing(false);
    return true;
  };

  const fillFallbackSignal = (ranksFallback: any | undefined, ranks: any) => {
    if (!ranksFallback) {
      return;
    }
    const ivRank = ranks.data?.find((s: any) => s?.name?.toUpperCase() === 'ImpliedVolatilityRank'.toUpperCase());
    if (ivRank) {
      return;
    }
    const ivRankFallback = ranksFallback.data?.find(
      (s: any) => s?.name?.toUpperCase() === 'ImpliedVolatilityRank'.toUpperCase(),
    );
    if (!ivRankFallback) {
      return;
    }
    ranks.data.push(ivRankFallback);
  };

  const getFallbackSymbol = () => {
    const additionalData = JSON.parse(window.localStorage.getItem('additionalData') || '{}');
    if (!additionalData.shouldFallBack) {
      return undefined;
    }
    return additionalData.fallbackRanksSymbol?.trim();
  };

  return fetchAndSetData;
};
