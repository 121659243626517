import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import { IconButton, Theme, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { isConfigurationUpdatedState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { uservoicegetLanguageType } from './uservoice-language-select';
import LocalizationContext from '../react-i18next/localization-context';

declare global {
  interface Window {
    UserVoice: Array<object>;
  }
}

export const UserVoiceWidget = (): JSX.Element => {
  const { t } = React.useContext(LocalizationContext);
  const isConfigurationUpdated = useRecoilValue(isConfigurationUpdatedState);
  const UserVoice = window.UserVoice || [];
  const theme = useTheme<Theme>();

  useEffect(() => {
    const configuration = ApplicationContext.configuration?.userVoiceConfiguration;
    if (!configuration) {
      return;
    }
    UserVoice.push(['setSSO', configuration.ssoToken]);
    UserVoice.push(['identify', configuration.identity]);
  }, [isConfigurationUpdated]);

  if (!UserVoice || UserVoice.length === 0) {
    return <></>;
  }

  const feedbackPopOver = () => {
    UserVoice.push([
      'show',
      'set',
      {
        locale: uservoicegetLanguageType(window.localStorage.i18nextLng),
        target: '#uv-1',
        mode: 'smartvote',
        menu: true,
      },
    ]);
    UserVoice.push(['addTrigger', '#uv-1', {}]);
  };

  const supportPopOver = () => {
    UserVoice.push([
      'show',
      'set',
      {
        target: '#uv-2',
        locale: uservoicegetLanguageType(window.localStorage.i18nextLng),
        mode: 'contact',
        menu: true,
      },
    ]);
    UserVoice.push(['addTrigger', '#uv-2', {}]);
  };

  return (
    <>
      <IconButton id="uv-1" onClick={feedbackPopOver} size="large">
        <Tooltip title={`${t('app.php.footer.labels.feedback')}`}>
          <FeedbackOutlinedIcon style={{ color: theme.palette.primary.dark }} />
        </Tooltip>
      </IconButton>
      {/* <IconButton size="small" id="uv-1" onClick={feedbackPopOver}>
        <Grid style={{ paddingBottom: 0, color: theme.palette.primary.dark }}>
          <FeedbackIcon fontSize="small" />
          <Typography style={{ //fontSize: 12 }}>{t('app.php.footer.labels.feedback')}</Typography>
        </Grid>
      </IconButton> */}
      <IconButton id="uv-2" onClick={supportPopOver} size="large">
        <Tooltip title={`${t('app.php.header.labels.askUs')}`}>
          <LiveHelpOutlinedIcon style={{ color: theme.palette.primary.dark }} />
        </Tooltip>
      </IconButton>
    </>
  );
};
