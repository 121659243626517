import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Button, Typography, useTheme } from '@mui/material';
import { Combination, ILeg } from '@op/shared/src/models';
import { LegType } from '@op/shared/src/models/enums/enums';
import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { addOptionLeg, canHideLegButton } from './modify';

type Props = {
  callback: (action: string, legs?: ILeg[]) => void;
  logActivity: (controlType: string, controlName: string, value?: any) => void;
  combination: Combination;
};

export const AddPutWidget: React.FC<Props> = ({ callback, combination, logActivity }) => {
  const theme = useTheme();
  const customization = useRecoilValue(customizationState);
  const { t } = React.useContext(LocalizationContext);

  if (!customization || !combination.chain || combination.chain.rows.length === 0) {
    return;
  }

  if (!customization.allowStockPlusOptionforCASymbol && canHideLegButton(combination, LegType.PUT)) {
    return;
  }

  const addLeg = () => {
    const options = addOptionLeg(LegType.PUT, combination);
    callback(options.action, options.legs);
    logActivity('BUTTON', 'editorAddLegButton', 'Put');
  };

  return (
    <Button onClick={addLeg} sx={{ color: theme.palette.legButtons.dark }} startIcon={<AddCircleOutlineRoundedIcon />}>
      <Typography variant="body1">{t('app.php.how.combinationEditor.buttons.put')}</Typography>
    </Button>
  );
};
