import { customizationState, howWhyRanksDataState, isDataLoadingState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { QuotebarLoaderWidget } from './quote-bar-loader-widget';
import { QuotebarStandardWidget } from './quote-bar-standard-widget';
import { QuotebarVariant1Widget } from './quote-bar-variant1-widget';
import { QuotebarVariant2Widget } from './quote-bar-variant2-widget';
import { QuotebarVariant3Widget } from './quote-bar-variant3-widget';

export const QuoteBarWidget: React.FC = () => {
  const customization = useRecoilValue(customizationState);
  const isDataLoading = useRecoilValue(isDataLoadingState);
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  if (!customization || isDataLoading || !howWhyRanksData.how || !howWhyRanksData.symbol) {
    return <QuotebarLoaderWidget />;
  }
  if (customization.quotebarName === 'variant1') {
    return <QuotebarVariant1Widget />;
  }
  if (customization.quotebarName === 'variant2') {
    return <QuotebarVariant2Widget />;
  }
  if (customization.quotebarName === 'variant3') {
    return <QuotebarVariant3Widget />;
  }
  return <QuotebarStandardWidget />;
};
