import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { currentSubscriptionScreenState, selectedSymbolState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import sadLook from '@op/shared/assets/images/sad-look.gif';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { cancelsubscription } from '@op/shared/src/services';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { IconComponent } from '../../common';
import LocalizationContext from '../../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../../styled';
import { SubscriptionFooterWidget } from './subscription-footer-widget';

export const SubscriptionCancel: React.FC = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const [value, setValue] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [cancelled, setCancelled] = React.useState(false);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setCurrentSubscriptionScreen = useSetRecoilState(currentSubscriptionScreenState);
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme();
  const [count, setCount] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleCommemtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCount(event.target.value.length);
    setComment((event.target as HTMLInputElement).value);
  };

  const clearState = () => {
    setValue('');
    setComment('');
  };

  useEffect(() => {
    setValue('');
    setComment('');
  }, []);

  const handleBack = () => {
    logActivity('BUTTON', 'paymentsCancelSubscriptionBackButton');
    setCurrentSubscriptionScreen('currentsubscription');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const cancelAccount = async (): Promise<void> => {
    logActivity('BUTTON', 'paymentsCancelSubscriptionContinueButton');
    const reason = {
      reasonCode: value,
      userComment: comment,
    };

    if (value <= '0') {
      setNotifications([{ type: 'error', content: 'Reason is Required...' }]);
    } else if (value === '9999' && !comment) {
      setNotifications([{ type: 'error', content: 'comment is Required...' }]);
    } else {
      try {
        const response = await cancelsubscription(reason);
        if (response.hasErrors) {
          setNotifications([{ type: 'error', content: 'Subscription cancel Failed' }]);
          clearState();
        }
        setNotifications([{ type: 'success', content: 'Subscription cancelled Successfully' }]);
        clearState();
        setCancelled(true);
        setTimeout(() => {
          setCancelled(false);
          setCurrentSubscriptionScreen('currentsubscription');
        }, 5000);
      } catch (error) {
        setNotifications([{ type: 'error', content: 'Subscription cancel Failed' }]);
      }
    }
  };

  const changePlan = () => {
    logActivity('BUTTON', 'paymentsAddSubscriptionButton');
    setCurrentSubscriptionScreen('SubscriptionWidget');
  };

  const renderView = () => {
    if (cancelled) {
      return (
        <Grid container justifyContent="center" alignItems="center" textAlign={'center'} height={600}>
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <OPBoldTypograpghy variant="h5" sx={{ color: theme.palette.primary.main }}>
              {t('app.php.subscriptions.yourSubscription')}
            </OPBoldTypograpghy>
            <OPBoldTypograpghy variant="h5" sx={{ color: theme.palette.error.main }}>
              {t('app.php.subscriptions.successfullyGotCancelled')}
            </OPBoldTypograpghy>
            <IconComponent name="cancelLoader" />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: 16, padding: 5 }}>
            {t('app.php.subscriptions.subscription')}
          </OPBoldTypograpghy>{' '}
        </Grid>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <OPBoldTypograpghy
            variant="subtitle2"
            style={{ color: theme.palette.primary.main, padding: 12, fontSize: '20px' }}>
            {t('app.php.subscriptions.sorryToSeeYouGo')}
          </OPBoldTypograpghy>
          <img src={sadLook} alt="" style={{ height: '74px', width: '74px' }} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : '#2E3439' }}>
          <Grid item xs={12}>
            <br />
            <Typography px={1} style={{ fontSize: theme.typography.subtitle2.fontSize, fontWeight: 500 }}>
              {t('app.php.subscriptions.youAreAboutToCancelCurrentSubscription')}
            </Typography>
            <br />
          </Grid>

          <Grid item container px={1} xs={12}>
            <FormControl component="fieldset" color="primary">
              <RadioGroup aria-label="reasons" name="reasons" value={value} onChange={handleChange}>
                <FormControlLabel
                  value="1001"
                  control={<Radio color="primary" />}
                  label={t('app.php.subscriptions.subscriptionWasExpensive')}
                  style={{ fontWeight: '500' }}
                />
                <FormControlLabel
                  value="1002"
                  control={<Radio color="primary" />}
                  label={t('app.php.subscriptions.difficultToUse')}
                  style={{ fontWeight: '500' }}
                />
                <FormControlLabel
                  value="1003"
                  control={<Radio color="primary" />}
                  label={t('app.php.subscriptions.didNotUse')}
                  style={{ fontWeight: '500' }}
                />
                <FormControlLabel
                  value="9999"
                  control={<Radio color="primary" />}
                  label={t('app.php.subscriptions.other')}
                  style={{ fontWeight: '500' }}
                />
              </RadioGroup>
              {value === '9999' && (
                <Grid item xs={12} style={{ margin: 10, width: '700px' }}>
                  <TextField
                    fullWidth
                    // label="Comment"
                    id="comment"
                    name="comment"
                    onChange={handleCommemtChange}
                    value={comment}
                    variant="outlined"
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 200 }}
                  />
                  <Typography textAlign="right">{count}/200</Typography>
                </Grid>
              )}
            </FormControl>
          </Grid>
          <Grid container item justifyContent="flex-end" paddingY={2}>
            <Box justifyContent="flex-end" p={0.5}>
              <Button onClick={handleBack} sx={{ color: theme.palette.primary.light }} size="medium" variant="outlined">
                <ArrowBackIosIcon fontSize="medium" sx={{ color: theme.palette.primary.light }} />
                <Typography variant="button" sx={{ color: theme.palette.text.primary }}>
                  {t('app.php.common.buttons.back')}
                </Typography>
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={handleBack} id="pw-subscribe" color="success" size="large" variant="contained">
                <OPBoldTypograpghy variant="button">{t('app.php.subscriptions.changeMyMind')}</OPBoldTypograpghy>
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={changePlan} id="pw-subscribe" size="large" color="primary" variant="contained">
                <OPBoldTypograpghy variant="button">{t('app.php.subscriptions.changePlan')}</OPBoldTypograpghy>
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                onClick={cancelAccount}
                sx={{ '&:hover': { backgroundColor: theme.palette.error.main } }}
                id="pw-subscribe"
                size="large"
                color="primary"
                variant="contained">
                <OPBoldTypograpghy variant="button">{t('app.php.subscriptions.cancelSubscription')}</OPBoldTypograpghy>
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SubscriptionFooterWidget />
        </Grid>
      </Grid>
    );
  };

  return renderView();
};

export default SubscriptionCancel;
