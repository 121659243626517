import { Grid, Typography } from '@mui/material';
import { PortfolioCombinationEntities, PortfolioCombinationEntity } from '@op/shared/src/models';
import { sortsPortfolioState } from '@op/shared/src/states';
import { filtersPortfoliosState } from '@op/shared/src/states/portfolio-alerts-hub-states';
import {
  isPortfolioCombinationsLoadingState,
  portfolioCombinationsOfSelectedAccountsState,
} from '@op/shared/src/states/portfolio-states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { PortfolioCombinationsWidget } from './portfolio-combinations-widget';
import { PortfolioWhatLoaderWidget } from './portfolio-what-loader-widget';

export interface IPorfolioCombinationsOfAnAccountWidgetProps {}

export const PorfolioCombinationsOfAccountsWidget: React.FC<IPorfolioCombinationsOfAnAccountWidgetProps> = () => {
  const itemsPerPage = 8;
  const combinationEntitiesOfSelectedAccounts = useRecoilValue(portfolioCombinationsOfSelectedAccountsState);
  const sorts = useRecoilValue(sortsPortfolioState);
  const setIsPortfolioCombinationsLoading = useSetRecoilState(isPortfolioCombinationsLoadingState);
  const portfolioFilters = useRecoilValue(filtersPortfoliosState);
  const [accountEntities, setAccountEntities] = useState<PortfolioCombinationEntities>();
  const [pagedAccountEntity, setPagedAccountEntity] = useState<PortfolioCombinationEntity[]>();
  const [hasNextPage, setHasNextPage] = useState(true);

  useEffect(() => {
    if (!combinationEntitiesOfSelectedAccounts) {
      return;
    }
    setIsPortfolioCombinationsLoading(true);
    const cloned = PortfolioCombinationEntities.fromSelf(combinationEntitiesOfSelectedAccounts);
    cloned.filter(portfolioFilters).sort(sorts);
    setAccountEntities(cloned);
  }, [combinationEntitiesOfSelectedAccounts, sorts, portfolioFilters]);

  useEffect(() => {
    if (!accountEntities) {
      return;
    }
    (async () => {
      const entities = accountEntities.combinations.slice(0, itemsPerPage);
      if (!entities) {
        setHasNextPage(false);
        return [];
      }
      setPagedAccountEntity(entities);
      setIsPortfolioCombinationsLoading(false);
      setHasNextPage(entities.length <= accountEntities.combinations.length);
    })();
  }, [accountEntities]);

  if (!combinationEntitiesOfSelectedAccounts) {
    return <PortfolioWhatLoaderWidget />;
  }

  if (!accountEntities) {
    return (
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Typography>No portfolio items found</Typography>
      </Grid>
    );
  }

  const loadMore = async (startIndex: number) => {
    if (!accountEntities) {
      setHasNextPage(false);
      return [];
    }
    const endIndex = startIndex + itemsPerPage;
    const entities = accountEntities.combinations.slice(startIndex, startIndex + itemsPerPage);
    if (!entities) {
      setHasNextPage(false);
      return [];
    }
    setHasNextPage(endIndex <= accountEntities.combinations.length);
    return entities;
  };

  if (!pagedAccountEntity) {
    return <>pagedAccountEntity loading...</>;
  }

  return (
    <PortfolioCombinationsWidget data={pagedAccountEntity} loadMoreCallback={loadMore} hasNextPage={hasNextPage} />
  );
};
