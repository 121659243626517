import { Button, Grid, Typography, useTheme } from '@mui/material';
import { AmountType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import Combination from '@op/shared/src/models/how/combination';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import {
  allCombinationsState,
  configurationState,
  customizationState,
  howDataState,
  selectedSymbolState,
  tradingRangeSimulatorState,
  tradingStrategiesSentimentAtom,
  tradingStrategiesUpdater,
} from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { GuideItem } from '../side-menu';
import { OptionsPlayCurrencyNonNegativeFormatInput, OptionsPlaySwitch, OptionsPlayTextField } from '../styled';
import { BasicCardWidget } from './basic-card-widget';

//TODO: Refactor: This needs refactoring. TradingStrategiesUpdater should only care about allCombinations collection to update.
export const RiskInvestmentWidget: React.FC = () => {
  const theme = useTheme();
  const howData = useRecoilValue(howDataState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const tradingStrategySelectedSentiment = useRecoilValue(tradingStrategiesSentimentAtom);
  const allCombinations = useRecoilValue(allCombinationsState);
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const setTradingStrategies = useSetRecoilState(tradingStrategiesUpdater);
  const [amountType, setAmountType] = useState<AmountType | undefined>();
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [amountInputValue, setAmountInputValue] = useState('');
  const [switchValue, setSwitchValue] = useState(0);
  const { t } = React.useContext(LocalizationContext);
  const navigate = useNavigate();

  useEffect(() => {
    setToggleByConfiguartion();
  }, [configuration, howData]);

  useEffect(() => {
    // https://stackoverflow.com/questions/54069253/usestate-set-method-not-reflecting-change-immediately/54069332#54069332
    // UseEffect ensures that the latest value of switch is retrieved in `calculate()` function.
    if (!amountType) {
      return;
    }
    if (amountType === AmountType.INVESTMENT) {
      setInvestmentAmount();
    }
    if (amountType === AmountType.RISK) {
      setRiskAmount();
    }
    ApplicationContext.userActivityHub?.logActivity(
      'investmentType',
      'investmentType',
      amountType === AmountType.INVESTMENT ? 'I want to invest' : 'I want to risk',
      'click',
      selectedSymbol,
    );
  }, [amountType]);

  const setToggleByConfiguartion = () => {
    if (
      !configuration ||
      !configuration.applicationConfiguration ||
      !configuration.applicationConfiguration.riskAndInvestment ||
      !howData
    ) {
      setAmountType(AmountType.INVESTMENT);
      setSwitchValue(0);
      setDefaultStockAmount();
      return;
    }
    const totalAmount = configuration.applicationConfiguration.riskAndInvestment?.riskAndInvestmentAmount;
    const percentage = configuration.applicationConfiguration.riskAndInvestment?.riskAndInvestmentPercentage;
    if (totalAmount > 0 && percentage > 0) {
      setAmountType(AmountType.RISK);
      setSwitchValue(1);
      return;
    }
    setAmountType(AmountType.INVESTMENT);
    setSwitchValue(0);
  };

  const setDefaultStockAmount = () => {
    if (!howData) {
      return;
    }
    const amount = howData.quote.last * 100;
    const amountRounded = NumberFormatHelper.roundNumber(amount);
    setAmount(amountRounded);
    setAmountInputValue(amountRounded.toString());
  };

  const setInvestmentAmount = () => {
    setDefaultStockAmount();
  };

  const setRiskAmount = () => {
    if (
      !configuration ||
      !configuration.applicationConfiguration ||
      !configuration.applicationConfiguration.riskAndInvestment
    ) {
      return;
    }
    const totalAmount = configuration.applicationConfiguration.riskAndInvestment?.riskAndInvestmentAmount;
    const percentage = configuration.applicationConfiguration.riskAndInvestment?.riskAndInvestmentPercentage;
    if (totalAmount > 0 && percentage > 0) {
      const amount = (totalAmount * percentage) / 100;
      const amountRounded = NumberFormatHelper.roundNumber(amount);
      setAmount(amountRounded);
      setAmountInputValue(amountRounded.toString());
      return;
    }
    setDefaultStockAmount();
  };

  if (!tradingRangeSimulator || !howData) {
    throw new Error('tradingRangeSimulator or howData is undefined');
  }

  if (!configuration) {
    return null;
  }

  const clonedCombinations: (Combination | undefined)[] = [];
  for (let combination of allCombinations) {
    if (!combination) {
      clonedCombinations.push(combination);
      continue;
    }
    clonedCombinations.push(Combination.fromSelf(combination));
  }

  const calculate = () => {
    if (amount === undefined) {
      return;
    }
    calculateRiskOrInvestment();
    setTradingStrategies(clonedCombinations);
  };

  const calculateRiskOrInvestment = () => {
    switch (amountType) {
      case AmountType.INVESTMENT:
        setSwitchValue(0);
        calculateInvestment();
        break;
      case AmountType.RISK:
        setSwitchValue(1);
        calculateRisk();
        break;
      default:
        throw new Error('Unknown investment type');
    }
  };

  const calculateInvestment = () => {
    for (let combination of clonedCombinations) {
      if (!combination) {
        continue;
      }
      if (combination.hasOnlyStx()) {
        calculateInvestmentForShares(combination);
      } else {
        calculateInvestmentForOptions(combination);
      }
    }
    ApplicationContext.userActivityHub?.logActivity(
      'investmentCalculateButton',
      'investmentCalculateButton',
      amount,
      'click',
      selectedSymbol,
    );
  };

  const calculateRisk = () => {
    if (amount === undefined) {
      throw new Error('amount is undefined');
    }
    const buyPowerAmount = Math.abs(amount);
    const sds = tradingRangeSimulator.sds;
    for (let combination of clonedCombinations) {
      if (!combination) {
        continue;
      }
      //&& combination.isSellCombination()
      if (combination.hasOnlyStx()) {
        calculateInvestmentForShares(combination);
        continue;
      }
      if (sds.length === 5) {
        const strikes = combination.strikes().filter((s: number) => {
          return s >= sds[0] && s <= sds[4];
        });
        strikes.push(sds[0]);
        strikes.push(sds[4]);
        const risks = strikes.map((spot) => {
          if (combination === undefined) {
            throw new Error('combination is undefined');
          }
          const payoff = combination.payoff(spot);
          return payoff < 0 ? -payoff / combination.absQuantity() : 0;
        });
        let maxRisk = Math.max(...risks);
        if (maxRisk === 0) {
          maxRisk = combination.askPrice() * combination.multiplier();
        }
        let qty = Math.floor(buyPowerAmount / maxRisk);
        if (qty < 1) {
          qty = 1;
        }
        combination.setAbsQuantity(Math.abs(qty));
      }
    }
    ApplicationContext.userActivityHub?.logActivity(
      'investmentCalculateButton',
      'investmentCalculateButton',
      buyPowerAmount,
      'click',
      selectedSymbol,
    );
  };

  const switchHandleChange = (isChecked: boolean) => {
    if (isChecked) {
      setSwitchValue(1);
      setAmountType(AmountType.RISK);
    } else {
      setSwitchValue(0);
      setAmountType(AmountType.INVESTMENT);
    }
  };

  const onChangeAmount = (event: any): void => {
    const amount = event.values.floatValue;
    setAmountInputValue(event.values.value);
    ApplicationContext.userActivityHub?.logActivity(
      'investmentAmount',
      'investmentAmount',
      event.values.value,
      'change',
      selectedSymbol,
    );
    setAmount(amount);
  };

  const calculateInvestmentForShares = (combination: Combination) => {
    if (amount === undefined) {
      throw new Error('amount is undefined');
    }
    const unitPrice = howData.quote.last;
    let shares = Math.floor(Math.abs(amount) / unitPrice);
    if (shares < 1) {
      shares = 1;
    }
    combination.setAbsQuantity(shares);
  };

  const calculateInvestmentForOptions = (combination: Combination) => {
    if (tradingStrategySelectedSentiment === undefined) {
      throw new Error('sentiment is undefined');
    }
    if (amount === undefined) {
      throw new Error('amount is undefined');
    }
    //TODO: enum comparision should be used
    const unitPrice = howData.quote.last;
    let numberOfContracts = Math.abs(amount) / (unitPrice * 100);
    if (numberOfContracts < 1) {
      numberOfContracts = 1;
    }
    combination.setAbsQuantity(numberOfContracts);
  };

  const navigateToRiskSettings = () => {
    navigate('/profile', { state: 10 });
  };

  const manageRiskSettingText = () => {
    if (!customization?.showProfileSettings) {
      return null;
    }
    return (
      <Typography variant="body1" lineHeight={1.7} alignItems="center">
        {t('app.php.how.toolbox.riskAndInvestmentCalculator.text.adjustYourDefault')}
        <Button id="manage-risk" color="primary" onClick={() => navigateToRiskSettings()} sx={{ mt: 0 }}>
          <Typography variant="body1" align="left">
            {t('app.php.how.toolbox.riskAndInvestmentCalculator.text.yourProfile')}
          </Typography>
        </Button>
      </Typography>
    );
  };

  const renderContent = () => {
    return (
      <Grid container rowSpacing={2}>
        <Grid item container xs={12} alignItems="center" columnSpacing={2}>
          <Grid item xs>
            <Typography variant="body2" textAlign="right">
              {t('app.php.profile.billingHistory.js.amountTitle')}
            </Typography>
          </Grid>
          <Grid item xs>
            <form
              id="investmentAmount"
              data-name="investmentAmount"
              data-value={amount}
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                calculate();
              }}>
              <OptionsPlayTextField
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="outlined-basic"
                size="small"
                value={amountInputValue}
                onChange={(event: any) => onChangeAmount(event)}
                InputProps={{
                  inputComponent: OptionsPlayCurrencyNonNegativeFormatInput as any,
                }}
              />
            </form>
          </Grid>
          <Grid item xs>
            <Button
              color="primary"
              id="investmentCalculateButton"
              variant="contained"
              data-name="investmentCalculateButton"
              data-value={amount}
              onClick={calculate}>
              <Typography sx={{ color: theme.palette.selectAndTextField.dark }} variant="button">
                {t('app.php.how.toolbox.riskAndInvestmentCalculator.buttons.calculate')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            lineHeight={1.7}
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: `${t('app.php.how.toolbox.riskAndInvestmentCalculator.text.investmentAmountReflects')}`,
            }}></Typography>
          <Typography
            variant="body2"
            lineHeight={1.7}
            dangerouslySetInnerHTML={{
              __html: `${t('app.php.how.toolbox.riskAndInvestmentCalculator.text.riskAmountReflects')}`,
            }}></Typography>
          {manageRiskSettingText()}
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicCardWidget
      title={
        <Grid container alignItems="center">
          <GuideItem selector=".investmentAmount_helpPinPlaceholder" />
          <Grid item>
            <Typography
              variant="subtitle1"
              lineHeight={1.2}
              dangerouslySetInnerHTML={{
                __html: `${t('app.php.how.toolbox.riskAndInvestmentCalculator.text.iWantToInvest')}`,
              }}></Typography>
          </Grid>
          <Grid item>
            <OptionsPlaySwitch
              value={switchValue}
              checked={switchValue === 1}
              onChange={(event, checked) => switchHandleChange(checked)}
              name="checkedC"
            />
          </Grid>
          <GuideItem selector=".riskAmount_helpPinPlaceholder" />
          <Grid item>
            <Typography
              variant="subtitle1"
              lineHeight={1.2}
              dangerouslySetInnerHTML={{
                __html: `${t('app.php.how.toolbox.riskAndInvestmentCalculator.text.iWantToRisk')}`,
              }}></Typography>
          </Grid>
        </Grid>
      }
      content={renderContent()}
    />
  );
};
