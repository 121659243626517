import { Grid, Rating, Theme, Typography, useTheme } from '@mui/material';
import { IDataProps } from '@op/shared/src/models';
import React from 'react';
import { customizationState } from '@op/shared/src/states';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';

export interface ILiquidityProps extends IDataProps<{ date: string; name: string; symbol: string; value: number }[]> {
  showRatings: boolean;
}

export const LiquidityRatingWidget: React.FC<ILiquidityProps> = ({ data, showRatings }: ILiquidityProps) => {
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme<Theme>();
  const customization = useRecoilValue(customizationState);
  const signal = data?.find((s) => s?.name?.toUpperCase() === 'Liquidity'.toUpperCase());
  let liquidityWords = '';
  let numberOfStars = 0;
  const isMinimalistic = customization?.isMinimalistic || true;

  if (!customization) {
    return null;
  }

  const backGroundColor = () => {
    if (!signal?.value) {
      return {
        color: 'none',
      };
    }
    let bgColor = 'none';
    if (signal.value === 3) {
      bgColor = theme.palette.error.main;
      numberOfStars = 1;
      liquidityWords = 'Not Liquid';
    } else if (signal.value === 2) {
      bgColor = theme.palette.warning.main;
      numberOfStars = 2;
      liquidityWords = 'Somewhat Liquid';
    } else if (signal.value === 1) {
      bgColor = theme.palette.success.main;
      numberOfStars = 3;
      liquidityWords = 'Very Liquid';
    } else {
      bgColor = theme.palette.error.main;
      liquidityWords = '';
      numberOfStars = 0;
    }
    return {
      color: bgColor,
    };
  };

  const getLiquidityWords = (inTradeIdea: boolean) => {
    if (!signal?.value) {
      return 'N/A';
      // return {
      //   color: 'none',
      // };
    }
    let bgColor = 'none';
    if (signal.value === 3) {
      bgColor = theme.palette.error.main;
      numberOfStars = 1;
      liquidityWords = inTradeIdea ? 'Not Liq' : 'Not Liquid';
    } else if (signal.value === 2) {
      bgColor = theme.palette.warning.main;
      numberOfStars = 2;
      liquidityWords = inTradeIdea ? 'Som Liq' : 'Somewhat Liquid';
    } else if (signal.value === 1) {
      bgColor = theme.palette.success.main;
      numberOfStars = 3;
      liquidityWords = inTradeIdea ? 'Very Liq' : 'Very Liquid';
    } else {
      bgColor = theme.palette.error.main;
      liquidityWords = '';
      numberOfStars = 0;
    }
    return liquidityWords;
  };

  const getBackgroundGradient = (theme: Theme) => {
    const defaultColor = theme.palette.grey[400]; //'gray';
    const primaryColor = backGroundColor();
    const thumbPercentage = 100;
    const carretPercentage = numberOfStars * 33.33;
    return `linear-gradient(90deg, ${primaryColor.color} ${carretPercentage}%, ${defaultColor} ${carretPercentage}% ${thumbPercentage}%, ${defaultColor} ${thumbPercentage}%)`;
  };

  const renderRating = () => {
    if (!signal?.value) {
      return (
        <Typography variant="captionSmall">
          <strong>{t('app.php.common.labels.notAvailable')}</strong>
        </Typography>
      );
    }
    return (
      <Grid item sx={{ marginTop: '2px' }}>
        <Rating size="small" style={backGroundColor()} value={numberOfStars} max={3} readOnly />
      </Grid>
    );
  };

  const renderLiquidity = () => {
    if (!customization.showLiquidity) {
      return;
    }
    return isMinimalistic && !showRatings ? (
      <>
        <Grid item className="js-trade-idea-liquidity-rating">
          <Typography variant={'captionSmall'}>{t('app.php.why.titles.Liquidity')}</Typography>
        </Grid>
        <Grid item className="js-trade-idea-liquidity-rating">
          <Typography variant="captionSmall">
            <strong>{getLiquidityWords(false)}</strong>
          </Typography>
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs={7} className="js-trade-idea-liquidity-rating">
          <Typography variant={'captionSmall'}>{t('app.php.why.titles.Liquidity')}</Typography>
        </Grid>
        <Grid item xs={2.5} className="js-trade-idea-liquidity-rating">
          {renderRating()}
        </Grid>
        <Grid item xs={2.5} pl={1} className="js-trade-idea-liquidity-rating">
          <Typography variant={'captionSmall'}>{getLiquidityWords(true)}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <Grid container justifyContent="space-between">
      {renderLiquidity()}
    </Grid>
  );
};
