import { BillingTransactions, ChangePasswordResponse, IChangePasswordResponseProps } from '../models';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { ApiService } from './api-service';
import { BaseApiService } from './base-api-service';

export class PaymentsService extends BaseApiService {
  private static _instance: PaymentsService;

  private constructor() {
    super('/payments');
    ApiService.instance.init(PaymentsService.onError, PaymentsService.onLoading);
  }

  public static get instance(): PaymentsService {
    if (PaymentsService._instance == null) {
      PaymentsService._instance = new PaymentsService();
    }
    return PaymentsService._instance;
  }

  private static onError = (errorCode: number): void => {
    console.error('Error in Profile-details-service: ', errorCode);
  };

  private static onLoading = (isLoading: boolean): void => {};

  public billingTransactions = async (): Promise<ResponseViewModel<BillingTransactions>> => {
    const response = await ApiService.instance.get<BillingTransactions>(this.getUrlPath('getTransactions'));
    const vm = new ResponseViewModel<BillingTransactions>();
    vm.data = response.data as unknown as BillingTransactions;
    return vm;
  };

  public saveImage = async (request: any) => {
    const url = 'sharing/save';
    const response = await ApiService.instance.post<any, string>(url, request);
    return response;
  };

  public canUseEmail = async (request: string) => {
    const url = `/authentication/canUseEmail`;
    const response = await ApiService.instance.post<string, boolean>(url, request);
    return response;
  };

  public sendEmailMessage = async (request: any): Promise<ChangePasswordResponse | null> => {
    const url = 'sharing/email';
    const response = await ApiService.instance.post<any, ChangePasswordResponse>(url, request);
    const editReponse = ChangePasswordResponse.fromJson(response as unknown as IChangePasswordResponseProps);
    if (response.errors) {
      return null;
    }
    return editReponse;
  };
}
