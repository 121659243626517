interface ICacheItem<T extends { symbol: string }> {
  key: string;
  item: T;
  expiry: Date;
}

//TODO: Readonly items in map. (*) expiration code
//TODO: Replace this with `https://github.com/isaacs/node-lru-cache`.
export class CacheService<T extends { symbol: string }> {
  private expiryDuration = 10 * 60 * 1000; // minutes * seconds * milliseconds. (10 mins)
  private map = new Map<string, ICacheItem<T>>();
  private callback: ((keys: string[]) => Promise<T[] | undefined>) | undefined;
  private name = '';

  private constructor() {}

  static fromCallback = <T extends { symbol: string }>(
    name: string,
    callback: (keys: string[]) => Promise<T[] | undefined>,
  ) => {
    const model = new CacheService<T>();
    model.name = name;
    model.map = new Map<string, ICacheItem<T>>();
    model.callback = callback;
    return model;
  };

  get readFromCallback() {
    if (!this.callback) {
      throw new Error('callback is undefined');
    }
    return this.callback;
  }

  get = async (keys: string[]) => {
    let items: ICacheItem<T>[] = [];
    if (keys.length === 0) {
      return [];
    }
    const symbolKeys = keys.map((k) => {
      return { symbol: k, isExists: false };
    });
    for (let key of symbolKeys) {
      const item = this.map.get(key.symbol);
      if (!item) {
        continue;
      }
      if (new Date().getTime() - item.expiry.getTime() > this.expiryDuration) {
        this.map.delete(key.symbol);        
        continue;
      }
      key.isExists = true;
      items.push(item);
    }
    const keysToFetch = symbolKeys.filter((k) => !k.isExists).map((k) => k.symbol);
    const fetchedItems = await this.getFromServer(keysToFetch);
    if (keysToFetch.length === 0) console.log('ALL FETCHED FROM CACHE');
    items = items.concat(fetchedItems);
    return items.filter((q) => q !== undefined).map((i) => i.item);
  };

  set = (items: T[]) => {
    for (let item of items) {
      const cacheItem: ICacheItem<T> = {
        item: item,
        key: item.symbol,
        expiry: new Date(),
      };
      this.map.set(item.symbol, cacheItem);
    }
  };

  getFromServer = async (keys: string[]) => {
    if (keys.length === 0) {
      return [];
    }
    const fetchedItems = await this.readFromCallback(keys);
    if (!fetchedItems || fetchedItems.length === 0) {
      return [];
    }   
    const items = [];
    for (let fi of fetchedItems) {
      const item = {
        item: fi,
        key: fi.symbol,
        expiry: new Date(),
      };
      this.map.set(fi.symbol, item);
      items.push(item);
    }
    return items;
  };
}
