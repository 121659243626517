import { Button, Grid, Link, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import LocalizationContext from '../../react-i18next/localization-context';

interface ISubscriptionErrorWidgetProps {
  onClickTryAnotherCard: () => void;
}

export const SubscriptionErrorWidget: React.FC<ISubscriptionErrorWidgetProps> = ({
  onClickTryAnotherCard,
}: ISubscriptionErrorWidgetProps) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();

  return (
    <Grid container p={2} justifyContent={'center'} rowSpacing={0.25}>
      <Grid item xs={12}>
        <Typography variant="body1" component="span" color={theme.palette.error.main}>
          {t('app.php.profile.payments.text.yourPaymentHasbeenDeclined')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="span" color={theme.palette.error.main}>
          {t('app.php.profile.payments.text.pleaseCheckTheInformationYouHaveEntered')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="span">
          If you continue to have issues with changing your card, please
          <Link href="mailto:support@optionsplay.com"> Contact Us</Link>
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent={'flex-end'}>
        <Button variant="contained" color="primary" onClick={onClickTryAnotherCard}>
          <Typography variant="button"> {t('app.php.profile.payments.buttons.tryAnotherCard')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
