import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Grid, IconButton, useTheme } from '@mui/material';
import { reportSearchState } from '@op/shared/src/states';
import React, { ReactNode, useState } from 'react';
import { useRecoilState } from 'recoil';
import { IconComponent, SortingWidget } from '../common';
import { OPIconButton, OptionsPlayTextField, OrangeButton } from '../styled';
import { Sorts } from '@op/shared/src/models';

interface IIdeaFilterProps {
  onSort?: (sorts: Sorts) => void;
  selectedSorts?: Sorts;
  defaultSortOptions?: Sorts;
  Filters?: ReactNode;
}

export const IdeaSecondRow: React.FC<IIdeaFilterProps> = ({ onSort, defaultSortOptions, selectedSorts, Filters }) => {
  const theme = useTheme();
  const [textSearchFieldInput, setTextSearchFieldInput] = useRecoilState(reportSearchState);
  const [showSearch, setShowSearch] = useState(false);

  const resetSearchValues = () => {
    setShowSearch(false);
    setTextSearchFieldInput('');
  };

  const renderSearchIcon = () => {
    if (showSearch) {
      return (
        <OrangeButton isSelected={showSearch} onClick={() => resetSearchValues()}>
          <IconComponent name={'searchIcon'} stroke={theme.palette.info.light} />
        </OrangeButton>
      );
    }
    return (
      <OPIconButton
        onClick={() => {
          setShowSearch(true);
        }}>
        <IconComponent name={'searchIcon'} stroke={theme.palette.info.light} />
      </OPIconButton>
    );
  };

  const renderSecondRow = () => {
    if (showSearch) {
      return (
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs={10}>
            <OptionsPlayTextField
              variant="outlined"
              onChange={(e) => {
                setTextSearchFieldInput(e.target.value.toUpperCase());
              }}
              placeholder="Search Symbol and Company Name"
              name="searchname"
              value={textSearchFieldInput}
              style={{ width: '100%' }}
              InputProps={
                textSearchFieldInput !== '' && {
                  endAdornment: (
                    <IconButton
                      onClick={() => setTextSearchFieldInput('')}
                      sx={{ '&.MuiIconButton-root': { padding: 0 } }}>
                      <ClearOutlinedIcon fontSize={'small'} sx={{ color: theme.palette.selectAndTextField.main }} />
                    </IconButton>
                  ),
                }
              }
            />
          </Grid>
          <Grid item container xs="auto" justifyContent="space-between">
            {renderSearchIcon()}
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} container justifyContent={'space-between'}>
        <Grid item xs={8}>
          <SortingWidget onSort={onSort} selectedSorts={selectedSorts} defaultSortOptions={defaultSortOptions} />
        </Grid>
        <Grid item xs={'auto'}>
          {Filters ?? <></>}
        </Grid>
        <Grid item xs={'auto'}>
          {renderSearchIcon()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12}>
      {renderSecondRow()}
    </Grid>
  );
};
