import { atom } from 'recoil';
import { Scan, Sector } from '@op/shared/src/models';

// export const modalState = atom({
//   key: 'modalState',
//   default: false,
// });

export const sectorSelectedState = atom<Sector>({
  key: 'sectorSelectedState',
  default: new Sector(),
});

export const scanSelectedState = atom<Scan>({
  key: 'scanSelectedState',
  default: new Scan(),
});

export const sentimentSelectedState = atom({
  key: 'sentimentSelectedState',
  default: '',
});

export const opSelectorMenuItemState = atom({
  key: 'opSelectorMenuItemState',
  default: [''],
});
