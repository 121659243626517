import { Grid, Typography } from '@mui/material';
import { ICreditSpreadsProps } from '../pager';
import { OPBoldTypograpghy } from '../../styled';
import React from 'react';
import LocalizationContext from '../../react-i18next/localization-context';

type Props = {
  data: ICreditSpreadsProps;
};
export const BuySellStrikeWidget: React.FC<Props> = ({ data }: Props) => {
  const { t } = React.useContext(LocalizationContext);

  const renderBuyStrike = () => {
    return (
      <>
        <Typography variant="caption" pr={0.5}>
          {t('app.php.what.tradeIdeas.ideas.buyStrike')}
        </Typography>
        <OPBoldTypograpghy variant="caption">{(data as ICreditSpreadsProps).buyStrike}</OPBoldTypograpghy>
      </>
    );
  };

  const renderSellStrike = () => {
    return (
      <>
        <Typography variant="caption" pr={0.5}>
          {t('app.php.what.tradeIdeas.ideas.sellStrike')}
        </Typography>
        <OPBoldTypograpghy variant="caption">{(data as ICreditSpreadsProps).sellStrike}</OPBoldTypograpghy>
      </>
    );
  };

  const renderBuySellStrikeView = () => {
    if (Number((data as ICreditSpreadsProps).sellStrike) < Number((data as ICreditSpreadsProps).buyStrike)) {
      return (
        <Grid container pr={1}>
          <Grid item xs={6}>
            {renderSellStrike()}
          </Grid>
          <Grid item xs={6} textAlign="right">
            {renderBuyStrike()}
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container pr={1}>
        <Grid item xs={6}>
          {renderBuyStrike()}
        </Grid>
        <Grid item xs={6} textAlign="right">
          {renderSellStrike()}
        </Grid>
      </Grid>
    );
  };
  return renderBuySellStrikeView();
};
