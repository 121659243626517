import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import { PowerhouseProService } from '@op/shared/src/services';
import { configurationState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';

import { ProfileCardWidget } from '../common';
import {
  OptionsPlayCurrencyNonNegativeFormatInput,
  OptionsPlayPercentageFormatInput,
  OptionsPlayTextField,
} from '../styled';

export const RiskInvestmentSettingsWidget: React.FC = () => {
  const [configuration, setConfiguration] = useRecoilState(configurationState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [amount, setAmount] = useState<number | undefined>();
  const [amountInputValue, setAmountInputValue] = useState('');
  const [percentage, setPercentage] = useState<number | undefined>();
  const [percentageInputValue, setPercentageInputValue] = useState('');
  const { t } = useContext(LocalizationContext);

  useEffect(() => {
    if (
      !configuration ||
      !configuration.applicationConfiguration ||
      !configuration.applicationConfiguration.riskAndInvestment
    ) {
      return;
    }
    const totalAmount = configuration.applicationConfiguration.riskAndInvestment.riskAndInvestmentAmount;
    const percentage = configuration.applicationConfiguration.riskAndInvestment.riskAndInvestmentPercentage;
    setAmount(totalAmount);
    setAmountInputValue(totalAmount === 0 ? '' : totalAmount.toString());
    setPercentage(percentage);
    setPercentageInputValue(percentage === 0 ? '' : percentage.toString());
  }, [configuration]);

  if (
    !configuration ||
    !configuration.applicationConfiguration ||
    !configuration.applicationConfiguration.riskAndInvestment
  ) {
    return null;
  }

  const onChangePer = (event: any) => {
    setPercentageInputValue(event.values.value);
    setPercentage(event.values.floatValue);
  };

  const onChangeAmount = (event: any): void => {
    const amount = event.values.floatValue;
    setAmountInputValue(event.values.value);
    setAmount(amount);
  };

  const onClickSave = async () => {
    if (!amount || !percentage) {
      setNotifications([{ type: 'error', content: `Fields cannot be empty / zero` }]);
      return;
    }
    const clonedConfiguration = cloneDeep(configuration);
    clonedConfiguration.applicationConfiguration.riskAndInvestment.riskAndInvestmentAmount = amount;
    clonedConfiguration.applicationConfiguration.riskAndInvestment.riskAndInvestmentPercentage = percentage;
    const data = [
      {
        Keys: ['riskAndInvestment', 'riskAndInvestmentAmount'],
        Value: amount,
      },
      {
        Keys: ['riskAndInvestment', 'riskAndInvestmentPercentage'],
        Value: percentage,
      },
    ];
    const saveConfigResponse = await PowerhouseProService.instance.SaveConfigurations(data);
    if (saveConfigResponse.hasErrors) {
      setNotifications([{ type: 'error', content: `Risk and Investment settings not saved.` }]);
      return;
    }
    setConfiguration(clonedConfiguration);
    setNotifications([{ type: 'success', content: `Risk and Investment settings saved.` }]);
  };

  const renderContent = () => {
    return (
      <>
        <Grid container alignItems={'center'} px={1}>
          <Typography variant="body1" alignItems="center" sx={{ pt: 0, pb: 2 }}>
            {t('app.php.profile.incomeSettings.js.preventAccountBlowupsAndDrawdowns')}{' '}
            <Link color="primary" href={'https://www.youtube.com/watch?v=Ct42yUtEu24'} target="_blank" rel="noreferrer">
              {'Portfolio Management webinar recording.'}
            </Link>
          </Typography>
        </Grid>
        <Grid container alignItems={'center'} columnSpacing={0.5}>
          <Grid item xs="auto">
            <Typography variant="body1" px={1}>
              Risk no more than
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <OptionsPlayTextField
              variant="outlined"
              value={percentageInputValue}
              placeholder={'(input %)'}
              onChange={(event: any) => onChangePer(event)}
              InputProps={{
                inputComponent: OptionsPlayPercentageFormatInput as any,
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Typography variant="body1" px={1}>
              of my
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <OptionsPlayTextField
              variant="outlined"
              value={amountInputValue}
              placeholder={'(input $)'}
              onChange={(event: any) => onChangeAmount(event)}
              fullWidth
              InputProps={{
                inputComponent: OptionsPlayCurrencyNonNegativeFormatInput as any,
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Typography variant="body1" px={1}>
              portfolio on each trade.
            </Typography>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" pt={2} pr={1}>
          <Button id="pw-cancel" size="medium" color="primary" variant={'contained'} onClick={onClickSave}>
            <Typography variant="button">{t('app.php.profile.incomeSettings.buttons.saveAsDefault')}</Typography>
          </Button>
        </Box>
      </>
    );
  };
  return <ProfileCardWidget title={t('app.php.profile.menu.titles.portfolioRiskSettings')} content={renderContent()} />;
};
