import { Theme, useTheme } from '@mui/material';
import { SentimentType } from '@op/shared/src/models/what/sentiment-type';
import React, { FC, ReactNode } from 'react';

export interface IRedGreenWidgetProps {
  children: ReactNode;
  value: number | string | undefined;
  style?: any;
}

const useStyles = (theme: Theme) => {
  return {
    green: {
      color: theme.palette.success.main,
      textTransform: 'capitalize',
      display: 'inline',
      fontSize: 'inherit',
    },
    red: {
      color: theme.palette.error.main,
      textTransform: 'capitalize',
      display: 'inline',
      fontSize: 'inherit',
    },
    orange: {
      color: theme.palette.warning.main,
      textTransform: 'capitalize',
      display: 'inline',
      fontSize: 'inherit',
    },
    black: {
      color: theme.palette.text.primary,
      textTransform: 'capitalize',
      display: 'inline',
      fontSize: 'inherit',
    },
  };
};

export const RedGreenWidget: FC<IRedGreenWidgetProps> = ({ value, children, style }: IRedGreenWidgetProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  let redGreenCssClass: any;
  let trandingCssClass: any;
  let newValue = typeof value;
  if (newValue !== 'string') {
    if (value && (value as number) > 0) {
      redGreenCssClass = classes.green;
    } else if (value && (value as number) < 0) {
      redGreenCssClass = classes.red;
    } else {
      redGreenCssClass = {};
    }
    return <span style={redGreenCssClass}>{children}</span>;
  } else {
    if (value && value.toString().trim().toUpperCase().includes(SentimentType.BULLISH.toString())) {
      trandingCssClass = classes.green;
    } else if (value && value.toString().trim().toUpperCase().includes(SentimentType.BEARISH.toString())) {
      trandingCssClass = classes.red;
    } else if (value && value.toString().trim().toUpperCase().includes(SentimentType.NEUTRAL.toString())) {
      trandingCssClass = classes.orange;
    } else {
      trandingCssClass = classes.black;
    }

    return <span style={trandingCssClass}>{children}</span>;
  }
};
