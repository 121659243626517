import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { SubscriptionPaymentForms } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { changeSubscriptionInformation, getPaymentPlans } from '@op/shared/src/services';
import {
  CardPaymentTypeState,
  SubscriptionPaymentFormsState,
  currentSubscriptionScreenState,
  currentSubscriptionState,
  selectedSymbolState,
} from '@op/shared/src/states';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../../styled';
import { EditCardInfoWidget } from './edit-card-info-widget';
import { SubscriptionErrorWidget } from './subscription-error-widget';
import { SubscriptionHeaderWidget } from './subscription-footer-widget';
import { SubscriptionCardInfoSkeleton } from './subscription-skeleton-widget';
import { BillingInfo, CardInfo } from './validation';

export const ChangeSubscriptionInformationWidget: React.FC = () => {
  const [CurrentSubscriptionData, setCurrentSubscriptionData] = useRecoilState(currentSubscriptionState);
  const [cardData, setCardData] = useRecoilState(SubscriptionPaymentFormsState);
  const cardType = useRecoilValue(CardPaymentTypeState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setCurrentSubscriptionScreen = useSetRecoilState(currentSubscriptionScreenState);
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme();
  const [errors, setErrors] = useState(new SubscriptionPaymentForms());
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (!CurrentSubscriptionData) {
    return null;
  }

  const handleBack = () => {
    setCurrentSubscriptionScreen('currentsubscription');
    logActivity('BUTTON', 'paymentsUpdateCreditCardBackButton');
  };

  const handleSubmit = () => {
    const errors = CardInfo(cardData, cardType);
    const billingErrors = BillingInfo(cardData);
    setErrors({ ...errors, ...billingErrors });
    if (Object.keys(errors).length > 0 || Object.keys(billingErrors).length > 0) {
      return;
    }
    handleNext();
  };

  const handleNext = async () => {
    setIsLoading(true);
    const data = {
      billingAddress: {
        address: cardData.address,
        city: cardData.city,
        state: cardData.state,
        postalCode: cardData.postalCode,
        country: cardData.country,
        phone: cardData.phone,
      },
      creditCard: {
        cardHolder: cardData.cardName,
        cardNumber: cardData.cardNumber,
        cardSecurityCode: cardData.cardCvv,
        expirationMonth: cardData.cardMonth,
        expirationYear: cardData.cardYear,
      },
      plan: cardData.plan,
      pricingModelCode: cardData.pricingModelCode,
    };
    const response = await changeSubscriptionInformation(data);
    if (response.hasErrors) {
      setIsError(true);
      setIsLoading(false);
      setCardData({ ...new SubscriptionPaymentForms(), plan: cardData.plan });
      return;
    }
    const getPaymentResponse = await getPaymentPlans();
    if (getPaymentResponse.hasErrors) {
      return;
    }
    setCurrentSubscriptionData(getPaymentResponse.data);
    setIsLoading(false);
    setCardData({ ...new SubscriptionPaymentForms(), plan: cardData.plan });
    setCurrentSubscriptionScreen('currentsubscription');
    logActivity('BUTTON', 'paymentsUpdateCreditCardNextButton');
  };

  const handleCancel = () => {
    setCurrentSubscriptionScreen('currentsubscription');
    logActivity('BUTTON', 'paymentsUpdateCreditCardBackButton');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  if (isLoading) {
    return <SubscriptionCardInfoSkeleton />;
  }

  const renderContent = () => {
    if (isError) {
      return <SubscriptionErrorWidget onClickTryAnotherCard={() => setIsError(false)} />;
    }
    return (
      <>
        <Grid item container xs={10} justifyContent="center" rowSpacing={1}>
          <Grid item xs={12}>
            <EditCardInfoWidget errors={errors} setErrors={setErrors} />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end" p={2} columnSpacing={2}>
          <Grid item>
            <Button onClick={handleBack} sx={{ color: theme.palette.primary.light }} size="large" variant="outlined">
              <ArrowBackIosIcon fontSize="small" sx={{ color: theme.palette.primary.light }} />
              <Typography variant="button" sx={{ color: theme.palette.text.primary }}>
                {t('app.php.common.buttons.back')}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleCancel} size="large" variant="outlined" color="primary">
              <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                {t('app.php.common.buttons.cancel')}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" size="large" color="primary" onClick={handleSubmit}>
              <OPBoldTypograpghy variant="button"> {t('app.php.common.buttons.save')}</OPBoldTypograpghy>
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid container justifyContent="center" rowSpacing={1}>
      <Grid item xs={12} columnSpacing={4}>
        <SubscriptionHeaderWidget
          label={
            <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: 16, margin: 6 }}>
              {t('app.php.subscriptions.subscriptionManageCardsBillingAdd')}
            </OPBoldTypograpghy>
          }
        />
      </Grid>
      {renderContent()}
    </Grid>
  );
};
