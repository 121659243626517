import { Button, Grid, Typography, useTheme } from '@mui/material';
import ChechIcon from '@op/shared/assets/images/checkIcon.svg';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import {
  currentSubscriptionScreenState,
  currentSubscriptionState,
  isSubscriptionRenewedState,
  selectedSymbolState,
} from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CreditCardTypeWidget, SubscriptionCardDetails, SubscriptionErrorWidget } from '.';
import { IconComponent } from '../../common';
import { LanguageSelectorWidget } from '../../header';
import LocalizationContext from '../../react-i18next/localization-context';
import { OPBoldTypograpghy, OPLightTypograpghy, OPSemiBoldTypograpghy, OptionsPlayDivider } from '../../styled';
import { SubscriptionCardLayout } from './subscription-card-layout';
import { SubscriptionScannerFooter } from './subscription-footer-widget';

interface ISubscriptionFinalStepProps {
  isError: boolean;
  onClickTryAnotherCard: () => void;
}

export const SubscriptionFinalStep: React.FC<ISubscriptionFinalStepProps> = ({
  isError,
  onClickTryAnotherCard,
}: ISubscriptionFinalStepProps) => {
  const theme = useTheme();
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setCurrentSubscriptionScreen = useSetRecoilState(currentSubscriptionScreenState);
  const CurrentSubscriptionData = useRecoilValue(currentSubscriptionState);
  const plan = CurrentSubscriptionData.currentSubscription?.billingModel;
  const startDate = CurrentSubscriptionData.currentSubscription?.startDate;
  const endDate = CurrentSubscriptionData.currentSubscription?.expirationDate;
  const paymentDate = CurrentSubscriptionData.currentSubscription?.createdDate;
  const { t } = React.useContext(LocalizationContext);
  const isSubscriptionRenewed = useRecoilValue(isSubscriptionRenewedState);

  const backToApp = () => {
    window.location.href = window.location.origin;
    return false;
  };

  useEffect(() => {
    if (!isSubscriptionRenewed) {
      return;
    }
    window.onpopstate = (e) => {
      backToApp();
    };
  }, [isSubscriptionRenewed]);

  const seeMySubscription = () => {
    logActivity('BUTTON', 'paymentsSeeMySubscriptionButton');
    setCurrentSubscriptionScreen('currentsubscription');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const getDiscountPrice = (type: string) => {
    if (CurrentSubscriptionData.currentPricingModel) {
      if (type === 'Monthly') {
        return CurrentSubscriptionData.currentPricingModel?.userMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.currentPricingModel?.userAnnualPrice.toFixed(2);
    }
    if (
      CurrentSubscriptionData &&
      CurrentSubscriptionData.pricingModels &&
      CurrentSubscriptionData.pricingModels.length > 0
    ) {
      if (type === 'Monthly') {
        return CurrentSubscriptionData.pricingModels[0].userMonthlyPrice.toFixed(2);
      }
      return CurrentSubscriptionData.pricingModels[0].userAnnualPrice.toFixed(2);
    }
  };

  const billSummary = () => {
    const totalPayment = getDiscountPrice(plan);
    return (
      <Grid container rowSpacing={1}>
        <SubscriptionCardDetails label={t('app.php.subscriptions.typeOfPlan')} value={plan} />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.region')}
          value={<LanguageSelectorWidget iconView={'subscription'} />}
        />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.startDate')}
          value={DateTimeHelper.format(startDate)}
        />
        <SubscriptionCardDetails label={t('app.php.subscriptions.endDate')} value={DateTimeHelper.format(endDate)} />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.paymentDate')}
          value={DateTimeHelper.format(paymentDate)}
        />
        <SubscriptionCardDetails
          label={t('app.php.subscriptions.cardPay')}
          value={
            <CreditCardTypeWidget
              cardNumber={CurrentSubscriptionData.currentSubscription?.billingInfo?.cardNumber}
              textView={'onlyNumber'}
            />
          }
        />
        <Grid item xs={12}>
          <OptionsPlayDivider />
        </Grid>
        <Grid item container justifyContent="space-between" alignItems={'center'}>
          <Grid item xs={6}>
            <OPBoldTypograpghy
              style={{ color: theme.palette.mode === 'light' ? theme.palette.grey[600] : '#999999', fontSize: '14px' }}>
              {t('app.php.subscriptions.totalPayment')}
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <OPBoldTypograpghy
              style={{ color: theme.palette.mode === 'light' ? theme.palette.grey[600] : '#999999', fontSize: '14px' }}>
              {NumberFormatHelper.toCurrency(totalPayment)}
            </OPBoldTypograpghy>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (isError) {
      return <SubscriptionErrorWidget onClickTryAnotherCard={onClickTryAnotherCard} />;
    }
    return (
      <Grid container p={1} justifyContent={'center'} rowSpacing={2}>
        <Grid item container xs={10} rowSpacing={1} justifyContent={'center'}>
          <Grid item container xs={12} rowSpacing={0.5} textAlign={'center'}>
            <Grid item xs={12}>
              <IconComponent name={theme.palette.mode === 'light' ? 'congratulation' : 'congratulationDark'} />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <OPSemiBoldTypograpghy
                style={{ color: theme.palette.primary.main, fontSize: '18px', fontWeight: 600, marginRight: '4px' }}
                variant="subtitle1"
                component="span">
                {`${t('app.php.subscriptions.your')} ${plan} ${t('app.php.subscriptions.subscriptionHasBeen')}`}
              </OPSemiBoldTypograpghy>
              <OPSemiBoldTypograpghy
                variant="subtitle1"
                style={{ color: theme.palette.success.main, fontSize: '18px', fontWeight: 600 }}>
                {t('app.php.subscriptions.successfullyActivated')}
              </OPSemiBoldTypograpghy>
            </Grid>
            <Grid item xs={12}>
              <OPLightTypograpghy
                variant="subtitle1"
                style={{ color: theme.palette.mode === 'light' ? theme.palette.grey[500] : '#fff', fontSize: '14px' }}
                component="span">
                {t('app.php.subscriptions.gainConfidenceOverInvestments')}
              </OPLightTypograpghy>
            </Grid>
          </Grid>
          <Grid item container xs={8} rowSpacing={1}>
            <Grid item xs={12}>
              <SubscriptionCardLayout
                title={
                  <Grid item container xs={12} justifyContent="space-between">
                    <OPBoldTypograpghy variant="subtitle1" sx={{ fontSize: '14px' }}>
                      {`${t('app.php.subscriptions.activePlan')} (${t('app.php.subscriptions.subscription')})`}
                    </OPBoldTypograpghy>
                    <img src={ChechIcon} alt="" />
                  </Grid>
                }
                content={billSummary()}
              />
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Typography
                style={{
                  fontSize: '10px',
                  fontWeight: 400,
                  color: theme.palette.mode === 'light' ? theme.palette.grey[500] : '#fff',
                }}
                variant="body2"
                component="span">
                {`${t('app.php.subscriptions.noteToKnowAutomaticRenew')} ${DateTimeHelper.format(endDate)}. ${t(
                  'app.php.subscriptions.ensuresUninterreptedAccess',
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={5}>
                <Button
                  onClick={backToApp}
                  id="pw-subscribe"
                  color="primary"
                  size="large"
                  variant="contained"
                  fullWidth>
                  <OPBoldTypograpghy variant="button">
                    {t('app.php.profile.payments.buttons.goToApp')}
                  </OPBoldTypograpghy>
                </Button>
              </Grid>
              <Grid item xs={7}>
                <Button
                  fullWidth
                  onClick={seeMySubscription}
                  id="pw-subscribe"
                  color="primary"
                  size="large"
                  variant="contained"
                  sx={{ ml: 1 }}>
                  <OPBoldTypograpghy variant="button">
                    {t('app.php.profile.payments.buttons.seeMySubscription')}
                  </OPBoldTypograpghy>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <SubscriptionScannerFooter />
          <Typography
            style={{
              color: theme.palette.mode === 'light' ? theme.palette.grey[500] : '#fff',
              justifyContent: 'center',
              fontSize: '14px',
            }}>
            {t('app.php.subscriptions.receiptOnYourMail')}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return renderContent();
};
