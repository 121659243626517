import { Grid, Typography } from '@mui/material';
import { ISupportResistance, ISupport } from '@op/shared/src/models';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { whyDataState, selectedSymbolState, expandedQuoteAtomFamily, isDataLoadingState } from '@op/shared/src/states';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { GuideItem } from '../side-menu';

export const SupportAndResistanceWidget: React.FC = () => {
  const whyData = useRecoilValue(whyDataState);
  const [supportAndResistanceData, setSupportAndResistanceData] = useState<
    { sup: { val: string; percent: string }; res: { val: string; percent: string } }[] | undefined
  >();
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const expandedQuote = useRecoilValue(expandedQuoteAtomFamily(selectedSymbol || ''));
  const isDataLoading = useRecoilValue(isDataLoadingState);
  const { t } = useContext(LocalizationContext);

  const convertSupportAndResistanceData = (supres: ISupportResistance, last: number) => {
    const sup = supres.support;
    const res = supres.resistance;
    const response = [];
    const buildSupResObject = (val: ISupport) => {
      let result;
      if (val && val.value) {
        const value = ((val.value - last) * 100) / last;
        const percent = NumberFormatHelper.toPercentage(value);
        result = { val: NumberFormatHelper.toCurrency(val.value), percent: '(' + percent + ')' };
      } else {
        result = { val: '', percent: '' };
      }
      return result;
    };
    for (let i = 0; i < 4; i++) {
      response.push({ sup: buildSupResObject(sup[i]), res: buildSupResObject(res[i]) });
    }
    return response;
  };
  useEffect(() => {
    if (!whyData || !expandedQuote || whyData.supportAndResistance == null) {
      return;
    }
    const supportAndResistanceData = convertSupportAndResistanceData(whyData.supportAndResistance, expandedQuote.last);
    setSupportAndResistanceData(supportAndResistanceData.slice(0, 2));
  }, [whyData, expandedQuote]);

  if (!supportAndResistanceData || supportAndResistanceData.length === 0 || isDataLoading) {
    return null;
  }

  return (
    <Grid container justifyContent="center" columnSpacing={2}>
      <Grid item xs>
        <div style={{ paddingLeft: '22%' }}><GuideItem selector=".supportAndResistance_helpPinPlaceholder" /></div>
        <Typography variant="subtitle1" fontWeight="bold" textAlign="right" lineHeight={1.5} color="success.main">
          {t('app.php.why.labels.support')}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        {supportAndResistanceData.map((item, index) => {
          return (
            <div key={index}>
              <Typography variant="body1" component="span" color="success.main">
                {item.sup.val}
              </Typography>
              <Typography variant="body1" component="span" pl={1} color="success.main">
                {item.sup.percent}
              </Typography>
            </div>
          );
        })}
      </Grid>
      <Grid item xs="auto">
        {supportAndResistanceData.map((item, index) => {
          return (
            <div key={index}>
              <Typography variant="body1" component="span" color="error.main">
                {item.res.val}
              </Typography>
              <Typography variant="body1" component="span" pl={1} color="error.main">
                {item.res.percent}
              </Typography>
            </div>
          );
        })}
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle1" fontWeight="bold" lineHeight={1.5} color="error.main">
          {t('app.php.why.labels.resistance')}
        </Typography>
      </Grid>
    </Grid>
  );
};
