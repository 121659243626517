export interface ISaveFiltersResponse {
  errorCode: string;
  formattedMessage: string;
  isSuccess: boolean;
}

export class SaveFiltersResponse implements ISaveFiltersResponse {
  errorCode = '';
  formattedMessage = '';
  isSuccess = true;

  public static fromJson = (data: SaveFiltersResponse): SaveFiltersResponse => {
    const model = new SaveFiltersResponse();
    model.errorCode = data.errorCode;
    model.formattedMessage = data.formattedMessage;
    model.isSuccess = data.isSuccess;
    return model;
  };
}
