import { atom } from 'recoil';

export interface INotification {
  type: 'success' | 'info' | 'error' | 'warning';
  content: string;
}

export const notificationsState = atom< INotification[] | undefined>({
  key: 'notificationStatesKey',
  default: undefined,
});
